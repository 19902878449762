import TagList from "../Common/TagList";
import { FC, useEffect, useMemo, useState } from "react";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { Trans, useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import TagSelect from "../../components/Common/TagSelect";
import ActionBar from "../../components/Common/ActionBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdClose, MdOutlineHistory } from "react-icons/md";
import PlusButton from "../../components/Common/PlusButton";
import ToolIcon from "../../components/Common/icons/ToolIcon";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import BaseModal from "../../components/Common/alerts/BaseModal";
import WorkerIcon from "../../components/Common/icons/WorkerIcon";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import MachineIcon from "../../components/Common/icons/MachineIcon";
import VehicleIcon from "../../components/Common/icons/VehicleIcon";
import ChemicalIcon from "../../components/Common/icons/ChemicalIcon";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import SupplierStatus from "../../components/Views/Sites/SupplierStatus";
import DownloadAllButton from "../../components/Common/DownloadAllButton";
import SiteSupplierResourcesViewModal from "./SiteSupplierResourcesViewModal";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import { DownloadFileScope } from "../../../domain/repositories/documentsDownloader";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import AddDocumentTypesModal from "../../components/Views/common/AddDocumentTypesModal";
import SiteResourceDocuments, {
  ResourceDocumentsPermissions,
} from "../../components/Views/common/SiteResourceDocuments";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SupplierEvaluationState } from "../../../domain/entities/supplierEvaluationState.enum";
import { useSiteSupplierDetailViewModel } from "../../hooks/Site/useSiteSupplierDetailViewModel";
import DocumentCommunicationModal from "../../components/Views/common/DocumentCommunicationModal";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import ResourceEvaluationToggle, {
  EvaluationButton,
} from "../../components/Common/ResourceEvaluationToggle";
import {
  useDocumentsFilesDownloader,
  useSupplierOrSiteFilesDownloader,
} from "../../hooks/Document/useDocumentsFilesDownloader";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import RejectionSummary from "../../components/Views/Documents/RejectionSummary";
import DOMPurify from "dompurify";
import ContactField from "../../components/Common/table/ContactField";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import AiButton from "../../components/Common/AiButton";
import { AiTaskStatus } from "../../../domain/entities/aiTask";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import { WarningBanner } from "../../components/Common/alerts/WarningBanner";
import useSiteDetailsViewModel from "../../hooks/Site/useSiteDetailsViewModel";

interface Props {
  fromList: boolean;
  permissions: SiteSupplierDetailPermissions;
  isWorkingSite?: boolean;
  isSubcontract?: boolean;
  deltaWidth?: string;
  aiTaskCompanySectionCode?: AiTaskSectionCode;
  aiTaskSiteSectionCode?: AiTaskSectionCode;
}

type SiteSupplierDetailPermissions = {
  downloadDocuments: Permission;
  showGlobalEvaluation: Permission;
  setGlobalEvaluation: Permission;
  viewRequirements: Permission;
  addRequirement: Permission;
  editTags: Permission;
  setStatus: Permission;
  showResources: Permission;
  documents: ResourceDocumentsPermissions;
  manageDocumentWithAi: Permission;
};

const SiteSupplierDetailView: FC<Props> = ({
  fromList,
  permissions,
  isWorkingSite = false,
  isSubcontract,
  deltaWidth,
  aiTaskCompanySectionCode,
  aiTaskSiteSectionCode,
}) => {
  const { siteId } = useParams();
  const { supplierId } = useParams();
  const { t } = useTranslation("supplier");
  const navigate = useNavigate();
  const location = useLocation();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const [showResources, setShowResources] = useState<boolean>(false);
  const [selectedRequirement, setSelectedRequirement] = useState<string>();
  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");
  const [selectedSubject, setSelectedSubject] = useState<RequirementSubject>();
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showNeedsRequirementsModal, setShowNeedsRequirementsModal] =
    useState<boolean>(false);
  const [
    showConfirmEvaluateResourceModal,
    setShowConfirmEvaluateResourceModal,
  ] = useState<false | SupplierEvaluationState>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [showWarningBanner, setShowWarningBanner] = useState(true);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const variantTranslation = t("showUpdateTagsModal", { ns: "siteResources" });
  const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);
  const [showUpdateVariantsModal, setShowUpdateVariantsModal] = useState<
    false | string
  >(false);
  const [showManageCompanyTaskWithAI, setShowManageCompanyTaskWithAI] =
    useState<boolean>(false);
  const [showManageSiteTaskWithAI, setShowManageSiteTaskWithAI] =
    useState<boolean>(false);
  const [addRequirementsIsLoading, setAddRequirementsIsLoading] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(
    location.state?.fromPrevUrl === "site" ? 1 : 0
  );

  const {
    siteResource,
    siteResourceRefetch,
    variants,
    siteSupplierResources,
    siteSupplierResourcesHasNextPage,
    siteSupplierResourcesFetchNextPage,
    siteSupplierResourcesFetching,
    setSiteSupplierResourcesSort,
    siteSupplierResourcesSort,
    filterSiteSupplierResources,
    updateFilterSiteSupplierResources,
    evaluateSiteResource,
    updateSiteResourceVariant,

    siteResourceDocumentsRejected,
    siteResourceDocuments,
    siteResourceDocumentsIsLoading,
    siteResourceDocumentsFetchNextPage,
    siteResourceDocumentsHasNextPage,
    siteResourceDocumentsRefetch,

    siteResourceSiteDocumentsRejected,
    siteResourceSiteDocuments,
    siteResourceSiteDocumentsIsLoading,
    siteResourceSiteDocumentsFetchNextPage,
    siteResourceSiteDocumentsHasNextPage,
    siteResourceSiteDocumentsRefetch,

    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    siteResourceEvaluationsHasNextPage,
    siteResourceEvaluationsFetchNextPage,
    setEnableGetEvaluationsHistory,
    evaluateDocument,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,

    sortCompanyDocuments,
    updateSortCompanyDocuments,
    updateFilterCompanyDocuments,
    filterCompanyDocuments,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,
    hookDocumentIdAndType,
    setHookDocumentIdAndType,
    documentEvaluations,
    documentEvaluationsFetching,
    addRequirementsToSiteResource,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    documentTypesProps,
    documentInfoProps,

    siteVariants,
    siteSpecializations,
    setSupplierDocumentType,
    setSupplierStatus,
    isCompanyDocumentsFetching,
    isSiteDocumentsFetching,
    autoEvaluateSiteResource,
    deleteRequirement,
    communicationProps,
    statusIsLoading,
    systemStaff,
    resourceType,
    setResourceType,
    documentAICompanyProps,
    documentAISiteProps,
    getRequirementTemplateUrl
  } = useSiteSupplierDetailViewModel(
    siteId,
    supplierId,
    isWorkingSite,
    aiTaskCompanySectionCode,
    aiTaskSiteSectionCode,
    undefined,
    supplierId ? true : false
    );

  const { hasPermissions } = useAuth();
  const hasCsePermission = useHasPermissions(Permission.Sites_CseShowSites, PermissionCheck.All);

  const siteHook = useSiteDetailsViewModel(siteId);

  const canManageFileWithAI = !hasCsePermission && hasPermissions(
    [
      Permission.Sites_Manage,
      permissions.addRequirement
    ],
    PermissionCheck.All
  );

  const resourceColumns = ["name"];
  const resourceWorkerColumns = [
    "lastName",
    "firstName",
    "fiscalCode",
    "jobTitle",
  ];
  const resourceVehicleColumns = ["name", "plate"];

  const availableCompanyDocumentTypes = useMemo(() => {
    return documentTypesProps.documentTypes.filter((documentType) => {
      return !siteResourceDocuments.find(
        (document) => document.documentTypeId === documentType.id
      );
    });
  }, [documentTypesProps.documentTypes, siteResourceDocuments]);

  const availableSiteDocumentTypes = useMemo(() => {
    return documentTypesProps.documentTypes.filter((documentType) => {
      return !siteResourceSiteDocuments.find(
        (document) => document.documentTypeId === documentType.id
      );
    });
  }, [documentTypesProps.documentTypes, siteResourceSiteDocuments]);

  const handleAddRequirements = async (documentTypes) => {
    setAddRequirementsIsLoading(true);
    await addRequirementsToSiteResource(documentTypes);
    setShowAddRequirementModal(false);
    setAddRequirementsIsLoading(false);
  };

  const askConfirmDelete = (id: string, subject: RequirementSubject) => {
    setSelectedRequirement(id);
    setSelectedSubject(subject);
    setShowAskConfirmDelete(true);
  };

  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedRequirement(undefined);
  };

  const confirmDelete = async () => {
    setDeleteIsLoading(true);
    await deleteRequirement(
      selectedRequirement,
      selectedTab===0 ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE,
      supplierId);
    cancelConfirmDelete();
    setDeleteIsLoading(false);
  };

  const street = siteResource?.company?.address?.street;
  const cap = siteResource?.company?.address?.cap;
  const city = siteResource?.company?.address?.city;
  const addressString = [
    street && `${street}`,
    cap && `${cap}`,
    city && `${city}`,
  ]
    .filter(Boolean)
    .join(" - ");

  const detailAttributes = [
    "name",
    "fiscalCode",
    "vat",
    "email",
    "pec",
    "address",
    "phoneNumber",
  ].map((column) => ({
    label: t(column, { ns: "companies" }),
    value: column === "address" ? addressString : siteResource?.company[column],
  }));

  const contactAttributes = [
    "doctor",
    "rspp",
    "contact",
    "employer",
    "rls",
  ].map((role) => ({
    label: t(role, { ns: "companies" }),
    value: (
      <ContactField staff={systemStaff?.find((staff) => staff.role === role)} />
    ),
  }));

  const onTabSelect = (t) => {
    setSupplierDocumentType(t === 0 ? DocumentTypeCategory.SUPPLIER_COMPANY : DocumentTypeCategory.SUPPLIER_SITE);
    documentTypesProps.setHookDocumentCategory(t === 0 ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE);
    setSelectedTab(t);
  };

  const documentDownloaderCompany = useDocumentsFilesDownloader(
    siteId,
    supplierId,
    "company" as DownloadFileScope
  );

  const documentDownloaderSite = useDocumentsFilesDownloader(
    siteId,
    supplierId,
    "site" as DownloadFileScope
  );

  const toast = useToast();

  const canGloballyEvaluateSupplier = useHasPermissions(
    permissions.setGlobalEvaluation
  );
  const canEditRequirements = useHasPermissions(permissions.documents.edit);

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("tagSelect", {
      ns: "siteResources",
      tag: t("variant", { ns: "siteResources" }),
      color: COLORS.sikuroBlue,
    })
  );

  useEffect(() => {
    documentTypesProps.setSupplierId(supplierId);
  }, []);

  const showToast = (ok: boolean) => {
    toast({
      duration: 5000,
      isClosable: true,
      colorScheme: ok ? "green" : "red",
      icon: ok ? <FiInfo /> : <FiAlertTriangle />,
      description: ok
        ? t("downloadAllFilesOk", {
            ns: "documents",
          })
        : t("downloadAllFilesError", {
            ns: "documents",
          }),
    });
  };

  const refetchCompanyTasks = () => {
    documentAICompanyProps.aiTasksRefetch();
    setShowManageCompanyTaskWithAI(true);
  };

  const refetchSiteTasks = () => {
    documentAISiteProps.aiTasksRefetch();
    setShowManageSiteTaskWithAI(true);
  };

  useEffect(() => {
    communicationProps?.refetchResourceDocumentComments();
  }, []);

  const refetchCommentsAndNotes = () => {
      communicationProps.refetchMissingDocuments();
      communicationProps.refetchResourceDocumentComments();
      communicationProps.refetchResourceDocumentNotes();
      communicationProps.refetchNotificationStatus();
  };

  if (!siteResource) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          {fromList && (
            <ActionBarItem
              bgColor={COLORS.sikuroBlue}
              color="white"
              icon={MdClose}
              description={t("close", { ns: "common" })}
              onClick={() => navigate(`/sites/${siteId}/suppliers`)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(); 
                setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {siteResource && (
        <>
          <Flex px={3} py={5} gap="20px" flexWrap={"wrap"}>
            <DetailsHeader
              isLoaded={!!siteResource.company.name}
              title={t("details", { ns: "common" })}
              shouldShowImage={true}
              image={{ url: siteResource.company.logo, disableFlag: true }}
              attributes={[...detailAttributes, ...contactAttributes]}
            />
            <VStack gap={2} flex="1 1">
              <SupplierStatus
                status={siteResource.status}
                onChange={(s) => setSupplierStatus(s)}
                permissions={permissions.setStatus}
                statusIsLoading={statusIsLoading}
              />
              <RenderIf permissions={permissions.showResources}>
                <ResourceLinks
                  setType={setResourceType}
                  showResourcesModal={setShowResources}
                />
              </RenderIf>
            </VStack>
          </Flex>
          <Flex padding={3}>
            <VStack
              rounded="md"
              borderWidth="1px"
              borderColor="gray.300"
              px={5}
              pt={3}
            >
              {!siteResource.isGlobal && siteResource.isForcedEvaluation && (
                <Alert my={2} mx={-3} py={1} status="error" width="100%">
                  <AlertIcon />
                  <div>
                    <Trans t={t} i18nKey="evaluationForced" ns={"supplier"}>
                      Warning: the evaluation is forced.
                      <Link
                        sx={{ fontWeight: 700, textDecoration: "underline" }}
                        onClick={() => autoEvaluateSiteResource()}
                      >
                        Click here
                      </Link>
                      to remove the force
                    </Trans>
                  </div>
                </Alert>
              )}
              <Flex
                justifyContent="center"
                gap={5}
                alignItems="center"
                w="100%"
              >
                {siteResource.isGlobal ? (
                  <EvaluationButton
                    evaluationType="resource"
                    value={siteResource.evaluationResult}
                    disabled={!canGloballyEvaluateSupplier}
                  />
                ) : (
                  <ResourceEvaluationToggle
                    type="resource"
                    status={siteResource.evaluationResult}
                    disabled={!canGloballyEvaluateSupplier}
                    onChange={(status) =>
                      setShowConfirmEvaluateResourceModal(
                        status as unknown as SupplierEvaluationState
                      )
                    }
                    showExpirationDate={false}
                  />
                )}
                {!hasCsePermission && (
                  <IconButton
                    fontSize="3xl"
                    backgroundColor="transparent"
                    onClick={() => {
                      setEnableGetEvaluationsHistory(true);
                      setShowResourceEvaluationHistoryModal(true);
                    }}
                    aria-label="document-history"
                  >
                    <MdOutlineHistory />
                  </IconButton>
                )}
              </Flex>

              <Flex
                gap={5}
                mt={5}
                width={"full"}
                minHeight={"100"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ minWidth: "200px" }}>
                  {siteResource.isGlobal ? (
                    <div>
                      {siteResource?.variant ? (
                        <Flex>
                          <Text
                            fontSize={14}
                            textColor={COLORS.sikuroBlue}
                            marginRight={5}
                          >
                            {t("variants", { ns: "requirements" })}
                          </Text>
                          <TagList
                            tags={
                              siteResource?.variant
                                ? [siteResource?.variant]
                                : []
                            }
                          />
                        </Flex>
                      ) : (
                        <Flex>
                          <Text
                            mt={-4}
                            fontSize={14}
                            textColor={COLORS.sikuroBlue}
                          >
                            {t("variants", { ns: "requirements" })}
                          </Text>
                          <Text mt={-3} fontSize={10} marginLeft="5">
                            {t("noVariantSelected", { ns: "workers" })}
                          </Text>
                        </Flex>
                      )}
                    </div>
                  ) : (
                    <div>
                      <TagSelect
                        tags={variants}
                        isMulti={false}
                        style={{ zIndex: "2" }}
                        selectedTags={[siteResource?.variant?.id].filter(
                          (v) => v
                        )}
                        setSelectedTags={(variant) => {
                          setShowUpdateVariantsModal(variant[0] ?? undefined);
                        }}
                        label={t("variant", { ns: "siteResources" })}
                        editable={canEditRequirements}
                        defaultMenuIsOpen={false}
                        isDisabled={hasCsePermission}
                      />
                      <Text
                        mt={-4}
                        fontSize={10}
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHtmlContent,
                        }}
                      />
                    </div>
                  )}
                </Box>
                <Flex flexGrow={1} />

                {(siteResource.isGlobal || !canManageFileWithAI) ? null : (
                  <RenderIf
                    check={PermissionCheck.All}
                    permissions={permissions.manageDocumentWithAi}
                  >
                    <AiButton
                      tooltipMessage={t("manageAi", {
                        ns: "documents",
                      })}
                      onClick={() => {
                        documentAICompanyProps.setEnableGetAiTasksQuery(true);
                        selectedTab === 0
                          ? refetchCompanyTasks()
                          : refetchSiteTasks();
                      }}
                      isAnimated={
                        selectedTab === 0
                          ? documentAICompanyProps.aiTasks.some(
                              (task) => task.status === AiTaskStatus.RUNNING
                            )
                          : documentAISiteProps.aiTasks.some(
                              (task) => task.status === AiTaskStatus.RUNNING
                            )
                      }
                    />
                  </RenderIf>
                )}

                {siteResource.isGlobal ? null : (
                  <RenderIf
                    check={PermissionCheck.All}
                    permissions={[
                      Permission.Sites_Manage,
                      permissions.addRequirement,
                    ]}
                  >
                    <PlusButton
                      onClick={() => {
                        setSupplierDocumentType(
                          selectedTab === 0 ?
                            DocumentTypeCategory.SUPPLIER_COMPANY :
                            DocumentTypeCategory.SUPPLIER_SITE
                        );
                        setShowAddRequirementModal(true);
                      }}
                    />
                  </RenderIf>
                )}
                {hasCsePermission && siteResourceDocuments.length > 0 && (
                    <DownloadAllButton
                      onClick={() => {
                        documentDownloaderCompany.initiateDocumentsZipDownload(
                          (ok: boolean) => {
                            showToast(ok);
                          }
                        );
                      }}
                    />
                )}
                {!hasCsePermission && siteResourceDocuments.length > 0 && (
                  <RenderIf permissions={permissions.downloadDocuments}>
                    <DownloadAllButton
                      onClick={() => {
                        documentDownloaderCompany.initiateDocumentsZipDownload(
                          (ok: boolean) => {
                            showToast(ok);
                          }
                        );
                      }}
                    />
                  </RenderIf>
                )}
              </Flex>

              <Tabs
                onChange={onTabSelect}
                width="100%"
                height="100%"
                defaultIndex={selectedTab}
                marginTop={5}
                marginBottom={5}
              >
                <TabList>
                  <Tab width="50%">
                    {t("companyDocuments", { ns: "documents" })}
                  </Tab>
                  <Tab width="50%">
                    {t("siteDocuments", { ns: "documents" })}
                  </Tab>
                </TabList>
                <TabPanels>
                  {["company", "site"].map((type: "company" | "site") => (
                    <TabPanel key={type} padding={0}>
                      {!hasCsePermission && (
                        <>
                          <Box mt={2}>
                            <RejectionSummary
                              groups={type === "company"
                                      ? siteResourceDocumentsRejected
                                      : siteResourceSiteDocumentsRejected}
                            />
                          </Box>
                          {showInfoBanner && (
                            <RenderIf
                              permissions={permissions.documents.addFile ?? []}
                            >
                              <InfoBanner
                                text={t("dragAndDropInfo", { ns: "documents" })}
                                onClose={() => setShowInfoBanner(false)}
                              />
                            </RenderIf>
                          )}
                          {showWarningBanner && siteId && !isWorkingSite ? (
                            <WarningBanner
                              description={
                                <Trans
                                  i18nKey="siteActionAlert"
                                  ns="documents"
                                  values={{ siteName: siteHook.site?.name }}
                                  components={{ strong: <Text as="span" fontWeight="semibold" /> }}
                                />
                              }
                              onClose={() => setShowWarningBanner(false)}
                            />
                          ) : null}
                        </>
                      )}
                      <SiteResourceDocuments
                        downloader={downloader}
                        evaluateDocument={evaluateDocument}
                        siteResourceRefetch={siteResourceRefetch}
                        isSubcontract={isSubcontract}
                        showCommunications={true}
                        {...(type === "company"
                          ? {
                              documents: siteResourceDocuments,
                              documentsRefetch: siteResourceDocumentsRefetch,
                              documentsHasNextPage:
                                siteResourceDocumentsHasNextPage,
                              documentsFetchNextPage:
                                siteResourceDocumentsFetchNextPage,
                              isLoadingDocuments: siteResourceDocumentsIsLoading,
                              sortDocuments: sortCompanyDocuments,
                              filterDocuments: filterCompanyDocuments,
                              updateFilterDocuments:
                                updateFilterCompanyDocuments,
                              updateSortDocuments: updateSortCompanyDocuments,
                              isWorkingSite: siteResource.isGlobal,
                            }
                          : {
                              documents: siteResourceSiteDocuments,
                              documentsRefetch:
                                siteResourceSiteDocumentsRefetch,
                              documentsHasNextPage:
                                siteResourceSiteDocumentsHasNextPage,
                              documentsFetchNextPage:
                                siteResourceSiteDocumentsFetchNextPage,
                              isLoadingDocuments: siteResourceSiteDocumentsIsLoading,
                              sortDocuments: sortDocuments,
                              filterDocuments: filterDocuments,
                              updateFilterDocuments: updateFilterDocuments,
                              updateSortDocuments: updateSortDocuments,
                              isWorkingSite: false,
                            })}
                        setSiteResourceHookDocumentId={setHookDocumentIdAndType}
                        documentEvaluations={documentEvaluations}
                        documentEvaluationsFetching={
                          documentEvaluationsFetching
                        }
                        filterDocumentEvaluations={filterDocumentEvaluations}
                        updateFilterDocumentEvaluations={
                          updateFilterDocumentEvaluations
                        }
                        sortDocumentEvaluations={sortDocumentEvaluations}
                        updateSortDocumentEvaluations={
                          updateSortDocumentEvaluations
                        }
                        {...documentInfoProps}
                        isAddingFile={
                          isCompanyDocumentsFetching || isSiteDocumentsFetching
                        }
                        isFetchingDocuments={
                          isCompanyDocumentsFetching || isSiteDocumentsFetching
                        }
                        isReadOnly={
                          isWorkingSite ||
                          (siteResource.isGlobal && type === "company")
                        }
                        onDelete={(id, subject) =>
                          askConfirmDelete(id, subject)
                        }
                        requirementSubject={type as RequirementSubject}
                        communicationProps={communicationProps}
                        permissions={permissions.documents}
                        resourceCategory={type as DocumentTypeCategory}
                        resourceId={supplierId}
                        deltaWidth={deltaWidth}
                        aiTaskSectionCode={
                          type === "company"
                            ? aiTaskCompanySectionCode
                            : aiTaskSiteSectionCode
                        }
                        documentsAiHook={
                          type === "company"
                            ? documentAICompanyProps
                            : documentAISiteProps
                        }
                        showManageTaskWithAi={
                          type === "company"
                            ? showManageCompanyTaskWithAI
                            : showManageSiteTaskWithAI
                        }
                        setShowManageTaskWIthAi={
                          type === "company"
                            ? setShowManageCompanyTaskWithAI
                            : setShowManageSiteTaskWithAI
                        }
                        isTablet={isTablet}
                        getRequirementTemplateUrl={getRequirementTemplateUrl}
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </VStack>
          </Flex>
        </>
      )}

      {showAddRequirementModal && (
        <AddDocumentTypesModal
          {...documentTypesProps}
          isRequirement
          documentTypes={
            documentTypesProps.hookDocumentCategory ===
            DocumentTypeCategory.COMPANY
              ? availableCompanyDocumentTypes
              : availableSiteDocumentTypes
          }
          onClose={() => setShowAddRequirementModal(false)}
          onConfirm={handleAddRequirements}
          isAddingDocumentsApi={addRequirementsIsLoading}
          isFetching={documentTypesProps.documentTypesIsFetching}
          showAvailableDocuments={
            documentTypesProps.hookDocumentCategory ===
            DocumentTypeCategory.COMPANY
          }
          showSourceSelection={true}
        />
      )}

      {showResources && (
        <SiteSupplierResourcesViewModal
          type={resourceType as unknown as DocumentTypeCategory}
          resourceColumns={
            resourceType === "worker" ?
              resourceWorkerColumns :
              resourceType === "vehicle" ?
                resourceVehicleColumns :
                resourceColumns
          }
          resources={siteSupplierResources}
          isWorkingSite={location.pathname.includes("workingsite")}
          siteResourcesHasNextPage={siteSupplierResourcesHasNextPage}
          siteResourcesFetchNextPage={siteSupplierResourcesFetchNextPage}
          isFetching={siteSupplierResourcesFetching}
          setShowResources={(show) => setShowResources(show)}
          setSiteSupplierResourcesSort={(sort) =>
            setSiteSupplierResourcesSort(sort)
          }
          siteSupplierResourcesSort={siteSupplierResourcesSort}
          filterSiteSupplierResources={filterSiteSupplierResources}
          updateFilterSiteSupplierResources={(
            column: string,
            value: string | string[]
          ) => updateFilterSiteSupplierResources(column, value)}
          variants={siteVariants}
          specializations={siteSpecializations}
        />
      )}

      {showConfirmEvaluateResourceModal && (
        <BaseModal
          title={t("supplierEvaluationManulOverride")}
          onClose={() => setShowConfirmEvaluateResourceModal(false)}
          onConfirm={() => {
            evaluateSiteResource(
              showConfirmEvaluateResourceModal as SupplierEvaluationState
            );
          }}
        >
          <Box>{t("showConfirmEvaluateSupplierModal")}</Box>
        </BaseModal>
      )}

      {showUpdateVariantsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowUpdateVariantsModal(false)}
          onConfirm={() => updateSiteResourceVariant(showUpdateVariantsModal)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(variantTranslation, {
                tag: t("variant", { ns: "workers" }),
              }),
            }}
          />
        </BaseModal>
      )}

      {showResourceEvaluationHistoryModal && (
        <ResourceEvaluationHistoryModal
          onClose={() => {
            setEnableGetEvaluationsHistory(false);
            setShowResourceEvaluationHistoryModal(false);
          }}
          evaluations={siteResourceEvaluations}
          evaluationsHasNextPage={siteResourceEvaluationsHasNextPage}
          evaluationsFetchNextPage={siteResourceEvaluationsFetchNextPage}
          getEvaluations={getSiteResourceEvaluations}
          getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
          filterResourceEvaluations={filterResourceEvaluations}
          updateFilterResourceEvaluations={updateFilterResourceEvaluations}
          sortResourceEvaluations={sortResourceEvaluations}
          updateSortResourceEvaluations={updateSortResourceEvaluations}
        />
      )}

      {showNeedsRequirementsModal && (
        <BaseModal
          title={t("warning", { ns: "common" })}
          onClose={() => setShowNeedsRequirementsModal(false)}
        >
          <Text>{t("needRequirements")}</Text>
        </BaseModal>
      )}

      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={communicationProps}
          permissions={permissions.documents}
        />
      )}
      <DeleteActionAlert
        isLoading={deleteIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDelete", { ns: "requirements" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      />
    </ContentLayout>
  );
};

export const ResourceLinks = ({
  setType,
  showResourcesModal,
}: {
  setType: (type: DocumentTypeCategory) => void;
  showResourcesModal: (show: boolean) => void;
}) => {
  const { t } = useTranslation("supplier");
  const [isTablet] = useMediaQuery("(max-width: 1550px)");

  const buttons = [
    {
      type: DocumentTypeCategory.WORKER,
      Icon: WorkerIcon,
      label: t("goToWorkers"),
    },
    {
      type: DocumentTypeCategory.VEHICLE,
      Icon: VehicleIcon,
      label: t("goToVehicles"),
    },
    {
      type: DocumentTypeCategory.MACHINE,
      Icon: MachineIcon,
      label: t("goToMachines"),
    },
    {
      type: DocumentTypeCategory.TOOL,
      Icon: ToolIcon,
      label: t("goToTools"),
    },
    {
      type: DocumentTypeCategory.CHEMICAL,
      Icon: ChemicalIcon,
      label: t("goToChemicals"),
    },
  ];
  return (
    <Flex
      direction={"column"}
      rounded="md"
      borderWidth="1px"
      borderColor="gray.300"
      p="10px 40px"
      width="100%"
    >
      <Box textStyle="h2">{t("resources", { ns: "sites" })}</Box>
      <Flex flexWrap={"wrap"} gap={isTablet && 4}>
        {buttons.map(({ type, label, Icon }) => (
          <Button
            key={type}
            sx={{
              marginTop: "5px",
              width: "232px",
              height: "50px",
              borderRadius: "4px",
              border: "1px solid #E2E8F0",
              backgroundColor: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 300,
              padding: "0  20px 0 70px",
              position: "relative",
              justifyContent: "flex-start",
              textWrap: "wrap",
              textAlign: "left",
            }}
            _hover={{
              backgroundColor: COLORS.sikuroBlue,
              color: "#FFFFFF",
            }}
            onClick={() => {
              return setType(type), showResourcesModal(true);
            }}
          >
            <Box
              sx={{
                backgroundColor: COLORS.yellow,
                height: "50px",
                width: "50px",
                position: "absolute",
                left: 0,
                top: 0,
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon fontSize="20px" />
            </Box>
            {label}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default SiteSupplierDetailView;
