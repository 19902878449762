import { Box, Checkbox, Flex, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import FileEntity, { FileFeedback, FileFeedbackEnum } from "../../../../domain/entities/file";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import { Alert } from "../../../screens/Common/Alert";
import Document, {
  SiteResourceDocument,
} from "../../../../domain/entities/document";
import "./editButton.css";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { FileRow } from "./FileRow";

interface DocumentFileListProps {
  files: FileEntity[];
  activeFile: FileEntity;
  setActiveFile: (file: FileEntity) => void;
  editFileNameAndDate: string;
  updatedFile: { name: string; date: string };
  editFileIsLoading: boolean;
  setEditFileNameAndDate: (value: string | undefined) => void;
  setUpdatedFile: Dispatch<
    SetStateAction<{
      name: string;
      date: string;
      feedback: FileFeedback;
    }>
  >;
  docDetailPermissions: ResourceDocumentsPermissions;
  renameModels: string[];
  type: "document" | "requirements" | "evaluation";
  document: Document | SiteResourceDocument | PendingEvaluation;
  downloader: UseDownloader;
  editFileDetails: (fileId?: string, fileName?: string, expireDate?: Date, feedbackToUpdate? : FileFeedbackEnum) => void;
  canAddFile: boolean;
  deleteFileFromDoc: (file: FileEntity) => void;
  setAction: (action: null) => void;
  siteGeneralDocument: boolean;
  selectAllFiles: boolean;
  handleSelectAll: (value: boolean) => void;
  handleCheckItem: (value: string) => void;
  fileIds: string[];
  showRepropagate: boolean
  setShowSelectAllFiles: (value: boolean) => void
  showSelectAllFiles: boolean
  hasSitesIsLoading?: boolean
}
export const DocumentFileList = ({
  files,
  activeFile,
  setActiveFile,
  editFileNameAndDate,
  editFileIsLoading,
  updatedFile,
  setEditFileNameAndDate,
  setUpdatedFile,
  docDetailPermissions,
  document,
  downloader,
  renameModels,
  type,
  editFileDetails,
  canAddFile,
  deleteFileFromDoc,
  setAction,
  siteGeneralDocument,
  selectAllFiles,
  handleSelectAll,
  handleCheckItem,
  fileIds,
  showRepropagate,
  setShowSelectAllFiles,
  showSelectAllFiles,
  hasSitesIsLoading
}: DocumentFileListProps) => {
  const { t } = useTranslation("documents");
  const [invalidDate, setInvalidDate] = useState<string>();
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [invalidFilename, setInvalidFilename] = useState<boolean>(false);

  const didRenameDocument = (name: string) => {
    // This regex allows strings that may start or end with a space, hyphen, or underscore,
    // while accepting valid combinations of alphanumeric characters, spaces, hyphens, and underscores.
    const validNameRX = /^[\p{L}\p{M}\p{N}\s'-]*$/u;
    const nameIsValid = validNameRX.test(name);
    if (nameIsValid) {
      setUpdatedFile((current) => ({ ...current, name }));
    }
    setInvalidFilename(!nameIsValid);
  };

  return (
    <>
      {showRepropagate && showSelectAllFiles && docDetailPermissions.addFile && files?.length > 0 && (
        <Flex height={30} bgColor={"lightgray"} p={2} pt={5} pb={5}>
          <Checkbox
            borderColor={"gray.500"}
            isChecked={selectAllFiles}
            onChange={() => handleSelectAll(!selectAllFiles)}
            fontWeight="bold"          
          >
            {t("selectAllFiles")}
          </Checkbox>
        </Flex>
      )}
      {files?.length > 0 ? (
        files.map((file: FileEntity) => (
          <FileRow
            key={file.id}
            file={file}
            activeFile={activeFile}
            setActiveFile={setActiveFile}
            editFileNameAndDate={editFileNameAndDate}
            updatedFile={updatedFile}
            setEditFileNameAndDate={setEditFileNameAndDate}
            setUpdatedFile={setUpdatedFile}
            docDetailPermissions={docDetailPermissions}
            renameModels={renameModels}
            type={type}
            document={document}
            downloader={downloader}
            editFileDetails={editFileDetails}
            canAddFile={canAddFile}
            deleteFileFromDoc={deleteFileFromDoc}
            setAction={setAction}
            siteGeneralDocument={siteGeneralDocument}
            selectAllFiles={selectAllFiles}
            showSelectAllFiles={showSelectAllFiles}
            handleCheckItem={handleCheckItem}
            fileIds={fileIds}
            didRenameDocument={didRenameDocument}
            setShowErrorDate={setShowErrorDate}
            setInvalidDate={setInvalidDate}
            showRepropagate={showRepropagate}
            editFileIsLoading={editFileIsLoading}
          />
        ))
      ) : (
        <Flex justifyContent={"center"} alignItems={"center"} h={"full"}>
          <Text>{t("noAvailableFiles")}</Text>
        </Flex>
      )}
      <Box>
        {showErrorDate && (
          <Text
            color={"#E53E3E"}
            fontSize="0.875rem"
            py="2"
            lineHeight={"normal"}
          >
            {t("invalidDateError", {
              ns: "common",
              date: invalidDate,
            })}
          </Text>
        )}
      </Box>
      {invalidFilename && (
        <Alert
          onClose={() => {
            setInvalidFilename(false);
            setAction(null);
          }}
          title={t("warning", { ns: "common" })}
          message={t("invalidFileName")}
          variant="question"
        />
      )}
    </>
  );
};
