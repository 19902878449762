import { Flex, Tbody, Td, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi";
import Document from "../../../../domain/entities/document";
import Evaluation from "../../../../domain/entities/evaluation";
import FileEntity, { FileFeedbackEnum } from "../../../../domain/entities/file";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import Tag from "../../../../domain/entities/tag";
import {
  formatDateBasedOnLanguage,
  processUploadedFiles,
} from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { UseDocumentInfo } from "../../../hooks/Document/useDocumentInfo";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import {
  Filter,
  Sort,
  UpdateFilter,
  UpdateSort,
} from "../../../hooks/Site/useSiteResourceDetailViewModel";

import { DocumentActionLogEntry } from "../../../../domain/entities/documentLogEntry";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import CreateDocumentSupplierParams from "../../../hooks/Suppliers/createSupplierDocumentParameters";
import UpdateSupplierDocumentParams from "../../../hooks/Suppliers/updateSupplierDocumentParam";
import { EvaluateSupplierDocumentParams } from "../../../hooks/Suppliers/useSupplierDetailModel";
import { Alert } from "../../../screens/Common/Alert";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import TagList from "../../../screens/Common/TagList";
import BaseModal from "../../Common/alerts/BaseModal";
import AddFileButton from "../../Common/table/AddFileButton";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import HistoryButton from "../../Common/table/HistoryButton";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { Permission } from "../../Permissions/Permissions";
import { useHasPermissions } from "../../Permissions/RenderIf";
import ExpirationIcon from "../Documents/ExpirationIcon";
import { DocumentDetailModal } from "./DocumentDetailModal";
import DocumentInfoModal from "./DocumentInfoModal";
import SelectSitesView from "./SelectSitesView";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import { parse } from "date-fns";
import Site from "../../../../domain/entities/site";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";

export type SupplierDocumentsProps = UseDocumentInfo & {
  documents: Document[];
  refetchDocument: () => void;
  documentsHasNextPage: boolean;
  documentsFetchNextPage: () => void;
  tableColumns?: {
    field: string;
    filterType: "text" | "tags" | "date-range" | "select";
    label: string;
  }[];
  tags?: Tag[];
  evaluateDocument?: (params: EvaluateSupplierDocumentParams) => void;
  sortDocuments: SortMeta;
  setSortDocuments: (sort: SortMeta) => void;
  updateSortDocuments: (sort: SortMeta) => void;
  filterDocuments: Filter;
  updateFilterDocuments: UpdateFilter;
  getDocumentEvaluations: (documentId: string) => void;
  documentEvaluations: Evaluation<ResourceDocumentEvaluationState>[];
  documentEvaluationsFetching: boolean;
  updateFilterDocumentEvaluations: UpdateFilter;
  filterDocumentEvaluations: Filter;
  sortDocumentEvaluations: Sort;
  updateSortDocumentEvaluations: UpdateSort;
  requirementSubject?: RequirementSubject;
  downloader: UseDownloader;
  isFetchingDocuments?: boolean;
  isCompanySupplier?: boolean;
  documentLogsFetching: boolean;
  documentFilesFetching: boolean;
  isReadOnly?: boolean;
  createDocumentWithProperties: (
    params: CreateDocumentSupplierParams,
  ) => Promise<void>;
  updateDocument: (params: UpdateSupplierDocumentParams) => void;
  documentActionLogs?: DocumentActionLogEntry[];
  communicationProps?: DocumentCommunicationHook;
  autosize?: boolean;
  permissions: {
    addDocument: Permission;
    documents: ResourceDocumentsPermissions;
    linkBadge: Permission;
  };
  fromSupplierResource: boolean,
  supplierResourceSites?: Site[],
  supplierResourceSitesHasNextPage?: boolean,
  supplierResourceSitesFetchNextPage?: () => void,
  supplierResourceSitesIsFetching?: boolean,
  setSortResourceSites?: (sort: SortMeta)=> void,
  setFilterResourceSites?: (value: any)=> void,
  sortResourceSites?: SortMeta,
  updateFilterResourceSites?: UpdateFilter,
  filterResourceSites?: GetSitesFilter,
  supplierResourceSiteCount?: number,
  repropagateEvaluationDocument: (documentId: string, siteIds: string[], selectAll: boolean) => void
};

const SupplierDocumentsTable = ({
  documents,
  refetchDocument,
  documentsHasNextPage,
  documentsFetchNextPage,
  tableColumns,
  tags,
  evaluateDocument,
  sortDocuments,
  setSortDocuments,
  updateFilterDocuments,
  filterDocuments,
  downloader,
  getDocumentEvaluations,
  setHookDocumentId,
  documentFileLogs,
  documentEvaluations,
  documentEvaluationsFetching,
  addFileToDocument,
  successMsg,
  deleteDocumentFile,
  documentFiles,
  updateFilterDocumentEvaluations,
  updateFilterDocumentLog,
  filterDocumentEvaluations,
  filterDocumentLog,
  sortDocumentEvaluations,
  sortDocumentLog,
  updateSortDocumentEvaluations,
  setSortDocumentLog,
  isFetchingDocuments,
  isCompanySupplier = false,
  documentLogsFetching,
  documentFilesFetching,
  isReadOnly = false,
  documentSites,
  isFetchingSites = false,
  setDocumentTypeId,
  handleDocumentTypeId,
  createDocumentWithProperties,
  updateFile,
  updateFileIsLoading,
  updateDocument,
  documentActionLogs,
  docLogsFetchNextPage,
  docLogsHasNextPage,
  fileLogsFetchNextPage,
  fileLogsHasNextPage,
  setEnableGetDocLogs,
  setEnableGetFileLogs,
  renameModel,
  refetchRenameModel,
  communicationProps,
  errorMsg,
  isAddingFile,
  deleteIsLoading,
  autosize,
  hasSites,
  updateFilterSites,
  filterSites,
  setFilterSites,
  sortSites,
  setSortSites,
  permissions,
  fromSupplierResource,
  supplierResourceSites,
  supplierResourceSitesHasNextPage,
  supplierResourceSitesFetchNextPage,
  supplierResourceSitesIsFetching,
  supplierResourceSiteCount,
  updateFilterResourceSites,
  filterResourceSites,
  setSortResourceSites,
  sortResourceSites,
  setFilterResourceSites,
  totalSiteCount,
  hasSitesNextPage,
  fetchSitesNextPage,
  repropagateEvaluationDocument,
  repropagateFileDocument
}: SupplierDocumentsProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const showDownloadAll = useHasPermissions(permissions.documents.downloadAll);
  const [uploadFeedback, setUploadFeedback] = useState<boolean>(true);
  const [doc, setDoc] = useState<Document>();
  const [showConfirmUploadeModal, setShowConfirmUploadModal] =
    useState<boolean>(false);
  const [showDocumentInfoModal, setShowDocumentInfoModal] = useState<
    false | { documentId: string; tab: 0 | 1 }
  >(false);
  const [uploadData, setUploadData] = useState<{
    id: string;
    documentName: string;
    files: FileList;
  }>();
  const [isDraggingOverRow, setIsDraggingOverRow] = useState(null);
  const [droppedFile, setDroppedFile] = useState<FileEntity>();
  const [showSites, setShowSites] = useState<boolean>(false);

  const docDetailPermissions = {
    isPublic: {
      canRead: false,
    },
    docExpiresAt: {
      canRead: true,
      canWrite: false,
    },
    evaluation: {
      canRead: true,
      canWrite: true,
    },
    actionsOnFile: {
      canRead: true,
      canWrite: false,
      canDelete: false,
      canDownload: showDownloadAll,
      canAdd: false,
    },
    comment: {
      canRead: showDownloadAll,
    },
    download: true,
  };
  // Handle drag&drop start
  const handleDragIn = (index: string) => {
    if (docDetailPermissions.actionsOnFile.canAdd) {
      setIsDraggingOverRow(index);
      if (!toast.isActive("uploadToast")) {
        toast({
          id: "uploadToast",
          description: t("dropDocument", { ns: "documents" }),
        });
      }
    }
  };

  const handleDrop = async (
    document: Document,
    e: React.DragEvent<HTMLTableRowElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    if (docDetailPermissions.actionsOnFile.canAdd) {
      setIsDraggingOverRow(null);
      await handleDocumentTypeId(document.type.id);
      setDoc(document);
      if (document.id && e?.dataTransfer?.files[0]) {
        handleFileDrop(document, e.dataTransfer.files);
      } else if (!document.id && e?.dataTransfer?.files[0]) {
        handleFileDrop(
          { ...document, isPublic: true, expiresAt: null },
          e.dataTransfer.files,
        );
      }
    } else {
      toast({
        id: "uploadToast",
        description: t("noPermission", { ns: "documents" }),
        status: "error",
      });
    }
  };

  const handleFileDrop = async (
    document: Document,
    files: FileList | null,
  ): Promise<void> => {
    if (!files) {
      return;
    }
    const uploadData = {
      id: document?.id,
      documentName: document.type.name,
      files: files,
    };
    toast.closeAll();
    setUploadData(uploadData);
    setIsDraggingOverRow(null);
    const hasSiteItems = hasSites && (await hasSites("add"));
    if (hasSiteItems) {
      setShowSites(true);
    } else {
      setShowConfirmUploadModal(true);
    }
  };
  // Handle drag&drop end
  const canSetExpirationDate = useHasPermissions(
    permissions.documents.setExpiration,
  );

  useEffect(() => {
    if (uploadData) setDroppedFile(processUploadedFiles(uploadData?.files));
  }, [uploadData]);

  const openHistory = async (document: Document) => {
    await setHookDocumentId(document.id);
    setEnableGetFileLogs(true);
    setEnableGetDocLogs(false);
    return setShowDocumentInfoModal({ documentId: document.id, tab: 1 });
  };

  const [showDocumentDetail, setShowDocumentDetail] = useState<boolean>(false);
  const handleAddFile = async (
    document?: Document,
    file?: FileEntity,
    siteIds?: string[],
    refetchFiles?: boolean,
  ) => {
    if (!document) return;

    if (document.id) {
      await addFileToDocument(document.id, file, siteIds, true);
      refetchDocument();
    } else {
      await createDocumentWithProperties({
        isPublic: true,
        documentTypeId: document.type.id,
        siteIds,
        noExpiration: document.noExpiration,
        result: document.evaluationResult,
        expiresAt: document.expiresAt,
        files: droppedFile,
      } as CreateDocumentSupplierParams);
    }

    if (refetchFiles) {
      await setHookDocumentId(document.id);
    }
    setDoc(null);
    setShowSites(false);
  };

  const handleDeleteFile = async (
    document: Document,
    fileId: string,
    siteIds: string[],
    selectAll: boolean,
  ) => {
    await deleteDocumentFile(document.id, fileId, siteIds, selectAll);
    setHookDocumentId(document.id);
  };

  const handleUpdateDoc = (
    isPublicValue: boolean,
    expirationDate: Date,
    documentId: string,
    noExpiration: boolean,
    siteIds: string[],
  ) => {
    const updatedDoc = {
      ...documents[currentIndex],
      isPublic: isPublicValue,
      expiresAt: expirationDate,
      noExpiration,
      documentId,
      siteIds,
    };
    if (documents[currentIndex].id) {
      updateDocument({ document: updatedDoc, siteIds });
    } else {
      createDocumentWithProperties({
        isPublic: isPublicValue,
        documentTypeId: documents[currentIndex].type.id,
        noExpiration,
        siteIds,
        result: documents[currentIndex].evaluationResult,
        expiresAt: expirationDate,
        files: null,
      } as CreateDocumentSupplierParams);
    }
  };

  const handleUpdateFile = (
    id: string,
    name: string,
    expiresAt: Date,
    feedback: FileFeedbackEnum,
    siteIds: string[],
  ) => {
    updateFile(
      documents[currentIndex].id,
      id,
      [{ id, name, expiresAt, updated: true, feedback: {type: feedback} }] as Partial<FileEntity>[],
      siteIds,
    );
  };

  const [currentIndex, setCurrentIndex] = useState<number>();
  const initialCurrentIndex = (document: Document) => {
    setCurrentIndex(
      documents.findIndex((d) =>
        d?.id ? d?.id === document?.id : d.type.id === document.type.id,
      ),
    );
  };

  const handleRepropagateFile = async (
    documentId?: string,
    siteIds?: string[],
    selectAll?: boolean,
    selectAllFiles?: boolean,
    fileIds?: string[]
  ) => {
    const siteIdsList = !selectAll ? siteIds : [];
    await repropagateFileDocument(documentId, siteIdsList, null, selectAll, selectAllFiles, fileIds );

    setShowSites(false);
  };


  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + documents.length) % documents.length,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % documents.length);
  };

  useEffect(() => {
    if (currentIndex >= 0) {
      setHookDocumentId(documents[currentIndex]?.id);
    }
  }, [currentIndex, documents]);

  useEffect(() => {
    if (documents[currentIndex]) {
      setDocumentTypeId(documents[currentIndex].type.id);
    }
  }, [documentSites]);

  useEffect(() => {
    if (isAddingFile) {
      toast({
        title: t("uploadInProgress", { ns: "common" }),
        status: "info",
      });
    } else {
      toast.closeAll();
    }
  }, [isAddingFile, toast]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width="100%"
        position="relative"
        overflow={"hidden"}
        marginTop={8}
        marginBottom={3}
        minHeight={"50vh"}
      >
        <InfiniteTable
          autosize={autosize}
          tableId="supplier-documents-table"
          infiniteScroll={{
            dataLength: documents.length,
            hasNextPage: documentsHasNextPage,
            fetchNextPage: documentsFetchNextPage,
          }}
          emptyText={t("noResourceRequirements", { ns: "requirements" })}
          isLoading={isFetchingDocuments}
        >
          <Thead>
            <Tr>
              {tableColumns.map((column) => (
                <Th key={column.label}>
                  <TableColumnHeader
                    text={column.label}
                    filter={{
                      isActive:
                        !!filterDocuments[column.field] &&
                        (!Array.isArray(filterDocuments[column.field]) ||
                          !!filterDocuments[column.field][0]),
                      component: (
                        <ColumnFilterComponent
                          value={filterDocuments[column.field]}
                          type={column.filterType}
                          updateFilter={(value) => {
                            return updateFilterDocuments(column.field, value);
                          }}
                          tags={tags}
                          selectOptions={
                            column.field === "isPublic"
                              ? [
                                  {
                                    option: "1",
                                    label: t("yes", { ns: "common" }),
                                  },
                                  {
                                    option: "0",
                                    label: t("no", { ns: "common" }),
                                  },
                                ]
                              : [
                                  {
                                    option: "available",
                                    label: t(`combinedEvaluation.available`, {
                                      ns: "enum",
                                    }),
                                  },
                                  {
                                    option: "not_available",
                                    label: t(
                                      `combinedEvaluation.not_available`,
                                      { ns: "enum" },
                                    ),
                                  },
                                  {
                                    option: "not_applicable",
                                    label: t(
                                      `combinedEvaluation.not_applicable`,
                                      { ns: "enum" },
                                    ),
                                  },
                                  {
                                    option: "unevaluated",
                                    label: t(`combinedEvaluation.unevaluated`, {
                                      ns: "enum",
                                    }),
                                  },
                                ]
                          }
                        />
                      ),
                    }}
                    sort={
                      column.filterType !== "tags" && {
                        handler: (direction) =>
                          setSortDocuments({ field: column.field, direction }),
                        direction:
                          sortDocuments?.field === column.field
                            ? sortDocuments.direction
                            : null,
                      }
                    }
                  />
                </Th>
              ))}

              <Th width="100px" textAlign="center"></Th>
            </Tr>
          </Thead>
          <Tbody borderRadius="xl">
            {documents?.map((document, index) => {
              return (
                <Tr
                  style={{
                    backgroundColor:
                      isDraggingOverRow === document.type.id
                        ? COLORS.lightBlue
                        : "white",
                  }}
                  key={document.type.id}
                  onDragEnter={() => handleDragIn(document.type.id)}
                  onDrop={(e) => handleDrop(document, e)}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Td>{document.type.name}</Td>
                  {tableColumns.some((c) => c.field === "expiresAt") && (
                    <Td>
                      <Flex gap={3}
                      >
                        
                        {!document.noExpiration && (
                          <Flex alignItems={"center"} gap={4}>
                             <ExpirationIcon days={document.expiresIn} />
                            {document.expiresAt
                              ? formatDateBasedOnLanguage(document.expiresAt)
                              : "--/--/----"}
                          </Flex>
                        )}
                         {document.noExpiration && (
                          <Flex alignItems={"center"} gap={4}>
                            {t("doesNotExpire", { ns: "common" })}
                            <ExpirationIcon days={100} noExpiration={true} />
                          </Flex>
                        )}
                      </Flex>
                    </Td>
                  )}
                  {tableColumns.some((c) => c.field === "isPublic") && (
                    <Td>
                      <Flex>
                        {document.isPublic ? (
                          <HiOutlineLockOpen size={26} />
                        ) : (
                          <HiOutlineLockClosed size={26} />
                        )}
                      </Flex>
                    </Td>
                  )}
                  {tableColumns.some((c) => c.field === "tags") && (
                    <Td>
                      <Flex>
                        <TagList tags={document.type.tags ?? []} />
                      </Flex>
                    </Td>
                  )}

                  <Td width="100px">
                    <Flex gap={3} justifyContent="center">
                      {!isReadOnly && (
                        <HistoryButton onClick={() => openHistory(document)} />
                      )}
                      {!isReadOnly && (
                        <AddFileButton
                          justFile={false}
                          onClick={async () => {
                            initialCurrentIndex(document);
                            setShowDocumentDetail(true);
                            await handleDocumentTypeId(document?.type.id);
                            refetchRenameModel();
                          }}
                          filesCount={document.filesCount}
                        />
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </InfiniteTable>
      </Flex>

      {showDocumentDetail && (
        <DocumentDetailModal
          deleteIsLoading={deleteIsLoading}
          errorMsg={errorMsg && uploadFeedback ? errorMsg : ""}
          setErrorMsg={() => setUploadFeedback(false)}
          document={documents[currentIndex]}
          fileIsLoading={documentFilesFetching}
          isOpen={showDocumentDetail}
          onClose={() => {
            return setShowDocumentDetail(false), setCurrentIndex(null);
          }}
          setFilterSites={fromSupplierResource ? setFilterResourceSites : setFilterSites}
          isReadOnly={isReadOnly}
          files={documentFiles}
          addFile={(file, siteIds) =>
            handleAddFile(documents[currentIndex], file, siteIds, true)
          }
          addFileIsLoading={isAddingFile}
          deleteFile={(fileId, siteIds, selectAll) =>
            handleDeleteFile(
              documents[currentIndex],
              fileId,
              siteIds,
              selectAll,
            )
          }
          editDocument={(expiresAt, isPublic, noExpiration, siteIds, file) => {
            documents[currentIndex].id
              ? handleUpdateDoc(
                  isPublic,
                  expiresAt,
                  documents[currentIndex].id,
                  noExpiration,
                  siteIds,
                )
              : createDocumentWithProperties({
                  isPublic: true,
                  documentTypeId: documents[currentIndex].type.id,
                  noExpiration,
                  siteIds,
                  result: documents[currentIndex].evaluationResult,
                  expiresAt,
                  files: file,
                } as CreateDocumentSupplierParams);
          }}
          editFile={(id, name, expiresAt, feedback, siteIds) =>
            handleUpdateFile(id, name, expiresAt, feedback, siteIds)
          }
          editFileIsLoading={updateFileIsLoading}
          evaluateDocument={(
            documentId,
            evaluationState,
            expirationDate,
            noEvaluationExpiration,
            siteIds,
            selectAll,
          ) => {
            if (documents[currentIndex].id) {
              const date = parse(expirationDate, "dd/MM/yyyy", new Date());
              evaluateDocument({
                documentId,
                evaluationState,
                expirationDate: expirationDate ? date : undefined,
                noEvaluationExpiration,
                siteIds,
                selectAll,
                filters:fromSupplierResource ? filterResourceSites : filterSites
              });
            } else {
              createDocumentWithProperties({
                isPublic: documents[currentIndex].isPublic,
                documentTypeId: documents[currentIndex].type.id,
                siteIds,
                result: evaluationState,
                noExpiration: documents[currentIndex].noExpiration,
                expiresAt: expirationDate ?? documents[currentIndex].expiresAt,
                files: null,
              } as CreateDocumentSupplierParams);
            }
          }}
          repropagateEvaluationDocument={repropagateEvaluationDocument}
          repropagateFileDocument={(documentId, siteIds, selectAllSites, selectAllFiles, fileIds) =>
            handleRepropagateFile(
              documentId,
              siteIds,
              selectAllSites,
              selectAllFiles,
              fileIds
            )
          }
          sites={fromSupplierResource ? supplierResourceSites : documentSites}
          onPrev={handlePrev}
          onNext={handleNext}
          renameModels={renameModel?.download}
          communicationProps={communicationProps}
          type="document"
          downloader={downloader}
          showDownloadAll={showDownloadAll}
          canSetExpirationDate={canSetExpirationDate}
          siteGeneralDocument={false}
          hasSites={hasSites}
          filterSites={fromSupplierResource ? filterResourceSites : filterSites}
          updateFilterSites={fromSupplierResource ? updateFilterResourceSites : updateFilterSites}
          setSortSites={fromSupplierResource ?  setSortResourceSites : setSortSites}
          sortSites={fromSupplierResource ? sortResourceSites : sortSites}
          hasSitesNextPage={fromSupplierResource?  supplierResourceSitesHasNextPage : hasSitesNextPage}
          fetchSitesNextPage={fromSupplierResource ? supplierResourceSitesFetchNextPage : fetchSitesNextPage}
          isFetchingSites={fromSupplierResource ? supplierResourceSitesIsFetching : isFetchingSites}
          docDetailPermissions={permissions.documents}
          siteCount={fromSupplierResource ? supplierResourceSiteCount : totalSiteCount}
        />
      )}

      {showDocumentInfoModal && (
        <DocumentInfoModal
          onClose={() => setShowDocumentInfoModal(false)}
          tab={showDocumentInfoModal.tab ?? 0}
          changeTab={(tab) =>
            setShowDocumentInfoModal({ ...showDocumentInfoModal, tab })
          }
          getDocumentEvaluations={() =>
            getDocumentEvaluations(showDocumentInfoModal.documentId)
          }
          getDocumentLogs={() =>
            setHookDocumentId(
              isCompanySupplier
                ? showDocumentInfoModal.documentId
                : showDocumentInfoModal.documentId,
            )
          }
          documentFileLogs={documentFileLogs}
          documentEvaluations={documentEvaluations}
          documentEvaluationsFetching={documentEvaluationsFetching}
          filterDocumentLog={filterDocumentLog}
          updateFilterDocumentLog={updateFilterDocumentLog}
          sortDocumentLog={sortDocumentLog}
          setSortDocumentLog={setSortDocumentLog}
          filterDocumentEvaluations={filterDocumentEvaluations}
          updateFilterDocumentEvaluations={updateFilterDocumentEvaluations}
          sortDocumentEvaluations={sortDocumentEvaluations}
          updateSortDocumentEvaluations={updateSortDocumentEvaluations}
          documentLogsFetching={documentLogsFetching}
          documentActionLogEntry={documentActionLogs}
          docLogsFetchNextPage={docLogsFetchNextPage}
          docLogsHasNextPage={docLogsHasNextPage}
          fileLogsFetchNextPage={fileLogsFetchNextPage}
          fileLogsHasNextPage={fileLogsHasNextPage}
          showDocumentEvaluations={false}
        />
      )}
      {showSites && (
        <BaseModal
          onClose={() => setShowSites(false)}
          includeFooterButton={false}
        >
          <SelectSitesView
            title={t("selectSitesTitle", { ns: "documents" })}
            subtitle={t("selectSites", { ns: "documents" })}
            reminderText={t("noSiteSelected", { ns: "common" })}
            alertText={t("siteSelectionAlert", { ns: "common" })}
            onClose={() => {
              setShowSites(false);
              (fromSupplierResource ? setFilterResourceSites(null) : setFilterSites(null))
            }}
            onSaveAction={async (siteIds) => {
              handleAddFile(doc, droppedFile, siteIds);
              (fromSupplierResource ? setFilterResourceSites(null) : setFilterSites(null));
            }
            }
            siteList={fromSupplierResource ? supplierResourceSites : documentSites}
            includeFooterButton={true}
            showSelectAll={false}
            operationIsBusy={false}
            filterResourceSites={fromSupplierResource ? filterResourceSites : filterSites}
            updateFilterResourceSites={fromSupplierResource ? updateFilterResourceSites : updateFilterSites}
            sortResourceSites={fromSupplierResource ? sortResourceSites : sortSites}
          />
        </BaseModal>
      )}

      {showConfirmUploadeModal && (
        <ConfirmAlert
          title={t("warning", { ns: "common" })}
          message={t("dropDocumentMessage", {
            documentName: uploadData.documentName,
            ns: "documents",
          })}
          variant="warning"
          onCancel={() => setShowConfirmUploadModal(false)}
          onConfirm={() => {
            handleAddFile(doc, droppedFile);
            setShowConfirmUploadModal(false);
          }}
        />
      )}
      {errorMsg && uploadFeedback && !showDocumentDetail && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(errorMsg, { ns: "errors" })}
          variant="info"
          onClose={() => {
            setUploadFeedback(false);
          }}
        />
      )}
      {successMsg && uploadFeedback && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(successMsg, { ns: "errors" })}
          variant="info"
          onClose={() => {
            setUploadFeedback(false);
          }}
        />
      )}
    </>
  );
};

export default SupplierDocumentsTable;
