import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FileEntity from "../../../domain/entities/file";
import "./dragDropFile.css";
import { v4 as uuidv4 } from "uuid";
import { TbFileUpload } from "react-icons/tb";
import { Flex, Text } from "@chakra-ui/react";
import LoadingView from "../../screens/Common/LoadingView";

// Props.
interface DragDropFileProps {
  onUploaded?: (uploadedFile: FileEntity) => void;
  acceptedExtensions?: string;
  processImmediately?: boolean;
  isLoading?: boolean;
}

const DragDropFileView: FC<DragDropFileProps> = ({
  onUploaded,
  acceptedExtensions,
  processImmediately,
  isLoading
}) => {
  const { t } = useTranslation();
  const [dragActive, setDragActive] = React.useState(false);
  const [fileName, setFileName] = React.useState(
    t("dragAndDrop", { ns: "common" }),
  );
  const [fileLoaded, setFileLoaded] = React.useState(false);
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    type: string;
  }) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const processUploadedFiles = (files: FileList) => {
    // FIXME: passing FileEntity for uploading seems unnecessary, as the upload operation happens
    //        on existing documents with not (yet) existing files.
    const newFile: FileEntity = {
      id: uuidv4(),
      uri: files[0].webkitRelativePath,
      name: files[0].name,
      uploadDate: new Date(),
      binaries: cloneFileList(files),
    };

    if (!processImmediately) {
      setFileName(files[0].name);
    }
    setFileLoaded(true);
    onUploaded(newFile);
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e?.dataTransfer?.files[0]) {
      processUploadedFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
  
    const files = e.target.files;
  
    if (files && files.length > 0) {
      processUploadedFiles(files);
    }
  
    e.target.value = ""; 
  };

  const cloneFileList = (files: FileList): FileList => {
    const dataTransfer = new DataTransfer();
    Array.from(files).forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
        accept={acceptedExtensions}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <Flex flexDirection="column" alignItems="center">
          {!isLoading &&
            <>
              <TbFileUpload size="40" />
                <button className="upload-button" onClick={onButtonClick}>{t("uploadFile", { ns: "common" })}</button>
              <Text>{fileName}</Text>
            </>
          }
          {isLoading &&
            <Flex padding={5} justifyContent={"center"} alignItems={"center"}>
              <LoadingView width="100" height="100" />
            </Flex>
          }
        </Flex>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};

export default DragDropFileView;
