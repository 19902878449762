import Requirement from "../../../../domain/entities/requirement";
import Variant from "../../../../domain/entities/variant";
import { Dispatch, SetStateAction, useState } from "react";
import useVerifiedVariantsAndSpecializationsForRequirement from "../../../hooks/Requirements/useVerifiedVariantsAndSpecializationsForRequirement";
import BaseModal from "../../Common/alerts/BaseModal";
import { DocumentTypeWithRequirement } from "./AddDocumentTypesModal";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import { useTranslation } from "react-i18next";
import MultiTagSelect from "../../Common/TagSelect/MultiTagSelect";
import Tag from "../../../../domain/entities/tag";
import { Grid } from "@chakra-ui/react";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import Specialization from "../../../../domain/entities/specialization";

type Props = {
  requirements?: Requirement[];
  variants?: Variant[];
  specializations?: Variant[];
  onClose: () => void;
  selected?: DocumentTypeWithRequirement;
  setRequirements: Dispatch<SetStateAction<DocumentTypeWithRequirement[]>>;
  checkAlreadyCreated: boolean;
  title?: string;
  createVariant?: (variant: Variant) => Promise<Variant>
  createSpecialization?: (Specialization: Specialization) => Promise<Specialization>
};

const AddVariantsAndSpecializationsToRequirementModal = ({
  requirements,
  specializations,
  variants,
  onClose,
  selected,
  setRequirements,
  checkAlreadyCreated,
  title,
  createVariant,
  createSpecialization
}: Props) => {
  const [selectedVariants, setSelectedVariants] = useState<Tag[]>([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState<Tag[]>(
    []
  );
  const handleConfirm = () => {
    setRequirements((prev) => [
      ...prev,
      {
        ...selected,
        variants: selectedVariants.map((v) => v.id),
        specializations: selectedSpecializations.map((s) => s.id),
      },
    ]);
  };

  const {
    variants: verifiedVariants,
    specializations: verifiedSpecializations,
  } = useVerifiedVariantsAndSpecializationsForRequirement(
    requirements?.filter((r) => r.documentType?.id === selected?.id),
    variants,
    specializations,
    {
      variants: selectedVariants,
      specializations: selectedSpecializations,
    }
  );

  const createTag = async(variantName: string) => {
    const newVariant = await createVariant({
      id: variantName,
      name: variantName,
      color: "#CCC",
      type: requirements[0]?.subject as unknown as DocumentTypeCategory,
    });
    setSelectedVariants([...selectedVariants, newVariant]);
  };

  const createSpecializationTag = async (specializationName: string) => {
    const newSpecialization = await createSpecialization({
      id: specializationName,
      name: specializationName,
      color: "#CCC",
      type: requirements[0]?.subject as unknown as DocumentTypeCategory,
    });
    setSelectedSpecializations([...selectedSpecializations, newSpecialization]);
  }

  const subject = requirements?.length > 0 ? requirements[0]?.subject : null;
  const { t } = useTranslation("requirements");
  return (
    <BaseModal
      onClose={onClose}
      onConfirm={handleConfirm}
      onConfirmDisabled={selectedVariants.length === 0}
      closeOnOverlayClick={false}
    >
      <Grid gap={8}>
        <p>{title}</p>
        <MultiTagSelect
          label={t("variants") + "*"}
          tags={checkAlreadyCreated ? verifiedVariants : variants}
          selectedTags={selectedVariants}
          setSelectedTags={setSelectedVariants}
          defaultMenuIsOpen={false}
          menuPosition={"fixed"}
          required
          createTag={createTag}
        />
        {![RequirementSubject.COMPANY, RequirementSubject.SITE, RequirementSubject.SUPPLIER].includes(
          subject,
        ) && (
            <MultiTagSelect
              label={t("specializations")}
              tags={
                checkAlreadyCreated ? verifiedSpecializations : specializations
              }
              selectedTags={selectedSpecializations}
              setSelectedTags={setSelectedSpecializations}
              defaultMenuIsOpen={false}
              menuPosition={"fixed"}
              createTag={createSpecializationTag}
            />
          )}
      </Grid>
    </BaseModal>
  );
};

export default AddVariantsAndSpecializationsToRequirementModal;
