import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import DocumentType, {
  RenameModel,
} from "../../../domain/entities/documentType";
import Tag from "../../../domain/entities/tag";
import InputAnimatedLabel from "../../components/Common/InputAnimatedLabel";

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import ActionButton from "../../components/Common/table/ActionButton";
import DeleteButton from "../../components/Common/table/DeleteButton";
import MultiTagSelect from "../../components/Common/TagSelect/MultiTagSelect";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";

// Props
interface EditDocuTypeProps {
  handleDocumentType: (documentType: DocumentType) => void;
  handleRenameModels: (renameModels: RenameModel) => void;
  documentType?: DocumentType;
  onClose: () => void;
  updateTag?: (tag: Tag) => void;
  tags: Tag[];
  renameModels: RenameModel;
  renameModelsFetching: boolean;
  target: string;
  isUpdate?: boolean;
}

const emptyDocumentType: DocumentType = {
  id: "",
  tags: [],
  name: "",
  duration: 0,
  description: "",
  renameModels: {
    upload: "",
    download: [],
  },
};

/**
 * Renders a modal view that asks user to confirm an action.
 *
 * @param isOpen the flag indicating if the view is visible
 * @param onCancel the handler that cancels the user action, usually closing the view
 * @param onConfirm the handler that confirms the user action, usually closing the view
 * @returns
 */
const EditDocumentTypeView: FC<EditDocuTypeProps> = ({
  documentType,
  handleDocumentType,
  handleRenameModels,
  onClose,
  updateTag,
  tags,
  renameModels,
  renameModelsFetching,
  target,
  isUpdate,
}) => {
  const { t, ready } = useTranslation();
  const [docType, setDocType] = useState<DocumentType | undefined>(
    documentType ?? emptyDocumentType,
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    documentType?.tags ?? [],
  );

  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [showInfoBanner, setShowInfoBanner] = useState<boolean>(true);
  const [inputsRenameDownload, setInputsRenameDownload] = useState<string[]>();
  const [inputsRenameUpload, setInputsRenameUpload] = useState<string>();
  const [editDocumentTypeIsLoading, setEditDocumentTypeIsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setInputsRenameDownload(
      renameModels?.download.length > 0 ? renameModels.download : [""],
    );
    setInputsRenameUpload(renameModels?.upload ? renameModels?.upload : "");
  }, [renameModels]);

  const availableTags = [...tags, ...newTags];
  const handleChangeInput = (key: string, value: string | number) => {
    if ((!isUpdate && key === "name") || key === "upload") {
      setInputsRenameUpload(value as string);
    }
    if (key !== "upload") {
      setDocType({
        ...docType,
        [key]: value,
      });
    }
  };

  const onConfirm = async () => {
    setEditDocumentTypeIsLoading(true);
    const filteredTags = [...tags, ...newTags].filter((tag) =>
      selectedTags.some((selected) => selected.id === tag.id)
    );
    if (target !== "system") {
      await handleDocumentType({
        ...docType,
        renameModels: {
          ...docType.renameModels,
          upload: inputsRenameUpload,
          download: inputsRenameDownload,
        },
        tags: filteredTags,
      });
    } else {
      (renameModels as RenameModel) &&
        (await handleRenameModels({
          ...renameModels,
          upload: inputsRenameUpload,
          download: inputsRenameDownload,
        }));
    }
    setEditDocumentTypeIsLoading(false);
    onClose();
  };

  const createTag = (tagName: string) => {
    setNewTags([...newTags, { id: tagName, name: tagName, color: "#CCC" }]);
    setSelectedTags([
      ...selectedTags,
      { id: tagName, name: tagName, color: "#CCC" },
    ]);
  };

  const handleAddInput = () => {
    setInputsRenameDownload([...inputsRenameDownload, ""]);
  };

  const handleChange = (event: string, index: number) => {
    const value = event;
    const updatedInputs = [...inputsRenameDownload];
    updatedInputs[index] = value;
    setInputsRenameDownload(updatedInputs);
  };

  const handleDeleteInput = (index: number) => {
    const updatedInputs = [...inputsRenameDownload];
    updatedInputs.splice(index, 1);
    setInputsRenameDownload(updatedInputs);
  };
  const placeholderOptions: string[] = t("renameModelPlaceholder.options", {
    returnObjects: true,
    ns: "documents",
  });
  const availablePlaceholders: { name: string; description: string }[] = t(
    "renameModelPlaceholder.availablePlaceholders",
    { returnObjects: true, ns: "documents" },
  );
  const firstExList: {
    beforeBold: string;
    bold: string;
    afterBold: string;
    italic: string;
  }[] = t("renameModelPlaceholder.firstExList", {
    returnObjects: true,
    ns: "documents",
  });
  const secondExList: {
    beforeBold: string;
    bold: string;
    afterBold: string;
    italic: string;
  }[] = t("renameModelPlaceholder.secondExList", {
    returnObjects: true,
    ns: "documents",
  });

  return (
    <Modal isOpen={true} onClose={onClose} size="xl" trapFocus={false}>
      <ModalOverlay />

      <ModalContent backgroundColor="white" padding={5}>
        <ModalHeader>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            alignItems="center"
            textAlign="center"
            flexDirection="row"
          >
            <Text textColor={"black"} fontSize={14}>
              {documentType
                ? t("saveDocumentType", { ns: "documents" })
                : t("createDocumentType", { ns: "documents" })}
            </Text>
            <Flex flex={1} />
            <IconButton
              marginLeft={3}
              fontSize="3xl"
              size="2xl"
              backgroundColor="transparent"
              onClick={onClose}
              aria-label="Edit"
            >
              <MdClose />
            </IconButton>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            rounded="md"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <InputAnimatedLabel
              id="name"
              label={t("name", { ns: "documents" })}
              defaultValue={documentType?.name}
              handleOnChange={handleChangeInput}
              showButton={false}
              width="100%"
              isDisabled={target === "system"}
              isRequired
            />

            <InputAnimatedLabel
              id="duration"
              label={t("duration", { ns: "documents" })}
              type="number"
              defaultValue={
                documentType?.duration
                  ? documentType?.duration.toString()
                  : undefined
              }
              handleOnChange={handleChangeInput}
              showButton={false}
              width="100%"
              marginTop={5}
              isDisabled={target === "system"}
            />

            <Flex height={5}></Flex>

            <MultiTagSelect
              tags={availableTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              createTag={createTag}
              fullWidth={true}
              placeholder={t("tagPlaceholder", { ns: "documents" })}
              isDisabled={target === "system"}
            />

            <InputAnimatedLabel
              id="description"
              label={t("description", { ns: "documents" })}
              defaultValue={documentType?.description}
              handleOnChange={handleChangeInput}
              showButton={false}
              width="100%"
              marginTop={5}
              isMultiLine
              isDisabled={target === "system"}
            />
            <InputAnimatedLabel
              id="upload"
              label={t("uploadRenameModel", { ns: "documents" })}
              defaultValue={inputsRenameUpload}
              handleOnChange={handleChangeInput}
              showButton={false}
              marginTop={5}
              width="100%"
              isRequired
            />
            {inputsRenameDownload?.length > 0 &&
              inputsRenameDownload?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <FormControl
                      id={`download-${index}`}
                      variant="floating"
                      borderColor="gray.400"
                      position="relative"
                      marginTop={2}
                      isRequired={true}
                    >
                      <FormLabel
                        mt="10px"
                        backgroundColor="white"
                        borderRadius={4}
                        color="gray.500"
                      >
                        {t("downloadRenameModel", { ns: "documents" })}
                      </FormLabel>
                      <InputGroup
                        display="flex"
                        alignItems="center"
                        gap={3}
                        marginTop={5}
                      >
                        <Input
                          value={item}
                          onChange={(e) => handleChange(e.target.value, index)}
                          color="black"
                          backgroundColor="white"
                          width="100%"
                        />
                        {inputsRenameDownload.length > 1 && (
                          <DeleteButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteInput(index);
                            }}
                          />
                        )}
                        {index === inputsRenameDownload.length - 1 && (
                          <ActionButton
                            aria-label="delete"
                            onClick={handleAddInput}
                            icon={<FaPlus />}
                          />
                        )}
                      </InputGroup>
                    </FormControl>
                  </React.Fragment>
                );
              })}
          </Flex>
          {ready && showInfoBanner && (
            <Alert
              status="info"
              borderRadius={8}
              my={4}
              display={"flex"}
              fontSize={".85em"}
              gap={"0.5rem"}
              alignItems={"start"}
            >
              <AlertIcon />
              <Box>
                <Text>
                  {t("renameModelPlaceholder.optionsTitle", {
                    ns: "documents",
                  })}
                </Text>
                <UnorderedList>
                  {placeholderOptions.map((option) => (
                    <ListItem margin={0} key={option}>
                      {option}
                    </ListItem>
                  ))}
                </UnorderedList>
                <Text mt={2}>
                  {t("renameModelPlaceholder.availablePlaceholdersTitle", {
                    ns: "documents",
                  })}
                </Text>
                <UnorderedList>
                  {availablePlaceholders.map((option) => {
                    return (
                      <ListItem margin={0} key={option.name}>
                        <strong>{option.name}</strong> : {option.description}
                      </ListItem>
                    );
                  })}
                </UnorderedList>
                <Text mt={2}>
                  {t("renameModelPlaceholder.ex", { ns: "documents" })}
                </Text>
                <Text>
                  {t("renameModelPlaceholder.firstExTitle", {
                    ns: "documents",
                  })}
                </Text>
                <UnorderedList>
                  {firstExList.map((option) => (
                    <ListItem margin={0} key={option.bold}>
                      {option.beforeBold} <strong>{option.bold}</strong>{" "}
                      {option.afterBold}{" "}
                      <strong>
                        <em>{option.italic}</em>
                      </strong>
                    </ListItem>
                  ))}
                </UnorderedList>
                <Text>
                  {t("renameModelPlaceholder.secondExTitle", {
                    ns: "documents",
                  })}
                </Text>
                <UnorderedList>
                  {secondExList.map((option) => {
                    return (
                      <ListItem margin={0} key={option.bold}>
                        {option.beforeBold} <strong>{option.bold}</strong>{" "}
                        {option.afterBold}{" "}
                        <strong>
                          <em>{option.italic}</em>
                        </strong>
                      </ListItem>
                    );
                  })}
                </UnorderedList>
              </Box>
              <span>
                <AiOutlineClose
                  onClick={() => setShowInfoBanner(false)}
                  cursor={"pointer"}
                  fontSize={"1.5em"}
                />
              </span>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex grow={1} />
          <Button
            isLoading={editDocumentTypeIsLoading}
            colorScheme="blue"
            onClick={onConfirm}
            marginRight={5}
            isDisabled={
              inputsRenameDownload?.length >= 0 &&
              (inputsRenameDownload?.some((element) => element === "") ||
                !inputsRenameUpload)
            }
          >
            {t("save", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { EditDocumentTypeView };
