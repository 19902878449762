import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  BusinessLicenseResponse,
  BusinessOption,
  BusinessSize,
  BusinessSizeResponse,
  BusinessTypeResponse,
  Credentials,
  LicenseType,
  SignUpCompany,
  SignUpUser
} from "../../../domain/interactors/auth/args";
import { useAuth } from "../../providers/Auth0JWTProvider";
import OnboardingViewModel from "../../viewmodels/onboarding/OnboardingViewModel";
import { useTranslation } from "react-i18next";
import { SYSTEM_STAFF_ROLES } from "../Onboarding/useOnboardingViewModel";

const staffInitialValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
};

export type FormType = SignUpCompany & SignUpUser;

interface CreateDerivedProps {
  onValidationError: SubmitErrorHandler<FormType>,
  hideModal?: () => void;
}

const useCreateDerivedTenantViewModel = (
  {onValidationError = undefined, hideModal}: CreateDerivedProps
) => {
  const vm = new OnboardingViewModel();
  const [searchParams] = useSearchParams({ email: "" });
  const { setLoginData } = useAuth();
  const navigate = useNavigate();
  const invitationToken = searchParams.get("token"); // invite token
  const company = searchParams.get("company");
  const { companyId, token } = useAuth()
  const emailFromParams = searchParams.get("email") ?? "";

  const methods = useForm<FormType>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      email:emailFromParams,
      password: "",
      confirmPassword: "",
      licenseType: LicenseType.Smart,
      privacyPolicy: false,
      newsletter: false,
      businessName: "",
      vatCountry: "IT",
      vat: "",
      businessSize: BusinessSize.Micro,
      businessType: null,
      firstName: "",
      lastName: "",
      pec: "",
      ccnl: "",
      ...SYSTEM_STAFF_ROLES.reduce(
        (acc, role) => ({ ...acc, [role]: staffInitialValues }),
        {},
      ),
    },
  });

  const { t } = useTranslation('onboarding')

  const [error, setError] = useState<string>();
  const [successCreateAccount, setSuccessCreateAccount] =
    useState<boolean>(false);

  const createBusinessAccountMutation = useMutation(
    async (company: SignUpCompany) => {
      return vm.createBusinessDerivedAccount({ ...company, token: invitationToken, bearerToken: token, companyId });
    },
    {
      onSuccess: () => {
        setSuccessCreateAccount(true);
        hideModal();
      },
      onError: (error: Error) => {
        const message = t(`apiErrors.${error.message}`);
        if (error.message === "error.email-conflict") {
          methods.setError("email", { message });
          onValidationError?.({ email: message } as any);
        }
        if (error.message === "error.vat-code-conflict") {
          methods.setError("vat", { message });
          onValidationError?.({ vat: message } as any);
        }
        setError(error.message)
      },
    },
  );

  const loginMutation = useMutation(
    async ({ email, password }: Credentials) => {
      const loginData = await vm.login(email, password);
      localStorage.setItem("tenantLogo", loginData.company?.logo);
      localStorage.setItem("tenantName", loginData.company?.name);
      localStorage.setItem("justLoggedIn", "true");

      setLoginData({
        ...loginData,
        generalPermissions: loginData.permissions,
        sitePermissions: [],
      });
    },
    {onSuccess: () => {
        const isSupplierActivation = searchParams.get("activateSupplier") === 'activateSupplier';
        const isUserActivation = searchParams.get("activateUser") === 'activateUser';
        const isSubcontractorActivation = searchParams.get("activateSubcontractor") === 'activateSubcontractor';
        const isConfirmSubcontractor = searchParams.get("confirmSubcontractor") === 'confirmSubcontractor';
        const isEmailcertification = searchParams.get("emailCertification") === 'emailCertification';
        const site = searchParams.get("site") ? `&site=${searchParams.get("site")}` : '';
        const owner = searchParams.get("owner") ? `&owner=${searchParams.get("owner")}` : '';

        const navigateTo = (path: string, includeCompany = false) => {
          const companyParam = includeCompany ? `&company=${company ?? searchParams.get("owner")}` : '';
          const usernameParam = searchParams.get("userName") ? `&userName=${searchParams.get("userName")}` : '';
          navigate(`${path}?token=${invitationToken}${companyParam}${site}${owner}${usernameParam}`);
        };

        if (invitationToken) {
          switch (true) {
            case isUserActivation:
              navigateTo('/activate-user', true);
              break;
            case isSupplierActivation:
              navigateTo('/activate-supplier');
              break;
            case isSubcontractorActivation:
              navigateTo('/activate-subcontractor');
              break;
            case isConfirmSubcontractor:
              navigateTo('/confirm-subcontractor');
              break;
            case isEmailcertification:
              navigateTo('/email-certification');
              break;
            default:
              navigate("/overview");
              break;
          }
        } else {
          navigate("/overview");
        }
      },
      onError: (error: Error) => setError(error.message),
    },
  );

  const createBusinessAccount = methods.handleSubmit((data) => {
    const { ...company } = data;
    createBusinessAccountMutation.mutate(company);
  }, onValidationError);

  const getBusinessVatCountries = useQuery<BusinessOption[], Error>(
    ["get-vat-countries"],
    async () => {
      const countries = await vm.getVatCountries();
      return countries;
    },
    {
      onSuccess: () => {
        methods.setValue("vatCountry", "IT");
      },
    }
   );
  
  
  const getBusinessSizes = useQuery<BusinessSizeResponse[], Error>(
    ["get-business-sizes"],
    async () => {
      const sizes = await vm.getBusinessSizes();      
      return sizes;
    },
    {
      onSuccess: (sizes) => {
        methods.setValue("businessSize", sizes[0].value);
      },
    }
  );

  const getBusinessTypes = useQuery<BusinessTypeResponse[], Error>(
    ["get-business-types"],
    async () => {
      const types = await vm.getBusinessTypes();
      return types;
    },
    {
      onSuccess: (types) => {
        methods.setValue("businessType", types[0].value);
      },
    }
  )
 
  const getBusinessLicenseTypes = useQuery<BusinessLicenseResponse[], Error>(
    ["get-license-types"],
    async () => {
      const types = await vm.getLicenseTypes();
      return types;
    },
    {
      onSuccess: (types) => {
        methods.setValue("licenseType", types[0].value);
      },
    }
  );

  return {
    methods,
    createBusinessAccount,
    login: loginMutation.mutate,
    isLoading:
      createBusinessAccountMutation.isLoading ||
      loginMutation.isLoading,
    vatCountries: getBusinessVatCountries.data,
    businessSizes: getBusinessSizes.data,
    businessTypes: getBusinessTypes.data,
    licenseTypes: getBusinessLicenseTypes.data,
  };
};

export default useCreateDerivedTenantViewModel;
