import { useMemo, useState } from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../utils";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SmartDownloadViewModel from "../../viewmodels/smartDownload/SmartDownloadViewModel";
import { GetGlobalSupplierFilters } from "../../../domain/repositories/supplierRepository";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";
import { GetSupplierResourcesFilters } from "../Suppliers/useSupplierResourcesViewModel";
import Worker from "../../../domain/entities/worker";
import Vehicle from "../../../domain/entities/vehicle";
import Tool from "../../../domain/entities/tool";
import Chemical from "../../../domain/entities/chemical";
import Machine from "../../../domain/entities/machine";
import { GetDocumentTypeFilters } from "../../../domain/repositories/documentTypeRepository";
import DocumentType from "../../../domain/entities/documentType";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";


export type ContextFiltersType = {
  siteIds?: string[];
  selectAllSites?: boolean;
  resourceIds?: string[];
  selectAllResources?: boolean;
  filters?: {
    site?: GetSitesFilter,
    supplier?: GetGlobalSupplierFilters,
    resource?: GetSupplierResourcesFilters | GetResourcesFilter,
    document?: GetDocumentTypeFilters
  };
};

export type SmartDownloadContextType = "supplier" | "documentType" | "resource";
export type SmartDownloadSubContextType = SmartDownloadSubContextDataType | "resource" | "company" | "all"
export type SmartDownloadSubContextDataType = "documentType.all" | "documentType.company" | "documentType.site";
export type SelectedResourcesByType = {
  vehicle: Vehicle[] | DocumentType[];
  worker: Worker[] | DocumentType[];
  machine: Machine[] | DocumentType[];
  tool: Tool[] | DocumentType[];
  chemical: Chemical[] | DocumentType[];
};
export type SelectedAllResourcesByType = {
  [key in ResourceType]?: boolean;
};

export interface DownloadParams {
  context: string;
  siteIds: string[];
  supplierIds: string[];
  resourceIds: ResourceIds;
  documentTypeIds: DocumentIds;
  selectAll: {
    site: boolean;
    supplier: boolean;
    vehicle: boolean;
    worker: boolean;
    machine: boolean;
    tool: boolean;
    chemical: boolean;
    documentTypeWorker: boolean;
    documentTypeVehicle: boolean;
    documentTypeMachine: boolean;
    documentTypeTool: boolean;
    documentTypeChemical: boolean;
  };
  filters: {
    site: GetSitesFilter;
    supplier: GetGlobalSupplierFilters;
    worker: GetSupplierResourcesFilters | GetDocumentTypeFilters;
    vehicle: GetSupplierResourcesFilters | GetDocumentTypeFilters;
    machine: GetSupplierResourcesFilters | GetDocumentTypeFilters;
    tool: GetSupplierResourcesFilters | GetDocumentTypeFilters;
    chemical: GetSupplierResourcesFilters | GetDocumentTypeFilters;
    documentTypeWorker: GetDocumentTypeFilters;
    documentTypeVehicle: GetDocumentTypeFilters;
    documentTypeMachine: GetDocumentTypeFilters;
    documentTypeTool: GetDocumentTypeFilters;
    documentTypeChemical: GetDocumentTypeFilters;
  };
}

interface ResourceIds {
  vehicle: string[];
  worker: string[];
  machine: string[];
  tool: string[];
  chemical: string[];
}

interface DocumentIds {
  vehicle: string[];
  worker: string[];
  machine: string[];
  tool: string[];
  chemical: string[];
}

const useSmartDownloadViewModel = (params: {
  sites?: {
    selectedSites?: string[];
    selectedFilterSites?: GetSitesFilter;
    selectedAllSites?: boolean;
  };
  suppliers?: {
    selectedSuppliers?: string[];
    selectedAllSuppliers?: boolean;
  };
  resources?: {
    selectedResourcesByType?: SelectedResourcesByType;
    selectedAllResourcesByType?: SelectedAllResourcesByType;
    selectedResources?: string[],
    selectedFilterResources?: GetResourcesFilter,
    resourceCount?: number,
    selectAllResources?: boolean,
    type?: ResourceType,
  };
  documents?: {
    selectedDocumentTypesByResourceType?: SelectedResourcesByType;
    selectedAllDocumentTypesByResourceType?: SelectedAllResourcesByType;
  };
}) => {
  const { companyId } = useAuth();
  const [context, setContext] = useState<SmartDownloadContextType>();
  const [subContext, setSubContext] = useState<SmartDownloadSubContextType>();
  const [subContextData, setSubContextData] = useState<SmartDownloadSubContextDataType>();
  const [supplierCount, setSupplierCount] = useState<number>(0);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [documentTypesCount, setDocumentTypesCount] = useState<number>(0);
  const [supplierFilters, setSupplierFilters] = useState<GetGlobalSupplierFilters>();
  const [supplierSort, setSupplierSort] = useState<SortMeta>();
  const [supplierWorkerFilters, setSupplierWorkerFilters] = useState<GetSupplierResourcesFilters>({});
  const [supplierVehicleFilters, setSupplierVehicleFilters] = useState<GetSupplierResourcesFilters>({});
  const [supplierMachineFilters, setSupplierMachineFilters] = useState<GetSupplierResourcesFilters>({});
  const [supplierToolFilters, setSupplierToolFilters] = useState<GetSupplierResourcesFilters>({});
  const [supplierChemicalFilters, setSupplierChemicalFilters] = useState<GetSupplierResourcesFilters>({});
  const [supplierResourceSort, setSupplierResourceSort] = useState<SortMeta>();
  const [documentTypesWorkerFilters, setDocumentTypesWorkerFilters] = useState<GetDocumentTypeFilters>();
  const [documentTypesVehicleFilters, setDocumentTypesVehicleFilters] = useState<GetDocumentTypeFilters>();
  const [documentTypesMachineFilters, setDocumentTypesMachineFilters] = useState<GetDocumentTypeFilters>();
  const [documentTypesToolFilters, setDocumentToolTypesFilters] = useState<GetDocumentTypeFilters>();
  const [documentTypesChemicalFilters, setDocumentTypesChemicalFilters] = useState<GetDocumentTypeFilters>();
  const [documentTypesFilters, setDocumentTypesFilters] = useState<GetDocumentTypeFilters>();
  const [documentTypesSort, setDocumentTypesSort] = useState<SortMeta>();
  const [resourceType, setResourceType] = useState<ResourceType>(params.resources?.type || null);
  const [documentTypesByResourceType, setDocumentTypesByResourceType] = useState<ResourceType>(null);
  const [downloadFeedback, setDownloadFeedback] = useState<string>();

  const viewModel = useMemo(
    () => new SmartDownloadViewModel(),
    []
  );
  const downloadRequest = useMutation(
    ["download-suppliers", companyId],
    async () => {
      const resourceSelection = context === "supplier"
        ? params.resources.selectedAllResourcesByType
        : params.documents.selectedAllDocumentTypesByResourceType;
      const getUpdatedContext = () => {
        if (subContext === "all") return "supplier.all";
        if (subContext === "company") {
          return subContextData === "documentType.all"
            ? "supplier.documentType.all"
            : subContextData === "documentType.company"
              ? "supplier.documentType.company"
              : "supplier.documentType.site";
        }
        return subContext === "resource" ? "supplier.resource" : context;
      };
      const getResourceIds = (type: string) => {
        if (context === "resource" && params.resources.selectedResources.length > 0) {
          return params.resources.selectedResources;
        }
        return params.resources.selectedResourcesByType?.[type]?.map(r => r.id) ?? [];
      };
      const downloadParams: DownloadParams = {
        context: getUpdatedContext() || null,
        siteIds: params.sites.selectedSites || [],
        supplierIds: params.suppliers.selectedSuppliers|| [],
        resourceIds: {
          vehicle: params.resources.type === "vehicle" ? getResourceIds("vehicle") : [],
          worker: params.resources.type === "worker" ? getResourceIds("worker") : [],
          machine: params.resources.type === "machine" ? getResourceIds("machine") : [],
          tool: params.resources.type === "tool" ? getResourceIds("tool") : [],
          chemical: params.resources.type === "chemical" ? getResourceIds("chemical") : [],
        },
        documentTypeIds: {
          vehicle: params.documents.selectedDocumentTypesByResourceType?.vehicle?.map(r => r.id) ?? [],
          worker: params.documents.selectedDocumentTypesByResourceType?.worker?.map(r => r.id) ?? [],
          machine: params.documents.selectedDocumentTypesByResourceType?.machine?.map(r => r.id) ?? [],
          tool: params.documents.selectedDocumentTypesByResourceType?.tool?.map(r => r.id) ?? [],
          chemical: params.documents.selectedDocumentTypesByResourceType?.chemical?.map(r => r.id) ?? []
        },
        selectAll: {
          site: params.sites.selectedAllSites || false,
          supplier: params.suppliers.selectedAllSuppliers || false,
          vehicle:  params.resources.type === "vehicle" ? params.resources.selectAllResources : resourceSelection["vehicle"],
          worker:   params.resources.type === "worker" ? params.resources.selectAllResources : resourceSelection["worker"],
          machine:  params.resources.type === "machine" ? params.resources.selectAllResources : resourceSelection["machine"],
          tool:     params.resources.type === "tool" ? params.resources.selectAllResources : resourceSelection["tool"],
          chemical: params.resources.type === "chemical" ? params.resources.selectAllResources : resourceSelection["chemical"],
          documentTypeWorker: resourceSelection["worker"],
          documentTypeVehicle: resourceSelection["vehicle"],
          documentTypeMachine: resourceSelection["machine"],
          documentTypeTool: resourceSelection["tool"],
          documentTypeChemical: resourceSelection["chemical"]
        },
        filters: {
          site: params.sites.selectedFilterSites,
          supplier: supplierFilters || {},
          worker: supplierWorkerFilters || {},
          vehicle: supplierVehicleFilters || {},
          machine: supplierMachineFilters || {},
          tool: supplierToolFilters || {},
          chemical: supplierChemicalFilters || {},
          documentTypeWorker: documentTypesWorkerFilters ?? {},
          documentTypeVehicle: documentTypesVehicleFilters ?? {},
          documentTypeMachine: documentTypesMachineFilters ?? {},
          documentTypeTool: documentTypesToolFilters ?? {},
          documentTypeChemical: documentTypesChemicalFilters ?? {}
        }
      };
      const p = getUpdatedContext() === "resource" ? { ...downloadParams, resourceType: resourceType } : downloadParams;
      await viewModel.downloadRequest(companyId, p);
    },
    {
      onSuccess: () => {
        console.log('Download request successful');
      },
      onError: (error: Error) => {
        setDownloadFeedback(error.message);
      }
    }
  );

  const handleFiltersChange = (field: string, value: string | string[] | [Date, Date]) => {
    const supplierFiltersMap = {
      worker: setSupplierWorkerFilters,
      vehicle: setSupplierVehicleFilters,
      machine: setSupplierMachineFilters,
      tool: setSupplierToolFilters,
      chemical: setSupplierChemicalFilters
    };

    const documentFiltersMap = {
      worker: setDocumentTypesWorkerFilters,
      vehicle: setDocumentTypesVehicleFilters,
      machine: setDocumentTypesMachineFilters,
      tool: setDocumentToolTypesFilters,
      chemical: setDocumentTypesChemicalFilters
    };
    const filtersMap = context === "supplier" ? supplierFiltersMap : documentFiltersMap;
    const setFilterFunction = filtersMap[context === "supplier" ? resourceType : documentTypesByResourceType];
    if (setFilterFunction) {
      updateFilterWithDelete(setFilterFunction, field, value);
    }
  };
  const filters = () => {
    const supplierFiltersMap = {
      worker: supplierWorkerFilters,
      vehicle: supplierVehicleFilters,
      machine: supplierMachineFilters,
      tool: supplierToolFilters,
      chemical: supplierChemicalFilters
    };

    const documentFiltersMap = {
      worker: documentTypesWorkerFilters,
      vehicle: documentTypesVehicleFilters,
      machine: documentTypesMachineFilters,
      tool: documentTypesToolFilters,
      chemical: documentTypesChemicalFilters
    };

    const filtersMap = context === "supplier" ? supplierFiltersMap : documentFiltersMap;
    const filterKey = context === "supplier" ? resourceType : documentTypesByResourceType;
    return filtersMap[filterKey] || {};
  };

  const getSuppliers = useInfiniteQuery(
    ["download-suppliers", companyId, supplierFilters, supplierSort],
    async ({ pageParam = 1 }) => {
      const contextFilters: ContextFiltersType = {
        siteIds: params.sites.selectedSites,
        selectAllSites: params.sites.selectedAllSites,
        filters: { site: params.sites.selectedFilterSites, supplier: supplierFilters }
      };
      const {
        results,
        count
      } = await viewModel.getSuppliersForDownload(companyId, supplierFilters, supplierSort, pageParam, contextFilters);
      setSupplierCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: context === "supplier",
      retry: false
    }
  );
  const supplierResources = useInfiniteQuery(
    ["download-supplier-resources", resourceType, companyId, supplierWorkerFilters,
      supplierVehicleFilters,
      supplierMachineFilters,
      supplierToolFilters,
      supplierChemicalFilters, supplierResourceSort, resourceType],
    async ({ pageParam = 1 }) => {
      const filtersByResource = filters();
      const contextFilters: ContextFiltersType = {
        siteIds: params.sites.selectedSites,
        selectAllSites: params.sites.selectedAllSites,
        filters: { site: params.sites.selectedFilterSites, supplier: supplierFilters, resource: filtersByResource }
      };
      const { results, count } = await viewModel.getSupplierResources(
        companyId,
        params.suppliers.selectedSuppliers,
        params.suppliers.selectedAllSuppliers,
        resourceType,
        filtersByResource as GetSupplierResourcesFilters,
        supplierResourceSort,
        pageParam,
        contextFilters);
      setResourceCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!resourceType && context === "supplier",
      retry: false
    }
  );

  const getDocumentTypes = useInfiniteQuery(
    [
      "download-documents",
      companyId,
      documentTypesByResourceType,
      documentTypesWorkerFilters,
      documentTypesVehicleFilters,
      documentTypesMachineFilters,
      documentTypesToolFilters,
      documentTypesChemicalFilters,
      documentTypesFilters,
      documentTypesSort
    ],
    async ({ pageParam = 1 }) => {
      const isResourceContext = context === "resource";
      const filtersByResource = isResourceContext ? documentTypesFilters : filters();
      const contextFilters: ContextFiltersType = {
        siteIds: params.sites.selectedSites,
        selectAllSites: params.sites.selectedAllSites,
        resourceIds: params.resources.selectedResources,
        selectAllResources: params.resources.selectAllResources,
        filters: { site: params.sites.selectedFilterSites, document: filtersByResource }
      };

      const getDocumentsFunction = isResourceContext
        ? viewModel.getDocumentsByResources
        : viewModel.getDocumentsBySites;
      const { results, count } = await getDocumentsFunction(
        companyId,
        context,
        documentTypesByResourceType,
        filtersByResource as GetDocumentTypeFilters,
        documentTypesSort,
        pageParam,
        contextFilters
      );

      setDocumentTypesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.length === 25 ? pages.length + 1 : undefined,

      enabled: !!documentTypesByResourceType,
      retry: false
    }
  );

  const supplierResourcesFlattened = useMemo(() => supplierResources.data?.pages?.flat() ?? [], [supplierResources.data]);
  const getDocumentTypesFlattened = useMemo(() => getDocumentTypes.data?.pages?.flat() ?? [], [getDocumentTypes.data]);
  const getSuppliersFlattened = useMemo(() => getSuppliers.data?.pages?.flat() ?? [], [getSuppliers.data]);
  return {
    contextHandlers: {
      context,
      setContext,
      subContext,
      setSubContext,
      subContextData,
      setSubContextData
    },
    download: downloadRequest.mutateAsync,
    downloadLoading: downloadRequest.isLoading,
    downloadFeedback,
    setDownloadFeedback,
    supplierData: {
      suppliers: getSuppliersFlattened,
      hasNextPage: getSuppliers.hasNextPage,
      fetchNextPage: getSuppliers.fetchNextPage,
      isLoading: getSuppliers.isLoading,
      count: supplierCount,
      sort: supplierSort,
      setSort: setSupplierSort,
      filters: supplierFilters,
      updateFilters: (
        field: string,
        value: string | string[] | [Date, Date]
      ) => {
        updateFilterWithDelete(setSupplierFilters, field, value);
      }
    },
    documentTypeData: {
      setDocumentTypesByResourceType,
      documentTypes: getDocumentTypesFlattened,
      hasNextPage: getDocumentTypes.hasNextPage,
      fetchNextPage: getDocumentTypes.fetchNextPage,
      isLoading: getDocumentTypes.isLoading,
      count: documentTypesCount,
      sort: documentTypesSort,
      setSort: setDocumentTypesSort,
      filters: context === "resource" ? documentTypesFilters : filters(),
      updateFilters: (
        field: string,
        value: string | string[] | [Date, Date]
      ) => {
        context === "resource" ? updateFilterWithDelete(setDocumentTypesFilters, field, value) : handleFiltersChange(field, value);
      }
    },
    supplierResources: {
      setResourceType,
      resources: supplierResourcesFlattened,
      hasNextPage: supplierResources.hasNextPage,
      fetchNextPage: supplierResources.fetchNextPage,
      isLoading: supplierResources.isLoading || supplierResources.isFetchingNextPage,
      count: resourceCount,
      sort: supplierResourceSort,
      setSort: setSupplierResourceSort,
      filters: filters(),
      updateFilters: (
        field: string,
        value: string | string[] | [Date, Date]
      ) => {
        handleFiltersChange(field, value);
      }
    }
  };
};
export default useSmartDownloadViewModel;
