import { Box, Flex, HStack, Tbody, Td, Th, Thead, Tooltip, Tr, Image, Text, Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FaPlugCircleCheck, FaPlugCircleXmark } from "react-icons/fa6";
import { MdCheckCircle } from "react-icons/md";
import Company from "../../../../domain/entities/company";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import User from "../../../../domain/entities/user";
import { LicenseType } from "../../../../domain/interactors/auth/args";
import { COLORS } from "../../../assets/theme/colors";
import SearchInput from "../../../screens/Common/SearchInput";
import ColumnFilterComponent, { ColumnFilterType } from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { GetTenantsFilters } from "./ChangeTenantModal";

interface tenantsProps {
    tenants?: Company[];
    tenantsHasNextPage?: boolean;
    tenantsFetchNextPage?: () => void;
    tenantsIsLoading?: boolean;
    setSearch?: (value: string) => void;
    filters?: GetTenantsFilters;
    setFilters?: (value: GetTenantsFilters) => void;
    defaultCompany?: string;
    companyUser?: User;
    sort?: SortMeta;
    setSort: (value: SortMeta) => void;
    manageTenantSelection?: (selectedTenant: Company)=>void;
    tableId?: string;
}

const TenantsTable = ({
    tenants, tenantsHasNextPage, tenantsFetchNextPage, tenantsIsLoading, setSearch, 
    filters, setFilters, defaultCompany, companyUser,
    sort, setSort, manageTenantSelection, tableId
}: tenantsProps) => {

    const { t } = useTranslation("common");

    const excludeSort = ["image"];

    const columns: {
        field: keyof GetTenantsFilters;
        type: "text" | "select" | "tags" | "licenseType";
        label: string;
        options?: Record<string, string>;
      }[] = [
        { field: "image", type: "text", label: "" },
        { field: "name", type: "text", label: t("tenant", { ns: "common" }) },
        {
          field: "licenseType",
          type: "licenseType",
          label: t("licenseType", { ns: "onboarding" }),
        },
        {
          field: "licenseState",
          type: "text",
          label: t("licenseState", { ns: "onboarding" }),
        },
      ];

    const getTypeFilter = (column: string) => {
    let type: string;
    if (column === "licenseType") {
        type = "select";
    } else if (column === "licenseState") {
        type = "select";
    } else {
        type = "text";
    }
    return type;
    };

    const filterFields: GetTenantsFilters = filters || {
        name: "",
        image: null,
        licenseType: null,
        licenseState: "enabled",
    };

    const updateFilter = (field: keyof GetTenantsFilters, value: string) => {
        return setFilters({ ...filters, [field]: value });
    };

    const selectLicenses = Object.values(LicenseType).map((type) => ({
        option: type,
        label: t(`${type}`, { ns: "onboarding" }),
    }));
    
    const selectLicenseStates = [
        {
            option: "enabled",
            label: t(`licenseStates.enabled`, { ns: "onboarding" }),
        },
        {
            option: "disabled",
            label: t(`licenseStates.disabled`, { ns: "onboarding" }),
        },
    ];

    return (
      <>
      <Flex justifyContent={"end"} alignItems="center" w={"100%"} mb={4}>
        <SearchInput onSearch={setSearch} />
        </Flex>
      <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          marginTop={5}
          position="relative"
          overflowY={"scroll"}
          height={"100%"}
          id={"testScrollable"}
          overflow={"hidden"}
        >
        <InfiniteTable
          autosize
          tableId={tableId ? tableId : "changeTable"}
          infiniteScroll={{
            hasNextPage: tenantsHasNextPage,
            fetchNextPage: tenantsFetchNextPage,
            dataLength: tenants.filter(Boolean).length,
            scrollableTarget: "testScrollable"
          }}
          showEmptyText={tenants.filter(Boolean).length === 0}
          emptyText={t("noTenants", { ns: "common" })}
          isLoading={tenantsIsLoading}
          bottomThreshold={250}
        >

            <Thead>
              <Tr>
                {columns.map((column) => {
                  return (
                    <Th
                      width={column.field === "image" && "100px"}
                      key={column.field}
                    >
                      <TableColumnHeader
                        text={column.label}
                        filter={
                          column.field !== "image" && {
                            component: (
                              <ColumnFilterComponent
                                type={
                                    getTypeFilter(column.field) as ColumnFilterType
                                }
                                value={filterFields[column.field]}
                                updateFilter={(value) =>
                                  updateFilter(column.field, value as string)
                                }
                                namespace="tenants"
                                selectOptions={
                                  column.field === "licenseType"
                                    ? selectLicenses
                                    : selectLicenseStates
                                }
                              />
                            ),
                            isActive: !!(Array.isArray(filterFields[column.field])
                              ? filterFields[column.field][0]
                              : filterFields[column.field]),
                          }
                        }
                        sort={
                          excludeSort.includes(column.field)
                            ? undefined
                            : {
                                handler: (direction) =>
                                  setSort({ field: column.field, direction }),
                                direction:
                                  sort?.field === column.field
                                    ? sort.direction
                                    : null,
                              }
                        }
                      />
                    </Th>
                  );
                })}
                <Th width="95px"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {tenants && tenants.filter(Boolean).length > 0 &&
                tenants?.map((company) => {
                  return (
                    <Tr
                      key={company?.id}
                      onClick={() => manageTenantSelection(company)}
                      cursor={"pointer"}
                    >
                      <Td width="100px">
                        {company?.logo ? (
                          <Image
                            src={company?.logo}
                            borderRadius="full"
                            w={"40px"}
                            h={"40px"}
                            border={company.id === defaultCompany && "2px solid"}
                            borderColor={
                              company.id === defaultCompany && "gray.600"
                            }
                          />
                        ) : (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            borderRadius="full"
                            boxSize="40px"
                            bg={"gray.400"}
                            border={company?.id === defaultCompany && "2px solid"}
                            borderColor={
                              company?.id === defaultCompany && "gray.600"
                            }
                          >
                            <Text textAlign={"center"} color={"#fff"}>
                              {Array.from(company?.name)[0]}
                            </Text>
                          </Box>
                        )}
                      </Td>
                      <Td>{company.name}</Td>
                      <Td>
                        <Tag variant="outline">{company.type?.toUpperCase()}</Tag>
                      </Td>
                      <Td>
                        <Tooltip
                          label={t("licenseStates." + company.state, {
                            ns: "onboarding",
                          })}
                        >
                          <HStack justifyContent={"center"}>
                            {company.state === "enabled" ? (
                              <IconContext.Provider
                                value={{ color: COLORS.sikuroBlue, size: "1.5rem" }}
                              >
                                <FaPlugCircleCheck />
                              </IconContext.Provider>
                            ) : (
                              <IconContext.Provider
                                value={{ color: COLORS.red, size: "1.5rem" }}
                              >
                                <FaPlugCircleXmark />
                              </IconContext.Provider>
                            )}
                          </HStack>
                        </Tooltip>
                      </Td>
                      <Td width="95px">
                        {company.id === defaultCompany &&
                          companyUser.state === "enabled" && (
                            <Tooltip label={t("loggedTenant")}>
                              <HStack justifyContent={"end"}>
                                <IconContext.Provider
                                  value={{ color: "green", size: "1.5rem" }}
                                >
                                  <MdCheckCircle />
                                </IconContext.Provider>
                              </HStack>
                            </Tooltip>
                          )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>

          </InfiniteTable>
        </Flex>
        </>
    )
}

export default TenantsTable;