import { Box, Divider } from "@chakra-ui/react";
import { MutableRefObject } from "react";
import { Droppable } from "react-beautiful-dnd";
import AiTaskDocument, { AiTaskDocumentType } from "../../../../domain/entities/aiTaskDocument";
import Document from "../../../../domain/entities/document";
import DocumentType from "../../../../domain/entities/documentType";
import { AiDocumentTypeList } from "./AiDocumentTypeList";
import { AiFileList } from "./AiFileList";

interface AiDocumentProps {
  documents?: AiTaskDocumentType[];
  files?: AiTaskDocument[];
  isRecognizedfile?: boolean;
  onDateChange?: (
    id: string,
    newDate: string,
    type: "recognized" | "unrecognized",
    notExpire: boolean,
    documentFileId?: string,
    isDocument?: boolean,
  ) => void;
  removeFile: (id: string, documentId?: string) => void;
  restoreFile: (id: string, documentId?: string) => void;
  addFileToDocumentType?: (
    newFile: AiTaskDocument,
    documentTypeId: string,
  ) => void;
  setSelectedTaskFile: (file: AiTaskDocument) => void;
  selectedTaskFile: AiTaskDocument;
  documentTypesForAi: DocumentType[];
  removeDocument?: (documentId: string) => void
  restoreDocument?: (documentId: string) => void
  newDocumentRef?: MutableRefObject<HTMLDivElement>
  handleChangeDocumentType?: (documentId: string, newDocumentType: string) => void
  showSelectSites: boolean
  recognizedFiles: AiTaskDocumentType[]
  documentList: Document[]
}

export const AiDocumentList = ({
  documents,
  files,
  isRecognizedfile,
  onDateChange,
  removeFile,
  addFileToDocumentType,
  setSelectedTaskFile,
  selectedTaskFile,
  documentTypesForAi,
  removeDocument,
  restoreDocument,
  newDocumentRef,
  handleChangeDocumentType,
  restoreFile,
  showSelectSites,
  recognizedFiles,
  documentList
}: AiDocumentProps) => {
  return (
    <>
      {isRecognizedfile
        ? documents?.map((document: AiTaskDocumentType, index) => {
            return (
              <Droppable  key={`${document.documentTypeId}-${index}`} droppableId={document.documentTypeId} isDropDisabled={showSelectSites}>
                {(provided) => (
                  <div
                  ref={(el) => {
                    if (index === documents.length - 1) {
                      newDocumentRef.current = el;
                    }
                    provided.innerRef(el);
                  }}
                    {...provided.droppableProps}
                    style={{ width: "100%" }}
                  >
                    <Box  key={`${document.documentTypeId}-${index}`}>
                        <AiDocumentTypeList
                          document={document}
                          selectedTaskFile={selectedTaskFile}
                          onDateChange={onDateChange}
                          removeFile={removeFile}
                          restoreFile={(fileId)=> restoreFile(fileId, document.documentTypeId)}
                          setSelectedTaskFile={setSelectedTaskFile}
                          documentTypesForAi={documentTypesForAi}
                          removeDocument={removeDocument}
                          restoreDocument={restoreDocument}
                          handleChangeDocumentType={handleChangeDocumentType}
                          showSelectSites={showSelectSites}
                          recognizedFiles={recognizedFiles}
                          documentsList={documentList}
                        />
                      <Divider orientation="horizontal" />
                    </Box>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })
        : files?.map((file: AiTaskDocument & { archived: boolean}) => {
            return (
              <AiFileList
                key={`${file.id}-${file.documentTypeId}`}
                file={file}
                isRecognizedfile={false}
                addFileToDocumentType={addFileToDocumentType}
                onDateChange={(
                  newDate: string,
                  type: "recognized" | "unrecognized",
                  notExpire: boolean,
                  documentFileId?: string,
                ) =>
                  onDateChange(
                    null,
                    newDate,
                    type,
                    notExpire,
                    documentFileId,
                  )
                }
                showSelectSites={showSelectSites}
                removeFile={removeFile}
                restoreFile={restoreFile}
                selectedTaskFile={selectedTaskFile}
                setSelectedTaskFile={setSelectedTaskFile}
                documentTypesForAi={documentTypesForAi}
                recognizedFiles={recognizedFiles}
                documentList={documentList}
              />
            );
          })}
    </>
  );
};
