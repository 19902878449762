import { Navigate, Route, Routes } from "react-router-dom";
import { Evaluable } from "../../domain/entities/evaluable.enum";
import AccessDeniedMainView from "../components/Permissions/AccessDeniedMainView";
import AccessDeniedView from "../components/Permissions/AccessDeniedView";
import { getSectionPermissions, Permission } from "../components/Permissions/Permissions";
import RequirePermissions from "../components/Permissions/RequirePermissions";
import SiteApproveEvaluationsResourceView from "../components/Views/SiteDetails/SiteApproveEvaluationsResourceView";
import BadgeDetailView from "../screens/Badge/BadgeDetailView";
import CompanyBadgesView from "../screens/Badge/CompanyBadgesView";
import CompanyDetailView from "../screens/Company/CompanyDetailView";
import CompanyDocuTypesMainView from "../screens/Company/CompanyDocuTypesMainView";
import CompanyDocumentTypesView from "../screens/Company/CompanyDocumentTypesView";
import CompanyGeneralDocumentsView from "../screens/Company/CompanyGeneralDocumentsView";
import CompanyMainView from "../screens/Company/CompanyMainView";
import CompanyReqGroupDetailMainView from "../screens/Company/CompanyReqGroupDetailMainView";
import CompanyReqGroupDetailView from "../screens/Company/CompanyReqGroupDetailView";
import CompanyRequirementsGroupsView from "../screens/Company/CompanyRequirementsGroupView";
import DocumentsPresetsContainerView from "../screens/Company/DocumentsPresets/DocumentsPresetsContainerView";
import DocumentsPresetsView from "../screens/Company/DocumentsPresets/DocumentsPresetsView";
import DashboardFallbackView from "../screens/Dashboard/DashboardFallbackView";
import DashboardMainView from "../screens/Dashboard/DashboardMainView";
import NotificationsList from "../screens/Notifications/NotificationsList";
import NotificationsMainView from "../screens/Notifications/NotificationsMainView";
import OnboardingView from "../screens/Onboarding/OnboardingView";
import ChemicalDetailView from "../screens/Resources/Chemicals/ChemicalDetailView";
import ChemicalsView from "../screens/Resources/Chemicals/ChemicalsView";
import MachineDetailView from "../screens/Resources/Machines/MachineDetailView";
import MachinesView from "../screens/Resources/Machines/MachinesView";
import ResourcesMainView from "../screens/Resources/ResourcesMainView";
import ToolDetailView from "../screens/Resources/Tools/ToolDetailView";
import ToolsView from "../screens/Resources/Tools/ToolsView";
import VehicleDetailView from "../screens/Resources/Vehicles/VehicleDetailView";
import VehiclesView from "../screens/Resources/Vehicles/VehiclesView";
import WorkerDetailView from "../screens/Resources/Workers/WorkerDetailView";
import WorkersView from "../screens/Resources/Workers/WorkersView";
import RoleDetailsMainView from "../screens/Settings/Roles/RoleDetailsMainView";
import RoleDetailsView from "../screens/Settings/Roles/RoleDetailsView";
import RolesView from "../screens/Settings/Roles/RolesView";
import SettingsMainView from "../screens/Settings/SettingsMainView";
import UserDetailsMainView from "../screens/Settings/Users/UserDetailsMainView";
import UserDetailsView from "../screens/Settings/Users/UserDetailsView";
import UsersView from "../screens/Settings/Users/UsersView";
import SiteDocumentsView from "../screens/Site/Documents/SiteDocumentsView";
import SiteDocumentsViewContainer from "../screens/Site/Documents/SiteDocumentsViewContainer";
import SiteAccessView from "../screens/Site/SiteAccessView";
import SiteApproveEvaluationsView from "../screens/Site/SiteApproveEvaluationsView";
import SiteBadgeReaderDetail from "../screens/Site/SiteBadgeReaderDetail";
import SiteBadgeReadersView from "../screens/Site/SiteBadgeReadersView";
import SiteBadgesView from "../screens/Site/SiteBadgesView";
import SiteChemicalDetailView from "../screens/Site/SiteChemicalDetailView";
import SiteChemicalsView from "../screens/Site/SiteChemicalsView";
import SiteMachineDetailView from "../screens/Site/SiteMachineDetailView";
import SiteMachinesView from "../screens/Site/SiteMachinesView";
import SiteOptionsView from "../screens/Site/SiteOptionsView";
import SiteOverviewEvaluations from "../screens/Site/SiteOverviewEvaluationsView";
import SiteRequirementsView from "../screens/Site/SiteRequirementsView";
import SiteResourcesView from "../screens/Site/SiteResourcesView";
import SiteRequirementsMainView from "../screens/Site/SiteRquirementsMainView";
import SiteSupplierDetailView from "../screens/Site/SiteSupplierDetailView";
import SiteSuppliersView from "../screens/Site/SiteSuppliersView";
import SiteToolDetailView from "../screens/Site/SiteToolDetailView";
import SiteToolsView from "../screens/Site/SiteToolsView";
import SiteVehicleDetailView from "../screens/Site/SiteVehicleDetailView";
import SiteVehiclesView from "../screens/Site/SiteVehiclesView";
import SiteWorkerDetailView from "../screens/Site/SiteWorkerDetailView";
import SiteWorkersView from "../screens/Site/SiteWorkersView";
import SiteDetailsContainerView from "../screens/Sites/SiteDetailsContainerView";
import SiteDetailsMainView from "../screens/Sites/SiteDetailsMainView";
import SiteDetailsView from "../screens/Sites/SiteDetailsView";
import SiteListView from "../screens/Sites/SiteListView";
import SitesMainView from "../screens/Sites/SitesMainView";
import SupplierChemicalDetailView from "../screens/Suppliers/SupplierChemicalDetailView";
import SupplierChemicalsView from "../screens/Suppliers/SupplierChemicalsView";
import SupplierDetailView from "../screens/Suppliers/SupplierDetailView";
import SupplierListView from "../screens/Suppliers/SupplierListView";
import SupplierMachineDetailView from "../screens/Suppliers/SupplierMachineDetailView";
import SupplierMachinesView from "../screens/Suppliers/SupplierMachinesView";
import SupplierMainView from "../screens/Suppliers/SupplierMainView";
import SupplierResourcesView from "../screens/Suppliers/SupplierResourcesView";
import SupplierToolDetailView from "../screens/Suppliers/SupplierToolDetailView";
import SupplierToolsView from "../screens/Suppliers/SupplierToolsView";
import SupplierVehicleDetailView from "../screens/Suppliers/SupplierVehicleDetailView";
import SupplierVehiclesView from "../screens/Suppliers/SupplierVehiclesView";
import SupplierWorkerDetailView from "../screens/Suppliers/SupplierWorkerDetailView";
import SupplierWorkersView from "../screens/Suppliers/SupplierWorkersView";
import SuppliersMainView from "../screens/Suppliers/SuppliersMainView";
import TimeTableCalendarView from "../screens/TimeTable/TimeTableCalendarView";
import TimeTableMainView from "../screens/TimeTable/TimeTableMainView";
import WorkingSiteChemicalDetailView from "../screens/WorkingSite/WorkingSiteChemicalDetailView";
import WorkingSiteChemicalsView from "../screens/WorkingSite/WorkingSiteChemicalsView";
import WorkingSiteDocumentView from "../screens/WorkingSite/WorkingSiteDocumentsView";
import WorkingSiteMachineDetailView from "../screens/WorkingSite/WorkingSiteMachineDetailView";
import WorkingSiteMachinesView from "../screens/WorkingSite/WorkingSiteMachinesView";
import WorkingSiteMainView from "../screens/WorkingSite/WorkingSiteMainView";
import WorkingSiteResourcesView from "../screens/WorkingSite/WorkingSiteResourcesView";
import WorkingSiteSubcontractsView from "../screens/WorkingSite/WorkingSiteSubcontractsView";
import WorkingSiteToolDetailView from "../screens/WorkingSite/WorkingSiteToolDetailView";
import WorkingSiteToolsView from "../screens/WorkingSite/WorkingSiteToolsView";
import WorkingSiteVehicleDetailView from "../screens/WorkingSite/WorkingSiteVehicleDetailView";
import WorkingSiteVehiclesView from "../screens/WorkingSite/WorkingSiteVehiclesView";
import WorkingSiteWorkerDetailsView from "../screens/WorkingSite/WorkingSiteWorkerDetailView";
import WorkingSiteWorkersView from "../screens/WorkingSite/WorkingSiteWorkersView";
import WorkingSiteListView from "../screens/WorkingSites/WorkingSiteListView";
import WorkingSitesMainView from "../screens/WorkingSites/WorkingSitesMainView";
import RequireLicense from "../components/Permissions/RequireLicense";
import LicenseNotActiveMainView from "../components/Permissions/LicenseNotActiveMainView";
import ActivationMainView from "../screens/Activation/ActivationMainView";
import { PermissionCheck, useAuth } from "../providers/Auth0JWTProvider";
import SiteAuditsView from "../screens/Site/SiteAuditsView";
import SubNavLayout from "../layout/SubNavLayout";
import WebHookView from "../screens/Settings/WebHooks/WebHookView";
import WorkingSiteSubcontractorDetailView from "../screens/WorkingSite/WorkingSiteSubcontractorDetailView";
import { AiTaskSectionCode } from "../../domain/entities/aiTaskSectionCode";
import WorkingSiteDetailsContainerView from "../screens/WorkingSites/WorkingSiteDetailsContainerView";
import UserSettingsMainView from "../screens/UserSettings/UserSettingsMainView";
import { UserSettingsSubNav } from "../screens/UserSettings/UserSettingsSubNav";
import UserSettingsView from "../screens/UserSettings/UserSettingsView";
import UserNotificationsView from "../screens/Settings/Users/UserNotificationsView";
import CustomFieldsView from "../screens/Settings/CustomFields/CustomFieldsView";
import AccessSubNav from "../components/Views/Settings/AccessSubNav";
import CustomFieldsSubNav from "../components/Views/CustomFieldsSubNav";
import {RequirementSubject} from "../../domain/entities/requirementSubject.enum";

const AppRouter = () => {
  const { hasPermissions } = useAuth();

  const getSettingsDefaultTab = (isAccess = false) => {
    if (hasPermissions([Permission.Settings_EditRoles])) {
      return "/settings/access/roles";
    } else if (hasPermissions([Permission.Settings_EditUsers])) {
      return "/settings/access/users";
    } else if (!isAccess && hasPermissions([Permission.Settings_EditWebHooks])) {
      return "/settings/webhooks";
    } else if (!isAccess && hasPermissions([Permission.Settings_EditCustomFields])) {
      return "/settings/custom-fields";
    }  else {
      return "/access-denied";
    }
  };


  const getUserSettingsDefaultTab = () => {
    if (hasPermissions([Permission.Settings_EditRoles])) {
      return "details";
    } else if (hasPermissions([Permission.Settings_EditUsers])) {
      return "notifications";
    } else {
      return "/access-denied";
    }
  };
  return (
    <Routes>
      {/* HOME */}
      <Route path="/" element={<RequireLicense />}>
        <Route path="/" element={<OnboardingView />} />
        {/* DASHBOARD */}
        <Route path="/overview" element={<DashboardMainView />}>
          <Route path="" element={<DashboardFallbackView />} />
        </Route>

        {/* ACTIVATE USER */}
        <Route path="/activate-user" element={<ActivationMainView />} />
        {/* ACTIVATE SUPPLIER */}
        <Route path="/activate-supplier" element={<ActivationMainView />} />
        {/* ACTIVATE SUBCONTRACTOR */}
        <Route
          path="/activate-subcontractor"
          element={<ActivationMainView />}
        />
        {/* CONFIRM SUBCONTRACTOR */}
        <Route path="/confirm-subcontractor" element={<ActivationMainView />} />
        {/* CONFIRM USER EMAIL */}
        <Route path="/email-certification" element={<ActivationMainView />}
        />
        {/* USER SETTINGS */}
        <Route path="/userSettings" element={<UserSettingsMainView />}>
          <Route
            index
            element={<Navigate to={getUserSettingsDefaultTab()} replace />}
          />
          <Route
            path="details"
            element={
              <RequirePermissions
                permissions={[Permission.Settings_EditRoles]}
                element={<SubNavLayout nav={<UserSettingsSubNav />} />}
              />
            }
          >
            <Route path="" element={<UserSettingsView />} />
          </Route>
          <Route
            path="notifications"
            element={
              <RequirePermissions
                permissions={[Permission.Settings_EditUsers]}
                element={<SubNavLayout nav={<UserSettingsSubNav />} />}
              />
            }
          >
            <Route path="" element={<UserNotificationsView />} />
          </Route>
        </Route>

        {/* OWNED SITES */}
        <Route
          path="/sites"
          element={
            <RequirePermissions
              routeName="sites"
              permissions={[Permission.Sites_Manage]}
              element={<SitesMainView />}
              check={PermissionCheck.All}
            />
          }
        >
          <Route path="" element={<SiteListView />} />
        </Route>
        <Route
          path="/sites/:siteId"
          element={
            <RequirePermissions
              routeName="sites"
              permissions={[Permission.Sites_Manage]}
              element={<SiteDetailsMainView />}
              check={PermissionCheck.All}
            />
          }
        >
          <Route
            path="details"
            element={
              <RequirePermissions
                routeName="sites"
                permissions={getSectionPermissions("sites", ["records", "cse-show-sites"])}
                element={<SiteDetailsContainerView />}
                check={PermissionCheck.Some}
              />
            }
          >
            <Route index element={<Navigate to="details" replace />} />
            <Route
              path="details"
              element={
                <SiteDetailsView
                  readonly={false}
                  showStatus={true}
                  isWorkingSite={false}
                  permissions={{
                    addDocument: Permission.Sites_InsertGeneralDocs,
                    downloadDocuments: Permission.Sites_DownloadAll,
                    documents: {
                      delete: Permission.Sites_DeleteGeneralDocs,
                      edit: Permission.Sites_EditGeneralDocs,
                      evaluate: Permission.Sites_EditGeneralDocs,
                      viewEvaluations: Permission.Sites_EditGeneralDocs,
                      downloadAll: Permission.Sites_EditGeneralDocs,
                      addFile: Permission.Sites_EditGeneralDocs,
                      setExpiration: Permission.Sites_EditGeneralDocs,
                      chat: Permission.Sites_EditGeneralDocs,
                      report: Permission.Sites_EditGeneralDocs
                    }
                  }}
                />
              }
            />
            <Route path="options" element={<SiteOptionsView isWorkingSite={false} />} />
          </Route>

          <Route path="documents" element={<SiteDocumentsViewContainer />}>
              <Route index element={<Navigate to="general" replace />} />

            <Route
              path="general"
              element={
                <SiteDocumentsView
                  permissions={{
                    addDocument: Permission.Sites_InsertGeneralDocs,
                    manageDocumentWithAi: Permission.Company_AIManageFile,
                    documents: {
                      addFile: Permission.Sites_EditGeneralDocs,
                      chat: Permission.Sites_EditGeneralDocs,
                      report: Permission.Sites_EditGeneralDocs,
                      delete: Permission.Sites_DeleteGeneralDocs,
                      downloadAll: Permission.Sites_DownloadAll,
                      edit: Permission.Sites_EditGeneralDocs,
                      evaluate: Permission.Sites_EditGeneralDocs,
                      setExpiration: Permission.Sites_EditGeneralDocs,
                      viewEvaluations: Permission.Sites_TemporaryExcludingPermission
                    }
                  }}
                />
              }
            />
            <Route
              path="supplier/:supplierId"
              element={
                <SiteSupplierDetailView
                  fromList={false}
                  deltaWidth={"280px"}
                  permissions={{
                    downloadDocuments: Permission.Sites_DownloadAll,
                    showGlobalEvaluation: Permission.Sites_Manage,
                    setGlobalEvaluation: Permission.Sites_SetOwnerGlobalEval,
                    addRequirement: Permission.Sites_InsertOwnerReq,
                    viewRequirements: Permission.Sites_Manage,
                    editTags: Permission.Sites_EditWorkerRequirements,
                    setStatus: Permission.Sites_SetOwnerStatus,
                    showResources: Permission.Sites_OwnerResources,
                    manageDocumentWithAi: Permission.Company_AIManageFile,
                    documents: {
                      delete: Permission.Sites_DeleteOwnerReq,
                      edit: Permission.Sites_EditOwnerReq,
                      evaluate: Permission.Sites_EvalOwnerReq,
                      viewEvaluations: Permission.Sites_EditOwnerReq,
                      downloadAll: Permission.Sites_EditOwnerReq,
                      addFile: Permission.Sites_EditOwnerReq,
                      setExpiration: Permission.Sites_EvalOwnerReq,
                      chat: Permission.Sites_OwnerChat,
                      report: Permission.Sites_OwnerChat
                    }
                  }}
                  aiTaskCompanySectionCode={
                    AiTaskSectionCode.SITE_DOCUMENTS_COMPANY
                  }
                  aiTaskSiteSectionCode={AiTaskSectionCode.SITE_DOCUMENTS_SITE}
                />
              }
            />
            <Route
              path="siteRequirements/:topic"
              element={<SiteOverviewEvaluations />}
            />
          </Route>

          <Route path="resources" element={<SiteResourcesView />}>
            <Route index element={<Navigate to="workers" replace />} />
            <Route path="workers" element={<SiteWorkersView />} />
            <Route path="vehicles" element={<SiteVehiclesView />} />
            <Route path="machines" element={<SiteMachinesView />} />
            <Route path="tools" element={<SiteToolsView />} />
            <Route path="chemicals" element={<SiteChemicalsView />} />
          </Route>
          <Route
            path="resources/workers/:workerId"
            element={<SiteWorkerDetailView />}
          />
          <Route
            path="resources/machines/:machineId"
            element={<SiteMachineDetailView />}
          />
          <Route
            path="resources/tools/:toolId"
            element={<SiteToolDetailView />}
          />
          <Route
            path="resources/chemicals/:chemicalId"
            element={<SiteChemicalDetailView />}
          />
          <Route
            path="resources/vehicles/:vehicleId"
            element={<SiteVehicleDetailView />}
          />

          <Route path="requirements" element={<SiteRequirementsMainView />}>
            <Route index element={<Navigate to="supplier" replace />} />
            <Route path="supplier" element={<SiteRequirementsView />} />
            <Route path="worker" element={<SiteRequirementsView />} />
            <Route path="vehicle" element={<SiteRequirementsView />} />
            <Route path="machine" element={<SiteRequirementsView />} />
            <Route path="tool" element={<SiteRequirementsView />} />
            <Route path="chemical" element={<SiteRequirementsView />} />
          </Route>

          <Route path="access" element={<SiteAccessView />}>
            <Route index element={<Navigate to="badges" replace />} />
            <Route
              path="badges"
              element={
                <SiteBadgesView
                  managePermissions={[Permission.Sites_ManageBadges]}
                  viewDetailsPermissions={[Permission.Sites_ManageBadges]}
                />
              }
            />
            <Route
              path="badge-readers"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowBadgeReaders]}
                  check={PermissionCheck.All}
                  element={
                    <SiteBadgeReadersView
                      managePermissions={[Permission.Sites_ManageBadgeReaders]}
                      viewDetailsPermissions={[
                        Permission.Sites_ShowBadgeReaderDetails
                      ]}
                      createBadgeReaderPermissions={[
                        Permission.Sites_ManageBadgeReaders,
                        Permission.Sites_CreatePhysicalBadgeReader
                      ]}
                      createVirtualBadgeReaderPermissions={[
                        Permission.Sites_ManageBadgeReaders,
                        Permission.Sites_CreateVirtualBadgeReader
                      ]}
                    />
                  }
                />
              }
            />

            <Route
              path="badge-readers/:badgeReaderId"
              element={
                <RequirePermissions
                  permissions={[Permission.Sites_ShowBadgeReaderDetails]}
                  check={PermissionCheck.All}
                  element={
                    <SiteBadgeReaderDetail
                      permissions={{
                        edit: Permission.Sites_ManageBadgeReaders,
                        delete: Permission.Sites_DeleteBadgeRecord,
                        addRecord: Permission.Sites_CreateBadgeRecord,
                        setStatus: Permission.Sites_SetBadgeReaderStatus
                      }}
                    />
                  }
                />
              }
            />

            <Route
              path="badges/:badgeId"
              element={
                <BadgeDetailView
                  fromSite={true}
                  permissions={{
                    edit: Permission.Sites_ManageBadges,
                    delete: Permission.Sites_DeleteBadgeRecord,
                    addRecord: Permission.Sites_AddManualRecord,
                    setStatus: Permission.Sites_ManageBadges
                  }}
                />
              }
            />
          </Route>

          <Route
            path="suppliers"
            element={
              <SiteSuppliersView
                invitePermissions={[
                  Permission.Sites_Manage,
                  Permission.Sites_InviteSupplier
                ]}
              />
            }
          />
          <Route
            path="suppliers/:supplierId"
            element={
              <SiteSupplierDetailView
                fromList={true}
                deltaWidth="220px"
                permissions={{
                  downloadDocuments: Permission.Supplier_DownloadAll,
                  showGlobalEvaluation: Permission.Sites_Manage,
                  setGlobalEvaluation: Permission.Sites_SetGlobalSupplierEval,
                  addRequirement: Permission.Sites_AddSupplierReq,
                  viewRequirements: Permission.Sites_Manage,
                  editTags: Permission.Sites_EditSupplierRequrements,
                  setStatus: Permission.Sites_SetSupplierStatus,
                  showResources: Permission.Sites_Manage,
                  manageDocumentWithAi: Permission.Company_AIManageFile,
                  documents: {
                    delete: Permission.Sites_DeleteSupplierReq,
                    edit: Permission.Sites_EditSupplierRequrements,
                    evaluate: Permission.Sites_EvalSupplierRequirements,
                    viewEvaluations: Permission.Sites_EditSupplierRequrements,
                    downloadAll: Permission.Sites_DownloadSupplierDocs,
                    addFile: Permission.Sites_EditSupplierRequrements,
                    setExpiration: Permission.Sites_EvalSupplierRequirements,
                    chat: Permission.Sites_SupplierChat,
                    report: Permission.Sites_SupplierChat
                  }
                }}
                aiTaskCompanySectionCode={
                  AiTaskSectionCode.SITE_SUPPLIERS_COMPANY
                }
                aiTaskSiteSectionCode={AiTaskSectionCode.SITE_SUPPLIERS_SITE}
              />
            }
          />

          <Route path="approve" element={<SiteApproveEvaluationsView />}>
            <Route index element={<Navigate to="companies" replace />} />
            <Route
              path="companies"
              element={
                <SiteApproveEvaluationsResourceView type={Evaluable.COMPANY} />
              }
            />
            <Route
              path="workers"
              element={
                <SiteApproveEvaluationsResourceView type={Evaluable.WORKER} />
              }
            />
            <Route
              path="vehicles"
              element={
                <SiteApproveEvaluationsResourceView type={Evaluable.VEHICLE} />
              }
            />
            <Route
              path="machines"
              element={
                <SiteApproveEvaluationsResourceView type={Evaluable.MACHINE} />
              }
            />
            <Route
              path="tools"
              element={
                <SiteApproveEvaluationsResourceView type={Evaluable.TOOL} />
              }
            />
            <Route
              path="chemicals"
              element={
                <SiteApproveEvaluationsResourceView type={Evaluable.CHEMICAL} />
              }
            />
          </Route>
          <Route
            path="audits"
            element={
              <SiteAuditsView createPermissions={[Permission.Worksite_Show]} />
            }
          />
        </Route>

        {/* WORKING SITES */}
        <Route
          path="/workingsites"
          element={
            <RequirePermissions
              routeName="workingSites"
              permissions={[Permission.Worksite_Show]}
              element={<WorkingSitesMainView />}
              check={PermissionCheck.All}
            />
          }
        >
          <Route path="" element={<WorkingSiteListView />} />
        </Route>

        <Route
          path="/workingsites/:siteId"
          element={
            <RequirePermissions
              routeName="workingSites"
              permissions={[Permission.Worksite_Show]}
              element={<WorkingSiteMainView />}
              check={PermissionCheck.All}
            />
          }
        >
            <Route
            path="details"
            element={
              <RequirePermissions
                routeName="workingsites"
                permissions={getSectionPermissions("worksite", ["records", "cse-show-working-sites"])}
                element={<WorkingSiteDetailsContainerView />}
                check={PermissionCheck.Some}
              />
            }
          >
          <Route index element={<Navigate to="details" replace />} />
          <Route
            path="details"
            element={
              <SiteDetailsView
                readonly={true}
                showStatus={false}
                isWorkingSite={true}
                permissions={{
                  addDocument: Permission.Worksite_EditCompanyReq,
                  downloadDocuments: Permission.Worksite_DownloadDocs,
                  documents: {
                    delete: Permission.Worksite_DeleteFile,
                    edit: Permission.Worksite_EditFile,
                    evaluate: Permission.Worksite_EvalReq,
                    viewEvaluations: Permission.Worksite_EvalReq,
                    downloadAll: Permission.Worksite_DownloadAllFile,
                    addFile: Permission.Worksite_AddFile,
                    setExpiration: Permission.Worksite_SetExpiration,
                    chat: Permission.Worksite_EditCompanyReq,
                    report: Permission.Worksite_EditCompanyReq
                  }
                }}
              />
            }
          />
          <Route path="options" element={<SiteOptionsView isWorkingSite={true} />} />
          </Route>

          <Route path="resources" element={<WorkingSiteResourcesView />}>
            <Route index element={<Navigate to="workers" replace />} />
            <Route path="workers" element={<WorkingSiteWorkersView />} />
            <Route path="vehicles" element={<WorkingSiteVehiclesView />} />
            <Route path="machines" element={<WorkingSiteMachinesView />} />
            <Route path="tools" element={<WorkingSiteToolsView />} />
            <Route path="chemicals" element={<WorkingSiteChemicalsView />} />
          </Route>
          <Route
            path="resources/workers/:workerId"
            element={<WorkingSiteWorkerDetailsView />}
          />
          <Route
            path="resources/chemicals/:chemicalId"
            element={<WorkingSiteChemicalDetailView />}
          />
          <Route
            path="resources/machines/:machineId"
            element={<WorkingSiteMachineDetailView />}
          />
          <Route
            path="resources/tools/:toolId"
            element={<WorkingSiteToolDetailView />}
          />
          <Route
            path="resources/vehicles/:vehicleId"
            element={<WorkingSiteVehicleDetailView />}
          />

          <Route
            path="documents"
            element={
              <RequirePermissions
                permissions={[Permission.Worksite_ShowRequirements, Permission.Worksite_CseShowWorkingSites]}
                check={PermissionCheck.Some}
                element={
                  <WorkingSiteDocumentView
                    permissions={{
                      downloadAll: Permission.Worksite_DownloadDocs,
                      addRequirement: Permission.Worksite_AddCompanyReq,
                      showGlobalEvaluation:
                      Permission.Worksite_ShowGlobalCompanyEval,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      documents: {
                        delete: Permission.Worksite_DeleteFile,
                        edit: Permission.Worksite_EditFile,
                        evaluate: Permission.Worksite_EvalReq,
                        viewEvaluations: Permission.Worksite_EvalReq,
                        downloadAll: Permission.Worksite_DownloadAllFile,
                        addFile: Permission.Worksite_EditCompanyReq,
                        setExpiration: Permission.Worksite_SetExpiration,
                        chat: Permission.Worksite_CompanyChat,
                        report: Permission.Worksite_Reports,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route
            path="subcontracts"
            element={
              <WorkingSiteSubcontractsView
                invitePermissions={[Permission.Worksite_InviteSupplier]}
              />
            }
          />
          <Route
            path="subcontracts/:supplierId"
            element={
              <WorkingSiteSubcontractorDetailView
                fromList={true}
                isWorkingSite={true}
                isSubcontract={true}
                permissions={{
                  downloadDocuments: Permission.Supplier_DownloadAll,
                  showGlobalEvaluation: Permission.Sites_Manage,
                  setGlobalEvaluation: Permission.Sites_SetGlobalSupplierEval,
                  addRequirement: Permission.Sites_AddSupplierReq,
                  viewRequirements: Permission.Sites_Manage,
                  editTags: Permission.Sites_EditSupplierRequrements,
                  setStatus: Permission.Sites_SetSupplierStatus,
                  showResources: Permission.Sites_Manage,
                  documents: {
                    delete: Permission.Sites_DeleteSupplierReq,
                    edit: Permission.Sites_EditSupplierRequrements,
                    evaluate: Permission.Sites_EvalSupplierRequirements,
                    viewEvaluations: Permission.Sites_EditSupplierRequrements,
                    downloadAll: Permission.Sites_EditSupplierRequrements,
                    addFile: Permission.Worksite_AddFileSubcontractor,
                    addNewFile: Permission.Worksite_AddFile,
                    setExpiration: Permission.Sites_EvalSupplierRequirements,
                    chat: Permission.Sites_SupplierChat,
                    report: Permission.Sites_SupplierChat,
                  },
                }}
              />
            }
          />
        </Route>

        {/* COMPANY */}
        <Route
          path="/company"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("company")}
              element={<CompanyMainView />}
            />
          }
        >
          <Route index element={<Navigate to="records" replace />} />
          <Route
            path="records"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("company", "records")}
                element={<CompanyDetailView />}
              />
            }
          />

          <Route
            path="general-documents"
            element={
              <RequirePermissions
                permissions={getSectionPermissions(
                  "company",
                  "general-documents"
                )}
                element={<CompanyGeneralDocumentsView />}
              />
            }
          />

          <Route
            path="requirements"
            element={
              <RequirePermissions
                permissions={getSectionPermissions(
                  "company",
                  "requirements-groups"
                )}
                element={
                  <CompanyRequirementsGroupsView
                    addGroupPermissions={[Permission.Company_AddReqGroup]}
                    manageGroupPermissions={[Permission.Company_EditReqGroup]}
                  />
                }
              />
            }
          />
          <Route
            path="requirements/:id"
            element={
              <RequirePermissions
                permissions={getSectionPermissions(
                  "company",
                  "requirements-groups"
                )}
                element={<CompanyReqGroupDetailMainView />}
              />
            }
          >
            <Route index element={<Navigate to="supplier" replace />} />
            <Route path="supplier" element={<CompanyReqGroupDetailView />} />
            <Route path="worker" element={<CompanyReqGroupDetailView />} />
            <Route path="vehicle" element={<CompanyReqGroupDetailView />} />
            <Route path="machine" element={<CompanyReqGroupDetailView />} />
            <Route path="tool" element={<CompanyReqGroupDetailView />} />
            <Route path="chemical" element={<CompanyReqGroupDetailView />} />
          </Route>

          <Route
            path="document-types"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("company", "document-types")}
                element={<CompanyDocuTypesMainView />}
              />
            }
          >
            <Route index element={<Navigate to="company" replace />} />
            <Route path="company" element={<CompanyDocumentTypesView />} />
            <Route path="worker" element={<CompanyDocumentTypesView />} />
            <Route path="vehicle" element={<CompanyDocumentTypesView />} />
            <Route path="machine" element={<CompanyDocumentTypesView />} />
            <Route path="tool" element={<CompanyDocumentTypesView />} />
            <Route path="chemical" element={<CompanyDocumentTypesView />} />
            <Route path="site" element={<CompanyDocumentTypesView />} />
          </Route>

          <Route
            path="documents-presets"
            element={
              <RequirePermissions
                permissions={getSectionPermissions(
                  "company",
                  "document-presets"
                )}
                element={<DocumentsPresetsContainerView />}
              />
            }
          >
            <Route index element={<Navigate to="workers" replace />} />
            <Route path="workers" element={<DocumentsPresetsView />} />
            <Route path="vehicles" element={<DocumentsPresetsView />} />
            <Route path="machines" element={<DocumentsPresetsView />} />
            <Route path="tools" element={<DocumentsPresetsView />} />
            <Route path="chemicals" element={<DocumentsPresetsView />} />
            <Route path="sites" element={<DocumentsPresetsView />} />
          </Route>

          <Route
            path="badges"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("company", "badge")}
                element={<CompanyBadgesView />}
              />
            }
          />
          <Route
            path="badges/:badgeId"
            element={
              <RequirePermissions
                permissions={[Permission.Company_ShowBadgeDetails]}
                routeName="companyBadgeDetails"
                element={
                  <BadgeDetailView
                    fromSite={false}
                    permissions={{
                      unlinkBadge: Permission.Company_UnlinkBadgeResource,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      edit: Permission.Company_AddBadge,
                      addRecord: Permission.Company_ShowBadgeDetails,
                      setStatus: Permission.Company_SetBadgeStatus,
                    }}
                  />
                }
              />
            }
          />
        </Route>
        {/* RESOURCES */}
        <Route
          path="/resources"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("resources")}
              element={<ResourcesMainView />}
            />
          }
        >
          <Route index element={<Navigate to="workers" replace />} />

          <Route path="workers" element={<WorkersView />} />
          <Route
            path="workers/:id/details"
            element={
              <RequirePermissions
                permissions={[Permission.Resources_ShowWorkerDetails]}
                element={
                  <WorkerDetailView
                    permissions={{
                      editTypology: Permission.Resources_EditWorkerTypology,
                      addDocument: Permission.Resources_AddWorkerDoc,
                      setState: Permission.Resources_SetWorkerState,
                      editRecord: Permission.Resources_AddWorker,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Resources_DeleteWorkerDoc,
                        edit: Permission.Resources_EditWorkerDocs,
                        evaluate: Permission.Resources_EvalWorkerDocs,
                        viewEvaluations: Permission.Resources_EvalWorkerDocs,
                        downloadAll: Permission.Resources_DownloadWorkerDocs,
                        addFile: Permission.Resources_EditWorkerDocs,
                        setExpiration:
                          Permission.Resources_SetWorkerDocExpiration,
                        chat: Permission.Resources_EditWorkerDocs,
                        report: Permission.Resources_EditWorkerDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="vehicles" element={<VehiclesView />} />
          <Route
            path="vehicles/:id/details"
            element={
              <RequirePermissions
                permissions={[Permission.Resources_ShowVehicleDetails]}
                element={
                  <VehicleDetailView
                    permissions={{
                      editTypology: Permission.Resources_EditVehicleTypology,
                      addDocument: Permission.Resources_AddVehicleDoc,
                      editRecord: Permission.Resources_AddVehicle,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      migrateToMachine:
                        Permission.Resources_MigrateVehicleToMachine,
                      documents: {
                        delete: Permission.Resources_DeleteVehicleDoc,
                        edit: Permission.Resources_EditVehicleDocs,
                        evaluate: Permission.Resources_EvalVehicleDocs,
                        viewEvaluations: Permission.Resources_EvalVehicleDocs,
                        downloadAll: Permission.Resources_DownloadVehicleDocs,
                        addFile: Permission.Resources_EditVehicleDocs,
                        setExpiration:
                          Permission.Resources_SetVehicleDocExpiration,
                        chat: Permission.Resources_EditVehicleDocs,
                        report: Permission.Resources_EditVehicleDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="machines" element={<MachinesView />} />
          <Route
            path="machines/:id/details"
            element={
              <RequirePermissions
                permissions={[Permission.Resources_ShowMachineDetails]}
                element={
                  <MachineDetailView
                    permissions={{
                      editTypology: Permission.Resources_EditMachineTypology,
                      addDocument: Permission.Resources_AddMachineDoc,
                      editRecord: Permission.Resources_AddMachine,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      migrateToVehicle:
                        Permission.Resources_MigrateMachineToVehicle,
                      documents: {
                        delete: Permission.Resources_DeleteMachineDoc,
                        edit: Permission.Resources_EditMachineDocs,
                        evaluate: Permission.Resources_EvalMachineDocs,
                        viewEvaluations: Permission.Resources_EvalMachineDocs,
                        downloadAll: Permission.Resources_DownloadMachineDocs,
                        addFile: Permission.Resources_EditMachineDocs,
                        setExpiration:
                          Permission.Resources_SetMachineDocExpiration,
                        chat: Permission.Resources_EditMachineDocs,
                        report: Permission.Resources_EditMachineDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="tools" element={<ToolsView />} />
          <Route
            path="tools/:id/details"
            element={
              <RequirePermissions
                permissions={[Permission.Resources_ShowToolDetails]}
                element={
                  <ToolDetailView
                    permissions={{
                      editTypology: Permission.Resources_EditToolTypology,
                      addDocument: Permission.Resources_AddToolDoc,
                      editRecord: Permission.Resources_AddTool,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      documents: {
                        delete: Permission.Resources_DeleteToolDoc,
                        edit: Permission.Resources_EditToolDocs,
                        evaluate: Permission.Resources_EvalToolDocs,
                        viewEvaluations: Permission.Resources_EvalToolDocs,
                        downloadAll: Permission.Resources_DownloadToolDocs,
                        addFile: Permission.Resources_EditToolDocs,
                        setExpiration:
                          Permission.Resources_SetToolDocExpiration,
                        chat: Permission.Resources_EditToolDocs,
                        report: Permission.Resources_EditToolDocs,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route path="chemicals" element={<ChemicalsView />} />
          <Route
            path="chemicals/:id/details"
            element={
              <RequirePermissions
                permissions={[Permission.Resources_ShowChemicalDetails]}
                element={
                  <ChemicalDetailView
                    permissions={{
                      editTypology: Permission.Resources_EditChemicalTypology,
                      addDocument: Permission.Resources_AddChemicalDoc,
                      manageDocumentWithAi: Permission.Company_AIManageFile,
                      editRecord: Permission.Resources_AddChemical,
                      documents: {
                        delete: Permission.Resources_DeleteChemicalDoc,
                        edit: Permission.Resources_EditChemicalDocs,
                        evaluate: Permission.Resources_EvalChemicalDocs,
                        viewEvaluations: Permission.Resources_EvalChemicalDocs,
                        downloadAll: Permission.Resources_DownloadChemicalDocs,
                        addFile: Permission.Resources_EditChemicalDocs,
                        setExpiration:
                          Permission.Resources_SetChemicalDocExpiration,
                        chat: Permission.Resources_EditChemicalDocs,
                        report: Permission.Resources_EditChemicalDocs,
                      },
                    }}
                  />
                }
              />
            }
          />
        </Route>

        {/* SUPPLIERS */}
        <Route
          path="/suppliers"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("supplier")}
              element={<SuppliersMainView />}
            />
          }
        >
          <Route path="" element={<SupplierListView />} />
        </Route>

        <Route
          path="/suppliers/:supplierId"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("supplier")}
              element={<SupplierMainView />}
            />
          }
        >
          <Route index element={<Navigate to="details" replace />} />
          <Route
            path="details"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "records")}
                element={
                  <SupplierDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadAll,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Supplier_Global_DeleteFile,
                        edit: Permission.Supplier_Global_EditFile,
                        evaluate: Permission.Supplier_EvalCompanyReq,
                        viewEvaluations: Permission.Supplier_EvalCompanyReq,
                        downloadAll: Permission.Supplier_DownloadAll,
                        addFile: Permission.Supplier_Global_AddFile,
                        setExpiration: Permission.Supplier_Global_SetExpiration,
                        chat: Permission.Supplier_EvalCompanyReq,
                        report: Permission.Supplier_EvalCompanyReq,
                      },
                    }}
                  />
                }
              />
            }
          />

          <Route
            path="resources"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", [
                  "workers",
                  "machines",
                  "tools",
                  "vehicles",
                  "chemicals",
                ])}
                element={<SupplierResourcesView />}
              />
            }
          >
            <Route index element={<Navigate to="workers" replace />} />
            <Route
              path="workers"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "workers")}
                  element={<SupplierWorkersView />}
                />
              }
            />
            <Route
              path="vehicles"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "vehicles")}
                  element={<SupplierVehiclesView />}
                />
              }
            />
            <Route
              path="machines"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "machines")}
                  element={<SupplierMachinesView />}
                />
              }
            />
            <Route
              path="tools"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "tools")}
                  element={<SupplierToolsView />}
                />
              }
            />
            <Route
              path="chemicals"
              element={
                <RequirePermissions
                  permissions={getSectionPermissions("supplier", "chemicals")}
                  element={<SupplierChemicalsView />}
                />
              }
            />
          </Route>

          <Route
            path="resources/workers/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "workers")}
                element={
                  <SupplierWorkerDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadWorkerDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Supplier_Global_Worker_DeleteFile,
                        edit: Permission.Supplier_Global_Worker_Edit,
                        evaluate: Permission.Supplier_EvalWorkerReq,
                        viewEvaluations: Permission.Supplier_EvalWorkerReq,
                        downloadAll: Permission.Supplier_DownloadWorkerDocs,
                        addFile: Permission.Supplier_Global_Worker_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Worker_Expiration,
                        chat: Permission.Supplier_EvalWorkerReq,
                        report: Permission.Supplier_EvalWorkerReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/vehicles/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "vehicles")}
                element={
                  <SupplierVehicleDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadVehicleDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Supplier_Global_Vehicle_DeleteFile,
                        edit: Permission.Supplier_Global_Vehicle_Edit,
                        evaluate: Permission.Supplier_EvalVehicleReq,
                        viewEvaluations: Permission.Supplier_EvalVehicleReq,
                        downloadAll: Permission.Supplier_DownloadVehicleDocs,
                        addFile: Permission.Supplier_Global_Vehicle_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Vehicle_Expiration,
                        chat: Permission.Supplier_EvalVehicleReq,
                        report: Permission.Supplier_EvalVehicleReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/machines/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "machines")}
                element={
                  <SupplierMachineDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadMachineDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Supplier_Global_Machine_DeleteFile,
                        edit: Permission.Supplier_Global_Machine_Edit,
                        evaluate: Permission.Supplier_EvalMachineReq,
                        viewEvaluations: Permission.Supplier_EvalMachineReq,
                        downloadAll: Permission.Supplier_DownloadMachineDocs,
                        addFile: Permission.Supplier_Global_Machine_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Machine_Expiration,
                        chat: Permission.Supplier_EvalMachineReq,
                        report: Permission.Supplier_EvalMachineReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/tools/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "tools")}
                element={
                  <SupplierToolDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadToolDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Supplier_Global_Tool_DeleteFile,
                        edit: Permission.Supplier_Global_Tool_Edit,
                        evaluate: Permission.Supplier_EvalToolReq,
                        viewEvaluations: Permission.Supplier_EvalToolReq,
                        downloadAll: Permission.Supplier_DownloadToolDocs,
                        addFile: Permission.Supplier_Global_Tool_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Tool_Expiration,
                        chat: Permission.Supplier_EvalToolReq,
                        report: Permission.Supplier_EvalToolReq,
                      },
                    }}
                  />
                }
              />
            }
          />
          <Route
            path="resources/chemicals/:resourceId"
            element={
              <RequirePermissions
                permissions={getSectionPermissions("supplier", "workers")}
                element={
                  <SupplierChemicalDetailView
                    permissions={{
                      addDocument: Permission.Supplier_DownloadChemicalDocs,
                      linkBadge: Permission.Company_LinkBadgeResource,
                      documents: {
                        delete: Permission.Supplier_Global_Chemical_DeleteFile,
                        edit: Permission.Supplier_Global_Chemical_Edit,
                        evaluate: Permission.Supplier_EvalChemicalReq,
                        viewEvaluations: Permission.Supplier_EvalChemicalReq,
                        downloadAll: Permission.Supplier_DownloadChemicalDocs,
                        addFile: Permission.Supplier_Global_Chemical_AddFile,
                        setExpiration:
                          Permission.Supplier_Global_Chemical_Expiration,
                        chat: Permission.Supplier_EvalChemicalReq,
                        report: Permission.Supplier_EvalChemicalReq,
                      },
                    }}
                  />
                }
              />
            }
          />
        </Route>

        {/* TIMETABLE */}
        <Route
          path="/timetable"
          element={
            <RequirePermissions
              permissions={getSectionPermissions("calendar")}
              element={<TimeTableMainView />}
            />
          }
        >
          <Route path="" element={<TimeTableCalendarView />} />
        </Route>

        {/* SETTINGS */}
        <Route
          path="/settings"
          element={
            <RequirePermissions
              check={PermissionCheck.Some}
              permissions={getSectionPermissions("settings")}
              element={<SettingsMainView />}
            />
          }
        >
          <Route
            index
            element={<Navigate to={getSettingsDefaultTab()} replace />}
          />
          <Route
            path="access"
          >
            <Route
              index
              element={<Navigate to={getSettingsDefaultTab(true)} replace />}
            />
            <Route
              path="roles"
              element={
                <RequirePermissions
                  permissions={[Permission.Settings_EditRoles]}
                  element={<SubNavLayout nav={<AccessSubNav />} />}
                />
              }
            >
              <Route path="" element={<RolesView />} />
            </Route>
            <Route
              path="users"
              element={
                <RequirePermissions
                  permissions={[Permission.Settings_EditUsers]}
                  element={<SubNavLayout nav={<AccessSubNav />} />}
                />
              }
            >
              <Route path="" element={<UsersView />} />
            </Route>
          </Route>

          <Route
            path="webhooks"
            element={
              <RequirePermissions
                permissions={[Permission.Settings_EditWebHooks]}
                element={<WebHookView />}
              />
            }
          />

          <Route
            path="custom-fields"
            element={
              <RequirePermissions
                permissions={[Permission.Settings_EditCustomFields]}
                element={<SubNavLayout nav={<CustomFieldsSubNav />} />}
              />
            }
          >
            <Route
              index
              element={<Navigate to="company" replace />}
            />
            {Object.values(RequirementSubject).map((subject) => (
              <Route key={subject} path={subject} element={<CustomFieldsView />} />
            ))}
          </Route>

        </Route>

        <Route
          path="/settings/access/roles/:roleId"
          element={
            <RequirePermissions
              permissions={[Permission.Settings_EditRoles]}
              element={<RoleDetailsMainView />}
            />
          }
        >
          <Route path="" element={<RoleDetailsView />} />
        </Route>
        <Route
          path="/settings/access/users/:userId"
          element={
            <RequirePermissions
              permissions={[Permission.Settings_EditUsers]}
              element={<UserDetailsMainView />}
            />
          }
        >
          <Route path="" element={<UserDetailsView />} />
        </Route>

        {/* NOTIFICATIONS */}
        <Route path="/notifications" element={<NotificationsMainView />}>
          <Route index element={<Navigate to="unread" replace />} />
          <Route
            path="unread"
            element={<NotificationsList pageSize={25} status="new" />}
          />
          <Route
            path="archived"
            element={<NotificationsList pageSize={25} status="archived" />}
          />
        </Route>

        {/* Other */}
        <Route
          path="/access-denied"
          element={<AccessDeniedMainView layout="main" />}
        >
          <Route path="" element={<AccessDeniedView />} />
        </Route>

        <Route
          path="license-not-active"
          element={<LicenseNotActiveMainView />}
        ></Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
