import { makeSiteMachineRepository } from './repositories';
import GetSiteMachine from '../../../../domain/interactors/resources/siteMachines/getSiteMachine';
import GetSiteMachines from '../../../../domain/interactors/resources/siteMachines/getSiteMachines';
import CreateSiteMachine from '../../../../domain/interactors/resources/siteMachines/createSiteMachine';
import DeleteSiteMachine from '../../../../domain/interactors/resources/siteMachines/deleteSiteMachine';
import RestoreSiteMachine from '../../../../domain/interactors/resources/siteMachines/restoreSiteMachine';
import EvaluateSiteMachine from '../../../../domain/interactors/resources/siteMachines/evaluateSiteMachine';
import GetWorkingSiteMachines from '../../../../domain/interactors/resources/siteMachines/getWorkingSiteMachines';
import AutoEvaluateSiteMachine from '../../../../domain/interactors/resources/siteMachines/autoEvaluateSiteMachine';
import GetSiteMachineDocuments from '../../../../domain/interactors/resources/siteMachines/getSiteMachineDocuments';
import UpdateSiteMachineVariant from '../../../../domain/interactors/resources/siteMachines/updateSiteMachineVariant';
import GetSiteMachineEvaluations from '../../../../domain/interactors/resources/siteMachines/getSiteMachineEvaluations';
import AddRequirementToSiteMachine from '../../../../domain/interactors/resources/siteMachines/addRequirementToSiteMachine';
import UpdateSiteMachineGlobalStatus from '../../../../domain/interactors/resources/siteMachines/updateSiteMachineGlobalStatus';
import UpdateSiteMachineSpecializations from '../../../../domain/interactors/resources/siteMachines/updateSiteMachineSpecializations';
import GetSiteMachineDocumentsRejected from '../../../../domain/interactors/resources/siteMachines/getSiteMachineDocumentsRejected';

export const makeGetSiteMachines = () => {
	return new GetSiteMachines(makeSiteMachineRepository());
};

export const makeGetWorkingSiteMachines = () => {
	return new GetWorkingSiteMachines(makeSiteMachineRepository());
};

export const makeGetSiteMachine = () => {
	return new GetSiteMachine(makeSiteMachineRepository());
};

export const makeCreateSiteMachine = () => {
	return new CreateSiteMachine(makeSiteMachineRepository());
};

export const makeDeleteSiteMachine = () => {
	return new DeleteSiteMachine(makeSiteMachineRepository());
};

export const makeRestoreSiteMachine = () => {
	return new RestoreSiteMachine(makeSiteMachineRepository());
};

export const makeGetSiteMachineDocuments = () => {
	return new GetSiteMachineDocuments(makeSiteMachineRepository());
};

export const makeGetSiteMachineDocumentsRejected = () => {
	return new GetSiteMachineDocumentsRejected(makeSiteMachineRepository());
};

export const makeUpdateSiteMachineVariant = () => {
	return new UpdateSiteMachineVariant(makeSiteMachineRepository());
};

export const makeUpdateSiteMachineSpecializations = () => {
	return new UpdateSiteMachineSpecializations(makeSiteMachineRepository());
};

export const makeEvaluateSiteMachine = () => {
	return new EvaluateSiteMachine(makeSiteMachineRepository());
};

export const makeAutoEvaluateSiteMachine = () => {
	return new AutoEvaluateSiteMachine(makeSiteMachineRepository());
};
export const makeGetSiteMachineEvaluations = () => {
	return new GetSiteMachineEvaluations(makeSiteMachineRepository());
};

export const makeAddRequirementToSiteMachine = () => {
	return new AddRequirementToSiteMachine(makeSiteMachineRepository());
};

export const makeUpdateSiteMachineGlobalStatus = () => {
	return new UpdateSiteMachineGlobalStatus(makeSiteMachineRepository());
};
