import UseCase from '../../../../core/app/usecase';
import SiteToolRepository from '../../../repositories/siteToolRepository';
import { SiteResourceDocument } from '../../../entities/document';

type Parameters = {
	companyId: string;
	siteId: string;
	toolId: string;
};

class GetSiteToolDocumentsRejected implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, toolId }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteToolDocumentsRejected(companyId, siteId, toolId);
	}
}

export default GetSiteToolDocumentsRejected;
