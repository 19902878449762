import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateForInputHTML } from "../../../../utils";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { Permission } from "../../Permissions/Permissions";
import SelectSitesView from "../common/SelectSitesView";
import {
  ExportItem,
  exportItems,
  ExportSection,
  exportSections,
} from "./ExportDialogData";
import InputAnimatedTimePicker from "../../Common/InputAnimatedTimePicker";
import Steps from "../../Common/stepper/Steps";
import InputField from "../../Common/table/InputField";
import useExportViewModel from "../../../hooks/Export/useExport";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import ExportResourceRequirementsView from "./ExportResourceRequirementsView";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { AuditAccordion } from "../../../screens/Site/AuditAccordion";

interface ExportDialogProps {
  onClose: () => void;
  onExport: () => void;
}

const ExportDialog = ({ onClose, onExport }: ExportDialogProps) => {
  const { t } = useTranslation("common");
  const toast = useToast();

  const {
    setSelectedSiteId,
    setSelectedResourceType,
    selectedResourceType,
    setIsWorkingSite,
    
    // own sites parameters
    ownSites,
    isLoading,
    ownSiteSort,
    setOwnSiteSort,
    getSites,
    ownSiteFilter,
    setOwnSiteFilter,
    updateOwnSiteFilter,

    // working sites parameters
    workingSites, isLoadingWorkingSites, 
    workingSiteFilter, updateWorkingSiteFilter, 
    workingSiteSort, setWorkingSiteSort, setWorkingSiteSearch,

    siteWorkers,
    siteWorkersHasNextPage,
    siteWorkersFetchNextPage,
    siteWorkersIsLoading,
    setEnableGetWorkers,

    workersRequirements,
    workersRequirementsHasNextPage,
    workersRequirementsFetchNextPage,
    workersRequirementsIsLoading,
    setEnableGetWorkersRequirements,

    siteVehicles,
    siteVehiclesHasNextPage,
    siteVehiclesFetchNextPage,
    siteVehiclesIsLoading,
    setEnableGetVehicles,

    vehiclesRequirements,
    vehiclesRequirementsHasNextPage,
    vehiclesRequirementsFetchNextPage,
    vehiclesRequirementsIsLoading,
    setEnableGetVehiclesRequirements,

    siteMachines,
    siteMachinesHasNextPage,
    siteMachinesFetchNextPage,
    siteMachinesIsLoading,
    setEnableGetMachines,

    machinesRequirements,
    machinesRequirementsHasNextPage,
    machinesRequirementsFetchNextPage,
    machinesRequirementsIsLoading,
    setEnableGetMachinesRequirements,

    siteTools,
    siteToolsHasNextPage,
    siteToolsFetchNextPage,
    siteToolsIsLoading,
    setEnableGetTools,

    toolsRequirements,
    toolsRequirementsHasNextPage,
    toolsRequirementsFetchNextPage,
    toolsRequirementsIsLoading,
    setEnableGetToolsRequirements,

    siteChemicals,
    siteChemicalsHasNextPage,
    siteChemicalsFetchNextPage,
    siteChemicalsIsLoading,
    setEnableGetChemicals,

    chemicalsRequirements,
    chemicalsRequirementsHasNextPage,
    chemicalsRequirementsFetchNextPage,
    chemicalsRequirementsIsLoading,
    setEnableGetChemicalsRequirements,

    filterSiteWorkers,
    setFilterSiteWorkers,
    setFilterSiteChemicals,
    setFilterSiteMachines,
    setFilterSiteVehicles,
    setFilterSiteTools,
    filterSiteChemicals,
    filterSiteMachines,
    filterSiteTools,
    filterSiteVehicles,

    supplierDocuments,
    supplierDocumentsHasNextPage,
    supplierDocumentsFetchNextPage,
    supplierDocumentsIsLoading,
    setEnabledSupplierDocuments,

    initiateExportFiles
  } = useExportViewModel();

  const [siteResourcesToInclude, setSiteResourcesToInclude] = useState<string[]>([]);
  const [siteResourcesRequirementsToExclude, setSiteResourcesRequirementsToExclude] = useState<string[]>([]);

  const [selectedSection, setSelectedSection] = useState<string>();
  const [selectedExportItem, setSelectedExportItem] = useState<ExportItem>();
  const { hasPermissions } = useAuth();

  // export badges stamps states
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);

  const [actionStartDate, setActionStartDate] = useState<Date>(undefined);
  const [actionStartTime, setActionStartTime] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [actionStartDateError, setActionStartDateError] = useState<
    string | null
  >(null);
  const [actionStartTimeError, setActionStartTimeError] = useState<
    string | null
  >(null);

  const [actionEndDate, setActionEndDate] = useState<Date>(undefined);
  const [actionEndTime, setActionEndTime] = useState<Date>(
    new Date(new Date().setHours(23, 59, 0, 0))
  );
  const [actionEndDateError, setActionEndDateError] = useState<string | null>(
    null
  );
  const [actionEndTimeError, setActionEndTimeError] = useState<string | null>(
    null
  );

  const [permissionSites, setPermissionsSites] = useState<{
    [key: string]: string;
  }>({});

  const [selectedFilters, setSelectedFilters] = useState<{
    siteId: string;
    siteIds: string[];
    dateRange: { startDate: Date; endDate: Date };
    resourceIds: string[];
    documentsIds: string[];
  }>({
    siteId: "",
    siteIds: [],
    dateRange: { startDate: new Date(), endDate: new Date() },
    resourceIds: [],
    documentsIds: []
  });

  const steps = [
    {
      title: t("selectSection"),
    },
    {
      title: t("selectFile"),
    },
    {
      title: t("selectParameters"),
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const onSelectStep = (index: number) => {
    setActiveStep(index);
  };

  const handleExport = () => {
    initiateExportFiles(
      selectedExportItem.topic,
      (ok: boolean) => {
        toast({
          duration: 5000,
          isClosable: true,
          colorScheme: ok ? "green" : "red",
          icon: ok ? <FiInfo /> : <FiAlertTriangle />,
          description: ok
            ? t("downloadAllFilesOk", { ns: "documents" })
            : t("downloadAllFilesError", { ns: "documents" }),
        });
      },
      selectedFilters.siteId,
      selectedSiteIds,
      new Date(
        actionStartDate?.getFullYear(),
        actionStartDate?.getMonth(),
        actionStartDate?.getDate(),
        actionStartTime?.getHours(),
        actionStartTime?.getMinutes()
      ),
      new Date(
        actionEndDate?.getFullYear(),
        actionEndDate?.getMonth(),
        actionEndDate?.getDate(),
        actionEndTime?.getHours(),
        actionEndTime?.getMinutes()
      ),
      permissionSites,
      siteResourcesToInclude,
      siteResourcesRequirementsToExclude
    );
    onExport();
  };

  const handleNext = () => {

    // Check if the resourceType exists and access it
    const resourceType = selectedExportItem?.params?.[0]?.resourceType;

    if (resourceType) {
      setSelectedResourceType(resourceType);
    } else {
      setSelectedResourceType(undefined);
    }

    if (activeStep===1){
      if (selectedExportItem?.topic?.includes("ws")){
        setIsWorkingSite(true);
      }else{
        setIsWorkingSite(false);
      }
    }

    if (activeStep===1){
      setEnableGetWorkers(false);
      setEnableGetWorkersRequirements(false);
      setEnableGetMachines(false);
      setEnableGetMachinesRequirements(false);
      setEnableGetVehicles(false);
      setEnableGetVehiclesRequirements(false);
      setEnableGetTools(false);
      setEnableGetToolsRequirements(false);
      setEnableGetChemicals(false);
      setEnableGetChemicalsRequirements(false);
      setEnabledSupplierDocuments(false);

      setSelectedSiteId(undefined);
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        siteId: undefined,
      }));
    }

    return activeStep === 2 ? handleExport() : onSelectStep(activeStep + 1);
  };

  const handleSectionChange = (value: string) => {
    setSelectedSection(value);
    setSelectedExportItem(undefined);
    setSelectedSiteIds([]);
    setPermissionsSites({})
    setActionStartDate(undefined)
    setActionEndDate(undefined)
  };

  const handleSelectExportItem = (val: any) => {
    const exportItem = JSON.parse(val);
    setSelectedExportItem(exportItem);
    setSelectedSiteIds([]);
    setPermissionsSites({})
    setActionStartDate(undefined)
    setActionEndDate(undefined)
  };

  const handleFilters = (e) => {
    const newSiteId = e.target.value;
    const resourceType = selectedExportItem?.params?.[0]?.resourceType;

    setSelectedSiteId(newSiteId);
    setSiteResourcesToInclude([]);

    switch ( resourceType ) {
      case ResourceType.WORKER:
        setEnableGetWorkers(true);
        setEnableGetWorkersRequirements(true);
        break;
      case ResourceType.MACHINE:
        setEnableGetMachines(true);
        setEnableGetMachinesRequirements(true);
        break;
      case ResourceType.VEHICLE:
        setEnableGetVehicles(true);
        setEnableGetVehiclesRequirements(true);
        break;
      case ResourceType.TOOL:
        setEnableGetTools(true);
        setEnableGetToolsRequirements(true);
        break;
      case ResourceType.CHEMICAL:
        setEnableGetChemicals(true);
        setEnableGetChemicalsRequirements(true);
        break;
      case ResourceType.SUPPLIER:
        setEnabledSupplierDocuments(true);
        break;
      default:
        console.log("no type");
    }

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      siteId: newSiteId,
    }));
  };

  const handleStartDateChange = (fieldName, value) => {
    setActionStartDateError(null);
    setActionStartDate(value ? new Date(value) : undefined);
  };

  const handleStartTimeChange = (fieldName, value) => {
    setActionStartTimeError(null);
    const [hours, minutes] = value.split(":");
    const today = new Date();
    setActionStartTime(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        parseInt(hours),
        parseInt(minutes)
      )
    );
  };

  const handleEndDateChange = (fieldName, value) => {
    setActionEndDateError(null);
    setActionEndDate(new Date(value));
  };

  const handleEndTimeChange = (fieldName, value) => {
    setActionEndTimeError(null);
    const [hours, minutes] = value.split(":");
    const today = new Date();
    setActionEndTime(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        parseInt(hours),
        parseInt(minutes)
      )
    );
  };

  const handleFilterType = (type: string) => {
    switch (type) {
      case "siteId-workers-documents":
      case "workingSiteId-workers-documents":
        return (
          <ExportResourceRequirementsView
            selectedSiteId={selectedFilters.siteId}
            handleFilters={handleFilters}
            sitesList={ownSites}
            suppliersList={workingSites}
            isWorkingSite={type === "workingSiteId-workers-documents" ? true : false}
            resourceAccordionTitle={t("workers", { ns: "audit" })}
            resourceList={siteWorkers}
            resourcesAreLoading={siteWorkersIsLoading}
            includedResource={siteResourcesToInclude}
            setIncludedResource={setSiteResourcesToInclude}

            setSiteResourceFilter={setFilterSiteWorkers}
            siteResourceFilter={filterSiteWorkers}
            resourcesHasNextPage={siteWorkersHasNextPage}
            resourcesFetchNextPage={siteWorkersFetchNextPage}
            selectedExportItem={selectedExportItem}
        
            requirements={workersRequirements}
            requirementsAccordionTitle={t("workersRequirements", { ns: "audit" })}
            requirementsHasNextPage={workersRequirementsHasNextPage}
            requirementsFetchNextPage={workersRequirementsFetchNextPage}
            requirementsAreLoading={workersRequirementsIsLoading}
            requirementsToExclude={siteResourcesRequirementsToExclude}
            setRequirementsToExlude={setSiteResourcesRequirementsToExclude}
          />
        );

      case "siteId-vehicles-documents":
      case "workingSiteId-vehicles-documents":
        return (
          <ExportResourceRequirementsView
            selectedSiteId={selectedFilters.siteId}
            handleFilters={handleFilters}
            sitesList={ownSites}
            suppliersList={workingSites}
            isWorkingSite={type === "workingSiteId-vehicles-documents" ? true : false}
            resourceAccordionTitle={t("vehicles", { ns: "audit" })}
            resourceList={siteVehicles}
            resourcesAreLoading={siteVehiclesIsLoading}
            includedResource={siteResourcesToInclude}
            setIncludedResource={setSiteResourcesToInclude}

            setSiteResourceFilter={setFilterSiteVehicles}
            siteResourceFilter={filterSiteVehicles}
            resourcesHasNextPage={siteVehiclesHasNextPage}
            resourcesFetchNextPage={siteVehiclesFetchNextPage}
            selectedExportItem={selectedExportItem}
        
            requirements={vehiclesRequirements}
            requirementsAccordionTitle={t("vehiclesRequirements", { ns: "audit" })}
            requirementsHasNextPage={vehiclesRequirementsHasNextPage}
            requirementsFetchNextPage={vehiclesRequirementsFetchNextPage}
            requirementsAreLoading={vehiclesRequirementsIsLoading}
            requirementsToExclude={siteResourcesRequirementsToExclude}
            setRequirementsToExlude={setSiteResourcesRequirementsToExclude}
          />
        );

      case "siteId-machines-documents":
      case "workingSiteId-machines-documents":
        return (
          <ExportResourceRequirementsView
            selectedSiteId={selectedFilters.siteId}
            handleFilters={handleFilters}
            sitesList={ownSites}
            suppliersList={workingSites}
            isWorkingSite={type === "workingSiteId-machines-documents" ? true : false}
            resourceAccordionTitle={t("machines", { ns: "audit" })}
            resourceList={siteMachines}
            resourcesAreLoading={siteMachinesIsLoading}
            includedResource={siteResourcesToInclude}
            setIncludedResource={setSiteResourcesToInclude}

            setSiteResourceFilter={setFilterSiteMachines}
            siteResourceFilter={filterSiteMachines}
            resourcesHasNextPage={siteMachinesHasNextPage}
            resourcesFetchNextPage={siteMachinesFetchNextPage}
            selectedExportItem={selectedExportItem}
        
            requirements={machinesRequirements}
            requirementsAccordionTitle={t("machinesRequirements", { ns: "audit" })}
            requirementsHasNextPage={machinesRequirementsHasNextPage}
            requirementsFetchNextPage={machinesRequirementsFetchNextPage}
            requirementsAreLoading={machinesRequirementsIsLoading}
            requirementsToExclude={siteResourcesRequirementsToExclude}
            setRequirementsToExlude={setSiteResourcesRequirementsToExclude}
          />
        );

        case "siteId-tools-documents":
        case "workingSiteId-tools-documents":
          return (
            <ExportResourceRequirementsView
              selectedSiteId={selectedFilters.siteId}
              handleFilters={handleFilters}
              sitesList={ownSites}
              suppliersList={workingSites}
              isWorkingSite={type === "workingSiteId-tools-documents" ? true : false}
              resourceAccordionTitle={t("tools", { ns: "audit" })}
              resourceList={siteTools}
              resourcesAreLoading={siteToolsIsLoading}
              includedResource={siteResourcesToInclude}
              setIncludedResource={setSiteResourcesToInclude}
  
              setSiteResourceFilter={setFilterSiteTools}
              siteResourceFilter={filterSiteTools}
              resourcesHasNextPage={siteToolsHasNextPage}
              resourcesFetchNextPage={siteToolsFetchNextPage}
              selectedExportItem={selectedExportItem}
          
              requirements={toolsRequirements}
              requirementsAccordionTitle={t("toolsRequirements", { ns: "audit" })}
              requirementsHasNextPage={toolsRequirementsHasNextPage}
              requirementsFetchNextPage={toolsRequirementsFetchNextPage}
              requirementsAreLoading={toolsRequirementsIsLoading}
              requirementsToExclude={siteResourcesRequirementsToExclude}
              setRequirementsToExlude={setSiteResourcesRequirementsToExclude}
            />
          );

          case "siteId-chemicals-documents":
          case "workingSiteId-chemicals-documents":
            return (
              <ExportResourceRequirementsView
                selectedSiteId={selectedFilters.siteId}
                handleFilters={handleFilters}
                sitesList={ownSites}
                suppliersList={workingSites}
                isWorkingSite={type === "workingSiteId-chemicals-documents" ? true : false}
                resourceAccordionTitle={t("chemicals", { ns: "audit" })}
                resourceList={siteChemicals}
                resourcesAreLoading={siteChemicalsIsLoading}
                includedResource={siteResourcesToInclude}
                setIncludedResource={setSiteResourcesToInclude}
    
                setSiteResourceFilter={setFilterSiteChemicals}
                siteResourceFilter={filterSiteChemicals}
                resourcesHasNextPage={siteChemicalsHasNextPage}
                resourcesFetchNextPage={siteChemicalsFetchNextPage}
                selectedExportItem={selectedExportItem}
            
                requirements={chemicalsRequirements}
                requirementsAccordionTitle={t("chemicalsRequirements", { ns: "audit" })}
                requirementsHasNextPage={chemicalsRequirementsHasNextPage}
                requirementsFetchNextPage={chemicalsRequirementsFetchNextPage}
                requirementsAreLoading={chemicalsRequirementsIsLoading}
                requirementsToExclude={siteResourcesRequirementsToExclude}
                setRequirementsToExlude={(siteIds)=>{
                  setSiteResourcesRequirementsToExclude(siteIds);
                  }
                }
              />
            );

      case "workingSiteId-supplier-documents":
        return (
          <Flex flexDirection={"column"} marginRight={25}>
            <Box my={4}>
              <Select onChange={handleFilters} value={selectedFilters.siteId}>
                <option value={""}>{t("selectSite", { ns: "common" })}</option>
                {workingSites &&
                  Array.isArray(workingSites) &&
                  workingSites.map((item) => (
                    <option key={item?.site.id} value={item?.site.id}>
                      {item?.site.name}
                    </option>
                  ))}
              </Select>
            </Box>
            <Box my={4}>
            <AuditAccordion
              documentTypeList={supplierDocuments}
              hasNextPage={supplierDocumentsHasNextPage}
              fetchNextPage={supplierDocumentsFetchNextPage}
              requirementsAreLoading={
                supplierDocumentsIsLoading
              }
              excludedDocumentTypes={siteResourcesRequirementsToExclude}
              setExcludedDocumentTypes={
                setSiteResourcesRequirementsToExclude
              }
              title={t("companyRequirements", { ns: "audit" })}
              type="requirement"
            />
          </Box>
          </Flex>
        );
      
      case "siteId":
        return (
          <Box my={4}>
            <Select onChange={handleFilters} value={selectedFilters.siteId}>
              <option value={""}>{t("selectSite")}</option>
              {ownSites &&
                Array.isArray(ownSites) &&
                ownSites.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
            </Select>
          </Box>
        );

      case "workingSiteId":
        return (
          <Box my={4}>
            <Select onChange={handleFilters} value={selectedFilters.siteId}>
              <option value={""}>{t("selectSite", { ns: "common" })}</option>
              {workingSites &&
                Array.isArray(workingSites) &&
                workingSites.map((item) => (
                  <option key={item?.site.id} value={item?.site.id}>
                    {item?.site.name}
                  </option>
                ))}
            </Select>
          </Box>
        );

      case "badgesParameters":
      case "badgesDeniedParameters":
        return (
          <Box>
            <SelectSitesView
              autosize
              title={t(type === "badgesParameters" ? "selectSites" : "selectSitesForDenied", { ns: "common" })}
              reminderText={t("noSiteSelected", { ns: "common" })}
              alertText={t("siteSelectionAlert", { ns: "common" })}
              siteSelectedAction={setSelectedSiteIds}
              siteList={ownSites}
              includeFooterButton={false}
              showSelectAll={false}
              showPermissionsSelect={type === "badgesParameters" ? true : false}
              setPermissionsSites={setPermissionsSites}
              hideSubtitles={true}
            />

            <Text marginLeft={2} marginTop={2} fontSize={16}>
              {t(type === "badgesParameters" ? "selectStampsDate" : "selectStampsDeniedDate", { ns: "common" })}
            </Text>

            <Flex flexDirection={"row"} marginTop={5}>
              <Box width={"30%"}>
                <InputField
                  field={"actionStartDate"}
                  type={"date"}
                  label={t("stampingStartDate")}
                  value={formatDateForInputHTML(actionStartDate) ?? ""}
                  onChange={handleStartDateChange}
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionStartDateError}
                </Text>
              </Box>

              <Box width={"30%"} marginLeft={10}>
                <InputAnimatedTimePicker
                  onChange={handleStartTimeChange}
                  id={"actionStartTime"}
                  label={t("stampingStartTime")}
                  defaultValue={
                    `${actionStartTime
                      ?.getHours()
                      ?.toString()
                      ?.padStart(2, "0")}:${actionStartTime
                      ?.getMinutes()
                      ?.toString()
                      ?.padStart(2, "0")}` ?? ""
                  }
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionStartTimeError}
                </Text>
              </Box>
            </Flex>

            <Flex flexDirection={"row"}>
              <Box width={"30%"}>
                <InputField
                  field={"actionEndDate"}
                  type={"date"}
                  label={t("stampingEndDate")}
                  value={formatDateForInputHTML(actionEndDate) ?? ""}
                  onChange={handleEndDateChange}
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionEndDateError}
                </Text>
              </Box>

              <Box width={"30%"} marginLeft={10}>
                <InputAnimatedTimePicker
                  onChange={handleEndTimeChange}
                  id={"actionEndTime"}
                  label={t("stampingEndTime")}
                  defaultValue={
                    `${actionEndTime
                      ?.getHours()
                      ?.toString()
                      ?.padStart(2, "0")}:${actionEndTime
                      ?.getMinutes()
                      ?.toString()
                      ?.padStart(2, "0")}` ?? ""
                  }
                />
                <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
                  {actionEndTimeError}
                </Text>
              </Box>
            </Flex>
          </Box>
        );

      default:
        <p>{t("noParametersRequired", { ns: "common" })}</p>;
    }
  };

  const renderSection = (section: ExportSection) => {
    const hasPermissionForSection = (section: ExportSection) => {
      switch (section.title) {
        case "mySites":
          return hasPermissions([Permission.Sites_Manage]);
        case "myWorkingSites":
          return hasPermissions([Permission.Worksite_Show]);
        default:
          return true;
      }
    };

    if (!hasPermissionForSection(section)) {
      return null;
    }

    return (
      <Flex flexDirection={"column"}>
        <Radio key={section.title} value={section.title}>
          <Text marginLeft={2}>{t("exportSections." + section.title)}</Text>
        </Radio>
        <Text
          marginLeft={8}
          fontStyle={"italic"}
          fontSize={"0,85rem"}
          color={"gray.500"}
        >
          {t(section.description, { ns: "export" })}
        </Text>
      </Flex>
    );
  };

  const manageCancelButton = () => {
    switch (activeStep){
      case 0:
        onClose();
        return;
      case 1: 
        setActiveStep(0);
        return;
      case 2:
        setActiveStep(1);
        return;
    }
    onClose();
  }

  const isNextStepDisabled = (() => { 
    if (!selectedSection || (activeStep !== 0 && !selectedExportItem)){
      return true
    } else if (activeStep === 2) {
      const { hasParams, topic } = selectedExportItem || {};
      const isBadgesTopic = topic === "badges-records" || topic === "badges-records-denied";
      const isRequirementsTopic = topic?.includes("requirements");
      const isSuppliersTopic = topic?.includes("suppliers");

      if (hasParams && !selectedFilters.siteId && !isBadgesTopic) {
        return true;
      }
      if (hasParams && isBadgesTopic && (selectedSiteIds?.length === 0 || !actionStartDate || !actionEndDate)) {
        return true;
      }
      if (topic !== "badges-records-denied" && selectedSiteIds?.length !== Object.keys(permissionSites).length) {
        return true;
      }
      if (isRequirementsTopic && !isSuppliersTopic && siteResourcesToInclude.length === 0) {
        return true;
      }
    }
    return false
  })()

  return (
    <Modal isOpen={true} onClose={onClose} size="6xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent
        style={{ background: "white", padding: "2rem" }}
        overflowY={"auto"}
      >
        <Box padding={4} border="1px" borderColor="gray.200" borderRadius={6}>
          <Steps selected={activeStep} steps={steps} onSelect={onSelectStep} />
        </Box>
        <Text fontSize="xl" fontWeight={500}>
          {t(`titleStep${activeStep + 1}`)}
        </Text>
        <Text fontSize="sm" mb={5}>
          {t(`descriptionStep${activeStep + 1}`)}
        </Text>
        <Box py={4} >
          {
            // section selection page
          }
          {activeStep === 0 && (
            <RadioGroup px={4} onChange={handleSectionChange} defaultValue={selectedSection}>
              <Stack spacing={4} direction="column">
                {exportSections.map((section: ExportSection) => {
                  return renderSection(section);
                })}
              </Stack>
            </RadioGroup>
          )}

          {
            // context selection page
          }
          {activeStep === 1 && (
            <Box 
              maxHeight={"400px"} 
              overflowY={"auto"}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '16px',
                  borderRadius: '8px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `rgba(0, 0, 0, 0.1)`,
                  borderRadius: '8px',
                  border: '2px solid rgba(0, 0, 0, 0.05)'
                },
              }}>
              <RadioGroup
                px={4}
                onChange={(value) => handleSelectExportItem(value)}
                defaultValue={JSON.stringify(selectedExportItem)}
              >
                <Stack spacing={4} direction="column">
                  {exportItems[selectedSection]?.map((x) => (
                    <Flex flexDirection={"column"} key={x.title}>
                      <Radio value={JSON.stringify(x)}>
                        <Text marginLeft={2}>
                          {t(x.title, { ns: "export" })}
                        </Text>
                      </Radio>
                      <Text
                        marginLeft={8}
                        fontStyle={"italic"}
                        fontSize={"0,85rem"}
                        color={"gray.500"}
                      >
                        {t(x.description, { ns: "export" })}
                      </Text>
                    </Flex>
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
          )}

          {
            // parameters selection page
          }
          {activeStep === 2 && (
            <Box maxHeight={"400px"} overflowY={"auto"}
            sx={{
              '&::-webkit-scrollbar': {
                width: '16px',
                borderRadius: '8px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                borderRadius: '8px',
                border: '2px solid rgba(0, 0, 0, 0.05)',
              },
            }}>
              {!selectedExportItem?.hasParams ? (
                <Text>{t("noParametersRequired", { ns: "common" })}</Text>
              ) : (
                selectedExportItem.params?.map((p) => (
                  <>{handleFilterType(p.type)}</>
                ))
              )}
            </Box>
          )}
        </Box>
        <Flex justifyContent="flex-end" gap={2} mt={5}>
          <Button
            colorScheme="blue"
            onClick={handleNext}
            isDisabled={isNextStepDisabled}
          >
            {activeStep === 2 ? t("export") : t("confirm")}
          </Button>
          <Button colorScheme="red" onClick={manageCancelButton}>
            {t("cancel")}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ExportDialog;
