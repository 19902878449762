import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  SelectedAllResourcesByType,
  SelectedResourcesByType, SmartDownloadContextType, SmartDownloadSubContextDataType, SmartDownloadSubContextType
} from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import { Resource } from "../../../../domain/repositories/smartDownloadRepository";
import Worker from "../../../../domain/entities/worker";
import { useMemo } from 'react';

interface Props {
  context?: SmartDownloadContextType;
  subContext?: SmartDownloadSubContextType;
  subContextData?: SmartDownloadSubContextDataType;
  selectedResourcesByType?: SelectedResourcesByType;
  selectedDocumentsByResourceType?: SelectedResourcesByType;
  selectedDocuments?: Resource[] | DocumentType[];
  selectedAll?: boolean;
}

const SmartDownloadSummary: FC<Props> = ({
                                           context,
                                           subContext,
                                           subContextData,
                                           selectedResourcesByType ,
                                           selectedDocumentsByResourceType,
                                           selectedDocuments,
                                           selectedAll}) => {
  const { t } = useTranslation("common");
  const contextTitle = useMemo(() => {
    switch (subContextData) {
      case 'documentType.all':
        return t("smartDownload.downloadSelected.companyAndSite");
      case 'documentType.company':
        return t("smartDownload.downloadSelected.company");
      case 'documentType.site':
        return t("smartDownload.downloadSelected.site");
      default:
        return null;
    }
  }, [subContextData, t]);
  const contentByContext = (() => {
    switch (context) {
      case "documentType":
        return (
            <Flex
              my={4}
              flexDirection="column"
              alignItems="start"
              width="100%"
              position="relative"
              overflow="hidden"
            >
              <Text fontSize="md" mb={4}>
                {t("smartDownload.downloadSelected.title")}
                &nbsp;
                {t("smartDownload.downloadSelected.documents")}
              </Text>
              {selectedDocumentsByResourceType && Object.entries(selectedDocumentsByResourceType).map(
                ([key, document]) =>
                  document.length > 0 && (
                    <Box key={key} mb={4}>
                      <Text fontSize="md" fontWeight="bold" mb={2}>
                        {t(`${key}s`, { ns: "audit" })}
                      </Text>
                      <UnorderedList>
                        {document.map((doc) => (
                          <ListItem key={doc.id}>
                            <Flex gap={2}>
                              <Text>{doc.name}</Text>
                            </Flex>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </Box>
                  )
              )}
            </Flex>
        );
      case "supplier":
        return (
          <Box>
            {subContext === "all" && (
              <Flex
                my={4}
                flexDirection="column"
                alignItems="start"
                width="100%"
                position="relative"
                overflow="hidden"
              >
                <Text fontSize="md" mb={4}>
                  {t("smartDownload.downloadSelected.title")}
                  &nbsp;
                  {t("smartDownload.downloadSelected.all") }
                </Text>
              </Flex>
            )}

            {subContext === "company" && (
              <Flex
                my={4}
                flexDirection="column"
                alignItems="start"
                width="100%"
                position="relative"
                overflow="hidden"
              >
                <Text fontSize="md" mb={4}>
                  {t("smartDownload.downloadSelected.title")}
                  &nbsp;
                  {contextTitle}
                </Text>
              </Flex>
            )}

            {subContext === "resource" && selectedResourcesByType && (
              <Flex
                my={4}
                flexDirection="column"
                alignItems="start"
                width="100%"
                position="relative"
                overflow="hidden"
              >
                <Text fontSize="md" mb={4}>
                  {t("smartDownload.downloadSelected.title")}
                  &nbsp;
                  {t("smartDownload.downloadSelected.resources") }
                </Text>
                {selectedAll ? <Text>{t("smartDownload.downloadSelected.all")}</Text> : <Box>
                  {Object.entries(selectedResourcesByType).map(([key, resources]) =>
                      resources.length > 0 && (
                        <Box key={key} mb={4}>
                          <Text fontSize="md" fontWeight="bold" mb={2}>{t(`${key}s`, { ns: "audit" })}</Text>
                          <UnorderedList>
                            {resources.map((resource) => (
                              <ListItem key={resource.id}>
                                <Flex gap={2}>
                                  {key === "worker" ? (
                                    <>
                                      <Text>{(resource as Worker).firstName}</Text>
                                      <Text>{(resource as Worker).lastName}</Text>
                                      <Text>{(resource as Worker).companyName}</Text>
                                    </>
                                  ) : (
                                    <>
                                      <Text>{resource.name}</Text>
                                      <Text>{resource.companyName}</Text>
                                    </>
                                  )}
                                </Flex>
                              </ListItem>
                            ))}
                          </UnorderedList>
                        </Box>
                      )
                  )}
                </Box>}
              </Flex>
            )}
          </Box>
        );
      case "resource":
        return <Box>
          <Text fontSize="md" mb={4}>
            {t("smartDownload.downloadSelected.title")}
            &nbsp;
            {t("smartDownload.downloadSelected.resources")}
          </Text>
          {!selectedAll && <UnorderedList>
            {selectedDocuments?.map((d) => (
              <ListItem key={d.id}>
                <Flex gap={2}>
                  <Text>{d.name}</Text>
                </Flex>
              </ListItem>
            ))}
          </UnorderedList>}
        </Box>;
      default:
        return ;
    }
  });

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius={6}
      px={4}>
      {contentByContext()}
    </Box>
  );
};

export default SmartDownloadSummary;