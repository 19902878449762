import { useQuery } from "@tanstack/react-query";
import { BusinessLicenseResponse, BusinessOption, BusinessSizeResponse, BusinessTypeResponse } from "../../../domain/interactors/auth/args";
import OnboardingViewModel from "../../viewmodels/onboarding/OnboardingViewModel";
import { UseFormReturn } from "react-hook-form";
import { FormType } from "../Onboarding/useOnboardingViewModel";

const useCompanyOnboardingViewModel = ({methods}: {methods: UseFormReturn<FormType>}) => {
  const vm = new OnboardingViewModel();

   const getBusinessVatCountries = useQuery<BusinessOption[], Error>(
    ["get-vat-countries"],
    async () => {
      const countries = await vm.getVatCountries();
      return countries;
    },
    {
      onSuccess: () => {
        methods.setValue("vatCountry", "IT");
      },
    }
   );
  
  
  const getBusinessSizes = useQuery<BusinessSizeResponse[], Error>(
    ["get-business-sizes"],
    async () => {
      const sizes = await vm.getBusinessSizes();      
      return sizes;
    },
    {
      onSuccess: (sizes) => {
        methods.setValue("businessSize", sizes[0].value);
      },
    }
  );

  const getBusinessTypes = useQuery<BusinessTypeResponse[], Error>(
    ["get-business-types"],
    async () => {
      const types = await vm.getBusinessTypes();
      return types;
    },
    {
      onSuccess: (types) => {
        methods.setValue("businessType", types[0].value);
      },
    }
  )
 
  const getBusinessLicenseTypes = useQuery<BusinessLicenseResponse[], Error>(
    ["get-license-types"],
    async () => {
      const types = await vm.getLicenseTypes();
      return types;
    },
    {
      onSuccess: (types) => {
        methods.setValue("licenseType", types[0].value);
      },
    }
  );
  return {
    methods,
    vatCountries: getBusinessVatCountries.data,
    businessSizes: getBusinessSizes.data,
    businessTypes: getBusinessTypes.data,
    licenseTypes: getBusinessLicenseTypes.data,
  }
}

export default useCompanyOnboardingViewModel;
