import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  Text,
  ModalOverlay,
  ModalHeader,
  Box,
  IconButton,
  Spacer,
  Skeleton,
  Switch,
  FormControl,
  FormLabel,
  ModalBody,
  Button,
  useMediaQuery,
  Icon,
  Tooltip,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Divider,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MentionsInput, Mention } from "react-mentions";
import { MdSend } from "react-icons/md";
import { COLORS } from "../../../assets/theme/colors";
import { MessageDirection, MessageView } from "./MessageView";
import { darken } from "polished";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { formatDateBasedOnLanguage } from "../../../../utils";
import Style from "./mentions.module.css";
import "./mentionStyle.css";
import LoadingView from "../../../screens/Common/LoadingView";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import { ButtonCommandMessage } from "../../../../domain/entities/documentComment";
import SearchInput from "../../../screens/Common/SearchInput";
import { PiExportBold } from "react-icons/pi";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { RiSlashCommands2 } from "react-icons/ri";

type DocumentCommunicationModalProps = {
  onClose: () => void;
  hook: DocumentCommunicationHook;
  permissions?: ResourceDocumentsPermissions;
};

const DocumentCommunicationModal = ({
  onClose,
  hook,
  permissions,
}: DocumentCommunicationModalProps) => {
  const isReady = hook.getNotificationsStatus && hook.resourceDocumentNote;
  return <Wrapper hook={hook} onClose={onClose} permissions={permissions} isReady={isReady ? true : false}/>;
};

const Wrapper = ({
  onClose,
  hook,
  permissions,
  isReady
}: {
  onClose: () => void;
  hook: DocumentCommunicationHook;
  permissions: ResourceDocumentsPermissions;
  isReady?: boolean;
}) => {
  const { t } = useTranslation("communication");
  const { user } = useAuth();
  const messagesEndRef = useRef(null);

  const {
    resourceDocumentNote,
    resourceDocumentNoteFetching,
    createResourceDocumentNote,
    createResourceDocumentIsLoading,
    resourceDocumentComments,
    createResourceDocumentComment,
    resourceDocumentCommentsFetching,
    taggableUsers,
    taggableDocuments,
    missingDocuments,
    updateNotificationsStatus,
    getNotificationsStatus,
    isFetching,
    exportChatComments,
    search, setSearch,
    setDocumentCommentsQueryEnabled
  } = hook;

  setDocumentCommentsQueryEnabled(true);
  
  const [statusChat, setStatusChat] = useState(getNotificationsStatus?.status);
  const [textNote, setTextNote] = useState(resourceDocumentNote?.note);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [askConfirmNotSave, setAskConfirmNotSave] = useState(false);
  const [textComment, setTextComment] = useState<string>("");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [popoverOpen, setPopOverOpen] = useState<boolean>();
  const toast = useToast();

  const recoveredUsers = useMemo(() => taggableUsers?.map((item) => ({
    id: item.id,
    display: item.name,
  })), [taggableUsers]);

  const recoveredDocuments = useMemo(() => taggableDocuments?.map((item) => ({
    id: item.id,
    display: item.name,
  })), [taggableDocuments]);

  const chatCommands = useMemo(() => {
    // this check on the content of the text input area is done to trigger the opening of
    // chat commands just when the input is empty
    if (textComment.trim() === "/" || textComment.trim() === ""){
      return Object.entries(ButtonCommandMessage).map(([key, value]) => ({
        id: key,
        display: t(value, {ns: "documents"})
      }));
    }
    return [];
  }, [textComment]);

  const handleClose = () => {
    if (textNote !== resourceDocumentNote.note) {
      setAskConfirmNotSave(true);
    } else {
      onClose();
    }
  };

  const handleButtonClick = async () => {
    const commentToBeSent = textComment;
    setTextComment("");
    await createResourceDocumentComment(commentToBeSent);
    
  };

  const handleExportClick = () => {
    exportChatComments((ok: boolean) => {
      toast({
        duration: 5000,
        isClosable: true,
        colorScheme: ok ? "green" : "red",
        icon: ok ? <FiInfo /> : <FiAlertTriangle />,
        description: ok
          ? t("exportChatOk", { ns: "communication" })
          : t("exportChatError", { ns: "communication" }),
      });
    });
  }

  const handleChatStatus = async (active: boolean) => {
    setStatusChat(active);
    await updateNotificationsStatus(active);
  };

  const onCommandSelected = async (commandId: string) => {
    let commandMessage = "";
    const matchedCommand = Object.entries(ButtonCommandMessage).find(
      ([key]) => key === commandId
    );
    if (matchedCommand) {
      commandMessage = matchedCommand[1];
    }
    if (commandMessage) {
      console.log(`Command matched: ${commandMessage}`);


      if (commandMessage.includes("missing")){
        //here we build the message using the missing documents
        if (missingDocuments.length > 0) {
          commandMessage = t(commandMessage, {ns: "documents"});
          commandMessage += "\n" + 
            missingDocuments
              .map(doc => `#[${doc.name}](${doc.id})`)
              .join("\n");
        }
      }

      await createResourceDocumentComment(t(commandMessage, {ns: "documents"}));
      setTextComment("");
    } else {
      console.error("Invalid command selected.");
    }
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [resourceDocumentComments]);

  useEffect(() => {
      setTextNote(resourceDocumentNote?.note);
  }, [resourceDocumentNote]);

  return (
    <>
      <Modal
        isOpen={true}
        onClose={handleClose}
        size={"6xl"}
        trapFocus={false}
      >
        <ModalOverlay />

        {isReady ? 
        (<ModalContent
          marginLeft={isTablet ? 0 : 40}
          marginRight={isTablet ? 0 : 40}
        >
          <ModalHeader
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            height={50}
            width="100%"
          >
            
            <Box mb={1}>
              <Text fontSize="xl" fontWeight={500}>
                {t(`noteTitle`)}
              </Text>
              <Text fontSize="sm" marginTop={2}>
                {t(`noteSubtitle`)}
              </Text>
            </Box>

            <Flex>
              <Tooltip
                label={t("exportChat", { ns: "communication" })}
                aria-label="Preview"
              >
              <IconButton
                  marginTop={6}
                  isDisabled={resourceDocumentComments?.length === 0}
                  borderRadius="50%"
                  aria-label="Circular Button"
                  variant={"ghost"}
                  size={"md"}
                  padding={0}
                  marginRight={4}
                  alignSelf={"center"}
                  color="black"
                  _hover={{ backgroundColor: darken(0.1, COLORS.sikuroBlue) }}
                  onClick={handleExportClick}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                <Icon
                  as={PiExportBold}
                  fontSize={"22px"}
                />
              </IconButton>
              </Tooltip>
              <FormControl
                display="flex"
                alignItems="center"
                width="auto"
                marginTop={7}
              >
                <FormLabel htmlFor="email-alerts" mb="0">
                  {statusChat
                    ? t("activeNotifications", { ns: "communication" })
                    : t("silentNotification", { ns: "communication" })}
                </FormLabel>
                <Switch
                  autoFocus={false}
                  id="email-alerts"
                  isChecked={statusChat}
                  onChange={(e) => handleChatStatus(e.target.checked)}
                />
              </FormControl>
            </Flex>
          </ModalHeader>

          <ModalCloseButton marginRight={2} />

          <ModalBody sx={{ display: "flex" }}>
              <Flex
                position="relative"
                width={"50%"}
                marginBottom={12}
                marginRight={5}
              >
                <Box flex="1">
                  <ReactQuill
                    style={{ height: "83.5%", width: "100%" }}
                    value={textNote}
                    onChange={(content) => {
                      setIsButtonDisabled(false), setTextNote(content);
                    }}
                  />
                  <Flex justifyContent="center" marginTop={2.5}>
                    <Button
                      spinnerPlacement="start"
                      isDisabled={isButtonDisabled}
                      colorScheme="blue"
                      alignSelf={"center"}
                      paddingLeft={10}
                      paddingRight={10}
                      marginTop={65}
                      isLoading={createResourceDocumentIsLoading}
                      onClick={async () => {
                        await createResourceDocumentNote(textNote),
                          setIsButtonDisabled(true);
                      }}
                    >
                      {t("saveNotes")}
                    </Button>
                  </Flex>
                </Box>
                {resourceDocumentNoteFetching && <LoadingNote />}
              </Flex>

            <Flex
              width={"50%"}
              flexDirection={"column"}
              marginBottom={3}
            >

              <SearchInput onSearch={setSearch} width={"100%"} searching={resourceDocumentCommentsFetching}/>

              <Flex
                marginTop={"5px"}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                height="calc(90vh - 310px)"
                overflow={"clip"}
              >
                <Box overflowY={"auto"} width={"100%"} paddingTop={2}>
                  {!Array.isArray(resourceDocumentComments) &&
                    resourceDocumentCommentsFetching ? (
                    <LoadingComments />
                  ) : (
                    <>
                    {resourceDocumentComments?.map((msg, index) => (
                      <MessageView
                        key={index}
                        message={msg.comment}
                        author={msg.author?.name}
                        messageDirection={
                          user.id === msg.author?.id
                            ? MessageDirection.SENT
                            : MessageDirection.RECEIVED
                        }
                        messageDate={formatDateBasedOnLanguage(
                          msg.createdAt as unknown as string,
                          true
                        )}
                        messageType={msg.type}
                        messageId={msg.id}
                        searchParameter={search}
                        isLastMessage={index === resourceDocumentComments.length - 1}
                      />
                    ))}
                    <div ref={messagesEndRef} />
                    </>
                  )}
                </Box>
              </Flex>
              <Flex marginTop={4} flexDirection={"row"}>
                <Flex
                  width={"100%"}
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="10px"
                >
                  <Flex
                    flexDirection={"row"}
                    width={"82%"}
                    paddingLeft={2}
                    paddingRight={2}
                    className="comment-input-container"
                    height={"80px"}
                  >
                    <MentionsInput
                      value={textComment}
                      onChange={(event) => {
                        if (!event.target.value){
                          setPopOverOpen(false);
                        }
                        setTextComment(event.target.value);
                      }}
                      className="mentions"
                      classNames={Style}
                      placeholder={t("mention")}
                      allowSuggestionsAboveCursor
                      forceSuggestionsAboveCursor
                      style={{
                        input: {
                          marginTop: -4,
                          overflow: 'auto',
                          height: 60,
                          paddingTop: 0
                        },
                        highlighter: {
                          marginTop: -4,
                          boxSizing: 'border-box',
                          overflow: 'hidden',
                          height: 60,
                        },
                        control: {
                          marginTop: -4,
                        }
                      
                      }}
                    >
                      
                      <Mention
                        trigger={textComment.trim() === "" ? "uselessTrigger" : "/"}
                        data={chatCommands}
                        className={Style.mentions__mention}
                        appendSpaceOnAdd
                        onAdd={(id: string) => onCommandSelected(id)}
                        displayTransform={(id) => ``}
                        >
                      </Mention>

                      <Mention
                        data={recoveredDocuments ?? []}
                        trigger="#"
                        className={Style.mentions__mention}
                        markup="#[__display__](__id__)"
                        appendSpaceOnAdd
                      ></Mention>

                      <Mention
                        data={recoveredUsers}
                        className={Style.mentions__mention_users}
                        markup=" @[__display__](__id__)"
                        appendSpaceOnAdd
                      />
                    </MentionsInput>
                  </Flex>
                  <Spacer width={4} />
                  <IconButton
                    isDisabled={isFetching || textComment === ""}
                    borderRadius="50%"
                    aria-label="Circular Button"
                    icon={<MdSend />}
                    marginRight={2}
                    alignSelf={"center"}
                    backgroundColor={COLORS.sikuroBlue}
                    color="white"
                    _hover={{ backgroundColor: darken(0.1, COLORS.sikuroBlue) }}
                    onClick={handleButtonClick}
                  />

                  <Popover 
                    isOpen={popoverOpen} 
                    onClose={()=>setPopOverOpen(false)}
                    placement={"left-start"}>
                    <Flex alignItems={"center"}>

                      <PopoverTrigger>
                        <Tooltip
                          label={t("commandsChat", { ns: "communication" })}
                          aria-label="Preview"
                        >
                          <IconButton
                              marginTop={0}
                              isDisabled={textComment?.length !== 0}
                              borderRadius="50%"
                              aria-label="Circular Button"
                              variant={"ghost"}
                              size={"md"}
                              padding={0}
                              marginRight={4}
                              alignSelf={"center"}
                              color="black"
                              _hover={{ backgroundColor: darken(0.1, COLORS.sikuroBlue) }}
                              onClick={()=> setPopOverOpen(!popoverOpen)}
                              alignContent={"center"}
                              justifyContent={"center"}
                              backgroundColor={COLORS.darkYellow}
                            >
                            <Icon
                              as={RiSlashCommands2}
                              fontSize={"22px"}
                            />
                          </IconButton>
                        </Tooltip>
                      </PopoverTrigger>

                    </Flex>
                    {chatCommands.length > 0 && ((textComment.trim() === "/" || textComment.trim() === "")) && (

                      <PopoverContent>
                        <PopoverArrow marginTop={-10}/>
                        <PopoverBody>
                            <Flex direction="column" gap={2}>
                              {chatCommands.map((command, index) => (
                                <>
                                <Button
                                  borderRadius={2}
                                  key={command.id}
                                  variant="ghost"
                                  onClick={() => {
                                    setPopOverOpen(false);
                                    console.log(`Command selected: ${command.display}`);
                                    onCommandSelected(command.id);
                                  }}
                                >
                                  {command.display}
                                </Button>
                                {index < chatCommands.length - 1 && <Divider />}
                                </>
                              ))}
                            </Flex>
                        </PopoverBody>
                      </PopoverContent>
                  )}

                  </Popover>

                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>)
        : (
          <ModalContent
            style={{ background: "white", padding: "2rem" }}
            marginLeft={40}
            marginRight={40}
            sx={{ height: "70vh" }}
          >
            <LoadingView />
          </ModalContent>
        )
        } 
      </Modal>
      {askConfirmNotSave && (
        <DeleteActionAlert
          onConfirm={async () => {
            onClose();
          }}
          onCancel={() => {
            setAskConfirmNotSave(false);
          }}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmNotSave", { ns: "communication" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!askConfirmNotSave}
        />
      )}
    </>
  );
};

const LoadingModal = ({ onClose }: { onClose?: () => void }) => (
  <Modal isOpen={true} onClose={onClose} size={"6xl"}>
    <ModalOverlay />
    <ModalContent
      style={{ background: "white", padding: "2rem" }}
      marginLeft={40}
      marginRight={40}
      sx={{ height: "70vh" }}
    >
      <LoadingView />
    </ModalContent>
  </Modal>
);

const LoadingNote = () => (
  <Flex
    position="absolute"
    marginTop={14}
    marginLeft={2}
    marginRight={2}
    width="97%"
    borderRadius={8}
    height={"96%"}
    backgroundColor={"white"}
    zIndex={1}
  >
    <Skeleton
      height={"100%"}
      width={"100%"}
      borderRadius={8}
      startColor="gray.200"
      endColor="gray.300"
    />
  </Flex>
);

const SkeletonComment = ({ alignment, marginTop }: { alignment: string, marginTop: string }) => (
  <Skeleton
    height={"15%"}
    width={"50%"}
    borderRadius={8}
    startColor="gray.200"
    endColor="gray.300"
    alignSelf={alignment}
    marginTop={marginTop}
  />
);

const LoadingComments = () => {
  return (
    <Flex
      width={"100%"}
      height={"80%"}
      padding={2}
      flexDirection={"column"}
      alignItems={"start"}
    >
      <SkeletonComment alignment={"start"} marginTop={""}/>
      <SkeletonComment alignment={"end"} marginTop={"5px"}/>
    </Flex>
  );
};

export default DocumentCommunicationModal;
