import { useQuery } from "@tanstack/react-query";
import { BusinessOption, BusinessSizeResponse } from "../../../domain/interactors/auth/args";
import OnboardingViewModel from "../../viewmodels/onboarding/OnboardingViewModel";

const useUpdateCompanyDetailsViewModel = () => {
  const vm = new OnboardingViewModel();

   const getBusinessVatCountries = useQuery<BusinessOption[], Error>(
    ["get-vat-countries"],
    async () => {
      const countries = await vm.getVatCountries();
      return countries;
    }
   );
  
  
  const getBusinessSizes = useQuery<BusinessSizeResponse[], Error>(
    ["get-business-sizes"],
    async () => {
      const sizes = await vm.getBusinessSizes();      
      return sizes;
    }
  );

  return {
    vatCountries: getBusinessVatCountries.data,
    businessSizes: getBusinessSizes.data,
  }
}

export default useUpdateCompanyDetailsViewModel;
