import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';
import { SiteResourceDocument } from '../../../entities/document';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
};

class GetSiteVehicleDocumentsRejected implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteVehicleDocumentsRejected(companyId, siteId, vehicleId );
	}
}

export default GetSiteVehicleDocumentsRejected;
