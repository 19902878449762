import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';
import { BusinessLicenseResponse, BusinessOption, BusinessSizeResponse, BusinessTypeResponse, SignUpCompany, SignUpderivedCompany } from './args';

export class UserAlreadyRegistered {
	constructor(private repository: AuthRepository) {}

	async Call(token: string, company: string): Promise<{ already_registered: string }> {
		return await this.repository.userAlreadyRegistered(token, company);
	}
}
export class CreateBusinessAccount implements UseCase<SignUpCompany, boolean> {
	constructor(private repository: AuthRepository) {}

	async Call(payload: SignUpCompany): Promise<boolean> {
		return await this.repository.createBusinessAccount(payload);
	}
}

export class CreateBusinessDerivedAccount implements UseCase<SignUpderivedCompany, boolean> {
	constructor(private repository: AuthRepository) {}

	async Call(payload: SignUpderivedCompany): Promise<boolean> {
		return await this.repository.createBusinessDerivedAccount(payload);
	}
}

export class GetBusinessTypes implements UseCase<null, Array<BusinessTypeResponse>> {
	constructor(private repository: AuthRepository) {}

	async Call(): Promise<Array<BusinessTypeResponse>> {
		return await this.repository.getBusinessTypes();
	}
}

export class GetVatCountries implements UseCase<null, Array<BusinessOption>> {
	constructor(private repository: AuthRepository) {}

	async Call(): Promise<Array<BusinessOption>> {
		return await this.repository.getVatCountries();
	}
}

export class GetBusinessSizes implements UseCase<null, Array<BusinessSizeResponse>> {
	constructor(private repository: AuthRepository) {}

	async Call(): Promise<Array<BusinessSizeResponse>> {
		return await this.repository.getBusinessSizes();
	}
}

export class GetLicenseTypes implements UseCase<null, Array<BusinessLicenseResponse>> {
	constructor(private repository: AuthRepository) {}

	async Call(): Promise<Array<BusinessLicenseResponse>> {
		return await this.repository.getLicenseTypes();
	}
}


export const makeCreateBusinessAccount = (): CreateBusinessAccount => {
	return new CreateBusinessAccount(makeAuthRepository());
};
export const makeCreateBusinessDerivedAccount = (): CreateBusinessDerivedAccount => {
	return new CreateBusinessDerivedAccount(makeAuthRepository());
};
export const makeGetBusinessTypes = () => {
	return new GetBusinessTypes(makeAuthRepository());
};
export const makeGetVatCountries = () => {
  return new GetVatCountries(makeAuthRepository());
};
export const makeGetBusinessSizes = () => {
  return new GetBusinessSizes(makeAuthRepository());
};
export const makeGetLicenseTypes = () => {
  return new GetLicenseTypes(makeAuthRepository());
};

export const makeUserAlreadyRegistered = (): UserAlreadyRegistered => {
	return new UserAlreadyRegistered(makeAuthRepository());
};
