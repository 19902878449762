import {
  Box,
  Flex,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import QRCode from "qrcode.react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiLink, BiUnlink } from "react-icons/bi";
import { FiAlertTriangle, FiInfo, FiPlus } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  BadgeLogEntry,
  BadgeLogEntryAction,
  BadgeLogEntryActionResult,
} from "../../../domain/entities/badgeLogEntry";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import { BadgeStatus } from "../../../domain/entities/badgeStatus.enum";
import { BadgeType } from "../../../domain/entities/badgeType.enum";
import { GetBadgeHistoryFilters } from "../../../domain/repositories/badgeRepository";
import { formatDateBasedOnLanguage } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import BaseModal from "../../components/Common/alerts/BaseModal";
import DetailsHeader from "../../components/Common/DetailsHeader";
import NFCIcon from "../../components/Common/NFCIcon";
import StatusToggle from "../../components/Common/StatusToggle";
import ActionButton from "../../components/Common/table/ActionButton";
import CellTagList from "../../components/Common/table/CellTagList";
import ColumnFilterComponent, {
  FilterComponentProps,
} from "../../components/Common/table/ColumnFilterComponent";
import DeleteButton from "../../components/Common/table/DeleteButton";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";
import NavigationButton from "../../components/Common/table/NavigationButton";
import SimpleTable from "../../components/Common/table/SimpleTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import CloneBadgeModal from "../../components/Views/Badge/CloneBadgeModal";
import CreateUpdateStampingModal from "../../components/Views/Badge/CreateUpdateStampingModal";
import LinkResourceToBadgeModal from "../../components/Views/Badge/LinkResourceToBadgeModal";
import { useBadgeDetailViewModel } from "../../hooks/Badge/useBadgeDetailViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { Alert } from "../Common/Alert";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import { ForcingDetailsModal } from "../Common/ForcingDetailModal";
import LoadingView from "../Common/LoadingView";

const BadgeIcon = {
  [BadgeType.NFC]: NFCIcon,
};

const statusColorMap = {
  active: "green",
  lost: "gray",
  blocked: "red",
};

const BadgeDetailView: FC<{
  fromSite: boolean;
  permissions: {
    linkBadge?: Permission;
    unlinkBadge?: Permission;
    edit: Permission;
    delete?: Permission;
    setStatus: Permission;
    addRecord: Permission;
  };
}> = ({ fromSite, permissions }) => {
  const { t } = useTranslation("badges");
  const { badgeId } = useParams<{ badgeId: string }>();
  const { siteId } = useParams<{ siteId: string }>();
  const {
    badge,
    badgeReaders,
    setStampingMgmtActive,
    badgeFetching,
    updateBadgeStatus,
    updateFilter,
    filters,
    sort,
    setSort,
    cloneBadge,
    badgeStatusSites,
    badgeStatusSitesIsLoading,
    unlinkBadgeResource,
    allTags,
    linkBadgeTag,
    unlinkBadgeTag,
    history,
    historyHasNextPage,
    historyFetchNextPage,
    historyIsLoading,
    createBadgeLogEntry,
    deleteBadgeLogEntry,
    deleteBadgeLogEntryIsLoading,
    resourceSites,
    resourceSitesFilters,
    updateResourceSitesFilter,
    resourceSiteSort,
    setResourceSitesSort,
    resourceSitesHasNextPage,
    resourceSitesFetchNextPage,
    updateTagBadge,
    tagRenameError,
    isTagLoading,
    error,
    setError,
    linkResourceError,
    setLinkResourceError,
    linkBadgeResourceMutationLoading,
    search,
    setSearch,
    resourceFilters,
    resourceSort,
    setResourceSort,
    availableResources,
    setResourceType,
    availableResourcesHasNextPage,
    availableResourcesFetchNextPage,
    updateResourcesFilter,
    linkResource,
    resourceType,
    availableResourcesLoading,
  } = useBadgeDetailViewModel(badgeId, siteId);

  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);
  const [badgeClonedModal, setBadgeClonedModal] = useState<boolean>(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [linkBadgeModal, setLinkBadgeModal] = useState<boolean>(false);
  const [showForcedDetails, setShowForcedDetails] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showCreateUpdateStamp, setShowCreateUpdateStamp] =
    useState<boolean>(false);
  const [selectedBadgeLogEntry, setSelectedBadgeLogEntry] =
    useState<BadgeLogEntry>(undefined);
  const [badgeLogEntryToDelete, setBadgeLogEntryToDelete] =
    useState<BadgeLogEntry>(undefined);

  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const hasCsePermission = useHasPermissions([Permission.Sites_CseShowSites, Permission.Worksite_CseShowWorkingSites], PermissionCheck.Some);

  const navigate = useNavigate();
  const [cloneBadgeModal, setCloneBadgeModal] = useState<boolean>();
  const logStateOptions = [
    {
      option: BadgeLogEntryActionResult.GRANTED,
      label: t("actionResults.accepted", {ns: 'badges'}),
    },
    {
      option: BadgeLogEntryActionResult.DENIED,
      label: t("actionResults.denied", {ns: 'badges'}),
    },
  ];
  const logEntryOptions = [
    {
      option: BadgeLogEntryAction.ENTER,
      label: t("actionTypes.incoming", {ns: 'badges'}),
    },
    {
      option: BadgeLogEntryAction.EXIT,
      label: t("actionTypes.outgoing", {ns: 'badges'}),
    },
  ];
  const columns: {
    field: keyof GetBadgeHistoryFilters;
    filterType: FilterComponentProps<null>["type"];
    options?:  Record<string, string> | {option: string, label: string}[];
  }[] = [
    { field: "actionDate", filterType: "dateTime-range" },
    { field: "actionType", filterType: "select", options: logEntryOptions },
    {
      field: "actionResult",
      filterType: "select",
      options: logStateOptions,
    },
    { field: "reader", filterType: "text" },
  ];

  const cloneResourceBadge = async (siteId: string) => {
    await cloneBadge(siteId);
    setBadgeClonedModal(true);
  };
  const toast = useToast();

  useEffect(() => {
    if (tagRenameError) {
      toast({
        title: t(tagRenameError, { ns: "common" }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [tagRenameError]);

  const canUpdateStatus = useHasPermissions(permissions.setStatus);
  const canDelete = useHasPermissions(permissions.delete);
  if (!badge) {
    return <LoadingView />;
  }
  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() =>
              navigate(
                siteId ? `/sites/${siteId}/access/badges` : "/company/badges",
              )
            }
          />
          <RenderIf permissions={permissions.unlinkBadge}>
            {!fromSite && badge.resource && (
              <ActionBarItem
                icon={BiUnlink}
                description={t("unlinkResource", { ns: "badges" })}
                onClick={() => {
                  if (badge?.resource?.id) {
                    setConfirmDeleteModal(true);
                  } else {
                    setShowInfoModal(true);
                  }
                }}
              />
            )}
          </RenderIf>
          <RenderIf permissions={permissions.linkBadge}>
            {!fromSite && !badge.resource && (
              <ActionBarItem
                icon={BiLink}
                description={t("linkResource", { ns: "badges" })}
                onClick={() => {
                  setLinkBadgeModal(true);
                  setResourceType(BadgeResourceType.WORKER);
                }}
              />
            )}
          </RenderIf>
          {siteId && (
            <RenderIf permissions={permissions.addRecord}>
              <ActionBarItem
                icon={FiPlus}
                description={t("addStamping", { ns: "badges" })}
                onClick={() => {
                  setStampingMgmtActive(true);
                  setShowCreateUpdateStamp(true);
                }}
                isDisabled={!badge.resource}
                disabledDescription={t("cannotStamp", { ns: "badges" })}
              />
            </RenderIf>
          )}
        </ActionBar>
      }
    >
      {badge && (
        <Flex px={3} py={5} gap="20px" flexWrap={"wrap"}>
          <Box flex="1 1 900px">
            <DetailsHeader
              title={t("details")}
              shouldShowImage={true}
              image={{
                Icon: BadgeIcon[badge.type],
                QRCode: badge.type === BadgeType.QR && (
                  <QRCode value={badge.serial} size={143} />
                ),
                disableFlag: true,
              }}
              attributes={[
                { label: t("columns.code"), value: badge.code },
                { label: t("columns.type"), value: badge.type },
                { label: t("columns.serial"), value: badge.serial },
                {
                  label: t("columns.resource"),
                  value: badge.resource ? (
                    <Text>{badge.resource.name}</Text>
                  ) : (
                    "-"
                  ),
                },
                {
                  label: t("columns.resourceType"),
                  value: badge.resource ? t(badge.resource.type) : "-",
                },

                {
                  label: t("Tag"),
                  value: (
                    <CellTagList
                      tags={allTags}
                      selectedTags={badge.tags}
                      onAdd={linkBadgeTag}
                      onDelete={unlinkBadgeTag}
                      editable={hasCsePermission ? false : true}
                      onUpdate={updateTagBadge}
                      isTagLoading={isTagLoading}
                      hasCsePermission={hasCsePermission}
                    />
                  ),
                },
              ]}
              isLoaded={!badgeFetching}
            />
          </Box>
          <Box
            p="10px 20px"
            flexDirection="column"
            gap="10px"
            width="308px"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            flex="1 1 300px"
          >
            <Box textStyle="h2">{t("columns.status")}</Box>
            <StatusToggle
              disabled={!canUpdateStatus}
              status={badge.status}
              onChange={(status) =>
                siteId
                  ? updateBadgeStatus({
                      status: status as BadgeStatus,
                      siteId,
                    })
                  : updateBadgeStatus({ status: status as BadgeStatus })
              }
              type="badge"
              isTablet={isTablet}
            />
          </Box>
        </Flex>
      )}
      {badge?.globalStatus && badge?.globalStatus !== BadgeStatus.ACTIVE && (
        <Flex
          mx={3}
          mb={4}
          px={3}
          py={5}
          flexDirection="column"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          bg={COLORS.lightRed}
          fontWeight={500}
        >
          <Text>
            {badge?.globalStatus === "blocked" &&
              t("globalStatusBlocked", { ns: "badges" })}
            {badge?.globalStatus === "lost" &&
              t("globalStatusLost", { ns: "badges" })}
          </Text>
        </Flex>
      )}

      {!siteId && (
        <Box mx={3} mb={5} py={5}>
          <Box textStyle={"h2"} marginBottom={5}>
            {t("badgeSites")}
          </Box>
          <SimpleTable
            tableId="badge-detail-sites-table"
            emptyText={t("unusedBadge")}
            showEmptyText={badgeStatusSites?.length === 0}
            isLoading={badgeStatusSitesIsLoading}
          >
            <Thead>
              <Tr>
                <Th key="SITE NAME">
                  <TableColumnHeader text={t("siteName", { ns: "sites" })} />
                </Th>
                <Th key="SITE ADDRESS">
                  <TableColumnHeader text={t("state", { ns: "sites" })} />
                </Th>
                <Th w={20} />
              </Tr>
            </Thead>
            <Tbody borderRadius="xl">
              {badgeStatusSites?.map((badgeStatusSite) => (
                <Tr key={JSON.stringify(badgeStatusSite)}>
                  <Td>{badgeStatusSite?.site?.name}</Td>
                  <Td>
                    <Tag
                      colorScheme={statusColorMap[badgeStatusSite?.badgeStatus]}
                    >
                      {t("status.badge." + badgeStatusSite?.badgeStatus, {
                        ns: "common",
                      })}
                    </Tag>
                  </Td>
                  <Td w={20}>
                    <NavigationButton
                      onClick={() =>
                        navigate(
                          `/sites/${badgeStatusSite?.site?.id}/access/badges/${badge?.id}`,
                        )
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </SimpleTable>
        </Box>
      )}

      <Box mx={3} mb={5} py={5}>
        <Flex justifyContent="start" alignItems={"center"} gap={2} pb={4}>
          <Text fontSize={"lg"} fontWeight={"bold"}>
            {t("history", { ns: "badges" })}
          </Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          width="100%"
          position="relative"
          overflow={"auto"}
          marginTop={3}
          minHeight={"50vh"}
        >
        <InfiniteTable
          tableId="badge-detail-history-table"
          emptyText={t("noHistory", { ns: "badges" })}
          showEmptyText={history?.length === 0}
          autosize
          bottomThreshold={220}
          isLoading={historyIsLoading}
          infiniteScroll={{
            dataLength: history.length,
            fetchNextPage: historyFetchNextPage,
            hasNextPage: historyHasNextPage,
          }}
        >
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.field}>
                  <TableColumnHeader
                    text={t(`historyColumns.${column.field}`)}
                    filter={{
                      isActive:
                        !!filters[column.field] &&
                        (!Array.isArray(filters[column.field]) ||
                          !!filters[column.field][0]),
                      component: (
                        <ColumnFilterComponent
                          selectOptions={column.options}
                          value={filters[column.field]}
                          type={column.filterType}
                          updateFilter={(value) =>
                            updateFilter(column.field, value)
                          }
                        />
                      ),
                    }}
                    sort={{
                      handler: (direction) =>
                        setSort({ field: column.field, direction }),
                      direction:
                        sort?.field === column.field ? sort.direction : null,
                    }}
                  />
                </Th>
              ))}
              <Th w="100px" />
            </Tr>
          </Thead>
          <Tbody borderRadius="xl">
            {history?.map((log) => (
              <Tr key={log.id}>
                <Td>{formatDateBasedOnLanguage(log?.actionDate, true)}</Td>
                <Td>{t(`actionTypes.${log?.actionType}`)}</Td>
                <Td sx={{ alignItems: "center" }}>
                  <Flex flexDirection={"row"}>
                    {t(`actionResults.${log?.actionResult}`)}
                    {log.anomaly && (
                      <Tooltip label={t("anomaly")}>
                        <span>
                          <FiAlertTriangle
                            style={{ marginLeft: "5px" }}
                            color={COLORS.darkYellow}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Flex>
                </Td>
                <Td>{log?.badgeReaderName}</Td>
                <Td w={"100px"} p={0} paddingLeft={3}>
                  {canDelete && log.isManual && fromSite ?
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setBadgeLogEntryToDelete(log);
                        setAskConfirmDelete(true);
                      }}
                    />
                  : null}
                  {log.isForced && (
                    <Tooltip
                      label={t("showDetails", { ns: "common" })}
                      shouldWrapChildren={true}
                    >
                      <ActionButton
                        aria-label="infoForced"
                        icon={<FiInfo color="red" />}
                        onClick={() => {
                          setShowForcedDetails(true);
                          setSelectedBadgeLogEntry(log);
                        }}
                      />
                    </Tooltip>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </InfiniteTable>
        </Flex>
      </Box>

      {showCreateUpdateStamp && (
        <CreateUpdateStampingModal
          onCancel={() => {
            setStampingMgmtActive(false);
            setShowCreateUpdateStamp(false);
            setSelectedBadgeLogEntry(undefined);
          }}
          onConfirm={(badgeLog) => {
            createBadgeLogEntry(badgeLog);
            setStampingMgmtActive(false);
            setShowCreateUpdateStamp(false);
            setSelectedBadgeLogEntry(undefined);
          }}
          stampFromBadge={true}
          currentBadge={badge}
          currentBadgeReader={badgeReaders?.find(
            (obj) => obj.name === selectedBadgeLogEntry?.badgeReaderName,
          )}
          badgeReaders={badgeReaders}
          badgeLogEntryToUpdate={selectedBadgeLogEntry}
        />
      )}

      <ForcingDetailsModal
        forcedDetails={{
          expiresAt: selectedBadgeLogEntry?.forcing?.expiresAt,
          note: selectedBadgeLogEntry?.forcing?.note,
          userName: selectedBadgeLogEntry?.forcing?.userName,
          codeBadge: badge?.code,
          resource: badge?.resource?.name,
        }}
        setShowForcedDetails={setShowForcedDetails}
        showForcedDetails={showForcedDetails}
        title={t("forcedAccessInfoTitle", { ns: "badgeReaders" })}
      />

      {confirmDeleteModal && (
        <BaseModal
          type="warning"
          onClose={() => setConfirmDeleteModal(false)}
          onConfirm={() => unlinkBadgeResource(badge?.resource?.type)}
        >
          {t("askUnlinkResource", { ns: "badges" })}
        </BaseModal>
      )}

      {askConfirmDelete && (
        <DeleteActionAlert
          isLoading={deleteBadgeLogEntryIsLoading}
          onConfirm={async () => {
            await deleteBadgeLogEntry(badgeLogEntryToDelete);
            setAskConfirmDelete(false);
            setBadgeLogEntryToDelete(undefined);
          }}
          onCancel={() => {
            setAskConfirmDelete(false);
            setBadgeLogEntryToDelete(undefined);
          }}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmLogEntryDelete", { ns: "badges" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!askConfirmDelete}
        />
      )}

      {cloneBadgeModal && (
        <CloneBadgeModal
          onClose={() => setCloneBadgeModal(false)}
          onConfirm={cloneResourceBadge}
          sites={resourceSites}
          sitesHasNextPage={resourceSitesHasNextPage}
          sitesFetchNextPage={resourceSitesFetchNextPage}
          updateSiteFilters={updateResourceSitesFilter}
          siteFilters={resourceSitesFilters}
          siteSort={resourceSiteSort}
          setSiteSort={setResourceSitesSort}
        />
      )}

      {badgeClonedModal && (
        <BaseModal
          title={t("badgeClonedTitle", { ns: "badges" })}
          onClose={() => setBadgeClonedModal(false)}
          onConfirm={() => setBadgeClonedModal(false)}
          onConfirmLabel={t("close", { ns: "common" })}
          onCancel={false}
        >
          {t("badgeCloned", { ns: "badges" })}
        </BaseModal>
      )}

      {showInfoModal && (
        <BaseModal
          bodyOverflow={"hidden"}
          title={t("unlinkResource", { ns: "badges" })}
          onClose={() => setShowInfoModal(false)}
          onConfirm={() => setBadgeClonedModal(false)}
          onConfirmLabel={t("close", { ns: "common" })}
          onCancel={false}
        >
          <Text>{t("noResourcesAssociated", { ns: "badges" })}</Text>
        </BaseModal>
      )}

      {linkBadgeModal && (
        <LinkResourceToBadgeModal
          resources={availableResources}
          isLoadingResources={availableResourcesLoading}
          resourcesHasNextPage={availableResourcesHasNextPage}
          resourcesFetchNextPage={() => {
            return availableResourcesFetchNextPage();
          }}
          search={search}
          setSearch={(val) => setSearch(val)}
          resourcesFilters={resourceFilters}
          resourcesSort={resourceSort}
          updateResourcesFilter={updateResourcesFilter}
          setResourcesSort={setResourceSort}
          onConfirm={async (resource) => {
            await linkResource(resource);
            !error && setLinkBadgeModal(false);
          }}
          onClose={() => setLinkBadgeModal(false)}
          resourceType={resourceType}
          setResourceType={setResourceType}
          error={linkResourceError}
          setError={(error) => setLinkResourceError(error)}
          isLinking={linkBadgeResourceMutationLoading}
        />
      )}
      {error && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("logEntryErrors." + error, { ns: "badgeReaders" })}
          onClose={() => setError(null)}
          variant="warning"
        />
      )}
    </ContentLayout>
  );
};

export default BadgeDetailView;
