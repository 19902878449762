import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Role from "../../../domain/entities/role";
import User from "../../../domain/entities/user";
import { GetUsersFilter } from "../../../domain/repositories/filters";
import { updateFilterWithDelete } from "../../../utils";
import { useAuth } from "../../providers/Auth0JWTProvider";
import UsersListViewModel from "../../viewmodels/users/UsersListViewModel";

interface CreateArgs {
  name: string;
  email: string;
  role: Role;
}

const useUsersViewModel = () => {
  const { companyId } = useAuth();
  const [search, setSearch] = useState<string>();
  const [messageInvitation, setMessageInvitation] = useState<string | null>();
  const [userAlreadyInvited, setUserAlreadyInvited] = useState(false);
  const [filters, setFilters] = useState<GetUsersFilter>({});
  const [sort, setSort] = useState<SortMeta>();
  const viewModel = useMemo(() => new UsersListViewModel(companyId), []);

  const {
    data: users,
    isLoading,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery<User[], Error>(
    ["get-users", companyId, filters, sort, search],
    async ({ pageParam = 1 }) => {
      const userFilters = search ? { ...filters, search } : filters;

      return await viewModel.get(userFilters, sort, pageParam);
    },
    { retry: false,
        getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      }, 
    },
  );

  const createMut = useMutation(
    async ({ name, email, role }: CreateArgs) =>
      await viewModel.create(name, email, role),
    {
      onError: (err: Error) => {
        if (err.message === "userAlreadyRegistered") {
          setUserAlreadyInvited(true);
        }
      },
      onSuccess: () => refetch(),
    },
  );

  const deleteMut = useMutation((userId: string) => viewModel.delete(userId), {
    onError: (err) => console.error("cannot delete user", err),
    onSuccess: () => refetch(),
  });

  const renewInvitationMutation = useMutation(
    (invitationToken: string) => viewModel.renewInvitation(invitationToken),
    {
      onError: (err: Error) => {
        setMessageInvitation(err.message);
      },
      onSuccess: () => {
        setMessageInvitation("success"), refetch();
      },
    },
  );

  const createUser = async (
    name: string,
    email: string,
    role: Role,
    onComplete?: () => void,
  ) => {
    try {
      await createMut.mutateAsync({ name, email, role }).finally(onComplete);
    } catch (err) {
      return undefined;
    }
  };

  const deleteUser = async (userId: string, onComplete?: () => void) => {
    deleteMut.mutateAsync(userId).finally(onComplete);
  };

  const renewInvitation = async (invitationToken: string) => {
    try {
      await renewInvitationMutation.mutateAsync(invitationToken);
    } catch (err) {
      return undefined;
    }
  };
  const updateFilters = (
    field: string,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setFilters, field, value);
  };
  return {
    users: users?.pages.flat() ?? [],
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
    createUser,
    deleteUser,
    renewInvitation,
    messageInvitation,
    setMessageInvitation,
    userAlreadyInvited,
    setUserAlreadyInvited,
    setSearch,
    filters,
    updateFilters,
    sort,
    setSort,
  };
};

export default useUsersViewModel;
