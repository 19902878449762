import { makeSiteToolRepository } from './repositories';
import GetSiteTool from '../../../../domain/interactors/resources/siteTools/getSiteTool';
import GetSiteTools from '../../../../domain/interactors/resources/siteTools/getSiteTools';
import CreateSiteTool from '../../../../domain/interactors/resources/siteTools/createSiteTool';
import DeleteSiteTool from '../../../../domain/interactors/resources/siteTools/deleteSiteToolts';
import RestoreSiteTool from '../../../../domain/interactors/resources/siteTools/restoreSiteTool';
import EvaluateSiteTool from '../../../../domain/interactors/resources/siteTools/evaluateSiteTool';
import GetWorkingSiteTools from '../../../../domain/interactors/resources/siteTools/getWorkingSiteTools';
import AutoEvaluateSiteTool from '../../../../domain/interactors/resources/siteTools/autoEvaluateSiteTool';
import GetSiteToolDocuments from '../../../../domain/interactors/resources/siteTools/getSiteToolDocuments';
import UpdateSiteToolVariant from '../../../../domain/interactors/resources/siteTools/updateSiteToolVariant';
import GetSiteToolEvaluations from '../../../../domain/interactors/resources/siteTools/getSiteToolEvaluations';
import AddRequirementToSiteTool from '../../../../domain/interactors/resources/siteTools/addRequirementToSiteTool';
import UpdateSiteToolGlobalStatus from '../../../../domain/interactors/resources/siteTools/updateSiteToolGlobalStatus';
import UpdateSiteToolSpecializations from '../../../../domain/interactors/resources/siteTools/updateSiteToolSpecializations';
import GetSiteToolDocumentsRejected from '../../../../domain/interactors/resources/siteTools/getSiteToolsDocumentsRejected';

export const makeGetSiteTools = () => {
	return new GetSiteTools(makeSiteToolRepository());
};

export const makeGetWorkingSiteTools = () => {
	return new GetWorkingSiteTools(makeSiteToolRepository());
};

export const makeGetSiteTool = () => {
	return new GetSiteTool(makeSiteToolRepository());
};

export const makeCreateSiteTool = () => {
	return new CreateSiteTool(makeSiteToolRepository());
};

export const makeDeleteSiteTool = () => {
	return new DeleteSiteTool(makeSiteToolRepository());
};

export const makeRestoreSiteTool = () => {
	return new RestoreSiteTool(makeSiteToolRepository());
};

export const makeGetSiteToolDocuments = () => {
	return new GetSiteToolDocuments(makeSiteToolRepository());
};

export const makeGetSiteToolDocumentsRejected = () => {
	return new GetSiteToolDocumentsRejected(makeSiteToolRepository());
};

export const makeUpdateSiteToolVariant = () => {
	return new UpdateSiteToolVariant(makeSiteToolRepository());
};

export const makeUpdateSiteToolSpecializations = () => {
	return new UpdateSiteToolSpecializations(makeSiteToolRepository());
};

export const makeEvaluateSiteTool = () => {
	return new EvaluateSiteTool(makeSiteToolRepository());
};

export const makeAutoEvaluateSiteTool = () => {
	return new AutoEvaluateSiteTool(makeSiteToolRepository());
};

export const makeGetSiteToolEvaluations = () => {
	return new GetSiteToolEvaluations(makeSiteToolRepository());
};

export const makeAddRequirementToSiteTool = () => {
	return new AddRequirementToSiteTool(makeSiteToolRepository());
};

export const makeUpdateSiteToolGlobalStatus = () => {
	return new UpdateSiteToolGlobalStatus(makeSiteToolRepository());
};
