import Chemical from "../../../domain/entities/chemical";
import {
  makeGetChemical,
  makeUpdateChemical,
} from "../../../core/app/factory/chemicals/usecases";
import {
  makeGetChemicalSites,
  makeGetResourceLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import Site, { ResourceSites } from "../../../domain/entities/site";
import { makeAddResourceToSites } from "../../../core/app/factory/workers/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";

class ChemicalViewModel {
  private getChemicalUseCase = makeGetChemical();
  private updateChemicalUseCase = makeUpdateChemical();
  private getChemicalSitesUseCase = makeGetChemicalSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getCustomFieldsUseCase = makeGetCustomFields();

  async get(companyId: string, chemicalId: string): Promise<Chemical> {
    return await this.getChemicalUseCase.Call({ companyId, chemicalId });
  }
  async getChemicalSites(
    companyId: string,
    chemicalId: string,
    sort: SortMeta,
    filter: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
    ignoreUserSiteRoleRestrictions?: boolean,
    documentTypeId?: string,
  ): Promise<ResourceSites> {
    return await this.getChemicalSitesUseCase.Call({
      companyId,
      chemicalId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      ignoreUserSiteRoleRestrictions,
      documentTypeId
    });
  }

  async update(
    companyId: string,
    chemical: Chemical,
    imageFile?: File,
  ): Promise<Chemical> {
    return await this.updateChemicalUseCase.Call({
      companyId,
      chemical,
      imageFile,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<{results: Site[], count: number }> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }
  async linkResourceToSites(
    companyId: string,
    workerId: string,
    siteIds: string[],
    copyLastEvaluation: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "chemical",
      resourceId: workerId,
      siteIds,
      copyLastEvaluation,
      selectAll,
      filters,
    });
  }
  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUseCase.Call({ companyId, subject: RequirementSubject.CHEMICAL });
  }
}

export { ChemicalViewModel };
