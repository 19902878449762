import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	siteId?: string;
	companyId: string;
	resourceType: string;
	resourceId: string;
}

class ExportChatComments implements UseCase<Args, boolean > {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, resourceType, resourceId }: Args): Promise<boolean> {
		return await this.repository.exportChatComments(companyId, resourceType, resourceId, siteId);
	}
}

export default ExportChatComments;
