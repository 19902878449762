import {
  Box,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import { darken } from "polished";
import { MentionsInput, Mention } from "react-mentions";
import Style from "../../../presentation/components/Views/common/mentions.module.css";
import { AiOutlineAudit } from "react-icons/ai";
import { UserMini } from "../../../domain/entities/user";

type Props = {
  onClick: (comment: string) => void;
  comment: string;
  isLoading: boolean;
  color?: string;
  fontSize?: string;
  isDisabled?: boolean;
  taggableUsers: UserMini[];
};
const CommentAuditButton = ({
  onClick,
  comment,
  color = "#767676",
  fontSize = "18px",
  isDisabled,
  taggableUsers,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [currentComment, setCurrentComment] = useState(comment);
  const [focus, setFocus] = useState(false);
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    onClick(currentComment);
  };

  useEffect(() => {
    setCurrentComment(comment);
  }, [comment]);

  return (
    <Popover closeOnEsc>
      <Tooltip
        label={t("commentAudit", { ns: "documents" })}
        aria-label="Preview"
      >
        <span>
          <PopoverTrigger>
            <IconButton
              aria-label="comment"
              variant="ghost"
              sx={{ color: "#767676" }}
              fill={color}
              minWidth="22px"
              paddingLeft={2}
              paddingRight={2}
              paddingBottom={0.5}
              borderRadius={25}
              isDisabled={isDisabled}
              _hover={{
                paddingLeft: "2",
                paddingRight: "2",
                borderRadius: "25",
                boxShadow: "0 0 12px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div style={{ position: "relative" }}>
                <Icon as={AiOutlineAudit} fontSize={fontSize} marginTop={1.5} />
              </div>
            </IconButton>
          </PopoverTrigger>
        </span>
      </Tooltip>

      <PopoverContent>
        <PopoverArrow backgroundColor={"lightblue"} />
        <PopoverCloseButton />
        <Box
          sx={{
            minHeight: "24px",
            maxHeight: "150px",
            paddingLeft: 4,
            paddingTop: 1,
            overflowY: "auto",
            background: "lightblue",
            borderTopRadius: 6,
            fontWeight: "bold",
          }}
        >
          {t("auditNote", { ns: "communication" })}
        </Box>
        <PopoverBody backgroundColor={"lightblue"} borderBottomRadius={6}>
          <Flex
            sx={{
              alignItems: "start",
              border: "1px solid",
              borderRadius: "6",
              gap: "5px",
              padding: "10px",
              borderColor: focus ? "#3182ce" : "lightgray",
              boxShadow: focus ? "0 0 0 1px #3182ce" : "none",
              backgroundColor: "white",
            }}
          >
            <MentionsInput
              value={currentComment}
              onChange={(event) => {
                setCurrentComment(event.target.value);
              }}
              className="mentions"
              classNames={Style}
              placeholder={t("addAuditNote", { ns: "communication" })}
              allowSuggestionsAboveCursor
              forceSuggestionsAboveCursor
            >
              <Mention
                data={
                  taggableUsers?.map(({ id, name }) => ({
                    id,
                    display: name,
                  })) ?? []
                }
                className={Style.mentions__mention}
              />
            </MentionsInput>
            <div>
              <IconButton
                isDisabled={currentComment === "" || isLoading}
                isLoading={isLoading}
                borderRadius="50%"
                aria-label="Circular Button"
                icon={<MdCheck />}
                alignSelf={"center"}
                backgroundColor={"#33cc33"}
                color="white"
                _hover={{ backgroundColor: darken(0.1, "#33cc33") }}
                onClick={handleButtonClick}
                sx={{ height: "24px", minWidth: "24px", fontSize: "12px" }}
              />
            </div>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CommentAuditButton;
