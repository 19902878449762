import { injectable } from 'tsyringe';
import SiteMachineRepository, { GetSiteMachinesFilters, GetWorkingSiteMachinesFilters } from '../../domain/repositories/siteMachineRepository';
import { mapSiteMachine, SiteMachine } from '../../domain/entities/siteMachine';
import Evaluation from '../../domain/entities/evaluation';
import { SiteResourceDocument } from '../../domain/entities/document';
import { ResourceEvaluationState } from '../../domain/entities/resourceEvaluationState.enum';
import Requirement from '../../domain/entities/requirement';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetSiteMachinesResponse } from '../responses/siteMachines';
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { ApiService } from '../utilities/apiService';
import { GetResourceEvaluationFilter } from '../../domain/repositories/siteRepository';
import { NewResource } from '../../presentation/hooks/Site/useSiteResourcesViewModel';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';

@injectable()
class ServerSiteMachineRepository implements SiteMachineRepository {
	constructor(private apiService: ApiService) { }

	private getURL = (companyId: string, siteId: string, machineId?: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/machines${machineId ? `/${machineId}` : ''}`;
	};

	// Public.
	async getSiteMachines(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetSiteMachinesFilters,
		sort?: SortMeta,
		supplierId?: string
	): Promise<PaginatedResults<SiteMachine>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/machines?${params.toString()}`
		);
		const data: GetSiteMachinesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteMachine(siteId)),
		};
	}

	// Public.
	async getWorkingSiteMachines(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetWorkingSiteMachinesFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SiteMachine>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/machines?${params.toString()}`
		);

		if (!response.ok) {
			if (response.status === 403) {
				window.location.href = '/overview';
			}
			return;
		}
		const data: GetSiteMachinesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteMachine(siteId)),
		};
	}

	// Public.
	async getSiteMachine(companyId: string, siteId: string, machineId: string): Promise<SiteMachine | undefined> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}`);
		if (!response.ok) {
			return undefined;
		}
		return response.json();
	}

	async createSiteMachine(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		copyLastEvaluation: boolean,
		filterSiteResources?: Record<string, string | string[]>
	): Promise<boolean> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId)}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				resources,
				selectAll,
				selectedResourceNumber,
				selectAllVariant: selectAllVariants,
				selectAllSpecializations,
				copyLastEvaluation,
				filterSiteResources,
			}),
		});
		return Promise.resolve(true);
	}

	async deleteSiteMachine(companyId: string, siteId: string, machineId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}`, {
			method: 'DELETE',
		});
	}

	async restoreSiteMachine(companyId: string, siteId: string, machineId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/restore`, {
			method: 'PUT',
		});
	}

	async updateSiteMachineVariant(companyId: string, siteId: string, machineId: string, variantId?: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/variants`, {
			method: 'POST',
			body: variantId ? JSON.stringify({ variantId: variantId }) : null,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async updateSiteMachineSpecializations(companyId: string, siteId: string, machineId: string, specializationIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/specializations`, {
			method: 'PUT',
			body: JSON.stringify({ specializationsIds: specializationIds }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async getSiteMachineEvaluations(
		companyId: string,
		siteId: string,
		machineId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
	): Promise<Evaluation<ResourceEvaluationState>[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...filter,
		}).toString();

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/evaluations?${query}`);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();
		return results.map(mapEvaluation);
	}

	async evaluateSiteMachine(
		companyId: string,
		siteId: string,
		machineId: string,
		value: ResourceEvaluationState
	): Promise<Evaluation<ResourceEvaluationState>> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/evaluations`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ value }),
		});
		return response.json();
	}

	async autoEvaluateSiteMachine(companyId: string, siteId: string, machineId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/evaluate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to auto evaluate site machine: ${response.status}`);
		}
	}

	async getSiteMachineDocuments(
		companyId: string,
		siteId: string,
		machineId: string,
		filter: GetDocumentsFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<SiteResourceDocument[]> {
		const { tags, expiresAt, ...restFilter } = filter ?? {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...(tags ? { tags: tags.join(',') } : {}),
			...sort,
		});

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/requirements?${params.toString()}`);
		const requirements = await response.json();
		return requirements['results'] ?? [];
	}

	async getSiteMachineDocumentsRejected(
		companyId: string,
		siteId: string,
		machineId: string
	): Promise<SiteResourceDocument[]> {

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/requirements/blocking`);
		const requirements = await response.json();
		return requirements['results'] ?? [];
	}

	async addRequirementToSiteMachine(
		companyId: string,
		siteId: string,
		machineId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[]
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/requirements`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements }),
		});
		return response.json();
	}

	async updateSiteMachineGlobalStatus(companyId: string, siteId: string, machineId: string, isGlobal: boolean): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, machineId)}/global`, {
			method: 'PUT',
			body: JSON.stringify({ isGlobal }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to update site worker global status: ${response.status}`);
		}
		const { hasRequirements = true } = await response.json();
		return hasRequirements;
	}
}

export default ServerSiteMachineRepository;
