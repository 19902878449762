import GetDashboardCount from '../../../../domain/interactors/dashboard/getDashboardCount';
import { makeDashboardRepository } from './repositories';
import GetDashboardReportsByTopic from "../../../../domain/interactors/dashboard/getDashboardReportsByTopic";

export const makeGetDashboardCount = () => {
	return new GetDashboardCount(makeDashboardRepository());
};

export const makeGetDashboardReportByTopic = () => {
	return new GetDashboardReportsByTopic(makeDashboardRepository());
};
