import { SiteResourceDocument } from '../../entities/document';
import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';

interface Args {
	siteId: string;
	companyId: string;
	supplierId?: string;
	target?: DocumentTypeCategory
}

class GetSiteSupplierDocumentsRejected implements UseCase<Args, SiteResourceDocument[]> {
	constructor(private repository: DocumentRepository) { }

	async Call({ siteId, companyId, supplierId, target }: Args): Promise<SiteResourceDocument[]> {
		return await this.repository.getSiteSupplierDocumentsRejected(companyId, siteId, supplierId, target);
	}
}

export default GetSiteSupplierDocumentsRejected;
