import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useCompanyRequirementsGroupsViewModel } from "../../../hooks/Company/useCompanyRequirementsGroupViewModel";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import {
  getSectionPermissions,
} from "../../Permissions/Permissions";

const CompanyTopBar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const { requirementsGroups } = useCompanyRequirementsGroupsViewModel();

  const findRequirementGroupName = (id: string) => {
    const requirementGroup = requirementsGroups.find((p) => p.id === id);
    return requirementGroup ? requirementGroup.name : null;
  };

  const isRequirementPage = location.pathname.includes("company/requirements/");
  const pageTitle = isRequirementPage
    ? findRequirementGroupName(id)
    : t('exportSections.myCompany', {ns: 'common'});
  const backPath = isRequirementPage ? "/company/requirements" : "";

  const reqGroupPermissions = [
    ...getSectionPermissions("company", "requirements-groups"),
    ...getSectionPermissions("sites", "my-sites"),
  ];
  const badgePermissions = [
    ...getSectionPermissions("company", "badge"),
    ...getSectionPermissions("sites", "my-sites"),
  ];

  const items: TopBarItem[] = [
    {
      title: t("details"),
      path: "/company/records",
      permissions: getSectionPermissions("company", "records"),
    },
    {
      title: t("generalDocuments", { ns: "companies" }),
      path: "/company/general-documents",
      permissions: getSectionPermissions("company", "general-documents"),
    },
    {
      title: t("navbar.title", { ns: "documentsPresets" }),
      path: "/company/documents-presets",
      permissions: getSectionPermissions("company", "documents-presets"),
    },
    {
      title: t("docTypes"),
      path: "/company/document-types",
      permissions: getSectionPermissions("company", "document-types"),
    },
    {
      title: t("requirementGroups"),
      path: "/company/requirements",
      permissions: reqGroupPermissions,
      check: PermissionCheck.All,
    },
    {
      title: t("badge"),
      path: "/company/badges",
      permissions: badgePermissions,
      check: PermissionCheck.All,
    },
  ];

  return <TopBar title={pageTitle} items={items} backPath={backPath} />;
};

export default CompanyTopBar;
