import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Flex,
  Text,
  Portal,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import DocumentType from "../../../../domain/entities/documentType";
import { AiTaskDocumentType } from "../../../../domain/entities/aiTaskDocument";
import Document from "../../../../domain/entities/document";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi";

interface SearchDocumentMenuProps {
  isRecognizedfile: boolean;
  documentType?: string;
  textColor?: string;
  documentTypesForAi: DocumentType[];
  handleChangeDocumentType?: (newDocumentType: DocumentType) => void;
  archived: boolean;
  isDisabled: boolean;
  recognizedFiles: AiTaskDocumentType[];
  documentsList: Document[];
}

export const SearchDocumentMenu = ({
  documentType,
  textColor,
  documentTypesForAi,
  isRecognizedfile,
  handleChangeDocumentType,
  archived,
  isDisabled,
  recognizedFiles,
  documentsList,
}: SearchDocumentMenuProps) => {
  const { t } = useTranslation("documents");
  const [searchQuery, setSearchQuery] = useState("");
  const [allItems, setAllItems] = useState<string[]>([]);
  const [items, setItems] = useState<string[]>([]);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    string | null
  >();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let filteredDocumentTypes;
    if (isRecognizedfile) {
      filteredDocumentTypes = documentTypesForAi.filter(
        (item) =>
          !recognizedFiles.some((file) => file.documentTypeId === item.id),
      );
    }
    const names = (filteredDocumentTypes ?? documentTypesForAi).map(
      (item) => item.name,
    );
    setAllItems(names);
    setItems(names);
  }, [documentTypesForAi, recognizedFiles]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery === "") {
      setItems(allItems);
    } else {
      const filteredItems = allItems.filter((item) =>
        item.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
      );
      setItems(filteredItems);
    }
  }, [debouncedSearchQuery, allItems]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const buttonName = selectedDocumentType
    ? selectedDocumentType
    : documentType
      ? documentType
      : t("addDocumentType");

  const checkIsPublic = (name?: string) => {
    let checkDoc = [];
    const documentName = name ? name : buttonName;
    if (documentsList?.length > 0) {
      const checkType = documentTypesForAi.find(
        (res) => res.name === documentName,
      );
      checkDoc = documentsList.filter((res) => res.type?.id === checkType?.id);
      return checkDoc[0]?.isPublic ? (
        <HiOutlineLockOpen style={{ marginLeft: 5 }} size={20} />
      ) : (
        <HiOutlineLockClosed style={{ marginLeft: 5 }} size={20} />
      );
    } else {
      return;
    }
  };

  return (
    <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Tooltip label={buttonName}>
        <MenuButton
          as={Button}
          rightIcon={isRecognizedfile ? <ChevronDownIcon /> : <AddIcon />}
          variant={"link"}
          color={textColor}
          minWidth={5}
          onClick={() => setIsOpen((prev) => !prev)}
          isDisabled={archived || isDisabled}
        >
          {isRecognizedfile && (
            <Flex>
              <Text isTruncated>{buttonName}</Text>
              <Box width={10}>
              {checkIsPublic()}{" "}
              </Box>
            </Flex>
          )}
        </MenuButton>
      </Tooltip>
      <MenuList p={4} maxH="400px" overflowY="auto" zIndex={1000}>
        <Input
          placeholder={t("search", { ns: "common" })}
          value={searchQuery}
          onChange={handleSearch}
          _placeholder={{ color: "grey" }}
          mb={2}
          color="black"
        />
        <Flex direction="column">
          {items.length > 0 ? (
            items.map((item) => {
              return (
                <MenuItem
                  color={"black"}
                  key={item}
                  onClick={() => {
                    const checkType = documentTypesForAi.find(
                      (res) => res.name === item,
                    );
                    handleChangeDocumentType(checkType);
                    setSelectedDocumentType(item);
                    setSearchQuery("");
                  }}
                >
                  {item}
                  {checkIsPublic(item)}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem color="black" disabled>
              {t("noResultsFound", { ns: "common" })}
            </MenuItem>
          )}
        </Flex>
      </MenuList>
    </Menu>
  );
};
