import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CommunicationAction,
  MessageType,
} from "../../../../domain/entities/documentComment";
import {
  SortDirection,
  SortMeta,
} from "../../../../domain/entities/interfaces/paginatedResults";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import useMessageViewModel from "../../../hooks/Document/useMessageViewModel";
import LoadingView from "../../../screens/Common/LoadingView";
import CommentAction from "../../Common/CommentAction";
import CommentMessage from "../../Common/CommentMessage";
import {
  PiClockClockwise,
  PiClockCounterClockwise,
} from "react-icons/pi";

export enum MessageDirection {
  SENT = "sent",
  RECEIVED = "received",
}

interface MessageViewProps {
  message: string;
  author: string;
  messageDirection: MessageDirection;
  messageDate: string;
  messageType: MessageType;
  messageId?: string;
  searchParameter?: string;
  isLastMessage?: boolean;
}

const MessageView: FC<MessageViewProps> = ({
  message,
  author,
  messageDirection,
  messageDate,
  messageType,
  messageId,
  searchParameter,
  isLastMessage
}) => {
  const { t } = useTranslation("onboarding");
  const [isSpinning, setIsSpinning] = useState(false);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [sort, setSort] = useState<SortMeta>({
    field: "createdAt",
    direction: SortDirection.Ascending,
  });

  const {
    resourceDocumentCommentGroup,
    resourceDocumentCommentGroupFetching,
    isFetchingGetDocumentCommentGroup,
    groupHasNextPage,
    groupFetchNextPage,
    getDocumentCommentGroupRefetch,
  } = useMessageViewModel(
    messageId,
    (messageType as MessageType) === MessageType.GROUP.valueOf() &&
      queryEnabled,
    sort,
  );

  const handleIconClick = (e) => {
    e.stopPropagation();
    setIsSpinning(true);
    setSort((prev) => {
      const newDirection =
        prev.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
      return {
        field: "createdAt",
        direction: newDirection,
      };
    });
    setTimeout(() => setIsSpinning(false), 1000);
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getDocumentCommentGroupRefetch();
    }
  }, [sort, getDocumentCommentGroupRefetch]);

  return (() => {
    switch (messageType) {
      case MessageType.COMMENT:
        return (
          <Flex
            width={"100%"}
            marginTop={0}
            justifyContent={
              messageDirection === MessageDirection.RECEIVED ? "start" : "end"
            }
            paddingLeft={1}
            paddingRight={1}
            marginBottom={isLastMessage ? 5 : 0}
          >
            {messageDirection === MessageDirection.RECEIVED && (
              <Tooltip label={author} placement="top">
                <Box
                  width="40px"
                  height="40px"
                  borderRadius="50%"
                  backgroundColor={"lightblue"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  marginLeft={1}
                  marginRight={2}
                >
                  <Text fontSize="16px" fontWeight="bold">
                    {author.slice(0, 2)}
                  </Text>
                </Box>
              </Tooltip>
            )}
            <Flex flexDirection={"column"} maxWidth={"70%"} marginRight={2}>
              {messageDirection === MessageDirection.RECEIVED && (
                <Text
                  marginLeft={2}
                  marginBottom={1}
                  fontSize={12}
                  alignSelf="flex-start"
                >
                  {author}
                </Text>
              )}
              <Flex
                flexDirection={"column"}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="8px"
                backgroundColor={
                  messageDirection === MessageDirection.RECEIVED
                    ? "lightblue"
                    : COLORS.white
                }
                overflow="hidden"
                paddingLeft={2}
                marginTop={1}
              >
                <CommentMessage message={message} searchParameter={searchParameter}/>
                <Text fontSize={12} alignSelf="flex-end" marginRight={2}>
                  {messageDate}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      case MessageType.ACTION:
        return (
          <Flex
            width={"100%"}
            marginTop={0}
            justifyContent={"start"}
            padding={1}
          >
            <CommentMessage message={message} searchParameter={searchParameter}/>
          </Flex>
        );
      default:
        return (
          <Flex width={"100%"} marginTop={1} justifyContent={"start"}>
            <Accordion
              allowToggle
              width="100%"
              border={"none"}
              borderColor={"white"}
            >
              <AccordionItem width="100%">
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        width="100%"
                        backgroundColor={"gray.100"}
                        onClick={() => {
                          setQueryEnabled(true),
                            setSort({
                              field: "createdAt",
                              direction: SortDirection.Ascending,
                            });
                        }}
                      >
                        <Box width="100%" textAlign="left">
                          {isExpanded
                            ? t("showLess", { ns: "common" })
                            : t("showMore", { ns: "common" })}
                        </Box>
                        {isExpanded && (
                          <Button
                            style={{
                              padding: 0,
                              transition: "transform 0.5s",
                              transform:
                                sort.direction === SortDirection.Ascending
                                  ? "rotate(360deg)"
                                  : "rotate(0deg)",
                            }}
                            mr={5}
                            onClick={handleIconClick}
                          >
                            {sort.direction === SortDirection.Ascending ? (
                              <PiClockClockwise />
                            ) : (
                              <PiClockCounterClockwise />
                            )}
                          </Button>
                        )}
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={2} backgroundColor={"gray.100"}>
                      {isFetchingGetDocumentCommentGroup ? (
                        <LoadingView />
                      ) : (
                        resourceDocumentCommentGroup?.map((message) => (
                          <CommentAction
                            key={message?.id}
                            action={message?.comment as CommunicationAction}
                            date={formatDateBasedOnLanguage(
                              message.createdAt as unknown as string,
                              true,
                            )}
                            messageParameter={message.parameters}
                            author={message.author}
                          />
                        ))
                      )}
                      {!isFetchingGetDocumentCommentGroup &&
                        groupHasNextPage && (
                          <Button
                            height={30}
                            width={"100%"}
                            onClick={() => groupFetchNextPage()}
                            isLoading={resourceDocumentCommentGroupFetching}
                          >
                            {t("showMore", { ns: "common" })}
                            <ChevronDownIcon />
                          </Button>
                        )}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </Flex>
        );
    }
  })();
};

export { MessageView };
