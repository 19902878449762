import UseCase from '../../../core/app/usecase';
import Tag from '../../entities/tag';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

interface Args {
	companyId: string;
	typology: Tag;
}

class UpdateTypologyToDocumentPreset implements UseCase<Args, void> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(args: Args): Promise<void> {
		await this.repository.updateTypologyToDocumentPreset(args.companyId, args.typology);
	}
}

export default UpdateTypologyToDocumentPreset;
