import { container } from 'tsyringe';
import { LoginResponse, UpdatePasswordResponse } from '../../infrastructure/responses/auth';
import { BusinessLicenseResponse, BusinessOption, BusinessSizeResponse, BusinessTypeResponse, SignUpCompany, SignUpderivedCompany, SignUpUser } from '../interactors/auth/args';

export interface AuthRepository {
	requestPasswordReset(email: string): Promise<boolean>;
	createPersonalAccount(payload: SignUpUser): Promise<boolean>;
	loginWithToken(refresh_token: string, tenant: string): Promise<LoginResponse>;
	login(email: string, password: string): Promise<LoginResponse>;
	createBusinessAccount(payload: SignUpCompany): Promise<boolean>;
	createBusinessDerivedAccount(payload: SignUpderivedCompany): Promise<boolean>;
	userAlreadyRegistered(token: string, company: string): Promise<{ already_registered: string }>;
	updatePassword(oldPassword: string, password: string, confirmPassword: string): Promise<UpdatePasswordResponse>;
	getBusinessTypes(): Promise<BusinessTypeResponse[]>;
	getVatCountries(): Promise<BusinessOption[]>;
  getBusinessSizes(): Promise<BusinessSizeResponse[]>;
  getLicenseTypes(): Promise<BusinessLicenseResponse[]>;
}

export const makeAuthRepository = (): AuthRepository => {
	return container.resolve('AuthRepository');
};
