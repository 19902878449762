import { injectable } from 'tsyringe';
import { ApiService } from '../utilities/apiService';
import SmartDownloadRepository, { Resource } from "../../domain/repositories/smartDownloadRepository";
import { GetSupplierResourcesFilters } from "../../presentation/hooks/Suppliers/useSupplierResourcesViewModel";
import { PaginatedResults, SortMeta } from "../../domain/entities/interfaces/paginatedResults";
import { ResourceType } from "../../presentation/screens/Site/ResourceSelectableTable";
import { ContextFiltersType, DownloadParams } from "../../presentation/hooks/SmartDownload/useSmartDownloadViewModel";

@injectable()
class ServerSmartDownloadRepository implements SmartDownloadRepository {
  constructor(private apiService: ApiService) {}

  async downloadRequest(
    companyId: string,
    params: DownloadParams
  ): Promise<void> {
     const response = await this.apiService.fetchWithToken(
      `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/download`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          params
        ),
      }
    );
    if (response.status !== 200 && response.status !== 204) {
      return Promise.reject(new Error('smartDownload.downloadFeedback'));
    }
  }
  async getSupplierResources(
    companyId: string,
    supplierIds: string[],
    selectAllSuppliers: boolean,
    resourceType: ResourceType,
    filter: GetSupplierResourcesFilters,
    sort: SortMeta,
    pageParam?:number,
    contextFilters?: ContextFiltersType,
    ): Promise<PaginatedResults<Resource>> {
    const filterParams = filter && Object.fromEntries(
      Object.entries(filter).map(([key, value]) => [key, String(value)])
    );
    const params = new URLSearchParams({
      ...(pageParam ? { page: pageParam.toString(), perPage: String(25) } : {}),
      ...sort,
      ...filterParams
    });
    const body = {...contextFilters}
    const response = await this.apiService.fetchWithToken(
      `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${resourceType}?${params.toString()}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          {...body, supplierIds, selectAllSuppliers}
        ),
      }
    );
    return await response.json();
  }
}

export default ServerSmartDownloadRepository;
