import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useApp } from "../../../app";
import sikuroLogo from "../../assets/img/appBar-logo.png";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import RenderIf from "../Permissions/RenderIf";
import ChangeTenantModal from "../Views/common/ChangeTenantModal";
import NavItem, { NavItemProps } from "./NavItem";
import {
  Avatar,
  AvatarBadge,
  Box,
  Divider,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  VStack
} from "@chakra-ui/react";
import Company from "../../../domain/entities/company";

import { COLORS } from "../../assets/theme/colors";
import { useNavigate } from "react-router-dom";
import { GrHelpBook } from "react-icons/gr";
import { ConfirmAlert } from "../../screens/Common/ConfirmAlert";

const Sidebar = ({ topItems, bottomItems, hideNavItems }: SidebarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLogoutAlert, setShowLogoutAlert] = useState<boolean>(false);
  const [showChangeTenantModal, setShowChangeTenantModal] =
    useState<boolean>(false);
  const {
    notificationContext: { notificationCount }
  } = useApp();
  const logo = localStorage.getItem("tenantLogo");
  const name = localStorage.getItem("tenantName");
  const { changeTenant, logout, user, token, companyUser } = useAuth();
  const [isUserHovered, setIsUserHovered] = useState(false);
  const [isChangeTenantHovered, setIsChangeTenantHovered] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const renderItems = (items: Array<NavItemProps>) => {
    return items.map((link) => (
      <RenderIf
        key={link.path}
        permissions={link.permissions ?? []}
        check={PermissionCheck.Some}
      >
        <NavItem
          icon={link.icon}
          Icon={link.Icon}
          path={link.path}
          name={link.name}
          details={notificationCount as number}
        />
      </RenderIf>
    ));
  };

  const handleSelectedTenant = async (company: Company) => {
    setShowChangeTenantModal(false);
    await changeTenant(company);
  };

  const renderGroups = (groups: Array<SidebarGroup>) => {
    return groups.map((group) => (
      <Box key={uuidv4()}>{renderItems(group.links)}</Box>
    ));
  };

  const goToUserSettings = async () => {
    companyUser.state === "blocked"
      ? navigate("/access-denied", {
        state: { blockedUser: companyUser.state === "blocked" },
        replace: true
      })
      : navigate("/userSettings");
  };

  useEffect(() => {
    const fetchImage = async (imageUrl: string) => {
      const response = await fetch(imageUrl, {
        headers: { Authorization: `Bearer ${token.access_token}` }
      });

      const blob = await response.blob();
      setImageUrl(URL.createObjectURL(blob));
    };

    if (user?.photo && token.access_token) {
      fetchImage(user?.photo);
    }
  }, [user?.photo, token?.access_token]);

  return (
    <Box transition="3s ease" h="100vh" overflowY={"auto"}>
      <Flex as="nav" direction="column" h="100vh">
        <Flex p={3} backgroundColor="white" h="55px">
          <Image src={sikuroLogo} height="100%" />
        </Flex>

        <VStack alignItems="start">
          <Flex direction="column" width="100%">
            {renderGroups(topItems)}
          </Flex>
        </VStack>
        <Box flex="1" />
        <VStack alignItems="start">
          <Flex width="100%" justifyContent={"center"}>
            <Tooltip shouldWrapChildren label={t("guide", { ns: "common" })}>
              <Link
                href="https://support.sikuro.it/"
                isExternal
                _hover={{ color: COLORS.sikuroBlue }}
              >
                <GrHelpBook size={"32px"} />
              </Link>
            </Tooltip>
          </Flex>
          <Divider orientation="horizontal" />
          <Flex direction="column" width="100%">
            {renderGroups(bottomItems)}
          </Flex>
        </VStack>

        <VStack alignItems="center" gap={1} zIndex={9}>
          <Tooltip label={t("changeTenant", { ns: "common" })}>
            <Box
              onMouseEnter={() => setIsChangeTenantHovered(true)}
              onMouseLeave={() => setIsChangeTenantHovered(false)}
              borderRadius={"full"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              w={"2.8rem"}
              h={"2.8rem"}
              cursor={"pointer"}
            >
              <Box
                onClick={() => setShowChangeTenantModal(true)}
                borderRadius={"full"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                w={"2.8rem"}
                h={"2.8rem"}
                background={
                  isChangeTenantHovered ? COLORS.sikuroBlue : "transparent"
                } cursor={"pointer"}
              >
                <Avatar
                  name={name}
                  w={"88%"}
                  h={"88%"}
                  border="2px solid white"
                  src={logo}
                  bg={"gray.400"}
                  color={"white"}
                />
              </Box>
            </Box>
          </Tooltip>

          <Menu placement="right">
            <Tooltip label={t("userSettings", { ns: "common", username: user?.name })}>
              <MenuButton
                onMouseEnter={() => setIsUserHovered(true)}
                onMouseLeave={() => setIsUserHovered(false)}
                pb="4"
              >
                <Box
                  borderRadius={"full"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  w={"2.8rem"}
                  h={"2.8rem"}
                  background={isUserHovered ? COLORS.sikuroBlue : "transparent"}
                  cursor={"pointer"}
                >
                  <Avatar
                    name={user?.name}
                    w={"88%"}
                    h={"88%"}
                    border="2px solid white"
                    src={imageUrl}
                  >
                    <AvatarBadge boxSize="0.85em" bg="green.500" />
                  </Avatar>
                </Box>
              </MenuButton>
            </Tooltip>
            <MenuList>
              <MenuGroup title={user?.name}>
                {!hideNavItems && (
                  <MenuItem onClick={() => goToUserSettings()}>
                    {t("userSettings", { ns: "common", username: "" })}
                  </MenuItem>
                )}
                <MenuItem onClick={() => setShowLogoutAlert(true)}>
                  {t("logout", { ns: "navbar" })}
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </VStack>
        {showLogoutAlert && (
          <ConfirmAlert
            onConfirm={logout}
            onCancel={() => setShowLogoutAlert(false)}
            variant={"warning"}
            title={t("warning", { ns: "common" })}
            message={t("logout", { ns: "common" })}
          />
        )}
        {showChangeTenantModal && (
          <ChangeTenantModal
            onClose={() => setShowChangeTenantModal(false)}
            setSelectedTenant={(company) => handleSelectedTenant(company)}
            enableGetCompanies={showChangeTenantModal}
          />
        )}
      </Flex>
    </Box>
  );
};

interface SidebarProps {
  topItems: Array<SidebarGroup>;
  bottomItems: Array<SidebarGroup>;
  hideNavItems?: boolean;
}

export interface SidebarGroup {
  links: Array<NavItemProps>;
}

export default Sidebar;
