import { makeSiteChemicalRepository } from './repositories';
import GetSiteChemical from '../../../../domain/interactors/resources/siteChemicals/getSiteChemical';
import GetSiteChemicals from '../../../../domain/interactors/resources/siteChemicals/getSiteChemicals';
import CreateSiteChemical from '../../../../domain/interactors/resources/siteChemicals/createSiteChemical';
import DeleteSiteChemical from '../../../../domain/interactors/resources/siteChemicals/deleteSiteChemical';
import RestoreSiteChemical from '../../../../domain/interactors/resources/siteChemicals/restoreSiteChemical';
import EvaluateSiteChemical from '../../../../domain/interactors/resources/siteChemicals/evaluateSiteChemical';
import GetWorkingSiteChemicals from '../../../../domain/interactors/resources/siteChemicals/getWorkingSiteChemicals';
import AutoEvaluateSiteChemical from '../../../../domain/interactors/resources/siteChemicals/autoEvaluateSiteChemical';
import GetSiteChemicalDocuments from '../../../../domain/interactors/resources/siteChemicals/getSiteChemicalDocuments';
import UpdateSiteChemicalVariant from '../../../../domain/interactors/resources/siteChemicals/updateSiteChemicalVariant';
import GetSiteChemicalEvaluations from '../../../../domain/interactors/resources/siteChemicals/getSiteChemicalEvaluations';
import AddRequirementToSiteChemical from '../../../../domain/interactors/resources/siteChemicals/addRequirementToSiteChemical';
import UpdateSiteChemicalGlobalStatus from '../../../../domain/interactors/resources/siteChemicals/updateSiteChemicalGlobalStatus';
import UpdateSiteChemicalSpecializations from '../../../../domain/interactors/resources/siteChemicals/updateSiteChemicalSpecializations';
import GetSiteChemicalDocumentsRejected from '../../../../domain/interactors/resources/siteChemicals/getSiteChemicalDocumentsRejected';

export const makeGetSiteChemicals = () => {
	return new GetSiteChemicals(makeSiteChemicalRepository());
};

export const makeGetWorkingSiteChemicals = () => {
	return new GetWorkingSiteChemicals(makeSiteChemicalRepository());
};

export const makeGetSiteChemical = () => {
	return new GetSiteChemical(makeSiteChemicalRepository());
};

export const makeCreateSiteChemical = () => {
	return new CreateSiteChemical(makeSiteChemicalRepository());
};

export const makeDeleteSiteChemical = () => {
	return new DeleteSiteChemical(makeSiteChemicalRepository());
};

export const makeRestoreSiteChemical = () => {
	return new RestoreSiteChemical(makeSiteChemicalRepository());
};

export const makeGetSiteChemicalDocuments = () => {
	return new GetSiteChemicalDocuments(makeSiteChemicalRepository());
};

export const makeGetSiteChemicalDocumentsRejected = () => {
	return new GetSiteChemicalDocumentsRejected(makeSiteChemicalRepository());
};

export const makeUpdateSiteChemicalVariant = () => {
	return new UpdateSiteChemicalVariant(makeSiteChemicalRepository());
};

export const makeUpdateSiteChemicalSpecializations = () => {
	return new UpdateSiteChemicalSpecializations(makeSiteChemicalRepository());
};

export const makeEvaluateSiteChemical = () => {
	return new EvaluateSiteChemical(makeSiteChemicalRepository());
};

export const makeAutoEvaluateSiteChemical = () => {
	return new AutoEvaluateSiteChemical(makeSiteChemicalRepository());
};

export const makeGetSiteChemicalEvaluations = () => {
	return new GetSiteChemicalEvaluations(makeSiteChemicalRepository());
};

export const makeAddRequirementToSiteChemical = () => {
	return new AddRequirementToSiteChemical(makeSiteChemicalRepository());
};

export const makeUpdateSiteChemicalGlobalStatus = () => {
	return new UpdateSiteChemicalGlobalStatus(makeSiteChemicalRepository());
};
