import { Font, StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../../assets/theme/colors';

Font.register({
	family: 'Montserrat',
  fonts: [
    { src: '/fonts/Montserrat-Bold.ttf', fontWeight: 900 },
    { src: '/fonts/Montserrat-SemiBold.ttf', fontWeight: 600 },
    { src: '/fonts/Montserrat-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/Montserrat-Light.ttf', fontWeight: 300 },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf', fontWeight: 400 },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 700 },
  ],
});

export const styles = StyleSheet.create({
  badgeContainer: {
    width: '85.7mm',
		height: '53.9mm',
    fontSize: 8,
    fontWeight: 400,
		fontFamily: 'Montserrat',
  },
  badgeHeaderContainer: {
    width: '100%',
    paddingRight: '2mm',
    paddingLeft: '2mm',
    paddingTop: '0.5mm',
    paddingBottom: '0.5mm',
    height: '15mm',
    color: COLORS.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.sikuroBlue,
  },
  badgeHeader: {
    fontWeight: 900,
    marginRight: '1mm',
  },
  mainContainer: {
    height: "100%",
    width: '100%',
    marginBottom: '2.5mm',
		flexDirection: 'row',
	},
	photoContainer: {
    width: '25mm',
    height: '100%',
    minHeight: "100%",
    alignItems: 'center',
    marginRight: '2mm',
    gap: "1.5mm",
  },
  photo: {
    height: '48mm',
		width: '100%',
		objectFit: 'contain',
  },
  badgeNumber: {
    justifyContent: 'center',
    width: '20mm',
    flexWrap: "wrap",
    flexDirection: 'row',
    height: '12mm',
    marginTop: '1.5mm',
    fontWeight: 700,
  },
  resourceName: {
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  badgeDetailsContainer: {
    flexDirection: 'column',
    width: "80%",
    height: "100%",
    justifyContent: "space-between",
    marginTop: '0.5mm',
    gap: "1mm",
  },
  dashedLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
    height: "1.5mm",
    marginTop: '0.25mm',
    marginBottom: '0.25mm',
    gap: '0.5mm',
  },
  dash: {
    width: "3mm",
    height: "0.5mm",
    borderRadius: "50%",
    backgroundColor: '#eece23',
  },
  badgeDetailsRow: {
    flexDirection: "row",
    flexWrap: "wrap",
	},
  detailTitle: {
    fontWeight: 900,
    marginRight: '1mm',
    color: COLORS.sikuroBlue,
  },
  text: {
    flex: 1,
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: '2mm',
  },
  logo: {
    objectFit: 'cover',
    width: '8mm',
    height: '8mm',
  },
  companyName: {
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  emptyContainer: {
    width: '85.7mm',
    height: '53.9mm',
  },
  siteImage: {
		width: '20mm',
		height: '20mm',
		objectFit: 'contain',
	},
  qrCodeContainer: {
		width: '100%',
    height: '100%',
    minHeight: '20mm',
		display: 'flex',
    flexDirection: "row",
    gap: '10mm',
		alignItems: 'center',
    justifyContent: 'center',
	},
	qrCodeOnlyContainer: {
		width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrImage: {
    width: '30mm',
    height: '30mm',
    object: 'contain',
  },
  footerBanner: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '8mm',
    paddingTop: '1mm',
    paddingBottom: '1mm',
    backgroundColor: COLORS.yellow,
  },
});
