import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import { COLORS } from "../../assets/theme/colors";
import useSiteRequirementsDocumentViewModel from "../../hooks/Document/useSiteRequirementsDocument";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import StateTag from "../../components/Common/StateTag";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import { format } from "date-fns";
import { SortDirection } from "../../../domain/entities/interfaces/paginatedResults";
import SearchInput from "../Common/SearchInput";
import { TFunction } from "i18next";
import SkeletonTableRow from "../../components/Common/table/SkeletonTableRow";
import { isArray } from "lodash";
import { formatDateBasedOnLanguage } from "../../../utils";

interface ColumnDefinition {
  field: string;
  type: "text" | "select" | "tags" | "date-range";
  label: string;
  options?: {
    option: string,
    label: string,
  }[];
}
const getStateOptions =(t: TFunction) => [
  {
    option: "available",
    label: t("combinedEvaluation.available", { ns: "enum" }),
  },
  {
    option: "not_available",
    label: t("combinedEvaluation.not_available", { ns: "enum" }),
  },
  {
    option: "unevaluated",
    label: t("combinedEvaluation.unevaluated", { ns: "enum" }),
  },
  {
    option: "not_applicable",
    label: t("combinedEvaluation.not_applicable", { ns: "enum" }),
  },
];
const getResourceOptions = (t: TFunction) => [
  {
    option: "chemical",
    label: t("chemical", { ns: "common" }),
  },
  {
    option: "machine",
    label: t("machine", { ns: "common" }),
  },
  {
    option: "tool",
    label: t("tool", { ns: "common" }),
  },
  {
    option: "vehicle",
    label: t("vehicle", { ns: "common" }),
  },
  {
    option: "worker",
    label: t("worker", { ns: "common" }),
  },
];
const getResourceColumns = (t: TFunction): ColumnDefinition[] => [
  {
    field: "name",
    type: "text",
    label: t("siteRequirementsDocument.name"),
  },
  {
    field: "resourceName",
    type: "text",
    label: t("siteRequirementsDocument.resourceName"),
  },
  {
    field: "resourceType",
    type: "select",
    label: t("siteRequirementsDocument.resourceType"),
    options: getResourceOptions(t)

  },
  {
    field: "resourceOwner",
    type: "text",
    label: t("siteRequirementsDocument.supplier"),
  },
  {
    field: "expiresAt",
    type: "date-range",
    label: t("siteRequirementsDocument.expiresAt"),
  },
  {
    field: "state",
    type: "select" as const,
    label: t("siteRequirementsDocument.state"),
    options: getStateOptions(t)
  },
];

const getSupplierColumns = (t: TFunction): ColumnDefinition[] => [
  {
    field: "name",
    type: "text",
    label: t("siteRequirementsDocument.name"),
  },
  {
    field: "resourceOwner",
    type: "text",
    label: t("siteRequirementsDocument.supplier"),
  },
  {
    field: "expiresAt",
    type: "date-range",
    label: t("siteRequirementsDocument.expiresAt"),
  },
  {
    field: "state",
    type: "select",
    label: t("siteRequirementsDocument.state"),
    options: getStateOptions(t)
  },
];

const excludeSort = new Set([]);
const SiteOverviewEvaluations = () => {
  const { t } = useTranslation("documents");
  const [searchParams, _] = useSearchParams();
  const daysSelected = Number(searchParams.get("daysSelected")) || 0;
  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setDate(currentDate.getDate() + 10);
  const location = useLocation();
  const { siteId } = useParams();
  const [showInfoBanner, setShowInfoBanner] = useState(false);

  const {
    indexTab, setIndexTab,
    siteReqResource,
    siteReqSupplier,
    siteReqResourceLoading,
    siteReqResourceHasNextPage,
    siteReqResourceFetchNextPage,
    siteReqResourceSort,
    setSiteReqResourceSort,
    siteReqResourceFilter,
    updateFilterSiteReqResource,
    siteReqSupplierLoading,
    siteReqSupplierHasNextPage,
    siteReqSupplierFetchNextPage,
    siteReqSupplierSort,
    setSiteReqSupplierSort,
    siteReqSupplierFilter,
    updateFilterSiteReqSupplier,
    setSearchResources,
    setSearchSuppliers,
  } = useSiteRequirementsDocumentViewModel();

  const navigate = useNavigate();
  const handleTabChange = useCallback((index: number) => {
    const path = index === 1
      ? `/sites/${siteId}/documents/siteRequirements/supplier`
      : `/sites/${siteId}/documents/siteRequirements/resource`;

    navigate(path);
    setIndexTab(index);
  }, [siteId, navigate, setIndexTab])

  useEffect(() => {
    if (location.state) {
      if (location.state?.fromPrevUrl === "ASC") {
        handleTabChange(1);
        setSiteReqResourceSort({
          field: "expiresAt",
          direction: SortDirection.Ascending,
        });
        setSiteReqSupplierSort({
          field: "expiresAt",
          direction: SortDirection.Ascending,
        });
        setShowInfoBanner(true);
      }
      if (location.state?.fromPrevUrl === "unevaluated") {
        handleTabChange(0);
        updateFilterSiteReqSupplier("state", "unevaluated");
        updateFilterSiteReqResource("state", "unevaluated");
        setShowInfoBanner(true);
      }
    }
  }, [handleTabChange, location.state, location.state?.fromPrevUrl, setSiteReqResourceSort, setSiteReqSupplierSort, updateFilterSiteReqResource, updateFilterSiteReqSupplier]);

  const resourceColumns = useMemo(() => getResourceColumns(t), [t]);
  const supplierColumns = useMemo(() => getSupplierColumns(t), [t]);

  useEffect(() => {
    const applyExpirationDateFilter = (days: number) => {
      if (days > 0) {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + days);

        const startOfDay = new Date(currentDate.setHours(0, 0, 0, 0));
        const endOfDay = new Date(futureDate.setHours(23, 59, 59, 0));

        updateFilterSiteReqSupplier("expiresAt", [startOfDay, endOfDay]);
        updateFilterSiteReqResource("expiresAt", [startOfDay, endOfDay]);
      }
    };
    applyExpirationDateFilter(daysSelected);
  }, [daysSelected, updateFilterSiteReqResource, updateFilterSiteReqSupplier]);

  return (
    <ContentLayout>
      <Box pt={6} px={10}>
        <Text
          fontSize="xl"
          textColor={COLORS.sikuroBlue}
          fontWeight="bold"
          pt={4}
        >
          {t("evaluationOverview")}
        </Text>
        <Text mb={5}>{t("evaluationOverviewSubtitle")}</Text>
        {showInfoBanner && (
          <InfoBanner
            text={t("evaluationOverviewInfo")}
            onClose={() => setShowInfoBanner(false)}
          />
        )}
        <Tabs
          isFitted
          defaultIndex={indexTab}
          onChange={(e) => handleTabChange(e)}
          marginTop={4}
        >
          <TabList >
            <Tab textAlign={"center"}>
              {t("siteRequirementsDocument.resourcesTab")}
            </Tab>
            <Tab width={"50%"}  textAlign={"center"}>
              {t("siteRequirementsDocument.suppliersTab")}
            </Tab>
          </TabList>

          <TabPanels marginTop={6}>
            <TabPanel p={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
                <SearchInput onSearch={setSearchResources} />
              </Flex>
              <Flex
                flexDirection={"column"}
                alignItems={"start"}
                width={"calc(100vw - 210px)"}
                position="relative"
                marginTop={3}
                overflowX={"auto"}
                overflowY={"hidden"}
                minHeight={'50vh'}
              >
                <InfiniteTable
                  tableId={"siteList"}
                  autosize
                  infiniteScroll={{
                    dataLength: siteReqResource?.length,
                    hasNextPage: siteReqResourceHasNextPage,
                    fetchNextPage: siteReqResourceFetchNextPage,
                  }}
                  isLoading={siteReqResourceLoading}
                  emptyText={t("noItems", { ns: "common" })}
                  showEmptyText={siteReqResource?.length === 0}
                >
                  <Thead>
                    <Tr bg={COLORS.table.headerBg}>
                      {resourceColumns.map((column) => (
                        <Th key={column.field}>
                          <TableColumnHeader
                            text={column.label}
                            filter={{
                              isActive:
                                !!siteReqResourceFilter[column.field] &&
                                (!Array.isArray(siteReqResourceFilter[column.field]) ||
                                  !!siteReqResourceFilter[column.field][0]),
                              component: (
                                <>
                                <ColumnFilterComponent
                                  value={siteReqResourceFilter[column.field]}
                                  type={column.type}
                                  updateFilter={(value) => {
                                    updateFilterSiteReqResource(
                                      column.field,
                                      value,
                                    );
                                  }}
                                  selectOptions={column.options}/>
                                </>
                              ),
                            }}
                            sort={
                              excludeSort.has(column.field)
                                ? undefined
                                : {
                                  handler: (direction) =>
                                    setSiteReqResourceSort({ field: column.field, direction }),
                                  direction:
                                    siteReqResourceSort?.field === column.field
                                      ? siteReqResourceSort.direction
                                      : null,
                                }
                            }
                          />
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  {isArray(siteReqResource) && (
                    <Tbody
                      className={siteReqResource?.length > 0 ? "real-row" : "skeleton-row"}
                    >
                      {siteReqResource?.length > 0 ? (
                        siteReqResource.map((r) => (
                          <Tr
                            key={r?.resourceId}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/sites/${siteId}/resources/${r.resourceType}s/${r?.resourceId}`)}
                          >
                            <Td>{r?.name}</Td>
                            <Td>{r?.resourceName}</Td>
                            <Td>{t(r?.resourceType, { ns: "common" })}</Td>
                            <Td>{r?.resourceOwner}</Td>
                            <Td>{r?.expiresAt ? format(new Date(r?.expiresAt), "dd/MM/yyyy") : "--/--/----"}</Td>
                            <Td>
                              <StateTag
                                value={r.state}
                                type={"combinedEvaluation"}
                              />
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <SkeletonTableRow
                          columnNumber={4}
                          rowNumber={3}
                          hasAction={false}
                        />
                      )}
                    </Tbody>
                  )}
                </InfiniteTable>
              </Flex>
            </TabPanel>
            <TabPanel p={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
                <SearchInput onSearch={setSearchSuppliers} />
              </Flex>
              <Flex
                flexDirection={"column"}
                alignItems={"start"}
                width={"calc(100vw - 210px)"}
                position="relative"
                marginTop={3}
                minHeight={'50vh'}
              >
                <InfiniteTable
                  tableId={"siteList"}
                  autosize
                  infiniteScroll={{
                    dataLength: siteReqSupplier?.length,
                    hasNextPage: siteReqSupplierHasNextPage,
                    fetchNextPage: siteReqSupplierFetchNextPage,
                  }}
                  isLoading={siteReqSupplierLoading}
                  emptyText={t("noItems", { ns: "common" })}
                  showEmptyText={siteReqSupplier?.length === 0}
                >
                  <Thead>
                    <Tr bg={COLORS.table.headerBg}>
                      {supplierColumns.map((column) => (
                        <Th key={column.field}>
                          <TableColumnHeader
                            text={column.label}
                            filter={{
                              isActive:
                                !!siteReqSupplierFilter[column.field] &&
                                (!Array.isArray(siteReqSupplierFilter[column.field]) ||
                                  !!siteReqSupplierFilter[column.field][0]),
                              component: (
                                <>
                                    <ColumnFilterComponent
                                      type={column.type}
                                      value={siteReqSupplierFilter[column.field]}
                                      updateFilter={(value) =>
                                        updateFilterSiteReqSupplier(
                                          column.field,
                                          value as string | string[],
                                        )
                                      }
                                      selectOptions={column.options}
                                    />
                                </>
                              ),
                            }}
                            sort={
                              excludeSort.has(column.field)
                                ? undefined
                                : {
                                  handler: (direction) =>
                                    setSiteReqSupplierSort({ field: column.field, direction }),
                                  direction:
                                    siteReqSupplierSort?.field === column.field
                                      ? siteReqSupplierSort.direction
                                      : null,
                                }
                            }
                          />
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  {siteReqSupplier?.length > 0 && (
                    <Tbody
                      className={siteReqSupplier?.length > 0 ? "real-row" : "skeleton-row"}
                    >
                      {siteReqSupplier?.length > 0 ? (
                        siteReqSupplier.map((r) => (
                          <Tr
                            key={r?.resourceId}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/sites/${siteId}/suppliers/${r.resourceId}`)}
                          >
                            <Td>{r?.name}</Td>
                            <Td>{r?.resourceOwner}</Td>
                            <Td>{r?.expiresAt ? formatDateBasedOnLanguage(new Date(r?.expiresAt)) : "--/--/----"}</Td>
                            <Td>
                              <StateTag value={t(r?.state , { ns: "enum" } )} type="combinedEvaluation" />
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <SkeletonTableRow
                          columnNumber={4}
                          rowNumber={3}
                          hasAction={false}
                        />
                      )}
                    </Tbody>
                  )}
                </InfiniteTable>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ContentLayout>
  );
};

export default SiteOverviewEvaluations;

