import { injectable } from "tsyringe";
import { DashboardCount } from "../../domain/entities/dashboardCount";
import { SortMeta } from "../../domain/entities/interfaces/paginatedResults";
import DashboardRepository, { GetSupplierEvaluationsFilter } from "../../domain/repositories/dashboardRepository";
import { ApiService } from "../utilities/apiService";
import { DashboardTopic } from "../../domain/entities/dashboardTopic";
import { DashboardReport } from "../../domain/entities/dasboardExpiringSupplierEvaluation";

@injectable()
class ServerDashboardRepository implements DashboardRepository {
	constructor(private apiService: ApiService) {}

	async getDashboardCount(companyId: string, topic: DashboardTopic, days?: number): Promise<DashboardCount> {
		try {
			const params = topic === 'expiring-documents' || (topic === 'expiring-evaluations' && days) ? `?days=${days}` : '';
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/count/${topic}${params}`
			);
			if (!response.ok) {
				return Promise.reject(new Error('error.unauthorized'));
			}

			return await response.json();
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardReportByTopic(
		companyId: string,
		topic: DashboardTopic,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetSupplierEvaluationsFilter,
		days?: number
	): Promise<DashboardReport[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort, ...filter });
			if (days) {
				params.append('days', String(days));
			}
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/report/${topic}?${params.toString()}`
			);
			const payload = await response.json();
			return payload.results.map(
				(item): DashboardReport => ({
					id: item.id,
					name: item.name,
					expiresAt: item.expiresAt,
					expiresIn: item.expiresIn,
					resourceId: item.resourceId,
					resourceType: item.resourceType,
					resourceName: item.resourceName,
					...(item.siteId && { siteId: item.siteId }),
					...(item.count && { count: item.count }),
					...(item.supplierId && { supplierId: item.supplierId }),
					...(item.supplierName && { supplierName: item.supplierName }),
					...(item.siteName && { siteName: item.siteName }),
					...(item.date && { date: new Date(item.date) }),
					...(item.isOwnedSite && { isOwnedSite: item.isOwnedSite }),
				})
			);
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}
}

export default ServerDashboardRepository;
