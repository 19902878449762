import { injectable } from 'tsyringe';
import Badge, { badgeFromInterval } from '../../domain/entities/badge';
import { BadgeLogEntry } from '../../domain/entities/badgeLogEntry';
import { BadgeResourceType } from '../../domain/entities/badgeResourceType.enum';
import File from '../../domain/entities/file';
import ImportInfo from '../../domain/entities/importInfo';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Tag from '../../domain/entities/tag';
import BadgeRepository, {
  GetAvailableBadgesFilters,
  GetBadgeHistoryFilters,
  GetBadgesFilters,
  GetBadgesQuery,
  GetSiteBadgesFilters,
  LinkResourcesToBadgesResponse,
} from '../../domain/repositories/badgeRepository';
import { BadgeStatusSite } from '../../presentation/hooks/Badge/useBadgeDetailViewModel';
import { GetSiteResourcesFilters } from '../../presentation/hooks/Badge/useSiteBadgesListViewModel';
import { ApiService } from '../utilities/apiService';
import { dateIntervals, formatISODate } from '../utilities/filters';

@injectable()
class ServerBadgeRepository implements BadgeRepository {
	constructor(private apiService: ApiService) {}

	async getBadges(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		filter?: GetBadgesFilters,
		sort?: SortMeta,
		query?: GetBadgesQuery,
	): Promise<PaginatedResults<Badge>> {
		const { tagIds, ...restFilter } = filter || {};
		const availableForSite = query ? (query === 'availableForSite' ? { availableForSite: siteId } : { availableForResources: 'true' }) : {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...{
				...restFilter,
			},
			...sort,
			...availableForSite,
		});

		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges?${params.toString()}`,
		);

		const badges = await response.json();

		return badges;
	}

	async getBadgesNoPaging(companyId: string, siteId: string): Promise<Badge[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges`,
		);

		const badges = await response.json();

		return badges['results'] ?? [];
	}

	async getBadgeSites(companyId: string, badgeId: string): Promise<BadgeStatusSite[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/sites`,
		);
		const badgeSites = await response.json();
		return badgeSites['results'] ?? [];
	}

	async getAvailableBadgesSiteCount(companyId: string, siteId: string): Promise<number> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges-count`,
		);
		const badgeCount = await response.json();
		return badgeCount['results'] ?? [];
	}

	async getSiteBadgeFromInterval(companyId: string, siteId: string, fromCode: number, toCode: number): Promise<badgeFromInterval> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges/check-interval-availability?fromCode=${fromCode}&toCode=${toCode}`,
		);
		const badgeCount = await response.json();
		return badgeCount ?? [];
	}

	async getSiteBadges(
		companyId: string,
		siteId: string,
		page?: number,
		perPage?: number,
		filter?: GetSiteBadgesFilters,
		sort?: SortMeta,
		query?: GetBadgesQuery,
	): Promise<PaginatedResults<Badge>> {
		const { tagIds, ...restFilter } = filter || {};
		let availableQuery;
		switch (query) {
			case 'availableForSite':
				availableQuery = { availableForSite: siteId };
				return availableQuery;
			case 'availableForResources':
				availableQuery = { availableForResources: true };
				break;
			default:
				query;
		}

		const params = new URLSearchParams({
      ...(page ? { page: page.toString() } : {}),
      ...(perPage ? { perPage: perPage.toString() } : {}),
			...restFilter,
			...sort,
			...(query ? availableQuery : {}),
		});
		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges?${params.toString()}`,
		);
		return await response.json();
	}

	async importBadges(companyId: string, file: File): Promise<ImportInfo> {
		const formData = new FormData();
		const files = Array.from(file.binaries);
		files.forEach((f) => {
			formData.append('file', f);
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/import`, {
			method: 'POST',
			body: formData,
		});
		return response.ok ? await response.json() : Promise.reject(new Error('error.conflict'));
	}

	async importLinkResourcesBadges(companyId: string, resourceType: BadgeResourceType, file: File, siteId?: string): Promise<void> {
		const formData = new FormData();
		const files = Array.from(file.binaries);
		files.forEach((f) => {
			formData.append('badgeOwnerType', resourceType);
			formData.append('file', f);
		});
		const url = siteId
			? `/companies/${companyId}/sites/${siteId}/import-resources-linked-badges`
			: `/companies/${companyId}/badges/import-resources-linked-badges`;
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}${url}`, {
			method: 'POST',
			body: formData,
		});
		if (!response.ok) {
			let error = '';
			if (response.status === 422) {
				error = 'errorLinkResource';
				return Promise.reject(new Error(error));
			} else {
				error = 'cannotLinkResourceToBadge';
			}
			return Promise.reject(new Error(error));
		} else {
			if (response.status === 204) {
				return Promise.resolve();
			}
		}
	}

	async deleteBadge(companyId: string, badgeId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}`, {
			method: 'DELETE',
		});
	}

	async createBadge(companyId: string, badge: Badge): Promise<Badge> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				...badge,
				tagIds: badge.tags.filter((tag) => tag.isSystem).map((t) => t.id),
				tags: undefined,
				tagNames: badge.tags.filter((tag) => !tag.isSystem).map((tag) => tag.name),
			}),
		});

		if (!response.ok) {
			const errorDetails = await response.json();
			throw new Error(JSON.stringify(errorDetails));
		}

		return await response.json();
	}

	async createMultipleBadgeQR(companyId: string, count: number, tags: Tag[]): Promise<Badge> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/generate-qr-badges`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					count,
					tagIds: tags.filter((tag) => tag.isSystem).map((t) => t.id),
					tagNames: tags.filter((tag) => !tag.isSystem).map((tag) => tag.name),
				}),
			},
		);

		if (!response.ok) {
			const errorDetails = await response.json();
			throw new Error(JSON.stringify(errorDetails));
		}

		return await response.json();
	}

	async updateBadge(companyId: string, badge: Partial<Badge>, siteId?: string): Promise<void> {
		const updateUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges/${badge.id}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badge.id}`;
		await this.apiService.fetchWithToken(updateUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ status: badge.status }),
		});
	}

	async getBadge(companyId: string, badgeId: string, siteId: string): Promise<Badge> {
		const getBadgeUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges/${badgeId}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}`;
		const response = await this.apiService.fetchWithToken(getBadgeUrl);
		return await response.json();
	}

	async getBadgeHistory(
		companyId: string,
		badgeId: string,
		filter?: GetBadgeHistoryFilters,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<BadgeLogEntry[]> {
		const { actionDate, actionTime, ...restFilter } = filter || {}; //FIXME: is actionTime required?
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...sort,
			...dateIntervals(actionDate),
			...(actionTime && actionTime[0] && actionTime[1] ? { actionTime: actionTime?.map((date) => date.toISOString()).join(',') } : {}),
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/records?${params.toString()}`,
		);
		const { results = [] } = await response.json();
		return results.map((entry: any) => ({
			id: entry.id,
			actionDate: new Date(entry.date),
			actionType: entry.type,
			actionResult: entry.result,
			badgeReaderName: entry?.reader?.name,
			badgeActionType: entry?.type,
			anomaly: entry.anomaly,
      isForced: entry.isForced,
			isManual: entry.isManual,
			forcing: entry.forcing,
		}));
	}

	async linkBadgesToSite(
		companyId: string,
		siteId: string,
		badgeIds: string[],
		selectAll: boolean,
		selectedBadgesNumber?: number,
		availableBadgesFilters?: { [p: string]: any },
	): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges`, {
			method: 'PUT',
			body: JSON.stringify({ badgeIds, selectAll, selectedBadgesNumber, availableBadgesFilters }),
			headers: { 'Content-Type': 'application/json' },
		});
	}

	async unlinkBadgeFromSite(companyId: string, siteId: string, badgeIds: string[], selectAll: boolean): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges`, {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				badgeIds: badgeIds,
				unlinkAll: selectAll,
			}),
		});
	}

	async linkBadgesToSiteWorkers(
		companyId: string,
		siteId: string,
		badgeIds: string[],
		workerIds: string[],
		selectAllResources: boolean,
		selectedResourcesNumber: number,
		siteResourcesFilters: GetSiteResourcesFilters,
		selectAllBadges: boolean,
		selectedBadgesNumber: number,
		availableBadgesFilters: GetAvailableBadgesFilters,
		automaticLinking: boolean,
	): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/workers-badges`, {
			method: 'PUT',
			body: JSON.stringify({
				badgeIds,
				workerIds,
				selectAllResources,
				selectedResourcesNumber,
				siteResourcesFilters,
				selectAllBadges,
				selectedBadgesNumber,
				availableBadgesFilters,
				automaticLinking,
			}),
			headers: { 'Content-Type': 'application/json' },
		});
	}

	async linkBadgesToSiteVehicles(
		companyId: string,
		siteId: string,
		badgeIds: string[],
		vehicleIds: string[],
		selectAllResources: boolean,
		selectedResourcesNumber: number,
		siteResourcesFilters: GetSiteResourcesFilters,
		selectAllBadges: boolean,
		selectedBadgesNumber: number,
		availableBadgesFilters: GetAvailableBadgesFilters,
		automaticLinking: boolean,
	): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/vehicles-badges`, {
			method: 'PUT',
			body: JSON.stringify({
				badgeIds,
				vehicleIds,
				selectAllResources,
				selectedResourcesNumber,
				siteResourcesFilters,
				selectAllBadges,
				selectedBadgesNumber,
				availableBadgesFilters,
				automaticLinking,
			}),
			headers: { 'Content-Type': 'application/json' },
		});
	}

	async linkBadgesToSiteMachines(
		companyId: string,
		siteId: string,
		badgeIds: string[],
		machineIds: string[],
		selectAllResources: boolean,
		selectedResourcesNumber: number,
		siteResourcesFilters: GetSiteResourcesFilters,
		selectAllBadges: boolean,
		selectedBadgesNumber: number,
		availableBadgesFilters: GetAvailableBadgesFilters,
		automaticLinking: boolean,
	): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/machines-badges`, {
			method: 'PUT',
			body: JSON.stringify({
				badgeIds,
				machineIds,
				selectAllResources,
				selectedResourcesNumber,
				siteResourcesFilters,
				selectAllBadges,
				selectedBadgesNumber,
				availableBadgesFilters,
				automaticLinking,
			}),
			headers: { 'Content-Type': 'application/json' },
		});
	}

	async linkBadgesToResources(
		companyId: string,
		badgeIds: string[],
		resourceIds: string[],
		selectAllResources: boolean,
		selectedResourcesNumber: number,
		siteResourcesFilters: GetSiteResourcesFilters,
		selectAllBadges: boolean,
		selectedBadgesNumber: number,
		availableBadgesFilters: GetAvailableBadgesFilters,
		automaticLinking: boolean,
		resourceType: BadgeResourceType,
	): Promise<LinkResourcesToBadgesResponse> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/resources-badges`,
			{
				method: 'PUT',
				body: JSON.stringify({
					badgeIds,
					resourceIds,
					selectAllResources,
					selectedResourcesNumber,
					siteResourcesFilters,
					selectAllBadges,
					selectedBadgesNumber,
					availableBadgesFilters,
					automaticLinking,
					resourceType,
				}),
				headers: { 'Content-Type': 'application/json' },
			},
		);
		return response.ok ? await response.json() : Promise.reject(new Error('error.conflict'));
	}

	async unlinkBadgesFromResources(companyId: string, badgeIds: string[], selectAll: boolean): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/resources-badges`, {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				badgeIds: badgeIds,
				unlinkAll: selectAll,
			}),
		});
	}

	async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/resource`, {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
		});
	}

	async linkBadgeResource(companyId: string, badgeId: string, resourceType: BadgeResourceType, resourceId: string): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/resource`,
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ resourceType, resourceId }),
				},
			);
			if (!response.ok) {
				if (response.status === 403 || response.status === 401) {
					const error = 'unauthorized';
					return Promise.reject(new Error(error));
				} else if (response.status === 404) {
					const error = 'notFound';
					return Promise.reject(new Error(error));
				} else if (response.status === 500) {
					const error = 'serverError';
					return Promise.reject(new Error(error));
				} else if (response.status === 409) {
					const error = 'conflictLinkBadge';
					return Promise.reject(new Error(error));
				} else {
					const { message } = await response.json();
					return Promise.reject(new Error(message));
				}
			}
		} catch (e) {
			const message = e.message;
			return Promise.reject(new Error(message));
		}
	}

	async createBadgeLogEntry(companyId: string, siteId: string, badgeLogEntry: BadgeLogEntry, badgeId: string): Promise<void> {
		const payload = {
			badgeReader: {
				id: badgeLogEntry.reader.id,
			},
			record: {
				date: formatISODate(badgeLogEntry.actionDate),
				type: badgeLogEntry.actionType,
			},
    };

		const badgeLogbaseUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges/${badgeId}/manual-record`;
		try {
			const response = await this.apiService.fetchWithToken(`${badgeLogbaseUrl}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});
			if (!response.ok && response.status === 403) {
				const { error } = await response.json();
				return Promise.reject(new Error(error));
			}
		} catch (error) {
			return Promise.reject(new Error(error));
		}
	}

  async deleteBadgeLogEntry(companyId: string, siteId: string, badgeLogEntry: BadgeLogEntry): Promise<void> {
		const badgeLogbaseUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/badges-record/${badgeLogEntry.id}`;

		await this.apiService.fetchWithToken(badgeLogbaseUrl, {
			method: 'DELETE',
		});
	}

	async linkBadgeTag(companyId: string, badgeId: string, tagIds: Tag, siteId?: string): Promise<void> {
		let tagToAdd = tagIds;
		if (tagToAdd.id === tagToAdd.name) {
			const tagsCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tags`;
			const createTagResponse = await this.apiService.fetchWithToken(tagsCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: tagToAdd.name }),
			});
			tagToAdd = await createTagResponse.json();
		}

		const updateUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/tags/${tagToAdd.id}`;
		await this.apiService.fetchWithToken(updateUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(tagToAdd),
		});
	}

	async unlinkBadgeTag(companyId: string, siteId: string, badgeId: string, tag: Tag): Promise<void> {
		const updateUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/tags/${tag.id}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/tags/${tag.id}`;
		await this.apiService.fetchWithToken(updateUrl, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}
}

export default ServerBadgeRepository;
