import Company from "../../../domain/entities/company";
import { makeGetSuppliersForDownload } from "../../../core/app/factory/suppliers/useCases";
import { GetGlobalSupplierFilters } from "../../../domain/repositories/supplierRepository";
import { PaginatedResults, SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";
import { Resource } from "../../../domain/repositories/smartDownloadRepository";
import { makeDownloadRequest, makeGetSupplierResources } from "../../../core/app/factory/smartDownload/useCases";
import { GetSupplierResourcesFilters } from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import { makeGetDocumentTypesSmartDownload } from "../../../core/app/factory/requirements/useCases";
import DocumentType from "../../../domain/entities/documentType";
import { GetDocumentTypeFilters } from "../../../domain/repositories/documentTypeRepository";
import { ContextFiltersType, DownloadParams } from "../../hooks/SmartDownload/useSmartDownloadViewModel";

class SmartDownloadViewModel {
  async downloadRequest(companyId: string, params: DownloadParams): Promise<void> {
    return await makeDownloadRequest().Call({ companyId, params });
  }

  async getSuppliersForDownload(companyId: string, filter?: GetGlobalSupplierFilters, sort?: SortMeta, pageParam?: number, contextFilters?: ContextFiltersType): Promise<PaginatedResults<Company>> {
    return await makeGetSuppliersForDownload().Call({ companyId, filter, sort, pageParam, contextFilters });
  }

  async getDocumentsBySites(
    companyId: string,
    context: string,
    documentByResourceType: ResourceType,
    filter?: GetDocumentTypeFilters,
    sort ?: SortMeta,
    pageParam?: number,
    contextFilters?: ContextFiltersType
  ): Promise<{ count: number; results: DocumentType[] }> {
    return await makeGetDocumentTypesSmartDownload().Call({
      companyId,
      context,
      documentByResourceType,
      filter,
      sort,
      pageParam,
      contextFilters
    });
  }

  async getDocumentsByResources(
    companyId: string,
    context: string,
    documentByResourceType: ResourceType,
    filter?: GetDocumentTypeFilters,
    sort ?: SortMeta,
    pageParam?: number,
    contextFilters?: ContextFiltersType
  ): Promise<{ count: number; results: DocumentType[] }> {
    return await makeGetDocumentTypesSmartDownload().Call({
      companyId,
      context,
      documentByResourceType,
      filter,
      sort,
      pageParam,
      contextFilters
    });
  }

  async getSupplierResources(
    companyId: string,
    supplierIds: string[],
    selectedAllSuppliers: boolean,
    resourceType: ResourceType,
    filter?: GetSupplierResourcesFilters,
    sort?: SortMeta,
    pageParam?: number,
    contextFilters?: ContextFiltersType
  ): Promise<PaginatedResults<Resource>> {
    return await makeGetSupplierResources().Call({
      companyId, supplierIds, selectedAllSuppliers, contextFilters, resourceType, filter, sort, pageParam
    });
  }
}

export default SmartDownloadViewModel;
