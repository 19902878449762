import { NewResource, SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import {
	makeCreateSiteVehicle,
	makeGetSiteVehicles,
	makeDeleteSiteVehicle,
	makeRestoreSiteVehicle,
} from '../../../core/app/factory/siteVehicles/usecases';
import { makeGetVehicles } from '../../../core/app/factory/vehicles/usecases';
import { makeGetSiteVariants } from '../../../core/app/factory/variants/usecases';
import { makeGetSiteSpecializations } from '../../../core/app/factory/specializations/usecases';
import { SiteVehicle, SiteVehicleParams } from '../../../domain/entities/siteVehicle';
import { GetSiteVehiclesFilters } from '../../../domain/repositories/siteVehicleRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { GetVehiclesFilter } from '../../../domain/repositories/vehicleRepository';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';

class SiteVehiclesViewModel implements SiteResourcesViewModel<SiteVehicleParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteVehiclesFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteVehicle>> {
		return await makeGetSiteVehicles().Call({ companyId, siteId, page, perPage: 25, filter, sort, supplierId, archived });
	}

	async getOwnedResources(
		companyId: string,
		siteId: string,
		filter?: GetVehiclesFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<SiteVehicleParams>> {
		return (await makeGetVehicles().Call({ companyId, filter: { siteAvailable: siteId, ...filter }, sort, pageParam })) as unknown as Promise<
			PaginatedResults<SiteVehicleParams>
		>;
	}

	async getVariants(companyId: string, siteId: string): Promise<Variant[]> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type: DocumentTypeCategory.VEHICLE });
	}

	async getSpecializations(companyId: string, siteId: string): Promise<Specialization[]> {
		return await makeGetSiteSpecializations().Call({ companyId, siteId, type: DocumentTypeCategory.VEHICLE });
	}

	async createSiteResource(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		copyLastEvaluation: boolean,
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		return await makeCreateSiteVehicle().Call({
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			copyLastEvaluation,
			filterSiteResources,
		});
	}

	async deleteSiteResource(companyId: string, vehicleId: string, siteId: string): Promise<void> {
		await makeDeleteSiteVehicle().Call({ companyId, vehicleId, siteId });
	}

	async restoreSiteResource(companyId: string, vehicleId: string, siteId: string): Promise<void> {
		await makeRestoreSiteVehicle().Call({ companyId, vehicleId, siteId });
	}
}

export default SiteVehiclesViewModel;
