import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { DashboardTopic } from "../../../domain/entities/dashboardTopic";
import DashboardViewModel from "../../viewmodels/dashboard/DashboardViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetSupplierEvaluationsFilter } from "../../../domain/repositories/dashboardRepository";
import { updateFilterWithDelete } from "../../../utils";
import { useHasPermissions } from "../../components/Permissions/RenderIf";
import { Permission } from "../../components/Permissions/Permissions";

const useDashboardViewModel = () => {
  const { companyId } = useAuth();
  const dashboardViewModel = new DashboardViewModel();
  const [unevaluatedDocsSort, setUnevaluatedDocsSort] = useState<SortMeta>();
  const [expiringEvaluationsSort, setExpiringEvaluationsSort] =
    useState<SortMeta>();
  const [pendingEvaluationsSort, setPendingEvaluationsSort] =
    useState<SortMeta>();
  const [invitedSuppliersSort, setInvitedSupplierSort] = useState<SortMeta>();
  useState<SortMeta>();
  const [companyExpiringDocsSort, setCompanyExpiringDocSort] =
    useState<SortMeta>();
  const [expiringResourcesEvaluationsSort, setExpiringResourcesEvaluationsSort] =
    useState<SortMeta>();
  const [expiringSupplierDocumentsSort, setExpiringSupplierDocumentsSort] =
    useState<SortMeta>();
  const [expiringCompanyDocumentsSort, setExpiringCompanyDocumentsSort] =
    useState<SortMeta>();

  const [expiringResourcesEvaluationsFilter, setExpiringResourcesEvaluationsFilter] =
    useState<GetSupplierEvaluationsFilter>();
  const [expiringSupplierDocumentsFilter, setExpiringSupplierDocumentsFilter] =
    useState<GetSupplierEvaluationsFilter>();

  const updateExpiringResourcesEvaluationsFilter = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setExpiringResourcesEvaluationsFilter, field, value);
  };

  const [expiringDocumentsDays, setExpiringDocumentsDays] =
    useState<number>(30);
  const [expiringCompanyDocumentsDays, setExpiringCompanyDocumentsDays] =
    useState<number>(30);
  const [expiringEvaluationsDays, setExpiringEvaluationsDays] =
    useState<number>(30);
  const [
    expiringResourcesEvaluationsDays,
    setExpiringResourcesEvaluationsDays,
  ] = useState<number>(30);
  const [
    expiringCompanyOrSiteEvaluationsDays,
    setExpiringCompanyOrSiteEvaluationsDays,
  ] = useState<number>(30);

  const [enableUnevaluatedDocuments, setEnableUnevaluatedDocuments] = useState<boolean>(false);
  const [enableExpiringDocumentsEvaluations, setEnableExpiringDocumentsEvaluations] = useState<boolean>(false);
  const [enableExpiringDocuments, setEnableExpiringDocuments] = useState<boolean>(false);
  const [enableGetExpiringSiteDocuments, setEnableGetExpiringSiteDocuments] = useState<boolean>(false);
  const [enableExpiringResourceEvaluations, setEnableExpiringResourceEvaluations] = useState<boolean>(false);
  const [enableGetExpiringCompanyOrSiteEvaluations, setEnableGetExpiringCompanyOrSiteEvaluations] = useState<boolean>(false);
  const [enablePendingEvaluations, setEnablePendingEvaluations] = useState<boolean>(false);
  const [enableInvitedSupplier, setEnableInvitedSupplier] = useState<boolean>(false);

  const updateSupplierDocumentsFilter = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setExpiringSupplierDocumentsFilter, field, value);
  };

  //Counts
  const getOwnedSitesCountQuery = useQuery(
    ["dashboard-count-owned-sites", companyId, "owned-sites"],
    async () => {
      return dashboardViewModel.getDashboardCount(
        companyId,
        "owned-sites" as DashboardTopic,
      );
    },
    {
      enabled: useHasPermissions([Permission.Dashboard_ShowOwnedSitesCount]),
    },
  );
  const getWorkingSitesCountQuery = useQuery(
    ["dashboard-count-working-sites", companyId, "working-sites"],
    async () => {
      return dashboardViewModel.getDashboardCount(
        companyId,
        "working-sites" as DashboardTopic,
      );
    },
    {
      enabled: useHasPermissions([Permission.Dashboard_ShowWorkingSitesCount]),
    },
  );
  
  const getSuppliersCountQuery = useQuery(
    ["dashboard-count-suppliers", companyId, "suppliers"],
    async () => {
      return dashboardViewModel.getDashboardCount(
        companyId,
        "suppliers" as DashboardTopic,
      );
    },
    {
      enabled: useHasPermissions([Permission.Dashboard_ShowSuppliersCount]),
    },
  );
  //End Counts

  const getExpiringCompanyDocuments = useInfiniteQuery(
    [
      "expiring-company-documents",
      companyId,
      expiringCompanyDocumentsSort,
      expiringCompanyDocumentsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        'expiring-company-documents',
        pageParam,
        expiringCompanyDocumentsSort,
        null,
        expiringCompanyDocumentsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringCompanyDocuments,
      ]) && enableGetExpiringSiteDocuments,
    },
  );

  // Unevaluated documents
  const getUnevaluatedDocumentsQuery = useInfiniteQuery(
    [
      "unevaluated-documents",
      companyId,
      unevaluatedDocsSort,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        "unevaluated-documents" as DashboardTopic,
        pageParam,
        unevaluatedDocsSort,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowUnevaluatedDocuments,
      ]) && enableUnevaluatedDocuments,
    },
  );

  //Expiring Evaluations
  const getExpiringEvaluations = useInfiniteQuery(
    [
      "expiring-evaluations",
      companyId,
      expiringEvaluationsDays,
      expiringEvaluationsSort,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        "expiring-evaluations" as DashboardTopic,
        pageParam,
        expiringEvaluationsSort,
        null,
        expiringEvaluationsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringCompanyDocuments,
      ]) && enableExpiringDocumentsEvaluations
    },
  );
  //Authorizations CSE
  const getPendingEvaluations = useInfiniteQuery(
    [
      "pending-evaluations",
      companyId,
      "pending-evaluations",
      pendingEvaluationsSort,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        "pending-evaluations" as DashboardTopic,
        pageParam,
        pendingEvaluationsSort,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([Permission.Dashboard_ShowPendingEvaluations]) && enablePendingEvaluations,
    },
  );

  //Invited suppliers
  const getInvitedSuppliers = useInfiniteQuery(
    ["invited-suppliers", companyId, invitedSuppliersSort],
    async ({ pageParam = 1 }) => {
      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        "invited-suppliers" as DashboardTopic,
        pageParam,
        invitedSuppliersSort,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([Permission.Dashboard_ShowInvitedSuppliers]) && enableInvitedSupplier,
    },
  );

  //Expiring My Resources Documents
  const getCompanyExpiringDocuments = useInfiniteQuery(
    [
      "expiring-my-resources-documents",
      companyId,
      "expiring-documents",
      companyExpiringDocsSort,
      expiringDocumentsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        "expiring-documents" as DashboardTopic,
        pageParam,
        companyExpiringDocsSort,
        null,
        expiringDocumentsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([Permission.Dashboard_ShowExpiringDocuments]) && enableExpiringDocuments,
    },
  );

  //ExpiringSupplierResources
  const getExpiringResourcesEvaluations = useInfiniteQuery(
    [
      "expiring-resources-evaluations",
      companyId,
      expiringResourcesEvaluationsSort,
      expiringResourcesEvaluationsFilter,
      expiringResourcesEvaluationsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        'expiring-resources-evaluations',
        pageParam,
        expiringResourcesEvaluationsSort,
        expiringResourcesEvaluationsFilter,
        expiringResourcesEvaluationsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringResourcesEvaluations,
      ]) && enableExpiringResourceEvaluations,
    },
  );

  //ExpiringSupplierDocuments
  const getExpiringSupplierDocuments = useInfiniteQuery(
    [
      "expiring-supplier-documents",
      companyId,
      expiringSupplierDocumentsSort,
      expiringSupplierDocumentsFilter,
      expiringCompanyOrSiteEvaluationsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardReportByTopic(
        companyId,
        'expiring-company-or-site-evaluations',
        pageParam,
        expiringSupplierDocumentsSort,
        expiringSupplierDocumentsFilter,
        expiringCompanyOrSiteEvaluationsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringCompanyOrSiteEvaluations,
      ]) && enableGetExpiringCompanyOrSiteEvaluations,
    },
  );

  const ownedSitesCount = getOwnedSitesCountQuery.data;
  const workingSitesCount = getWorkingSitesCountQuery.data;
  const suppliersCount = getSuppliersCountQuery.data;
  const unevaluatedDocs =
    getUnevaluatedDocumentsQuery.data?.pages?.flat() ?? [];
  const expiringEvaluations = getExpiringEvaluations.data?.pages?.flat() ?? [];
  const expiringCompanyDocuments =
    getExpiringCompanyDocuments.data?.pages?.flat() ?? [];
  const pendingEvaluations = getPendingEvaluations.data?.pages?.flat() ?? [];
  const invitedSuppliers = getInvitedSuppliers.data?.pages?.flat() ?? [];
  const companyExpiringDocs =
    getCompanyExpiringDocuments.data?.pages?.flat() ?? [];
  const expiringResourcesEvaluations =
    getExpiringResourcesEvaluations.data?.pages?.flat() ?? [];
  const expiringSupplierDocuments =
    getExpiringSupplierDocuments.data?.pages?.flat() ?? [];

  return {
    ownedSitesCount,
    ownedSitesCountFetching: getOwnedSitesCountQuery.isFetching,
    workingSitesCount,
    workingSitesCountFetching: getWorkingSitesCountQuery.isFetching,
    suppliersCount,
    suppliersCountFetching: getSuppliersCountQuery.isFetching,
    unevaluatedDocs,
    unevaluatedDocumentsFetching: getUnevaluatedDocumentsQuery.isLoading,
    unevaluatedDocsSort,
    setUnevaluatedDocsSort,
    unevaluatedDocsHasNextPage: getUnevaluatedDocumentsQuery.hasNextPage,
    unevaluatedDocsFetchNextPage: getUnevaluatedDocumentsQuery.fetchNextPage,
    expiringEvaluations,
    expiringEvaluationsFetching: getExpiringEvaluations.isLoading,
    expiringEvaluationsSort,
    setExpiringEvaluationsSort,
    expiringEvaluationsHasNextPage: getExpiringEvaluations.hasNextPage,
    expiringEvaluationsFetchNextPage: getExpiringEvaluations.fetchNextPage,
    expiringCompanyDocuments,
    expiringCompanyDocumentsFetching: getExpiringCompanyDocuments.isLoading,
    expiringCompanyDocumentsSort,
    setExpiringCompanyDocumentsSort,
    expiringCompanyDocumentsHasNextPage:
      getExpiringCompanyDocuments.hasNextPage,
    expiringCompanyDocumentsFetchNextPage:
      getExpiringCompanyDocuments.fetchNextPage,
    pendingEvaluations,
    pendingEvaluationsSort,
    setPendingEvaluationsSort,
    pendingEvaluationsFetching: getPendingEvaluations.isLoading,
    pendingEvaluationsHasNextPage: getPendingEvaluations.hasNextPage,
    pendingEvaluationsFetchNextPage: getPendingEvaluations.fetchNextPage,
    invitedSuppliers,
    invitedSuppliersSort,
    setInvitedSupplierSort,
    invitedSuppliersFetching: getInvitedSuppliers.isLoading,
    invitedSuppliersHasNextPage: getInvitedSuppliers.hasNextPage,
    invitedSuppliersFetchNextPage: getInvitedSuppliers.fetchNextPage,

    companyExpiringDocs,
    companyExpiringDocsSort,
    setCompanyExpiringDocSort,
    companyExpiringDocsFetching: getCompanyExpiringDocuments.isLoading,
    companyExpiringDocsHasNextPage:
      getCompanyExpiringDocuments.hasNextPage,
    companyExpiringDocsFetchNextPage:
      getCompanyExpiringDocuments.fetchNextPage,
    expiringResourcesEvaluations,
    expiringResourcesEvaluationsSort,
    setExpiringResourcesEvaluationsSort,
    expiringResourcesEvaluationsFilter,
    updateExpiringResourcesEvaluationsFilter,
    expiringResourcesEvaluationsFetching:
    getExpiringResourcesEvaluations.isLoading,
    expiringResourcesEvaluationsHasNextPage:
    getExpiringResourcesEvaluations.hasNextPage,
    expiringResourcesEvaluationsFetchNextPage:
    getExpiringResourcesEvaluations.fetchNextPage,

    expiringSupplierDocuments,
    expiringSupplierDocumentsSort,
    setExpiringSupplierDocumentsSort,
    expiringSupplierDocumentsFilter,
    updateSupplierDocumentsFilter,
    expiringSupplierDocumentsFetching:
    getExpiringSupplierDocuments.isLoading,
    expiringSupplierDocumentsHasNextPage:
    getExpiringSupplierDocuments.hasNextPage,
    expiringSupplierDocumentsFetchNextPage:
    getExpiringSupplierDocuments.fetchNextPage,

    expiringDocumentsDays,
    setExpiringDocumentsDays,
    expiringCompanyDocumentsDays,
    setExpiringCompanyDocumentsDays,
    expiringEvaluationsDays,
    setExpiringEvaluationsDays,
    expiringResourcesEvaluationsDays,
    setExpiringResourcesEvaluationsDays,
    expiringCompanyOrSiteEvaluationsDays,
    setExpiringCompanyOrSiteEvaluationsDays,

    setEnableUnevaluatedDocuments,
    setEnableExpiringDocumentsEvaluations,
    setEnableExpiringDocuments,
    setEnableGetExpiringSiteDocuments,
    setEnableExpiringResourceEvaluations,
    setEnableGetExpiringCompanyOrSiteEvaluations,
    setEnablePendingEvaluations,
    setEnableInvitedSupplier
  };
};

export { useDashboardViewModel };
