import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { WebHookViewModel } from "../../viewmodels/webhook/WebHookViewModel";
import { WebHook } from "../../../domain/entities/webHook";
import Site from "../../../domain/entities/site";

type BaseData = {
  status: "error" | "idle" | "loading" | "success";
  error?: string;
};

type QueryData<T> = BaseData & { data: T };

export type WebHookFilters = {
  event?: string;
  endpoints?: string;
  siteId?: string;
};

export interface UseWebHookViewModel {
  ownedSites: QueryData<Site[]>;
  webHooks: QueryData<WebHook[]>;
  events: QueryData<string[]>;
  setWebHooks: {
    mutate: (webHooks: WebHook[]) => void;
    status: "error" | "idle" | "loading" | "success";
    error?: string;
  };
}

const useWebHookViewModel = (): UseWebHookViewModel => {
  const { companyId } = useAuth();
  const viewModel = new WebHookViewModel(companyId);

  const getSites = useQuery(["get-owned-sites-list", companyId], async () => {
      const {results, count} = await viewModel.getOwnedSites();
      return results;
    }
  );

  const getEvents = useQuery(["get-webhook-events", companyId], async () =>
    viewModel.getWebHookEvents(),
  );

  const getWebHooks = useQuery(["get-webhooks", companyId], async () =>
    viewModel.getWebHooks(),
  );

  const setWebHooks = useMutation(
    async (webHooks: WebHook[]) => viewModel.setWebHooks(webHooks),
    {
      onSuccess: () => {
        getWebHooks.refetch();
      },
    },
  );

  return {
    ownedSites: {
      data: getSites.data,
      status: getSites.status,
      error: getSites.error?.toString(),
    },
    webHooks: {
      data: getWebHooks.data,
      status: getWebHooks.status,
      error: getWebHooks.error?.toString(),
    },
    events: {
      data: getEvents.data,
      status: getEvents.status,
      error: getEvents.error?.toString(),
    },
    setWebHooks: {
      mutate: setWebHooks.mutate,
      status: setWebHooks.status,
      error: setWebHooks.error?.toString(),
    },
  };
};

export default useWebHookViewModel;
