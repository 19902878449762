import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import vehiclePlaceholder from "../../../../assets/images/vehicle.png";
import Badge, { VehicleMetas } from "../../../../domain/entities/badge";
import { styles } from "./badgeStyles";
import FrontBadgeContainer from "./FrontBadgeContainer";
import { Format } from "../../../screens/Site/SiteBadgesPrint";

type NfcBadgeVehicleProps = {
  badge: Badge;
  format?: Format;
};
const FrontBadgeVehicle = ({ badge, format }: NfcBadgeVehicleProps) => {
  const { t } = useTranslation("badges")
  const { plate, photo } = badge.resource.meta as VehicleMetas;

  return (
    <FrontBadgeContainer badge={badge} photo={photo || vehiclePlaceholder} format={format}>
      <View style={[styles.badgeDetailsRow, {marginTop: 10}]}>
        <Text style={styles.detailTitle}>{t("pdfBadgeDetails.vehicle.resourceType")}</Text>
        <Text style={styles.text}>{badge.resource.name}</Text>
      </View>
      <View style={styles.badgeDetailsRow}>
        <Text style={styles.detailTitle}>{t("pdfBadgeDetails.vehicle.plate")}</Text>
        <Text style={styles.text}>{plate}</Text>
      </View>
    </FrontBadgeContainer>
  );
};
export default FrontBadgeVehicle;
