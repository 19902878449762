import UseCase from '../../../core/app/usecase';
import TaggableDocument from '../../../infrastructure/responses/taggableDocument';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	siteId?: string;
	companyId: string;
	resourceType: string;
	resourceId: string;
	pageParam?: number;
	onlyMissing?: boolean;
}

class GetTaggableDocuments implements UseCase<Args, TaggableDocument[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, resourceType, resourceId, pageParam, onlyMissing }: Args): Promise<TaggableDocument[]> {
		return await this.repository.getTaggableDocuments(companyId, resourceType, resourceId, pageParam, siteId, onlyMissing);
	}
}

export default GetTaggableDocuments;
