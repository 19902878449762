import { NewResource, SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import {
	makeCreateSiteChemical,
	makeGetSiteChemicals,
	makeDeleteSiteChemical,
	makeRestoreSiteChemical,
} from '../../../core/app/factory/siteChemicals/usecases';
import { makeGetAvailableChemicalsCount, makeGetChemicals } from '../../../core/app/factory/chemicals/usecases';
import { makeGetSiteVariants } from '../../../core/app/factory/variants/usecases';
import { makeGetSiteSpecializations } from '../../../core/app/factory/specializations/usecases';
import { SiteChemical, SiteChemicalParams } from '../../../domain/entities/siteChemical';
import { GetSiteChemicalsFilters } from '../../../domain/repositories/siteChemicalRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { GetChemicalsFilter } from '../../../domain/repositories/chemicalRepository';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';

class SiteChemicalsViewModel implements SiteResourcesViewModel<SiteChemicalParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteChemicalsFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteChemical>> {
		return await makeGetSiteChemicals().Call({ companyId, siteId, page, perPage: 25, filter, sort, supplierId, archived });
	}

	async getOwnedResources(
		companyId: string,
		siteId: string,
		filter?: GetChemicalsFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<SiteChemicalParams>> {
		return (await makeGetChemicals().Call({ companyId, filter: { siteAvailable: siteId, ...filter }, sort, pageParam })) as unknown as Promise<
			PaginatedResults<SiteChemicalParams>
		>;
	}

	async getAvailableResourcesTotalCount(companyId: string, siteId: string, filter?: GetChemicalsFilter, sort?: SortMeta): Promise<number> {
		return (await makeGetAvailableChemicalsCount().Call({
			companyId,
			filter: { siteAvailable: siteId, ...filter },
			sort,
		})) as unknown as Promise<number>;
	}

	async getVariants(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type: DocumentTypeCategory.CHEMICAL });
	}

	async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteSpecializations().Call({ companyId, siteId, type: DocumentTypeCategory.CHEMICAL });
	}

	async createSiteResource(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		copyLastEvaluation: boolean,
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		return await makeCreateSiteChemical().Call({
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			copyLastEvaluation,
			filterSiteResources,
		});
	}

	async deleteSiteResource(companyId: string, chemicalId: string, siteId: string): Promise<void> {
		await makeDeleteSiteChemical().Call({ companyId, chemicalId, siteId });
	}

	async restoreSiteResource(companyId: string, chemicalId: string, siteId: string): Promise<void> {
		await makeRestoreSiteChemical().Call({ companyId, chemicalId, siteId });
	}
}

export default SiteChemicalsViewModel;
