import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';
import { SiteResourceDocument } from '../../../entities/document';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
};

//TODO rename this method in getSiteWorkerRequirement
class GetSiteWorkerDocumentsRejected implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteWorkerDocumentsRejected(companyId, siteId, workerId);
	}
}

export default GetSiteWorkerDocumentsRejected;
