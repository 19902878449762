import { injectable } from 'tsyringe';
import SiteVehicleRepository, { GetSiteVehiclesFilters, GetWorkingSiteVehiclesFilters } from '../../domain/repositories/siteVehicleRepository';
import { mapSiteVehicle, SiteVehicle } from '../../domain/entities/siteVehicle';
import Evaluation from '../../domain/entities/evaluation';
import { SiteResourceDocument } from '../../domain/entities/document';
import { ResourceEvaluationState } from '../../domain/entities/resourceEvaluationState.enum';
import Requirement from '../../domain/entities/requirement';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetSiteVehiclesResponse } from '../responses/siteVehicles';
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { ApiService } from '../utilities/apiService';
import { GetResourceEvaluationFilter } from '../../domain/repositories/siteRepository';
import { NewResource } from '../../presentation/hooks/Site/useSiteResourcesViewModel';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';

@injectable()
class ServerSiteVehicleRepository implements SiteVehicleRepository {
	constructor(private apiService: ApiService) { }

	private getURL = (companyId: string, siteId: string, vehicleId?: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/vehicles${vehicleId ? `/${vehicleId}` : ''}`;
	};

	// Public.
	async getSiteVehicles(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetSiteVehiclesFilters,
		sort?: SortMeta,
		supplierId?: string
	): Promise<PaginatedResults<SiteVehicle>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/vehicles?${params.toString()}`
		);
		const data: GetSiteVehiclesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteVehicle(siteId)),
		};
	}

	// Public.
	async getWorkingSiteVehicles(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetWorkingSiteVehiclesFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SiteVehicle>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/vehicles?${params.toString()}`
		);
		if (!response.ok) {
			if (response.status === 403) {
				window.location.href = '/overview';
			}
			return;
		}
		const data: GetSiteVehiclesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteVehicle(siteId)),
		};
	}

	// Public.
	async getSiteVehicle(companyId: string, siteId: string, vehicleId: string): Promise<SiteVehicle | undefined> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}`);
		if (!response.ok) {
			return undefined;
		}
		return response.json();
	}

	async createSiteVehicle(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		copyLastEvaluation: boolean,
		filterSiteResources?: Record<string, string | string[]>
	): Promise<boolean> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId)}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				resources,
				selectAll,
				selectedResourceNumber,
				selectAllVariant: selectAllVariants,
				selectAllSpecializations,
				copyLastEvaluation,
				filterSiteResources,
			}),
		});
		return Promise.resolve(true);
	}

	async deleteSiteVehicle(companyId: string, siteId: string, vehicleId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}`, {
			method: 'DELETE',
		});
	}

	async restoreSiteVehicle(companyId: string, siteId: string, vehicleId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/restore`, {
			method: 'PUT',
		});
	}

	async updateSiteVehicleVariant(companyId: string, siteId: string, vehicleId: string, variantId?: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/variants`, {
			method: 'POST',
			body: variantId ? JSON.stringify({ variantId: variantId }) : null,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async updateSiteVehicleSpecializations(companyId: string, siteId: string, vehicleId: string, specializationIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/specializations`, {
			method: 'PUT',
			body: JSON.stringify({ specializationsIds: specializationIds }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async getSiteVehicleEvaluations(
		companyId: string,
		siteId: string,
		vehicleId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
	): Promise<Evaluation<ResourceEvaluationState>[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...filter,
		}).toString();

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/evaluations?${query}`);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();
		return results.map(mapEvaluation);
	}

	async evaluateSiteVehicle(
		companyId: string,
		siteId: string,
		vehicleId: string,
		value: ResourceEvaluationState
	): Promise<Evaluation<ResourceEvaluationState>> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/evaluations`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ value }),
		});
		return response.json();
	}

	async autoEvaluateSiteVehicle(companyId: string, siteId: string, vehicleId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/evaluate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to auto evaluate site vehicle: ${response.status}`);
		}
	}

	async getSiteVehicleDocuments(
		companyId: string,
		siteId: string,
		vehicleId: string,
		filter: GetDocumentsFilter,
		sort: SortMeta,
		pageParam: number
	): Promise<SiteResourceDocument[]> {
		const { tags, expiresAt, ...restFilter } = filter ?? {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...(tags ? { tags: tags.join(',') } : {}),
			...sort,
		});
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/requirements?${params.toString()}`);
		const requirements = await response.json();
		return requirements['results'] ?? [];
	}

	async getSiteVehicleDocumentsRejected(
		companyId: string,
		siteId: string,
		vehicleId: string
	): Promise<SiteResourceDocument[]> {

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/requirements/blocking`);
		const requirements = await response.json();
		return requirements['results'] ?? [];
	}

	async addRequirementToSiteVehicle(
		companyId: string,
		siteId: string,
		vehicleId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[]
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/requirements`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements }),
		});
		return response.json();
	}

	async updateSiteVehicleGlobalStatus(companyId: string, siteId: string, vehicleId: string, isGlobal: boolean): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, vehicleId)}/global`, {
			method: 'PUT',
			body: JSON.stringify({ isGlobal }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to update site worker global status: ${response.status}`);
		}
		const { hasRequirements = true } = await response.json();
		return hasRequirements;
	}
}

export default ServerSiteVehicleRepository;
