import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';
import { SiteResourceDocument } from '../../../entities/document';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
};

class GetSiteMachineDocumentsRejected implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteMachineDocumentsRejected(companyId, siteId, machineId );
	}
}

export default GetSiteMachineDocumentsRejected;
