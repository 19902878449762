import { Token } from '../../../infrastructure/responses/auth';
import Staff from '../../entities/staff';

export interface Credentials {
	email: string;
	password: string;
}

export enum BusinessSize {
	Micro = 'micro', // employees:       < 10
	Small = 'small', // employees: 10  ..< 50
	Medium = 'medium', // employees: 50  ..< 100
	MediumLarge = 'medium-large', // employees: 100 ..< 500
	Large = 'large', // employees:       > 500
}

export enum LicenseType {
	Smart = 'smart',
	Standard = 'standard',
	Master = 'master',
}

export enum BusinessType {
  StandardCompany = 'standard_company',
  SelfEmployed = 'self_employed',
}

export interface BusinessTypeResponse {
  name: string;
  value: BusinessType;
}
export interface BusinessSizeResponse {
  name: string;
  value: BusinessSize;
}
export interface BusinessLicenseResponse {
  name: string;
  value: LicenseType;
}
export interface BusinessOption {
  name: string;
  value: string;
}

export type Privacy = {
	privacyPolicy: boolean;
	newsletter: boolean;
};

type StaffData = Omit<Staff, 'id' | 'type' | 'role'>;

export type SignUpCompany = Credentials &
	Privacy & {
		confirmPassword: string;
		businessName: string;
		vat: string;
		vatCountry: string;
		businessSize: BusinessSize;
		licenseType: LicenseType;
		employer?: StaffData;
		rls?: StaffData;
		rspp?: StaffData;
		doctor?: StaffData;
		contact?: StaffData;
		businessType: string;
		token?: string;
		pec: string;
		ccnl: string;
	};

export type SignUpderivedCompany = SignUpCompany & {
	companyId: string;
	bearerToken: Token;
};

export type SignUpUser = Credentials &
	Privacy & {
		confirmPassword: string;
		lastName: string;
		firstName: string;
		token?: string;
	};

export type UpdatePasswordPayload = {
	oldPassword: string;
	password: string;
	confirmPassword: string;
};
