import { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import DashboardCountersView from "./DashboardCountersView";
import DashboardDaysSelector from "./DashboardDaysSelector";
import DashboardTableWidgetView from "./DashboardTableWidgetView";
import { Permission } from "../../components/Permissions/Permissions";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useDashboardViewModel } from "../../hooks/Dashboard/useDashboard";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { Alert } from "../Common/Alert";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useMediaQuery } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardOverviewView = () => {
  const { t } = useTranslation("dashboard");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { company, hasPermissions } = useAuth();
  const [showLicenseNotActive, setShowLicenseNotActive] = useState<boolean>(
    localStorage.getItem("justLoggedIn") === "true"
  );

  const {
    ownedSitesCount,
    workingSitesCount,
    suppliersCount,
    ownedSitesCountFetching,
    workingSitesCountFetching,
    suppliersCountFetching,
    unevaluatedDocs,
    unevaluatedDocumentsFetching,
    unevaluatedDocsSort,
    setUnevaluatedDocsSort,
    unevaluatedDocsHasNextPage,
    unevaluatedDocsFetchNextPage,
    expiringEvaluations,
    expiringEvaluationsFetching,
    expiringEvaluationsSort,
    setExpiringEvaluationsSort,
    expiringEvaluationsHasNextPage,
    expiringEvaluationsFetchNextPage,
    pendingEvaluations,
    pendingEvaluationsSort,
    setPendingEvaluationsSort,
    pendingEvaluationsFetching,
    pendingEvaluationsHasNextPage,
    pendingEvaluationsFetchNextPage,
    invitedSuppliers,
    invitedSuppliersSort,
    setInvitedSupplierSort,
    invitedSuppliersFetching,
    invitedSuppliersHasNextPage,
    invitedSuppliersFetchNextPage,
    companyExpiringDocs,
    companyExpiringDocsSort,
    setCompanyExpiringDocSort,
    companyExpiringDocsFetching,
    companyExpiringDocsHasNextPage,
    companyExpiringDocsFetchNextPage,
    expiringResourcesEvaluations,
    expiringResourcesEvaluationsSort,
    setExpiringResourcesEvaluationsSort,
    expiringResourcesEvaluationsFilter,
    updateExpiringResourcesEvaluationsFilter,
    expiringResourcesEvaluationsFetching,
    expiringResourcesEvaluationsHasNextPage,
    expiringResourcesEvaluationsFetchNextPage,
    expiringSupplierDocuments,
    expiringSupplierDocumentsSort,
    setExpiringSupplierDocumentsSort,
    expiringSupplierDocumentsFilter,
    updateSupplierDocumentsFilter,
    expiringSupplierDocumentsFetching,
    expiringSupplierDocumentsHasNextPage,
    expiringSupplierDocumentsFetchNextPage,
    expiringCompanyDocuments,
    expiringCompanyDocumentsFetchNextPage,
    expiringCompanyDocumentsFetching,
    expiringCompanyDocumentsHasNextPage,
    expiringCompanyDocumentsSort,
    setExpiringCompanyDocumentsSort,

    expiringDocumentsDays,
    setExpiringDocumentsDays,
    expiringCompanyDocumentsDays,
    setExpiringCompanyDocumentsDays,
    expiringEvaluationsDays,
    setExpiringEvaluationsDays,
    expiringResourcesEvaluationsDays,
    setExpiringResourcesEvaluationsDays,
    expiringCompanyOrSiteEvaluationsDays,
    setExpiringCompanyOrSiteEvaluationsDays,

    setEnableUnevaluatedDocuments,
    setEnableExpiringDocumentsEvaluations,
    setEnableExpiringDocuments,
    setEnableGetExpiringSiteDocuments,
    setEnableExpiringResourceEvaluations,
    setEnableGetExpiringCompanyOrSiteEvaluations,
    setEnablePendingEvaluations,
    setEnableInvitedSupplier
  } = useDashboardViewModel();

  const unevaluatedDocumentsColumns = [
    { field: "name", type: "text" as const, label: t("site") },
    {
      field: "count",
      type: "text" as const,
      label: t("unevaluatedDocuments.documentsToCheck"),
    },
  ];
  const expiringEvaluationsColumns = [
    { field: "name", type: "text" as const, label: t("site") },
    {
      field: "count",
      type: "text" as const,
      label: t("expiringEvaluations.expiredEvaluations"),
    },
  ];
  const pendingEvaluationsColumns = [
    { field: "supplierName", type: "text" as const, label: t("supplier") },
    {
      field: "date",
      type: "date" as const,
      label: t("cseAuthorizations.evaluationDate"),
    },
    { field: "siteName", type: "text" as const, label: t("site") },
  ];
  const invitedSuppliersColumns = [
    { field: "supplierName", type: "text" as const, label: t("supplier") },
    { field: "siteName", type: "text" as const, label: t("site") },
    { field: "date", type: "date" as const, label: t("suppliers.date") },
  ];
  const companyExpiringDocsColumns = [
    { field: "name", type: "text" as const, label: t("documentName") },
    { field: "expiresAt", type: "date" as const, label: t("expiringDate") },
    { field: "expiresIn", type: "text" as const, label: t("daysLeft") },
    { field: "resourceType", type: "text" as const },
    { field: "resourceName", type: "text" as const, label: t("resource") },

  ];

  const expiringSupplierResourcesColumns = [
    { field: "name", type: "text" as const, label: t("documentName") },
    { field: "expiresAt", type: "date" as const, label: t("expiringDate") },
    { field: "expiresIn", type: "text" as const, label: t("daysLeft") },
    { field: "resourceType", type: "text" as const },
    { field: "resourceName", type: "text" as const, label: t("resource") },
    { field: "siteName", type: "text" as const, label: t("site") },
  ];

  const expiringSupplierDocumentsColumns = [
    { field: "name", type: "text", label: t("documentName") },
    { field: "expiresAt", type: "date" as const, label: t("expiringDate") },
    { field: "expiresIn", type: "text" as const, label: t("daysLeft") },
    { field: "siteName", type: "text" as const, label: t("site") },
  ];

  const lastTenantSelected = localStorage.getItem("lastTenant");
  const lastUser = localStorage.getItem("lastUser");

  const getCompanyName = () => {
    if (lastTenantSelected && lastUser) {
      try {
        const parsedCompany = JSON.parse(lastTenantSelected);
        const parsedLastUser = JSON.parse(lastUser);
        if (parsedLastUser.id === company.id) {
          return parsedCompany?.name;
        } else {
          return company?.name;
        }
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
        return company?.name;
      }
    } else {
      return company?.name;
    }
  };

  return (
    <ContentLayout>
      <Box p={10} width={isMobile ? "1200px" : "100%"}>
        <Flex direction={"row"} gap={2} mb={5}>
          <Text fontSize={30}>{t("welcome")}</Text>
          <Text fontSize={30} fontWeight={"bold"} color={COLORS.sikuroBlue}>
            {getCompanyName()}
          </Text>
        </Flex>
        <DashboardCountersView
          ownedSitesCount={ownedSitesCount}
          workingSitesCount={workingSitesCount}
          suppliersCount={suppliersCount}
          ownedSitesCountFetching={ownedSitesCountFetching}
          workingSitesCountFetching={workingSitesCountFetching}
          suppliersCountFetching={suppliersCountFetching}
          ownedSitesPermissions={[Permission.Dashboard_ShowOwnedSitesCount]}
          workingSitesPermissions={[Permission.Dashboard_ShowWorkingSitesCount]}
          suppliersPermissions={[Permission.Dashboard_ShowSuppliersCount]}
        />

        <Grid
          templateColumns={
            !isMobile
              ? { lg: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }
              : "repeat(4, 1fr)"
          }
          gap={4}
          py={4}
        >
          
          {hasPermissions([Permission.Dashboard_ShowUnevaluatedDocuments]) && (
            <GridItem colSpan={2} height={"100%"}>
              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=>setEnableUnevaluatedDocuments(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("unevaluatedDocuments.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6}>
                      <DashboardTableWidgetView
                        items={unevaluatedDocs}
                        isFetching={unevaluatedDocumentsFetching}
                        sort={unevaluatedDocsSort}
                        setSort={(sort: SortMeta) => setUnevaluatedDocsSort(sort)}
                        columns={unevaluatedDocumentsColumns}
                        hasNextPage={unevaluatedDocsHasNextPage}
                        fetchNextPage={() => unevaluatedDocsFetchNextPage()}
                        widget={"unevaluatedDocuments"}
                      />
                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowExpiringEvaluations]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"} >
                    <AccordionButton onClick={()=>setEnableExpiringDocumentsEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                          <Text fontWeight={600} flexGrow={1} mr={1}>
                            {t("expiringEvaluations.title")}
                          </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                          days={expiringEvaluationsDays}
                          onSelectDays={setExpiringEvaluationsDays}
                        />
                      <DashboardTableWidgetView
                        items={expiringEvaluations}
                        isFetching={expiringEvaluationsFetching}
                        sort={expiringEvaluationsSort}
                        setSort={(sort: SortMeta) => setExpiringEvaluationsSort(sort)}
                        columns={expiringEvaluationsColumns}
                        hasNextPage={expiringEvaluationsHasNextPage}
                        fetchNextPage={() => expiringEvaluationsFetchNextPage()}
                        widget={"expiringEvaluations"}
                        daysSelected={expiringEvaluationsDays}
                      />
                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}
          {/*"Documenti in scadenza delle mie risorse"*/}
          {hasPermissions([Permission.Dashboard_ShowExpiringDocuments]) && (
            <GridItem colSpan={2}>

                <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=>setEnableExpiringDocuments(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("expiringDocumentsResource.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringDocumentsDays}
                        onSelectDays={setExpiringDocumentsDays}
                      />
                      <DashboardTableWidgetView
                        items={companyExpiringDocs}
                        isFetching={companyExpiringDocsFetching}
                        sort={companyExpiringDocsSort}
                        setSort={(sort: SortMeta) =>
                          setCompanyExpiringDocSort(sort)
                        }
                        columns={companyExpiringDocsColumns}
                        hasNextPage={companyExpiringDocsHasNextPage}
                        fetchNextPage={() => companyExpiringDocsFetchNextPage()}
                        widget={"expiringDocumentsResource"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}
          {/*"Documenti in scadenza delle mia azienda"*/}
          {hasPermissions([
            Permission.Dashboard_ShowExpiringCompanyDocuments,
          ]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=>setEnableGetExpiringSiteDocuments(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("expiringCompanyDocuments.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringCompanyDocumentsDays}
                        onSelectDays={setExpiringCompanyDocumentsDays}
                      />
                      <DashboardTableWidgetView
                        items={expiringCompanyDocuments}
                        isFetching={expiringCompanyDocumentsFetching}
                        sort={expiringCompanyDocumentsSort}
                        setSort={(sort: SortMeta) =>
                          setExpiringCompanyDocumentsSort(sort)
                        }
                        columns={companyExpiringDocsColumns}
                        hasNextPage={expiringCompanyDocumentsHasNextPage}
                        fetchNextPage={() =>
                          expiringCompanyDocumentsFetchNextPage()
                        }
                        widget={"expiringCompanyDocuments"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([
            Permission.Dashboard_ShowExpiringResourcesEvaluations,
          ]) && (
            <GridItem colSpan={2}>

                <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableExpiringResourceEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                        {t("myExpiringEvaluations.title")}
                      </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringResourcesEvaluationsDays}
                        onSelectDays={setExpiringResourcesEvaluationsDays}
                      />
                      <DashboardTableWidgetView
                        items={expiringResourcesEvaluations}
                        isFetching={expiringResourcesEvaluationsFetching}
                        sort={expiringResourcesEvaluationsSort}
                        setSort={(sort: SortMeta) =>
                          setExpiringResourcesEvaluationsSort(sort)
                        }
                        filterFields={expiringResourcesEvaluationsFilter}
                        updateFilters={(field: string, value: string | string[]) =>
                          updateExpiringResourcesEvaluationsFilter(field, value)
                        }
                        columns={expiringSupplierResourcesColumns}
                        hasNextPage={expiringResourcesEvaluationsHasNextPage}
                        fetchNextPage={() =>
                          expiringResourcesEvaluationsFetchNextPage()
                        }
                        widget={"expiringSupplierResources"}
                      />
                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([
            Permission.Dashboard_ShowExpiringCompanyOrSiteEvaluations,
          ]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableGetExpiringCompanyOrSiteEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("companyExpiringEvaluations.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringCompanyOrSiteEvaluationsDays}
                        onSelectDays={setExpiringCompanyOrSiteEvaluationsDays}
                      />
                      <DashboardTableWidgetView
                        items={expiringSupplierDocuments}
                        isFetching={expiringSupplierDocumentsFetching}
                        sort={expiringSupplierDocumentsSort}
                        setSort={(sort: SortMeta) =>
                          setExpiringSupplierDocumentsSort(sort)
                        }
                        filterFields={expiringSupplierDocumentsFilter}
                        updateFilters={(field: string, value: string | string[]) =>
                          updateSupplierDocumentsFilter(field, value)
                        }
                        columns={expiringSupplierDocumentsColumns}
                        hasNextPage={expiringSupplierDocumentsHasNextPage}
                        fetchNextPage={() =>
                          expiringSupplierDocumentsFetchNextPage()
                        }
                        widget={"expiringSupplierDocuments"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowPendingEvaluations]) && (
            <GridItem colSpan={2}>

                <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnablePendingEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("cseAuthorizations.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardTableWidgetView
                        items={pendingEvaluations}
                        isFetching={pendingEvaluationsFetching}
                        sort={pendingEvaluationsSort}
                        setSort={(sort: SortMeta) => setPendingEvaluationsSort(sort)}
                        columns={pendingEvaluationsColumns}
                        hasNextPage={pendingEvaluationsHasNextPage}
                        fetchNextPage={() => pendingEvaluationsFetchNextPage()}
                        widget={"cseAuthorizations"}
                      />
                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowInvitedSuppliers]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableInvitedSupplier(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("suppliers.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                    <DashboardTableWidgetView
                      items={invitedSuppliers}
                      isFetching={invitedSuppliersFetching}
                      sort={invitedSuppliersSort}
                      setSort={(sort: SortMeta) => setInvitedSupplierSort(sort)}
                      columns={invitedSuppliersColumns}
                      hasNextPage={invitedSuppliersHasNextPage}
                      fetchNextPage={() => invitedSuppliersFetchNextPage()}
                      widget={"invitedSupplier"}
                    />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}
        </Grid>
      </Box>

      {showLicenseNotActive && company?.state === "disabled" && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("tenantMissingLicense", { ns: "common" })}
          variant="info"
          onClose={() => {
            setShowLicenseNotActive(false);
            localStorage.setItem("justLoggedIn", "false");
          }}
        />
      )}
    </ContentLayout>
  );
};

export default DashboardOverviewView;
