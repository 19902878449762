import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useSteps, Text, ModalCloseButton, Spinner
} from "@chakra-ui/react";
import Steps from "../stepper/Steps";
import { COLORS } from "../../../assets/theme/colors";
import useSmartDownloadViewModel, {
  SelectedAllResourcesByType,
  SelectedResourcesByType
} from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import SmartDownloadSelectContext from "./SmartDownloadSelectContext";
import SmartDownloadSelectSubContext from "./SmartDownloadSelectSubContext";
import SmartDownloadSummary from "./SmartDownloadSummary";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { Alert } from "../../../screens/Common/Alert";
import Company from "../../../../domain/entities/company";
import SmartDownloadSelectSubContextDataByResourceType from "./SmartDownloadSelectSubContextDataByResourceType";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";

interface Props {
  onClose: () => void;
  selectedSites?: string[];
  selectedFilterSites?: GetSitesFilter;
  siteCount?: number;
  selectAllSites?:boolean;
  selectedResources?: string[];
  selectedFilterResources?: GetResourcesFilter;
  resourceCount?: number;
  selectAllResources?:boolean;
  resourceType?: ResourceType;
}

const SmartDownloadModal: FC<Props> = ({
  onClose,
  selectedSites,
  selectedFilterSites,
  siteCount,
  selectAllSites,
  selectedResources,
  selectedFilterResources,
  resourceCount,
  selectAllResources,
  resourceType}) => {
  const { t } = useTranslation("common");
  const [selectedSuppliers, setSelectedSuppliers] = useState<Company[]>([]);
  const [selectedResourceDocumentTypes, setSelectedResourceDocumentTypes] = useState<DocumentType[]>([]);
  const [selectedAllSuppliers, setSelectedAllSuppliers] = useState<boolean>(false);
  const [selectedAllResourceDocumentTypes, setSelectedAllResourceDocumentTypes] = useState<boolean>(false);
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType>(ResourceType.WORKER);
  const [selectedDocumentTypeResourceType, setSelectedDocumentTypeResourceType] = useState<ResourceType>(ResourceType.WORKER);
  const [selectedResourcesByType, setSelectedResourcesByType] = useState<SelectedResourcesByType>({
    vehicle: [],
    worker: [],
    machine: [],
    tool: [],
    chemical: [],
  });
  const [selectedAllResourcesByType, setSelectedAllResourcesByType] = useState<SelectedAllResourcesByType>({
    vehicle: false,
    worker: false,
    machine: false,
    tool: false,
    chemical: false,
  });

  const [selectedDocumentTypesByResourceType, setSelectedDocumentTypesByResourceType] = useState<SelectedResourcesByType>({
    vehicle: [],
    worker: [],
    machine: [],
    tool: [],
    chemical: [],
  });
  const [selectedAllDocumentTypesByResourceType, setSelectedAllDocumentTypesByResourceType] = useState<SelectedAllResourcesByType>({
    vehicle: false,
    worker: false,
    machine: false,
    tool: false,
    chemical: false,
  });
  const {
    contextHandlers,
    supplierData,
    supplierResources,
    documentTypeData,
    download,
    downloadFeedback,
    downloadLoading,
    setDownloadFeedback
  } = useSmartDownloadViewModel({
    sites: {
      selectedSites: selectAllSites ? [] : selectedSites,
      selectedFilterSites,
      selectedAllSites: selectAllSites,
    },
    suppliers: {
      selectedSuppliers: selectedAllSuppliers ? [] : selectedSuppliers.map((supplier) => supplier.id),
      selectedAllSuppliers,
    },
    resources: {
      selectedResourcesByType,
      selectedAllResourcesByType,
      selectedResources,
      selectedFilterResources,
      selectAllResources,
      type:resourceType,
    },
    documents: {
      selectedDocumentTypesByResourceType,
      selectedAllDocumentTypesByResourceType,
    },
  });

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: resourceType ? 2 :3,
  });
  const steps = [
    { title: t("smartDownload.selectContext") },
    ...(resourceType ? [] : [{ title: t("smartDownload.selectSubContext") }]),
    { title: t("smartDownload.summary") },
  ];

  const nextStep = async () => {
    if (contextHandlers.context === 'resource') {
      if (activeStep < 1) {
        setActiveStep(activeStep + 1);
      } else {
        download().then(() => {
          onClose();
        });
      }
    } else {
      if (activeStep < 2) {
        setActiveStep(activeStep + 1);
      } else {
        download().then(() => {
          onClose();
        });
      }
    }
  };

  const prevStep = async () => {
    if (activeStep === 0) {
      onClose()
    } else {
      setActiveStep(activeStep - 1);
      contextHandlers.setSubContextData(undefined);
    }
  };

  const disableNextPage = useMemo(() => {
    const isSuppliersSelectionEmpty = () => selectedSuppliers.length === 0 && !selectedAllSuppliers;
    const isResourcesSelectionEmpty = () =>
      Object.keys(selectedResourcesByType).every(
        (type) => selectedResourcesByType[type].length === 0 && !selectedAllResourcesByType[type]
      );

    const isDocumentsSelectionEmpty = () =>
      Object.keys(selectedDocumentTypesByResourceType).every(
        (type) => selectedDocumentTypesByResourceType[type].length === 0 && !selectedAllDocumentTypesByResourceType[type]
      );

    const { context, subContext, subContextData } = contextHandlers;
    switch (activeStep) {
      case 0:
        if (!context) return true;
        if (context === "resource" ) return selectedResourceDocumentTypes.length === 0 && !selectedAllResourceDocumentTypes;
        return context === "supplier" ? isSuppliersSelectionEmpty() : false;
      case 1:
        if (subContext) {
          if (subContext === "all") return false;
          if (
            subContext === "company" &&
            ["documentType.company", "documentType.site", "documentType.all"].includes(subContextData)
          ) {
            return false;
          }
          if (subContext === "resource") return isResourcesSelectionEmpty();
          if (context === "documentType") return isDocumentsSelectionEmpty();
        }
        return false;
      case 2:
        return false;
      default:
        return false;
    }
  }, [
    contextHandlers,
    activeStep,
    selectedSuppliers.length,
    selectedAllSuppliers,
    selectedResourcesByType,
    selectedAllResourcesByType,
    selectedDocumentTypesByResourceType,
    selectedAllDocumentTypesByResourceType,
    selectedResourceDocumentTypes.length,
    selectedAllResourceDocumentTypes]);

  useEffect(() => {
    setSelectedDocumentTypesByResourceType(prev => ({...prev, [resourceType]: selectedResourceDocumentTypes}))
    }, [resourceType, selectedResourceDocumentTypes]);

  useEffect(() => {
    if (resourceType) {
      documentTypeData.setDocumentTypesByResourceType(resourceType);
    }
  }, [documentTypeData, documentTypeData.setDocumentTypesByResourceType, resourceType]);

  const renderStepContent = () => {
    if (activeStep === 0) {
      return contextHandlers.context === 'supplier' ? (
        <SmartDownloadSelectContext
          context={contextHandlers.context}
          setContext={contextHandlers.setContext}
          subContext={contextHandlers.subContext}
          setSubContext={contextHandlers.setSubContext}
          data={supplierData}
          selectedItems={selectedSuppliers}
          setSelectedItems={setSelectedSuppliers}
          setSelectedAllItems={setSelectedAllSuppliers}
          resourceType={resourceType}
        />
      ) : (
        <SmartDownloadSelectContext
          context={contextHandlers.context}
          setContext={contextHandlers.setContext}
          data={documentTypeData}
          selectedItems={selectedResourceDocumentTypes}
          setSelectedItems={setSelectedResourceDocumentTypes}
          setSelectedAllItems={setSelectedAllResourceDocumentTypes}
          resourceType={resourceType}
        />
      );
    }

    if (activeStep === 1) {
      if (contextHandlers.context === 'supplier') {
        return (
          <SmartDownloadSelectSubContext
            setSelectedResourcesByType={setSelectedResourcesByType}
            subContext={contextHandlers.subContext}
            setSubContext={contextHandlers.setSubContext}
            subContextData={contextHandlers.subContextData}
            setSubContextData={contextHandlers.setSubContextData}
            supplierResources={supplierResources}
            setSelectedAllResourcesByType={setSelectedAllResourcesByType}
            selectedResourceType={selectedResourceType}
            setSelectedResourceType={setSelectedResourceType}
          />
        );
      }

      if (contextHandlers.context === 'documentType') {
        return (
          <SmartDownloadSelectSubContextDataByResourceType
            context={contextHandlers.context}
            setSelectedDataResourcesByType={setSelectedDocumentTypesByResourceType}
            selectedResourceType={selectedDocumentTypeResourceType}
            setSelectedResourceType={setSelectedDocumentTypeResourceType}
            data={documentTypeData}
            setSelectedAllDataResourcesByType={setSelectedAllDocumentTypesByResourceType}
          />
        );
      }

      if (contextHandlers.context === 'resource') {
        return (
          <SmartDownloadSummary
            subContext={contextHandlers.subContext}
            subContextData={contextHandlers.subContextData}
            selectedResourcesByType={selectedResourcesByType}
            context={contextHandlers.context}
            selectedDocuments={selectedResourceDocumentTypes}
            selectedAll={selectedAllResourceDocumentTypes}
          />
        );
      }
    }

    if (activeStep === 2) {
      return (
        <SmartDownloadSummary
          subContext={contextHandlers.subContext}
          subContextData={contextHandlers.subContextData}
          selectedResourcesByType={selectedResourcesByType}
          selectedDocumentsByResourceType={ selectedDocumentTypesByResourceType}
          context={contextHandlers.context}
          selectedAll={selectedAllDocumentTypesByResourceType[selectedResourceType]}
        />
      );
    }
    return null;
  };
  const handleDownloadButton = () => {
    return downloadLoading ? (
      <Spinner size="xs" />
    ) : (
      contextHandlers.context === "resource" && activeStep === 1 ? (
        t("smartDownload.download", { ns: "common" })
      ) : (
        t(activeStep <= 1 ? "next" : "smartDownload.download", { ns: "common" }) || "Default Text"
      )
    );
  };

  return (
    <Modal isOpen onClose={onClose} size="6xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box paddingY={4} fontWeight={"normal"}>
            <Text fontWeight={"bold"}>{t("smartDownload.title")}</Text>
            <Text fontSize={"sm"} py={2}>
              {resourceType ?
                t("smartDownload.subtitleResources", {
                count: selectAllResources ? resourceCount : selectedResources?.length
              }) : t("smartDownload.subtitleSites", {
                count: selectAllSites ? siteCount : selectedSites?.length
              })}
            </Text>
          </Box>
          <Box padding={2} border="1px" borderColor="gray.200" borderRadius={6}>
            <Steps
              selected={activeStep}
              onSelect={(index) => {
                setActiveStep(index);
                prevStep();
              }}
              steps={steps}
            />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight={500} overflowY={'scroll'}  width={'100%'} sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '5px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.1)`,
            borderRadius: '5px',
            border: '2px solid rgba(0, 0, 0, 0.05)'
          },
        }}>
          {renderStepContent()}
          {downloadFeedback && (
            <Alert
              variant="warning"
              title={t('warning', { ns: 'common' })}
              message={t(downloadFeedback)}
              onClose={() => {
                setDownloadFeedback(null);
                onClose();
              }}
            />
          )}
        </ModalBody>
        <ModalFooter zIndex={"1"}>
          <Button
            onClick={nextStep}
            bgColor={COLORS.sikuroBlue}
            color="white"
            isLoading={downloadLoading}
            isDisabled={disableNextPage}
          >
            {handleDownloadButton()}
          </Button>
          <Button
            onClick={prevStep}
            bgColor={COLORS.error}
            color="white"
            marginLeft={4}
          >
            {t(activeStep <= 0 ? "cancel" : "back", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>  );
};

export default SmartDownloadModal;
