import { useAuth } from "../../../providers/Auth0JWTProvider";
import Vehicle from "../../../../domain/entities/vehicle";
import { VehicleViewModel } from "../../../viewmodels/vehicles/VehicleViewModel";
import React, { useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { GetSitesFilter, GetSitesWithBadgesFilter } from "../../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../../utils";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { GetAvailableBadgesFilters } from "../../../../domain/repositories/badgeRepository";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";
import { MapRecordInput } from "../Machines/useMachineViewModel";
import {CustomField} from "../../../../domain/entities/customFields";

const useVehicleViewModel = (id: string) => {
  const { companyId } = useAuth();
  const [sortResourceDocumentSites, setSortResourceDocumentSites] = useState<SortMeta>();
  const [filterResourceDocumentSites, setFilterResourceDocumentSites] =
    useState<GetSitesFilter>();

  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
  useState<GetSitesWithBadgesFilter>();

  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [resourceLinkableSitesCount, setResourceLinkableSitesCount] = useState<number>();
  const [badgeIds, setBadgeIds] = useState<string[]>([]);
  const [badgeId, setBadgeId] = useState<string>();
  const [selectAll, setSelectAll] = useState(false);
  const viewModel = useMemo(() => new VehicleViewModel(), []);
  const [act, setAct] = useState<string>();
  const [availableBadgesFilters, setAvailableBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableBadgesSort, setAvailableBadgesSort] =
    useState<SortMeta>(null);
  const [showInfoSuccess, setShowInfoSuccess] = useState<string>(null);
  const queryClient = useQueryClient();
  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [records, setRecords] = React.useState<MapRecordInput[]>([]);
  const [documentTypeId, setDocumentTypeId] = React.useState<string>();
  const [enableGetVehicleSites, setEnableGetVehicleSites] = useState<boolean>(false);
  const [enableGetVehicleSiteCollection, setEnableGetVehicleCollection] = useState<boolean>(false);
  const [enableGetMigrationVehicleSites, setGetMigrationVehicleSites] = useState<boolean>(false);
  const {
    data: vehicle,
    isFetching,
    refetch: getVehicle,
    isLoading
  } = useQuery<Vehicle, Error>(
    ["vehicle", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const getAvailableBadgesQuery = useQuery(
    [
      "available-badges",
      companyId,
      availableBadgesFilters,
      availableBadgesSort,
    ],
    async () => {
      return await viewModel.getAvailableBadges(
        companyId,
        "vehicles",
        id,
        availableBadgesSort,
        availableBadgesFilters,
      );
    },
  );

  const linkBadgesToResourceMutation = useMutation(
    async () => {
      return await viewModel.linkBadgesToResource(
        companyId,
        badgeIds,
        BadgeResourceType.VEHICLE,
        id,
      );
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getVehicleSites.refetch();
        setBadgeIds([]);
        setShowInfoSuccess('badgeAssociatedToResource');
      },
    },
  );

  const unlinkBadgeResourceMutation = useMutation(
    async () => {
      return await viewModel.unlinkBadgeResource(companyId, badgeId);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getVehicleSites.refetch();
      },
    },
  );

  const updateMutation = useMutation(
    ({ vehicle, imageFile }: { vehicle: Vehicle; imageFile?: File }) =>
      viewModel.update(companyId, vehicle, imageFile),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getVehicle(),
    },
  );

  const updateVehicle = (vehicle: Vehicle) => {
    return updateMutation.mutateAsync({ vehicle });
  };

  const updateImage = (vehicle: Vehicle, imageFile?: File) => {
    return updateMutation.mutateAsync({
      vehicle,
      imageFile,
    });
  };

  const getVehicleDocumentSites = useInfiniteQuery(
    ["resource-document-sites", companyId, id, sortResourceDocumentSites, filterResourceDocumentSites, documentTypeId],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getVehicleSites(
        companyId,
        id,
        sortResourceDocumentSites,
        filterResourceDocumentSites,
        pageParam,
        includeWorkingSites,
        false,
        documentTypeId
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!documentTypeId,
    },
  );
  const getVehicleSites = useInfiniteQuery(
    ["resource-sites", companyId, id, sortResourceSites, filterResourceSites],
    async ({ pageParam = 1 }) => {
      return await viewModel.getVehicleSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        false,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetVehicleSites
    },
  );
  /**
   * This method differs from the one above because here we want to know if the vehicle is used on any site for the purpose of migration
   * while in the other method (getVehicleSites), we want to know the sites where the document can be propagated
   * (this means only those visible to the current user according to their permissions)
   */
  const getVehicleMigrationSites = useInfiniteQuery(
    ["resource-migration-sites", companyId, id],
    async ({ pageParam = 1 }) => {
      return await viewModel.getVehicleSites(
        companyId,
        id,
        null,
        null,
        pageParam,
        false,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMigrationVehicleSites
    },
  );

  const hasSites = async (action, documentTypeId?: string) => {
    setAct(action);
    setDocumentTypeId(documentTypeId);
    await queryClient.invalidateQueries([
      "resource-document-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getVehicleDocumentSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getResourceLinkableSites(
        companyId,
        "vehicle" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
      setResourceLinkableSitesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetVehicleSiteCollection,
    },
  );

  const unlinkBadgeResource = () => {
    return unlinkBadgeResourceMutation.mutateAsync();
  };

  const linkBadgesToResource = () => {
    linkBadgesToResourceMutation.mutateAsync();
  };

  const linkResourceToSites = (siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean) => {
    const params = {siteIds, copyLastEvaluation, selectAll, filters: filterSiteCollection}
    linkResourceToSitesMutation.mutateAsync(params);
  };
  const linkResourceToSitesMutation = useMutation(
    async (params: {siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean, filters?: GetSitesFilter}) => {
      return await viewModel.linkResourceToSites(companyId, id, params.siteIds, params.copyLastEvaluation, params.selectAll, params.filters);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getVehicleSites.refetch();
        setShowInfoSuccess('resourceAssociatedToSite')
      }
    },
  );

  const getMapRecordsQuery = useQuery(
    ["machine-vehicle-document-type-map-records", companyId],
    async () => {
      const documentTypesMap =
        await viewModel.getMachineVehicleDocumentTypeMapRecords(companyId);
      await getVehicleDocuments.refetch();
      return documentTypesMap;
    },
    {
      enabled: openMigrationModal,
    },
  );

  const getMachineTypologiesQuery = useQuery(
    ["machine-typologies", companyId],
    async () => await viewModel.getMachineTypologies(companyId),
    {
      enabled: openMigrationModal,
    },
  );

  const migrateToMachineMutation = useMutation(
    async ({ typologyId }: { typologyId: string }) => {
      return await viewModel.migrateToMachine(companyId, id, typologyId);
    },
    {
      onError: (e) => console.error(e),
    },
  );

  const upsertMachineVehicleDocumentTypeMapRecordsMutation = useMutation(
    async (
      records: {
        machineDocumentTypeId: string;
        vehicleDocumentTypeId: string;
      }[],
    ) => {
      return await viewModel.upsertMachineVehicleDocumentTypeMapRecords(
        companyId,
        records,
      );
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getMapRecordsQuery.refetch(),
    },
  );

  const getVehicleDocuments = useQuery(
    ["vehicle-documents", companyId, id],
    async () => {
      const response = await viewModel.getDocuments(companyId, id);
      setRecords(
        response.map((d) => ({
          vehicleDocumentTypeId: d.type.id,
          machineDocumentTypeId: getMapRecordsQuery.data.find(
            (r) => r.vehicleDocumentType.id === d.type.id,
          )?.machineDocumentType.id,
        })),
      );
      return response;
    },
    {
      enabled: !!getMapRecordsQuery.data,
    },
  );

  const getMachineDocumentTypes = useQuery(
    ["machine-document-types", companyId],
    async () => await viewModel.getMachineDocumentTypes(companyId),
    {
      enabled: openMigrationModal,
    },
  );

  const resourceDocumentSites =
    getVehicleDocumentSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalResourceDocumentSiteCount = getVehicleDocumentSites.data?.pages?.[0]?.count ?? 0;
  const availableBadgesResource = getAvailableBadgesQuery.data;
  const resourceSites =
    getVehicleSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const resourceMigrationSites =
    getVehicleMigrationSites.data?.pages?.flatMap((page) => page.results) ?? [];

  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["vehicle-custom-fields", companyId],
    async () => await viewModel.getCustomFields(companyId),
  );

  return {
    vehicle,
    getVehicle,
    isFetching,
    updateImage,
    updateVehicle,
    resourceMigrationSites,
    setGetMigrationVehicleSites,

    resourceDocumentSites,
    isFetchingResourceDocumentSites: getVehicleDocumentSites.isFetching,
    resourceDocumentSitesHasNextPage: getVehicleDocumentSites.hasNextPage,
    resourceDocumentSitesFetchNextPage: getVehicleDocumentSites.fetchNextPage,
    updateFilterResourceDocumentSites: (field, value) => {
      field != null && updateFilterWithDelete(setFilterResourceDocumentSites, field, value);
    },
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    isFetchingSites: getVehicleSites.isFetching,
    hasNextPage: getVehicleSites.hasNextPage,
    fetchNextPage: getVehicleSites.fetchNextPage,
    setEnableGetVehicleSites,
    updateFilterResourceSites: (field, value) => {
      field != null && updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    setFilterResourceSites,
    filterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,

    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    setEnableGetVehicleCollection,
    siteCollectionIsLoading: getResourceLinkableSites.isLoading,
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    linkResourceToSites,
    isLinking: linkResourceToSitesMutation.isLoading,

    setBadgeIds,
    badgeIds,
    setBadgeId,
    availableBadgesResource,
    availableBadgesFilters,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesIsLoading: getAvailableBadgesQuery.isLoading,
    availableBadgesRefetch: getAvailableBadgesQuery.refetch,
    updateFilterAvailableBadge: (field, value) => {
      updateFilterWithDelete(setAvailableBadgesFilters, field, value);
    },
    linkBadgesToResource,
    linkBadgesToResourceIsLoading: linkBadgesToResourceMutation.isLoading,
    showInfoSuccess,
    setShowInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading: unlinkBadgeResourceMutation.isLoading,
    selectAll,
    setSelectAll,
    machineVehicleMigrationProps: {
      targetTypologies: getMachineTypologiesQuery.data ?? [],
      migrate: migrateToMachineMutation.mutateAsync,
      loading:
        migrateToMachineMutation.isLoading ||
        getMachineTypologiesQuery.isLoading ||
        getMapRecordsQuery.isFetching ||
        upsertMachineVehicleDocumentTypeMapRecordsMutation.isLoading ||
        getVehicleDocuments.isFetching ||
        getMachineDocumentTypes.isLoading,
      upsertMapRecords:
        upsertMachineVehicleDocumentTypeMapRecordsMutation.mutateAsync,
      documentTypeMapRecords: getMapRecordsQuery.data ?? [],
      documents: getVehicleDocuments.data ?? [],
      openMigrationModal,
      setOpenMigrationModal,
      targetDocumentTypes: getMachineDocumentTypes.data ?? [],
      records,
      setRecords,
    },
    resourceLinkableSitesCount,
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    loading: getCustomFieldsQuery.isLoading || isLoading,
  };
};

export default useVehicleViewModel;
