import {
  MutateOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import DocumentComment, {
  MessageType,
} from "../../../domain/entities/documentComment";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import DocumentCommunicationViewModel from "../../viewmodels/documents/DocumentCommunicationViewModel";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import { UserMini } from "../../../domain/entities/user";
import { useLocation } from "react-router-dom";
import { useHasPermissions } from "../../components/Permissions/RenderIf";
import { Permission } from "../../components/Permissions/Permissions";
import { useState } from "react";
import TaggableDocument from "../../../infrastructure/responses/taggableDocument";

export type DocumentCommunicationHook = {
  resourceDocumentNote: Identifiable & { note: string };
  createResourceDocumentNote: (
    variables: string,
    options?: MutateOptions<void, unknown, string, unknown>
  ) => Promise<void>;
  resourceDocumentNoteFetching: boolean;
  createResourceDocumentIsLoading: boolean;
  updateNotificationsStatus: (status: boolean) => Promise<void>;
  resourceDocumentComments: DocumentComment[];
  createResourceDocumentComment: (comment: string) => Promise<void>;
  resourceDocumentCommentsFetching: boolean;
  getNotificationsStatus: { status: boolean };
  refetchNotificationStatus: () => void;
  taggableDocuments: TaggableDocument[];
  missingDocuments: TaggableDocument[];
  taggableUsers: UserMini[];
  isFetching: boolean;
  refetchTaggableDocuments: () => void;
  refetchMissingDocuments:()=>void;
  refetchResourceDocumentComments: () => void;
  refetchResourceDocumentNotes: () => void;
  setDocumentCommentsQueryEnabled: (value: boolean) => void;
  exportChatComments: (onComplete?: (ok: boolean) => void,) => void;
  search?: string;
  setSearch?: (value: string) => void;
};

const useDocumentCommunicationViewModel = (
  resourceId: string,
  resourceType: DocumentTypeCategory,
  siteId?: string,
  isWorkingSite?: boolean
): DocumentCommunicationHook => {
  const { companyId, user } = useAuth();
  const viewModel = new DocumentCommunicationViewModel();
  const location = useLocation();

  const hasCsePermission = useHasPermissions([Permission.Sites_CseShowSites, Permission.Worksite_CseShowWorkingSites], PermissionCheck.Some);
  // this could be treated as a static state because queries are called manually,
  // we keep it here with a setter just in case it will be useful
  const [hookQueriesEnabled, setHookQueriesEnabled] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [documentComments, setDocumentCommments] = useState<DocumentComment[]>(undefined);

  const getResourceDocumentNotes = useQuery(
    ["resource-document-notes", resourceId, siteId],
    async () => {
      if (hasCsePermission){
        return;
      }
      return await viewModel.getResourceDocumentNotes(
        companyId,
        resourceId,
        resourceType,
        siteId
      );
    },
    {
      enabled: hookQueriesEnabled
    }
  );
  

  const createNoteMutation = useMutation(
    (note: string) =>
      viewModel.createResourceDocumentNote(
        companyId,
        resourceId,
        resourceType,
        note,
        siteId
      ),
    {
      onSuccess: () => getResourceDocumentNotes.refetch(),
      onError: (err) => console.error(err),
    }
  );

  const getResourceDocumentComments = useQuery(
    ["resource-document-comments", resourceId, siteId, search],
    async () => {
      if (hasCsePermission){
        return;
      }
      const comments = await viewModel.getResourceDocumentComments(
        companyId,
        resourceId,
        resourceType,
        siteId,
        search
      );
      setDocumentCommments(comments.reverse());
      return comments;
    },
    {
      enabled: hookQueriesEnabled
    }
  );

  const createCommentMutation = useMutation(
    async (textComment: string) => {
      const mentions = extractTags(textComment);
      const comment = {
        id: "",
        comment: textComment,
        createdAt: new Date(),
        author: user,
        taggedUsers: mentions.userIds,
        taggedDocuments: mentions.documentIds,
        commentUrl: location.pathname,
        type: MessageType.COMMENT,
      };

      //optimistic approach
      setDocumentCommments((prevComments)=>[...prevComments, comment]);

      await viewModel.createResourceDocumentComment(
        companyId,
        resourceId,
        resourceType,
        comment,
        siteId
      );
    },
    {
      onSuccess: () => getResourceDocumentComments.refetch(),
      onError: (err) => console.error(err),
    }
  );

  const getTaggableUsers = useQuery(
    ["taggable-users", resourceId, siteId],
    async () => {
      if (hasCsePermission){
        return;
      }
      const result = await viewModel.getTaggableUsers(
        companyId,
        siteId,
        resourceId,
        resourceType
      );
      return result;
    }
  );

  const getTaggableDocuments = useQuery(
    ["taggable-documents", resourceType, resourceId, siteId],
    async () => {
      if (hasCsePermission){
        return;
      }
      const result = await viewModel.getTaggableDocuments(
        companyId,
        resourceType,
        resourceId,
        undefined,
        siteId,
        false
      );
      return result;
    }
  );

  const getMissingFileDocuments = useQuery(
    ["missing-file-documents", resourceType, resourceId, siteId],
    async () => {
      if (hasCsePermission){
        return;
      }
      const result = await viewModel.getTaggableDocuments(
        companyId,
        resourceType,
        resourceId,
        undefined,
        siteId,
        true
      );
      return result;
    }
  );

  const getNotificationsStatus = useQuery(
    ["notification-status", resourceId, siteId, resourceType],
    async () => {
      if (hasCsePermission){
        return;
      }
      return await viewModel.getNotificationsStatus(
        companyId,
        resourceType,
        resourceId,
        siteId
      );
    },
    {
      enabled: hookQueriesEnabled
    }
  );

  const exportChatComments = async (
    onComplete?: (ok: boolean) => void
  ) => {
      return await viewModel.exportChatComments(
        companyId,
        resourceType,
        resourceId,
        siteId
      ).then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
    }

  const updateNotificationsStatusMutation = useMutation(
    (status: boolean) => {
      return viewModel.updateNotificationsStatus(
        companyId,
        resourceType,
        resourceId,
        status,
        siteId
      );
    },
    {
      onSuccess: () => getNotificationsStatus.refetch(),
      onError: (err) => console.error(err),
    }
  );

  return {
    isFetching:
      createCommentMutation.isLoading ||
      getResourceDocumentComments.isFetching ||
      getTaggableUsers.isFetching ||
      getTaggableDocuments.isFetching,
    resourceDocumentNote: getResourceDocumentNotes.data,
    resourceDocumentNoteFetching: getResourceDocumentNotes.isLoading,
    createResourceDocumentNote: createNoteMutation.mutateAsync,
    createResourceDocumentIsLoading: createNoteMutation.isLoading,
    resourceDocumentComments: documentComments,
    resourceDocumentCommentsFetching: getResourceDocumentComments.isLoading,
    createResourceDocumentComment: createCommentMutation.mutateAsync,
    taggableUsers: getTaggableUsers.data,
    updateNotificationsStatus: updateNotificationsStatusMutation.mutateAsync,
    getNotificationsStatus: getNotificationsStatus.data,
    refetchNotificationStatus: getNotificationsStatus.refetch,
    taggableDocuments: getTaggableDocuments.data ?? [],
    refetchTaggableDocuments: getTaggableDocuments.refetch,
    missingDocuments: getMissingFileDocuments.data ?? [],
    refetchMissingDocuments: getMissingFileDocuments.refetch,

    refetchResourceDocumentComments: getResourceDocumentComments.refetch,
    refetchResourceDocumentNotes: getResourceDocumentNotes.refetch,
    setDocumentCommentsQueryEnabled: setHookQueriesEnabled,
    exportChatComments,
    search, setSearch
  };
};

function extractTags(inputString: string) {
  const regex = /[@#]\[([^\]]+)\]\(([^)]+)\)/g;
  const documentIds = [];
  const userIds = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    const type = match[0].startsWith("@") ? "user" : "document";
    const id = match[2];
    if (type === "user" && !userIds.includes(id)) {
      userIds.push(id);
    } else if (type === "document" && !documentIds.includes(id)) {
      documentIds.push(id);
    }
  }

  return { documentIds, userIds };
}
export default useDocumentCommunicationViewModel;
