import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import LoadingView from "../../Common/LoadingView";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import Machine from "../../../../domain/entities/machine";
import ContentLayout from "../../../layout/ContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import RenderIf from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useMachineViewModel from "../../../hooks/Resources/Machines/useMachineViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import useMachineDocumentsViewModel from "../../../hooks/Resources/Machines/useMachineDocumentsViewModel";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import DOMPurify from "dompurify";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import SelectBadgesTable from "../../../components/Views/Badge/SelectBadgesTable";
import { BiLink } from "react-icons/bi";
import { Alert } from "../../Common/Alert";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import MachineVehicleMigrationButton from "../MachineVehicleMigrationButton";
import { LuPackagePlus } from "react-icons/lu";
import ResourceSitesList from "../ResourceSitesList";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { LicenseType } from "../../../../domain/interactors/auth/args";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    addDocument: Permission;
    editRecord: Permission;
    linkBadge: Permission;
    migrateToVehicle: Permission;
  };
}

const MachineDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const hook = useMachineViewModel(id as string)
  return hook.loading ? <LoadingView /> : <MachineDetailViewMain permissions={permissions} hook={hook} />
}

const MachineDetailViewMain: FC<Props & {hook: ReturnType<typeof useMachineViewModel>}> = ({ permissions, hook }) => {
  const { t } = useTranslation("machines");
  const { id } = useParams();
  const {
    machine,
    isFetching,
    updateImage,
    updateMachine,
    resourceMigrationSites,

    resourceDocumentSites,
    isFetchingResourceDocumentSites,
    resourceDocumentSitesHasNextPage,
    resourceDocumentSitesFetchNextPage,
    updateFilterResourceDocumentSites,
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    setGetMigrationMachineSites,
    setEnableGetMachineSites,
    hasSites,
    siteCollection,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setEnableGetMachineSiteCollection,
    siteCollectionIsLoading,
    selectAll,
    setSelectAll,
    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkMachineToSites,
    isLinking,
    badgeIds,

    setBadgeIds,
    setSortMachineBadge,
    sortMachineBadge,
    setBadgeId,
    availableBadgesRefetch,
    availableBadgesFilters,
    availableBadgesIsLoading,
    availableBadgesResource,
    availableBadgesSort,
    linkBadgesToResource,
    linkBadgesToResourceIsLoading,
    setAvailableBadgesSort,
    setShowInfoSuccess,
    showInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading,
    updateFilterAvailableBadge,
    machineVehicleMigrationProps,
    setFilterResourceSites,
    customFields
  } = hook;
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const documentsHook = useMachineDocumentsViewModel(id);
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const downloader = useDocumentFilesDownloader();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [copyLastEvaluation, setCopyLastEvaluation] = useState(false)
  const { company } = useAuth();
  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", { ns: "sites", resource: `${machine?.name}` })
  );
  const sanitizedHtmlContentLinkBadges = DOMPurify.sanitize(
    t("linkToBadges", { ns: "badges", resource: `${machine?.name}` })
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", {
      ns: "badges",
      resource: `${machine?.name}`,
    })
  );

  const navigate = useNavigate();
  const goToMachinesList = () => {
    navigate(`/resources/machines`);
  };

  const onSubmit: SubmitHandler<Machine> = (data) => {
    if (uploadedImageFile) {
      updateImage(
        { ...machine,...data },
        uploadedImageFile
      );
    } else {
      updateMachine({
        ...machine,
        ...data
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Machine>({ mode: "all", defaultValues: machine });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  useEffect(() => {
    if (documentsHook.communicationProps){
      documentsHook.communicationProps.refetchResourceDocumentComments();
    }
  }, []);

  const refetchCommentsAndNotes = () => {
    documentsHook.communicationProps.refetchMissingDocuments();
    documentsHook.communicationProps.refetchResourceDocumentComments(),
    documentsHook.communicationProps.refetchResourceDocumentNotes();
    documentsHook.communicationProps.refetchNotificationStatus();
  };

  if (!machine) {
    return <LoadingView />;
  }
  const handleLinkToSites = async () => {
    await linkMachineToSites(selectAll? [] : selectedSiteIds, copyLastEvaluation, selectAll);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  const goToSiteConnection = (siteId: string) => {
    navigate(`/sites/${siteId}/resources/machines/${id}`)
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToMachinesList}
          />
          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
         {company?.type !== LicenseType.Smart &&  <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={LuPackagePlus }
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${machine?.name}`,
            })}
            onClick={() => {
              setEnableGetMachineSiteCollection(true), setShowSiteCollection(true);
              refetchSiteCollection();
            }}
          />}
          {company?.type === LicenseType.Master && <RenderIf permissions={permissions.linkBadge}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${machine?.name}`,
              })}
              onClick={async () => {
                await availableBadgesRefetch();
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>}
          <RenderIf permissions={permissions.migrateToVehicle}>
            <Box onClick={() => setGetMigrationMachineSites(true)}>
              <MachineVehicleMigrationButton
                namespace="machines"
                hasSites={resourceMigrationSites.length > 0}
                {...machineVehicleMigrationProps}
              />
            </Box>
          </RenderIf>
        </ActionBar>
      }
    >
      {machine && (
        <Box px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {machine?.name} {machine?.model}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : machine.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="plate"
                    name="plate"
                    label={t("plate")}
                    rules={requiredRule}
                  />,
                  <FormTextField key="model" name="model" label={t("model")} />,
                ]}
                customFields={customFields.data}
              />
            </FormProvider>
          </Box>
          <Box
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            padding={5}
            mt={5}
          >
            <Tabs height="100%" defaultIndex={0} marginTop={4} isFitted>
              <TabList>
                <Tab>{t("documents", { ns: "common" })}</Tab>
               {company?.type !== LicenseType.Smart && <Tab onClick={()=> setEnableGetMachineSites(true)}>{t("sites", { ns: "sites" })}</Tab>}
              </TabList>
              <TabPanels>
                <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                  <ResourceDocumentsView
                    category={DocumentTypeCategory.MACHINE}
                    documentsHook={documentsHook}
                    resourcePath="/resources/workers"
                    showAddDocumentType={showAddDocumentType}
                    setShowAddDocumentType={setShowAddDocumentType}
                    downloader={downloader}
                    resourceSites={resourceDocumentSites}
                    hasSites={hasSites}
                    isFetchingSites={isFetchingResourceDocumentSites}
                    hasNextPage={resourceDocumentSitesHasNextPage}
                    fetchNextPage={() => resourceDocumentSitesFetchNextPage()}
                    siteCount={totalResourceDocumentSiteCount}
                    selectAllSites={selectAll}
                    setSelectAllSites={setSelectAll}
                    filterResourceSites={filterResourceDocumentSites}
                    updateFilterResourceSites={updateFilterResourceDocumentSites}
                    setSortResourceSites={setSortResourceDocumentSites}
                    sortResourceSites={sortResourceDocumentSites}
                    showManageTaskWithAI={showManageTaskWithAI}
                    setShowManageTaskWithAI={setShowManageTaskWithAI}
                    permissions={permissions}
                    resourceId={machine.id}
                    setFilterResourceSites={setFilterResourceSites}
                  />
                </TabPanel>

               {company?.type !== LicenseType.Smart && <TabPanel key={"site"} paddingLeft={0} paddingRight={0}>
                  <ResourceSitesList
                    sites={resourceSites}
                    sitesFilter={filterResourceSites}
                    updateSitesFilter={updateFilterResourceSites}
                    setSitesSort={setSortResourceSites}
                    sitesSort={sortResourceSites}
                    sitesLoading={isFetchingSites}
                    getSitesHasNextPage={hasNextPage}
                    getSitesFetchNextPage={fetchNextPage}
                    showBadgeColumns
                    navigateAction={(siteId)=> goToSiteConnection(siteId)}
                    showUnlink={true}
                    unlinkBadgeAction={(badgeId) => {
                      setBadgeId(badgeId);
                      setShowDeleteAlert(true);
                    }}
                  ></ResourceSitesList>
                </TabPanel>}
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setShowSiteCollection(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectSitesView
            autosize
            subtitle={sanitizedHtmlContent}
            showCopyLastEvaluation
            copyLastEvaluation={copyLastEvaluation}
            setCopyLastEvaluation={setCopyLastEvaluation}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            isFetchingSites={siteCollectionIsLoading}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={20}
              dangerouslySetInnerHTML={{
                __html: sanitizedHtmlContentLinkBadges,
              }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: showInfoSuccess === 'resourceAssociatedToSite' ? "siteResources" : "badges"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};

export default MachineDetailView;
