import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { parse } from "date-fns";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import AiTaskDocument, {
  AiTaskDocumentType,
} from "../../../../domain/entities/aiTaskDocument";
import Document from "../../../../domain/entities/document";
import DocumentType from "../../../../domain/entities/documentType";
import {
  updateAiDocuments,
  updateFileInAiDocuments,
} from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { AiDocumentList } from "./AiDocumentList";
import { hasMatchingPublic } from "./AiTaskDetailModal";

interface ManageAiDocumentsProps {
  recognizedFiles: AiTaskDocumentType[];
  setRecognizedFiles: Dispatch<SetStateAction<AiTaskDocumentType[]>>;
  documentTypesForAi: DocumentType[];
  unrecognizedFiles: AiTaskDocument[];
  setUnrecognizedFiles: Dispatch<SetStateAction<AiTaskDocument[]>>;
  selectedTaskFile: AiTaskDocument;
  setSelectedTaskFile: Dispatch<SetStateAction<AiTaskDocument>>;
  onClose: () => void;
  onConfirmTaskIsLoading: boolean;
  showSelectSites: boolean;
  onConfirm: () => void;
  setShowSelectSites: Dispatch<SetStateAction<boolean>>;
  isPropagable: boolean;
  setShowNoManagedModal: Dispatch<SetStateAction<boolean>>;
  documents: Document[]
}

export const ManageAiDocuments = ({
  recognizedFiles,
  documentTypesForAi,
  setRecognizedFiles,
  setUnrecognizedFiles,
  unrecognizedFiles,
  selectedTaskFile,
  setSelectedTaskFile,
  onClose,
  onConfirmTaskIsLoading,
  showSelectSites,
  onConfirm,
  setShowSelectSites,
  isPropagable,
  setShowNoManagedModal,
  documents
}: ManageAiDocumentsProps) => {
  const { t } = useTranslation("documents");
  const newDocumentRef = useRef<HTMLDivElement | null>(null);
  const [tab, setTab] = useState(0);
  const [files, setFiles] = useState<AiTaskDocumentType[]>();

  useEffect(() => {
    setFiles(recognizedFiles);
  }, [recognizedFiles]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
  
    if (!destination) return;
  
    const updatedFiles = [...files];
  
    const sourceIndex = updatedFiles.findIndex(
      (doc) => doc.documentTypeId === source.droppableId
    );
    const destinationIndex = updatedFiles.findIndex(
      (doc) => doc.documentTypeId === destination.droppableId
    );
  
    if (sourceIndex === -1 || destinationIndex === -1) return;
  
    const sourceDoc = updatedFiles[sourceIndex];
    const destinationDoc = updatedFiles[destinationIndex];
  
    if (!sourceDoc?.files || !destinationDoc?.files) return;
  
    if (source.droppableId === destination.droppableId) {
      const reorderedFiles = [...sourceDoc.files];
      const [movedItem] = reorderedFiles.splice(source.index, 1);
      reorderedFiles.splice(destination.index, 0, movedItem);
  
      updatedFiles[sourceIndex] = { ...sourceDoc, files: reorderedFiles };
    } else {
      const sourceFiles = [...sourceDoc.files];
      const destinationFiles = [...destinationDoc.files];
  
      const [movedItem] = sourceFiles.splice(source.index, 1);
      destinationFiles.splice(destination.index, 0, movedItem);
  
      updatedFiles[sourceIndex] = { ...sourceDoc, files: sourceFiles };
      updatedFiles[destinationIndex] = { ...destinationDoc, files: destinationFiles };
    }

    setFiles(updatedFiles);
    setRecognizedFiles(updatedFiles)
  };
  
  

  const filteredList = recognizedFiles?.filter(
      (file) =>
        documentTypesForAi?.some(
          (docType) => docType.id === file.documentTypeId,
        ) && !file.archived,
    )
    .map((document) => {
      const filteredFiles = document?.files?.filter((file) => !file.archived);
      return { ...document, files: filteredFiles };
    })

  const handleDateChange = (
    id: string,
    newDate: string,
    type: "recognized" | "unrecognized",
    notExpire: boolean,
    documentFileId?: string,
    isDocument?: boolean,
  ) => {
    const formattedDate = newDate
      ? parse(newDate, "dd/MM/yyyy", new Date())
      : null;

    if (isDocument) {
      setRecognizedFiles((prevFiles) =>
        prevFiles.map((document) =>
          document.documentTypeId === id
            ? {
                ...document,
                expiresAt: formattedDate,
                files: document.files,
              }
            : document,
        ),
      );
    } else {
      if (type === "recognized") {
        setRecognizedFiles((prevFiles) =>
          prevFiles.map((document) =>
            document.documentTypeId === id
              ? {
                  ...document,
                  files: document.files.map((file) =>
                    file.id === documentFileId
                      ? {
                          ...file,
                          expiresAt: formattedDate,
                          notExpire: notExpire,
                        }
                      : file,
                  ),
                }
              : document,
          ),
        );
      } else {
        setUnrecognizedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === documentFileId
              ? { ...file, expiresAt: formattedDate, notExpire: notExpire }
              : file,
          ),
        );
      }
    }
  };

  const removeFile = (fileId: string, docId: string) => {
    if (docId) {
      setRecognizedFiles((prevFiles) =>
        updateFileInAiDocuments(prevFiles, docId, fileId, { archived: true }),
      );
    } else {
      setUnrecognizedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId ? { ...file, archived: true } : file,
        ),
      );
    }
  };

  const restoreFile = (fileId: string, docId: string) => {
    if (docId) {
      setRecognizedFiles((prevFiles) =>
        updateFileInAiDocuments(prevFiles, docId, fileId, { archived: false }),
      );
    } else {
      setUnrecognizedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId ? { ...file, archived: false } : file,
        ),
      );
    }
  };

  const removeDocument = (documentId: string) => {
    setRecognizedFiles((prevFiles) =>
      updateAiDocuments(prevFiles, documentId, { archived: true }),
    );
  };

  const restoreDocument = (documentId: string) => {
    setRecognizedFiles((prevFiles) =>
      updateAiDocuments(prevFiles, documentId, { archived: false }),
    );
  };

  const AddFileToDocumentType = (
    newFile: AiTaskDocument,
    documentTypeId: string,
  ) => {
    setRecognizedFiles((prevData) => {
      const existingDocument = prevData.find(
        (item) => item.documentTypeId === documentTypeId,
      );

      if (existingDocument) {
        return prevData.map((item) =>
          item.documentTypeId === documentTypeId
            ? {
                ...item,
                files: [...item.files, newFile],
                expiresAt: item.expiresAt,
                notExpire: false,
              }
            : item,
        );
      } else {
        const newDocument = {
          documentTypeId,
          expiresAt: null, 
          notExpire: false,
          files: [newFile],
        };

        return [...prevData, newDocument];
      }
    });
    setUnrecognizedFiles((prevData) =>
      prevData.filter((item) => item.id !== newFile.id),
    );
  };

  const handleChangeDocumentType = (
    documentId: string,
    newDocumentType: string,
  ) => {
    setRecognizedFiles((prev) => {
      return prev.map((doc) => {
        if (doc.documentTypeId === documentId) {
          return {
            ...doc,
            documentTypeId: newDocumentType,
          };
        }
        return doc;
      });
    });
  };

  const handleAddDocumentType = () => {
    setRecognizedFiles((prev) => {
      const newDocument = {
        expiresAt: new Date(),
        documentTypeId: `${prev.length + 1}`,
        files: [],
      };
      return [...prev, newDocument];
    });

    setTimeout(() => {
      newDocumentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  const renderNoFileAvailable = () => {
    return (
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        h="100%"
        w="100%"
        mt={10}
      >
        <Text>{t("noAvailableFiles")}</Text>
      </Flex>
    );
  };

  return (
    <Flex direction="column" width="100%" height="100%" backgroundColor={showSelectSites ? COLORS.lightGrey : 'unset'}>
      <Box height={"calc(85vh - 130px)"}>
        <Tabs
          defaultIndex={recognizedFiles?.length === 0 ? 1 : 0}
          isFitted
          onChange={(value) => setTab(value)}
        >
          <TabList>
            <Tab isDisabled={showSelectSites}>{t("recognized")}</Tab>
            <Tab isDisabled={showSelectSites}>{t("unrecognized")}</Tab>
          </TabList>

          <TabPanels overflowY="auto">
            <TabPanel p={0}>
              <div style={{ overflowY: "auto", height: "calc(85vh - 170px)" }}>
                {recognizedFiles?.length > 0 ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <AiDocumentList
                      documentTypesForAi={documentTypesForAi.filter(
                        (item, index, self) =>
                          index === self.findIndex((t) => t.name === item.name),
                      )}
                      documents={recognizedFiles}
                      isRecognizedfile={true}
                      onDateChange={handleDateChange}
                      selectedTaskFile={selectedTaskFile}
                      setSelectedTaskFile={setSelectedTaskFile}
                      removeDocument={removeDocument}
                      restoreDocument={restoreDocument}
                      newDocumentRef={newDocumentRef}
                      removeFile={removeFile}
                      restoreFile={restoreFile}
                      handleChangeDocumentType={handleChangeDocumentType}
                      showSelectSites={showSelectSites}
                      recognizedFiles={recognizedFiles}
                      documentList={documents}
                    />
                  </DragDropContext>
                ) : (
                  renderNoFileAvailable()
                )}
              </div>
            </TabPanel>
            <TabPanel p={0}>
              <div style={{ overflowY: "auto", height: "calc(85vh - 170px)" }}>
                {unrecognizedFiles?.length > 0 ? (
                  <AiDocumentList
                    documentTypesForAi={documentTypesForAi.filter(
                      (item, index, self) =>
                        index === self.findIndex((t) => t.name === item.name),
                    )}
                    files={unrecognizedFiles}
                    onDateChange={handleDateChange}
                    removeFile={removeFile}
                    restoreFile={restoreFile}
                    addFileToDocumentType={AddFileToDocumentType}
                    selectedTaskFile={selectedTaskFile}
                    setSelectedTaskFile={setSelectedTaskFile}
                    showSelectSites={showSelectSites}
                    recognizedFiles={recognizedFiles}
                    documentList={documents}
                  />
                ) : (
                  renderNoFileAvailable()
                )}
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
     {!showSelectSites && <Flex
        justifyContent={tab === 0 ? "space-between" : "flex-end"}
        p={2}
        width="100%"
        bottom={0}
        position="sticky"
        zIndex={1}
        borderTop="solid"
        borderColor={COLORS.lightGrey}
        borderTopWidth="1px"
      >
        {tab === 0 && (
          <Tooltip label={t("addDocumentType", { ns: "documents" })}>
            <IconButton
              backgroundColor={COLORS.yellow}
              aria-label="add"
              icon={<AddIcon />}
              onClick={handleAddDocumentType}
            ></IconButton>
          </Tooltip>
        )}
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="blue"
            isLoading={onConfirmTaskIsLoading}
            isDisabled={
              files === undefined ||
              files?.length == 0 ||
              !files?.every(
                (file) =>
                  file?.documentTypeId !== undefined &&
                  file?.documentTypeId !== null,
              ) ||
              filteredList?.length === 0
            }
            onClick={() => {
              if (showSelectSites || !isPropagable || !hasMatchingPublic(documents, recognizedFiles)) {
                if (unrecognizedFiles.length > 0) {
                  setShowNoManagedModal(true);
                } else {
                  onConfirm();
                  setShowSelectSites(false);
                }
              } else {
                if (unrecognizedFiles.length > 0) {
                  setShowNoManagedModal(true);
                } else {
                  setShowSelectSites(true);
                }
              }
            }}
          >
            {t("confirm", { ns: "common" })}
          </Button>
          <Button marginLeft={4} colorScheme="red" onClick={onClose}>
            {t("cancel", { ns: "common" })}
          </Button>
        </Flex>
      </Flex>}
    </Flex>
  );
};
