import BaseModal from "../../Common/alerts/BaseModal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  Text,
  Flex,
  Button,
  Tbody,
  Tr,
  Td,
  Thead,
  Spacer,
} from "@chakra-ui/react";
import AiTask from "../../../../domain/entities/aiTask";
import DragDropFileView from "../../Common/DragDropFileView";
import InfiniteTable from "../../Common/table/InfiniteTable";
import AiTaskRow from "./AiTaskRow";
import FileEntity from "../../../../domain/entities/file";
import ImportInfo from "../../../../domain/entities/importInfo";
import AiTaskDetailModal from "./AiTaskDetailModal";
import DocumentType from "../../../../domain/entities/documentType";
import Site from "../../../../domain/entities/site";
import AiTaskDocument, { AiTaskDocumentType } from "../../../../domain/entities/aiTaskDocument";
import { AiTaskSectionCode } from "../../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { UseDocumentAI } from "../../../hooks/Document/useDocumentAI";
import Document from "../../../../domain/entities/document";

export type ConfirmTaskParams = {
  taskId: string;
  aiTaskDocuments: AiTaskDocumentType[];
  siteIds: string[];
};

interface ManageTaskWithAiModalProps {
  title: string;
  aiTasks: AiTask[];
  aiTaskSectionCode: AiTaskSectionCode;
  createAiTask: (
    files: FileEntity,
    sectionCode: AiTaskSectionCode,
    resourceId?: string,
    type?: DocumentTypeCategory
  ) => void;
  aiTaskDetail: AiTask;
  aiTaskDetailFetching: boolean;
  setSelectedAiTaskId: (aiTaskId: string) => void;
  aiTasksHasNextPage: boolean;
  aiTasksFetchNextPage: any;
  aiTasksFetching: boolean;
  onClose: () => void;
  onDeleteTask: (taskId: string) => void;
  onStopTask: (taskId: string) => void;
  onConfirmTask: (params: ConfirmTaskParams) => void;
  onConfirmTaskIsLoading: boolean;
  isPropagable?: boolean;
  documentTypesForAi: DocumentType[];
  setEnableDocumentTypesForAiQuery: (enabled: boolean) => void;
  sitesWhereToPropagate: Site[];
  setHookTaskId?: (taskId: string) => void;
  documentsAiHook: UseDocumentAI,
  documents?: Document[]
}

const ManageTaskWithAiModal = ({
  title,
  aiTasks,
  aiTaskDetail,
  aiTaskDetailFetching,
  setSelectedAiTaskId,
  aiTasksHasNextPage,
  aiTasksFetchNextPage,
  aiTasksFetching,
  aiTaskSectionCode,
  createAiTask,
  onClose,
  isPropagable,
  onDeleteTask,
  onStopTask,
  onConfirmTask,
  onConfirmTaskIsLoading,
  documentTypesForAi,
  sitesWhereToPropagate,
  setHookTaskId,
  setEnableDocumentTypesForAiQuery,
  documentsAiHook,
  documents
}: ManageTaskWithAiModalProps) => {
  const { t } = useTranslation("documents");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTaskDetail, setShowTaskDetail] = useState<boolean>(false);

  const handleFileUploaded = async (uploadedFile: FileEntity) => {
    setIsLoading(true);
    await createAiTask(uploadedFile, aiTaskSectionCode);
    setIsLoading(false);
  };

  return (
    <BaseModal
      size={"6xl"}
      title={title}
      onClose={onClose}
      onCancelLabel={t("close")}
      onConfirm={undefined}
      footer={
        <Button colorScheme="red" onClick={onClose}>
          {t("cancel", { ns: "common" })}
        </Button>
      }
    >
      <Flex flexDirection={"column"} rowGap={2}>
        <>
          <Text mb={5}>{t("importMessageAi", { ns: "documents" })}</Text>
          <DragDropFileView
            onUploaded={handleFileUploaded}
            processImmediately={true}
            isLoading={isLoading}
          />
        </>
        <Spacer height={35} />
        <InfiniteTable
          infiniteScroll={{
            dataLength: aiTasks.length,
            hasNextPage: aiTasksHasNextPage,
            fetchNextPage: aiTasksFetchNextPage,
          }}
          tableId="aiTasks-list"
          isLoading={aiTasksFetching}
          emptyText={t("noAiTask")}
        >
          <Thead sx={{ display: "none !important" }} width={"100%"}>
            <Tr>
              <Td />
            </Tr>
          </Thead>
          <Tbody>
            {aiTasks?.length > 0 &&
              aiTasks?.map((task) => (
                <Tr
                  key={task.id}
                  marginBottom={0}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedAiTaskId(task.id);
                    setEnableDocumentTypesForAiQuery(true);
                    if (task.status === "terminated") {
                      setShowTaskDetail(true);
                    }
                  }}
                >
                  <Td colSpan={3}>
                    <AiTaskRow
                      aiTask={task}
                      onDelete={() => {
                        onDeleteTask(task.id);
                      }}
                      onStop={() => {
                        onStopTask(task.id)
                      }}
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </InfiniteTable>
      </Flex>

      {showTaskDetail && (
        <AiTaskDetailModal
          showTaskDetail={showTaskDetail}
          title={t("uploadWithAi", { ns: "documents" })}
          onClose={() => setShowTaskDetail(false)}
          isPropagable={isPropagable}
          onConfirm={async (aiTaskDocuments: AiTaskDocumentType[],  siteIds: string[]) => {
            await onConfirmTask({
              taskId: aiTaskDetail.id,
              aiTaskDocuments: aiTaskDocuments,
              siteIds: siteIds,
            });
            setShowTaskDetail(false);
          }}
          onConfirmTaskIsLoading={onConfirmTaskIsLoading}
          aiTask={aiTaskDetail}
          aiTaskDetailFetching={aiTaskDetailFetching}
          documentTypesForAi={documentTypesForAi}
          sitesWhereToPropagate={sitesWhereToPropagate}
          documentsAiHook={documentsAiHook}
          documents={documents}
        />
      )}
    </BaseModal>
  );
};

export default ManageTaskWithAiModal;
