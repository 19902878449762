import { RepeatClockIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";
import { FaGripLines } from "react-icons/fa";
import { FaCalendarXmark } from "react-icons/fa6";
import AiTaskDocument, {
  AiTaskDocumentType,
} from "../../../../domain/entities/aiTaskDocument";
import Document from "../../../../domain/entities/document";
import DocumentType from "../../../../domain/entities/documentType";
import { formatDateString } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import CustomDateInput from "../common/CustomDateInput/CustomDateInput";
import { SearchDocumentMenu } from "./SearchDocumentMenu";

interface AiFileListProps {
  isRecognizedfile: boolean;
  file: AiTaskDocument;
  onDateChange?: (
    newDate: string,
    type: "recognized" | "unrecognized",
    notExpire: boolean,
    documentFileId?: string,
  ) => void;
  removeFile?: (id: string) => void;
  restoreFile?: (id: string) => void;
  addFileToDocumentType?: (
    newFile: AiTaskDocument,
    documentTypeId: string,
  ) => void;
  setSelectedTaskFile: (file: AiTaskDocument) => void;
  selectedTaskFile: AiTaskDocument;
  documentTypesForAi?: DocumentType[];
  documentArchived?: boolean;
  showSelectSites: boolean;
  recognizedFiles: AiTaskDocumentType[]
  documentList: Document[]
}

export const AiFileList = ({
  isRecognizedfile,
  file,
  onDateChange,
  addFileToDocumentType,
  removeFile,
  restoreFile,
  selectedTaskFile,
  setSelectedTaskFile,
  documentTypesForAi,
  documentArchived,
  showSelectSites,
  recognizedFiles,
  documentList
}: AiFileListProps) => {
  const { t } = useTranslation("documents");
  const [notExpire, setNotExpire] = useState(false);
  const type = isRecognizedfile ? "recognized" : "unrecognized";

  useEffect(() => {
    if (!formatDateString(file.expiresAt) || file.notExpire) {
      setNotExpire(true);
    }
  }, [file.expiresAt]);

  useEffect(() => {
    setSelectedTaskFile(null);
  }, [showSelectSites]);

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        p={2}
        pr={0}
        pt={2}
        key={file.id}
        onClick={(e) => {
          setSelectedTaskFile(file);
        }}
        cursor="pointer"
        color={
          selectedTaskFile?.id === file.id
            ? "white"
            : file.archived
              ? "red.100"
              : "unset"
        }
        backgroundColor={
          selectedTaskFile?.id === file.id && !file.archived
            ? COLORS.sikuroBlue
            : file.archived && selectedTaskFile?.id === file.id
              ? "red.200"
              : file.archived && selectedTaskFile?.id !== file.id
                ? "red.100"
                : "unset"
        }
        _hover={{
          bg: file.archived ? "red.200" : COLORS.lightBlue,
          color: "white",
          transition: "all 0.2s ease-in-out",
        }}
        transition="all 0.2s ease-in-out"
        borderWidth="1px"
        borderRadius="md"
      >
        <Flex alignItems="center" flex="1" minWidth="0">
          <Box width="16px" flexShrink={0}>
            <FaGripLines fontSize="16px" />
          </Box>
          <Tooltip label={file.name}>
            <Text
              isTruncated
              ml={2}
              color={
                file.archived && selectedTaskFile?.id === file.id
                  ? "white"
                  : documentArchived ||
                      (file.archived && selectedTaskFile?.id !== file.id)
                    ? "white"
                    : "black"
              }
            >
              {file.name}
            </Text>
          </Tooltip>
        </Flex>

        {!notExpire ? (
          <Flex width={120}>
            <CustomDateInput
              value={file.expiresAt ? formatDateString(file.expiresAt) : ""}
              onChange={(e) => {
                setNotExpire(false);
                onDateChange && onDateChange(e, type, false, file.id);
              }}
              name="expiresAt"
              isClearable={false}
              isDisabled={documentArchived || file.archived || showSelectSites}
            />
          </Flex>
        ) : (
          <Text fontWeight={"bold"}>
            {t("doesNotExpire", { ns: "common" })}
          </Text>
        )}
        <Tooltip
          label={t(!notExpire ? "doesNotExpire" : "disableNotExpireAi", {
            ns: "common",
          })}
        >
          <IconButton
            aria-label={"doesNotExpireAi"}
            variant="link"
            onClick={() => {
              const date = file.expiresAt
                ? new Date(file.expiresAt)
                : new Date();
              const newNotExpire = !notExpire;
              setNotExpire(newNotExpire);
              onDateChange &&
                onDateChange(
                  date.toLocaleDateString("it-IT"),
                  type,
                  newNotExpire,
                  file.id,
                );
            }}
            isDisabled={documentArchived || file.archived || showSelectSites}
            color={
              !notExpire || !file.notExpire
                ? "#718096"
                : selectedTaskFile?.id === file.id && file.notExpire
                  ? "white"
                  : COLORS.sikuroBlue
            }
          >
            <FaCalendarXmark />
          </IconButton>
        </Tooltip>

        {!isRecognizedfile && (
          <SearchDocumentMenu
            isRecognizedfile={false}
            documentTypesForAi={documentTypesForAi}
            archived={file.archived}
            handleChangeDocumentType={(doc: DocumentType) =>
              addFileToDocumentType(file, doc.id)
            }
            isDisabled={showSelectSites}
            recognizedFiles={recognizedFiles}
            documentsList={documentList}
          />
        )}

        <Flex justifyContent="right">
          <Tooltip
            label={
              file.archived ? t("restore", { ns: "common" }) : t("deleteFile")
            }
          >
            <IconButton
              aria-label={file.archived ? "restore" : "delete"}
              variant="link"
              onClick={() =>
                file.archived ? restoreFile(file.id) : removeFile(file.id)
              }
              isDisabled={documentArchived || showSelectSites}
              color={
                file.archived && selectedTaskFile?.id === file.id
                  ? "white"
                  : file.archived && selectedTaskFile?.id !== file.id
                    ? COLORS.sikuroBlue
                    : "red"
              }
            >
              {file.archived ? <RepeatClockIcon /> : <BiTrash />}
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>
      {!isRecognizedfile && <Divider orientation="horizontal" />}
    </>
  );
};
