import Badge, {MachineMetas} from "../../../../domain/entities/badge";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./badgeStyles";
import React from "react";
import machinePlaceholder from "../../../../assets/images/machine.png";
import FrontBadgeContainer from "./FrontBadgeContainer";
import { useTranslation } from "react-i18next";
import { Format } from "../../../screens/Site/SiteBadgesPrint";

type FrontBadgeMachineProps = {
  badge: Badge;
  format?: Format;
};
const FrontBadgeMachine = ({ badge, format }: FrontBadgeMachineProps) => {
  const {
    photo
  } = badge.resource.meta as MachineMetas;
  const { t } = useTranslation("badges")

  return (
    <FrontBadgeContainer badge={badge} photo={photo || machinePlaceholder} format={format}>
      <View style={[styles.badgeDetailsRow, {marginTop: 10}]}>
        <Text style={styles.detailTitle}>{t("pdfBadgeDetails.machine.resourceType")}</Text>
        <Text style={styles.text}>{badge.resource.name}</Text>
      </View>
    </FrontBadgeContainer>
  );
};
export default FrontBadgeMachine;
