import Chemical from "../../../domain/entities/chemical";
import {
  makeCreateChemical,
  makeDeleteChemical,
  makeGetChemicals,
  makeRestoreChemical,
  makeUpdateChemicals,
} from "../../../core/app/factory/chemicals/usecases";
import { ResourceListViewModel } from "../../hooks/Resources/useResourceListViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import Typology from "../../../domain/entities/typology";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import ImportInfo from "../../../domain/entities/importInfo";
import FileEntity from "../../../domain/entities/file";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import Site from "../../../domain/entities/site";
import {
  GetSitesFilter,
  LinkResourcesToSiteWarning,
} from "../../../domain/repositories/siteRepository";
import {
  makeAddResourcesToSites,
  makeGetLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";

class ChemicalsListViewModel implements ResourceListViewModel<Chemical> {
  private getChemicalsUseCase = makeGetChemicals();
  private restoreChemicalUseCase = makeRestoreChemical();
  private deleteChemicalUseCase = makeDeleteChemical();
  private crateChemicalUseCase = makeCreateChemical();
  private getTypologiesUseCase = makeGetTypologies();
  private getCustomFieldsUseCase = makeGetCustomFields();
  private uploadChemicals = makeUpdateChemicals();
  private getLinkableSitesUseCase = makeGetLinkableSites();
  private addResourcesToSitesUseCase = makeAddResourcesToSites();

  async get(
    companyId: string,
    filter?: GetResourcesFilter,
    archived?: boolean,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<PaginatedResults<Chemical>> {
    return await this.getChemicalsUseCase.Call({
      companyId,
      archived,
      filter,
      sort,
      pageParam,
    });
  }

  async delete(companyId: string, resourceId: string): Promise<void> {
    await this.deleteChemicalUseCase.Call({
      companyId,
      chemicalId: resourceId,
    });
  }

  async restore(companyId: string, resourceId: string): Promise<void> {
    await this.restoreChemicalUseCase.Call({
      companyId,
      chemicalId: resourceId,
    });
  }

  async create(
    companyId: string,
    resource: Chemical,
    photo: File,
  ): Promise<Chemical> {
    const newChemical = await this.crateChemicalUseCase.Call({
      companyId,
      chemical: resource,
      photo,
    });

    return newChemical;
  }
  async uploadResources(
    companyId: string,
    file: FileEntity,
  ): Promise<ImportInfo> {
    return await this.uploadChemicals.Call({ companyId, file });
  }
  async getTypologies(companyId: string): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeCategory.CHEMICAL,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    type: string,
    filters?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<{results: Site[] , count: number }> {
    return await this.getLinkableSitesUseCase.Call({
      companyId,
      resourceType: type,
      filters,
      sort,
      pageParam,
    });
  }
  async addResourcesToSites(
    companyId: string,
    resourceIds: string[],
    siteIds: string[],
    copyLastEvaluation: boolean,
    selectAllSites?: boolean,
    selectAllResources?: boolean,
    resourceFilters?: GetResourcesFilter,
    siteFilters?: GetSitesFilter
  ): Promise<LinkResourcesToSiteWarning> {
    return await this.addResourcesToSitesUseCase.Call({
      companyId,
      resourceType: ResourceType.CHEMICAL,
      resourceIds,
      siteIds,
      copyLastEvaluation,
      selectAllSites,
      selectAllResources,
      resourceFilters,
      siteFilters
    });
  }

  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUseCase.Call({ companyId, subject: RequirementSubject.CHEMICAL });
  }
}

export { ChemicalsListViewModel };
