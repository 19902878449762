import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import DocumentTypeRepository, { GetDocumentTypeFilters } from "../../repositories/documentTypeRepository";
import { SortMeta } from "../../entities/interfaces/paginatedResults";
import { ResourceType } from "../../../presentation/screens/Site/ResourceSelectableTable";
import { ContextFiltersType } from "../../../presentation/hooks/SmartDownload/useSmartDownloadViewModel";
import { ResultsModel } from "../../entities/ResultsModel";

type GetDocumentTypesSmartDownloadParameters = {
  companyId: string;
  context: string;
  documentByResourceType?: ResourceType
  filter?:  GetDocumentTypeFilters;
  sort?: SortMeta;
  pageParam?: number;
  contextFilters?: ContextFiltersType
};
class GetDocumentTypesSmartDownload implements UseCase<GetDocumentTypesSmartDownloadParameters, { count: number; results: DocumentType[] }> {
  constructor(private repository: DocumentTypeRepository) {}

  async Call({companyId, context, documentByResourceType,  filter,sort,  pageParam, contextFilters}: GetDocumentTypesSmartDownloadParameters): Promise<ResultsModel<DocumentType>> {
    return await this.repository.getDocumentTypesSmartDownload(
      companyId,
      context,
      documentByResourceType,
      filter,
      sort,
      pageParam,
      contextFilters
    );
  }
}

export default GetDocumentTypesSmartDownload;
