import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Worker, { WorkerStatus } from "../../../../domain/entities/worker";
import { GetSitesFilter, GetSitesWithBadgesFilter } from "../../../../domain/repositories/siteRepository";
import { updateFilterWithDelete } from "../../../../utils";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { WorkerViewModel } from "../../../viewmodels/workers/WorkerViewModel";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import {
  GetAvailableBadgesFilters,
} from "../../../../domain/repositories/badgeRepository";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";
import {CustomField} from "../../../../domain/entities/customFields";

const useWorkerViewModel = (id: string) => {
  const { companyId } = useAuth();

  const [sortResourceDocumentSites, setSortResourceDocumentSites] = useState<SortMeta>();
  const [filterResourceDocumentSites, setFilterResourceDocumentSites] =
    useState<GetSitesFilter>();

  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
  useState<GetSitesWithBadgesFilter>();
  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [resourceLinkableSitesCount, setResourceLinkableSitesCount] = useState<number>();
  const [badgeIds, setBadgeIds] = useState<string[]>([]);
  const [badgeId, setBadgeId] = useState<string>();
  const [selectAll, setSelectAll] = useState(false);
  const [documentTypeId, setDocumentTypeId] = useState<string>()
  const viewModel = useMemo(() => new WorkerViewModel(), []);
  const [act, setAct] = useState<string>();
  const [availableBadgesFilters, setAvailableBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableBadgesSort, setAvailableBadgesSort] =
    useState<SortMeta>(null);
  const [showInfoSuccess, setShowInfoSuccess] = useState<string>('');
  const [enableGetWorkerSites, setEnableGetWorkerSites] = useState<boolean>(false);
  const [enableGetWorkerSiteCollection, setEnableGetWorkerSiteCollection] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    data: worker,
    isFetching,
    refetch: getWorker,
    isLoading
  } = useQuery<Worker, Error>(
    ["worker", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const getWorkerDocumentSites = useInfiniteQuery(
    [
      "resource-document-sites",
      companyId,
      id,
      sortResourceDocumentSites,
      filterResourceDocumentSites,
      act,
      documentTypeId
    ],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add" || act === "repropagateFiles" ;
      return await viewModel.getWorkerSites(
        companyId,
        id,
        sortResourceDocumentSites,
        filterResourceDocumentSites,
        pageParam,
        includeWorkingSites,
        false,
        documentTypeId
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceDocumentSites || !!filterResourceDocumentSites
    },

  );
  const getWorkerSites = useInfiniteQuery(
    [
      "resource-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
    ],
    async ({ pageParam = 1 }) => {
      return await viewModel.getWorkerSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        false,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetWorkerSites
    },
  );

  const hasSites = async (action, documentTypeId) => {
    setAct(action);
    setDocumentTypeId(documentTypeId)
    await queryClient.invalidateQueries([
      "resource-document-sites",
      companyId,
      id,
      sortResourceDocumentSites,
      filterResourceDocumentSites,
      action,
    ]);
    const data = await getWorkerDocumentSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };
  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["worker-custom-fields", companyId],
    async () => await viewModel.getCustomFields(companyId),
  );
  const getAvailableBadgesQuery = useQuery(
    [
      "available-badges",
      companyId,
      availableBadgesFilters,
      availableBadgesSort,
    ],
    async () => {
      return await viewModel.getAvailableBadges(
        companyId,
        "workers",
        id,
        availableBadgesSort,
        availableBadgesFilters,
      );
    },
  );

  const linkBadgesToResourceMutation = useMutation(
    async () => {
      return await viewModel.linkBadgesToResource(
        companyId,
        badgeIds,
        BadgeResourceType.WORKER,
        id,
      );
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getWorkerSites.refetch();
        setBadgeIds([]);
        setShowInfoSuccess('badgeAssociatedToResource');
      },
    },
  );

  const unlinkBadgeResourceMutation = useMutation(
    async () => {
      return await viewModel.unlinkBadgeResource(companyId, badgeId);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getWorkerSites.refetch();
      },
    },
  );

  const updateMutation = useMutation(
    async ({ worker, imageFile }: { worker: Worker; imageFile?: File }) => {
      const { updatedAt, ...workerObj } = worker;
      return await viewModel.update(companyId, workerObj, imageFile);
    },
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getWorker(),
    },
  );

  const updateStatusMutation = useMutation(
    async (status: WorkerStatus) => {
      return await viewModel.setStatus(companyId, id, status);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getWorker(),
    },
  );

  const updateWorker = (worker: Worker) => {
    return updateMutation.mutateAsync({ worker });
  };

  const updateImage = (worker: Worker, imageFile?: File) => {
    return updateMutation.mutateAsync({
      worker,
      imageFile,
    });
  };

  const unlinkBadgeResource = () => {
    return unlinkBadgeResourceMutation.mutateAsync();
  };

  const linkBadgesToResource = () => {
    linkBadgesToResourceMutation.mutateAsync();
  };
  const updateStatus = (status: WorkerStatus) => {
    updateStatusMutation.mutateAsync(status);
  };
  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getResourceLinkableSites(
        companyId,
        "worker" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
      setResourceLinkableSitesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetWorkerSiteCollection
    },
  );
  const linkWorkerToSites = (siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean) => {
    const params = { siteIds, selectAll, copyLastEvaluation, filters: filterSiteCollection };
    linkWorkerToSitesMutation.mutateAsync(params);
  };
  const linkWorkerToSitesMutation = useMutation(
    async (params:{ siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean, filters?: GetSitesFilter }) => {
      return await viewModel.linkResourceToSites(companyId, id, params.siteIds, params.copyLastEvaluation, params.selectAll, params.filters);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getWorkerSites.refetch();
        setShowInfoSuccess('resourceAssociatedToSite')
      },
    },
  );

  const availableBadgesResource = getAvailableBadgesQuery.data;
  const resourceSites =
    getWorkerSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalResourceSiteCount = getWorkerSites.data?.pages?.[0]?.count ?? 0;
  const resourceDocumentSites =
    getWorkerDocumentSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalResourceDocumentSiteCount = getWorkerDocumentSites.data?.pages?.[0]?.count ?? 0;

  return {
    worker,
    getWorker,
    isFetching,
    updateImage,
    updateWorker,
    updateStatus,

    resourceDocumentSites,
    isFetchingResourceDocumentSites: getWorkerDocumentSites.isFetching,
    resourceDocumentSitesHasNextPage: getWorkerDocumentSites.hasNextPage,
    resourceDocumentSitesFetchNextPage: getWorkerDocumentSites.fetchNextPage,
    updateFilterResourceDocumentSites: (field, value) => {
      field != null &&  updateFilterWithDelete(setFilterResourceDocumentSites, field, value);
    },
    filterResourceDocumentSites,
    setFilterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,

    resourceSites,
    isFetchingSites: getWorkerSites.isFetching || getWorkerSites.isLoading,
    hasNextPage: getWorkerSites.hasNextPage,
    fetchNextPage: getWorkerSites.fetchNextPage,
    setEnableGetWorkerSites,
    updateFilterResourceSites: (field, value) => {
      field != null &&  updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    setFilterResourceSites,
    filterResourceSites,
    setSortResourceSites,
    sortResourceSites,

    hasSites,
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    setFilterSiteCollection,
    filterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      field != null && updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    siteCollectionIsLoading: getResourceLinkableSites.isLoading,
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    setEnableGetWorkerSiteCollection,
    linkWorkerToSites,
    isLinking: linkWorkerToSitesMutation.isLoading,

    setBadgeIds,
    badgeIds,
    badgeId,
    setBadgeId,
    availableBadgesResource,
    availableBadgesFilters,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesIsLoading: getAvailableBadgesQuery.isLoading,
    availableBadgesRefetch: getAvailableBadgesQuery.refetch,
    updateFilterAvailableBadge: (field, value) => {
      updateFilterWithDelete(setAvailableBadgesFilters, field, value);
    },
    linkBadgesToResource,
    linkBadgesToResourceIsLoading: linkBadgesToResourceMutation.isLoading,
    showInfoSuccess,
    setShowInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading: unlinkBadgeResourceMutation.isLoading,
    totalResourceSiteCount,
    totalResourceDocumentSiteCount,
    selectAll,
    setSelectAll,
    resourceLinkableSitesCount,
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    loading: getCustomFieldsQuery.isLoading || isLoading,
  };
};

export default useWorkerViewModel;
