import UseCase from '../../../core/app/usecase';
import SupplierRepository, { GetGlobalSupplierFilters } from '../../repositories/supplierRepository';
import Company from '../../entities/company';
import { PaginatedResults, SortMeta } from "../../entities/interfaces/paginatedResults";
import {
	ContextFiltersType,
} from "../../../presentation/hooks/SmartDownload/useSmartDownloadViewModel";
type ARGS = {
	companyId: string;
	filter?: GetGlobalSupplierFilters;
	sort?: SortMeta;
	pageParam?: number;
	contextFilters?: ContextFiltersType;
};
class GetSuppliersForDownload implements UseCase<ARGS, PaginatedResults<Company>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, filter, sort, pageParam, contextFilters }: ARGS): Promise<PaginatedResults<Company>> {
		return await this.supplierRepository.getSuppliersForDownload(companyId, filter, sort, pageParam, contextFilters);
	}
}

export default GetSuppliersForDownload;
