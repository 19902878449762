import UseCase from '../../../core/app/usecase';
import DocumentComment from '../../entities/documentComment';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	siteId?: string;
	resourceId: string;
	resourceType: DocumentTypeCategory;
	search?: string;
}

class GetResourceDocumentComments implements UseCase<Args, DocumentComment[]> {
	constructor(private repository: DocumentRepository) {}
	async Call({ companyId, siteId, resourceId, resourceType, search }: Args): Promise<DocumentComment[]> {
		return this.repository.getResourceDocumentComments(companyId, resourceId, resourceType, siteId, search);
	}
}

export default GetResourceDocumentComments;
