import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AiTask from "../../../../domain/entities/aiTask";
import { formatDateString, formatDateTime } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";

interface TaskInfoProps {
  aiTask: AiTask;
}

export const AiTaskInfo = ({ aiTask }: TaskInfoProps) => {
  const { t } = useTranslation();
  return (
    <Box p={4} pl={0}>
      <Text color={COLORS.sikuroBlue} fontWeight="bold">
       {t('taskInfo', {ns: 'documents'} )}
      </Text>
      <Flex>
       
          {t("siteRequirementsDocument.state", { ns: "documents" })}:
          <Text fontWeight={"bold"}>
            {t(`aiTaskStatus.${aiTask?.status}`, { ns: "enum" })}
            <Box
              as="span"
              height={4}
              width={4}
              backgroundColor={COLORS.lightGreen}
              borderRadius="100%"
              display="inline-block"
              ml={2}
            ></Box>
          </Text>

      </Flex>
      <Flex>
        {t("taskCreateOn", { ns: "documents" })}:{" "}
        <Text fontWeight={"bold"}>{" "}{formatDateTime(aiTask?.createdAt)}</Text>
      </Flex>
      <Flex>
        {t("taskEndedAt", { ns: "documents" })}:{" "}
        <Text fontWeight={"bold"}>{" "}{formatDateTime(aiTask?.endedAt)}</Text>
      </Flex>
    </Box>
  );
};
