export enum Permission {

  //CSE permissions
  Sites_CseShowSites = "sites:cse-show-sites",
  Worksite_CseShowWorkingSites = "worksite:cse-show-working-sites",

  Calendar_ShowExpiration = "calendar:show-expiration", // aggiungere per nascondere il calendario
  Supplier_CreateDerivedTenants = "supplier:create-derived-tenants",
  Company_AIManageFile = "company:ai",
  Company_AddBadge = "company:add-badge",
  Company_UnlinkBadgeResource = "company:unlink-badge-resource",
  Company_LinkBadgeResource = "company:link-badge-resource",
  Company_AddNewCompanyDoc = "company:add-new-company-doc",
  Company_AddReq = "company:add-req",
  Company_AddReqGroup = "company:add-req-group",
  Company_DeleteCompanyDoc = "company:delete-company-doc",
  Company_DownloadDocs = "company:download-docs",
  Company_EditCompanyDocs = "company:edit-company-docs",
  Company_EvalCompanyDocs = "company:eval-company-docs",
  Company_EditDocType = "company:edit-doc-type",
  Company_EditPreset = "company:edit-preset",
  Company_EditRecords = "company:edit-records",
  Company_EditReqGroup = "company:edit-req-group",
  Company_EditRoles = "company:edit-roles",
  Company_ImportBadge = "company:import-badge",
  Company_SetBadgeStatus = "company:set-badge-status",
  Company_SetCompanyDocExpiration = "company:set-company-doc-expiration",
  Company_ShowBadgeDetails = "company:show-badge-details",
  Dashboard_ShowExpiringCompanyOrSiteEvaluations = "dashboard:show-expiring-company-or-site-evaluations",
  Dashboard_ShowExpiringDocuments = "dashboard:show-expiring-documents",
  Dashboard_ShowExpiringCompanyDocuments = "dashboard:show-expiring-company-documents",
  Dashboard_ShowExpiringEvaluations = "dashboard:show-expiring-evaluations",
  Dashboard_ShowExpiringResourcesEvaluations = "dashboard:show-expiring-resources-evaluations",
  Dashboard_ShowInvitedSuppliers = "dashboard:show-invited-suppliers",
  Dashboard_ShowOwnedSitesCount = "dashboard:show-owned-sites-count",
  Dashboard_ShowPendingEvaluations = "dashboard:show-pending-evaluations",
  Dashboard_ShowSuppliersCount = "dashboard:show-suppliers-count",
  Dashboard_ShowUnevaluatedDocuments = "dashboard:show-unevaluated-documents",
  Dashboard_ShowWorkingSitesCount = "dashboard:show-working-sites-count",
  Resources_AddChemical = "resources:add-chemical",
  Resources_AddChemicalDoc = "resources:add-chemical-doc",
  Resources_AddMachine = "resources:add-machine",
  Resources_AddMachineDoc = "resources:add-machine-doc",
  Resources_AddTool = "resources:add-tool",
  Resources_AddToolDoc = "resources:add-tool-doc",
  Resources_AddVehicle = "resources:add-vehicle",
  Resources_AddVehicleDoc = "resources:add-vehicle-doc",
  Resources_AddWorker = "resources:add-worker",
  Resources_AddWorkerDoc = "resources:add-worker-doc",
  Resources_DeleteChemicalDoc = "resources:delete-chemical-doc",
  Resources_DeleteMachineDoc = "resources:delete-machine-doc",
  Resources_DeleteToolDoc = "resources:delete-tool-doc",
  Resources_DeleteVehicleDoc = "resources:delete-vehicle-doc",
  Resources_DeleteWorkerDoc = "resources:delete-worker-doc",
  Resources_DownloadChemicalDocs = "resources:download-chemical-docs",
  Resources_DownloadMachineDocs = "resources:download-machine-docs",
  Resources_DownloadToolDocs = "resources:download-tool-docs",
  Resources_DownloadVehicleDocs = "resources:download-vehicle-docs",
  Resources_DownloadWorkerDocs = "resources:download-worker-docs",
  Resources_EditChemicalDocs = "resources:edit-chemical-docs",
  Resources_EditChemicalTypology = "resources:edit-chemical-typology",
  Resources_EditMachineDocs = "resources:edit-machine-docs",
  Resources_EditMachineTypology = "resources:edit-machine-typology",
  Resources_EditToolDocs = "resources:edit-tool-docs",
  Resources_EditToolTypology = "resources:edit-tool-typology",
  Resources_EditVehicleDocs = "resources:edit-vehicle-docs",
  Resources_EditVehicleTypology = "resources:edit-vehicle-typology",
  Resources_EditWorkerDocs = "resources:edit-worker-docs",
  Resources_EditWorkerTypology = "resources:edit-worker-typology",
  Resources_MigrateMachineToVehicle = "resources:migrate-machine-to-ve",
  Resources_MigrateVehicleToMachine = "resources:migrate-vehicle-to-ma",
  Resources_SetChemicalDocExpiration = "resources:set-chemical-doc-expiration",
  Resources_SetChemicalState = "resources:set-chemical-state", // per ora non utilizzate
  Resources_SetMachineDocExpiration = "resources:set-machine-doc-expiration",
  Resources_SetMachineState = "resources:set-machine-state", // per ora non utilizzate
  Resources_SetToolDocExpiration = "resources:set-tool-doc-expiration",
  Resources_SetToolState = "resources:set-tool-state", // per ora non utilizzate
  Resources_SetVehicleDocExpiration = "resources:set-vehicle-doc-expiration",
  Resources_SetVehicleState = "resources:set-vehicle-state", // per ora non utilizzate
  Resources_SetWorkerDocExpiration = "resources:set-worker-doc-expiration",
  Resources_SetWorkerState = "resources:set-worker-state",
  Resources_ShowChemicalDetails = "resources:show-chemical-details",
  Resources_ShowMachineDetails = "resources:show-machine-details",
  Resources_ShowToolDetails = "resources:show-tool-details",
  Resources_ShowVehicleDetails = "resources:show-vehicle-details",
  Resources_ShowWorkerDetails = "resources:show-worker-details",
  Resources_EvalChemicalDocs = "resources:eval-chemical-docs",
  Resources_EvalMachineDocs = "resources:eval-machine-docs",
  Resources_EvalToolDocs = "resources:eval-tool-docs",
  Resources_EvalVehicleDocs = "resources:eval-vehicle-docs",
  Resources_EvalWorkerDocs = "resources:eval-worker-docs",
  Settings_EditRoles = "settings:edit-roles",
  Settings_EditUsers = "settings:edit-users",
  Settings_EditCustomFields = "settings:edit-custom-fields",
  Settings_EditWebHooks = "settings:edit-webhooks",

  Sites_AccessChemicalChat = "sites:access-chemical-chat",
  Sites_AccessMachineChat = "sites:access-machine-chat",
  Sites_AccessToolChat = "sites:access-tool-chat",
  Sites_AccessVehicleChat = "sites:access-vehicle-chat",
  Sites_AccessWorkerChat = "sites:access-worker-chat",
  Sites_AddChemical = "sites:add-chemical",
  Sites_AddMachine = "sites:add-machine",
  Sites_AddManualRecord = "sites:add-manual-record",
  Sites_AddSupplierReq = "sites:add-supplier-req",
  Sites_AddTool = "sites:add-tool",
  Sites_AddVehicle = "sites:add-vehicle",
  Sites_AddWorker = "sites:add-worker",
  Sites_ConfirmPendingEvals = "sites:confirm-pending-evals",
  Sites_CreateBadgeRecord = "sites:create-badge-record",
  Sites_DeleteBadgeRecord = "sites:delete-badge-record",
  Sites_CreatePhysicalBadgeReader = "sites:create-physical-badge-reader",
  Sites_CreateVirtualBadgeReader = "sites:create-virtual-badge-reader",
  Sites_DeleteChemicalRequirement = "sites:delete-chemical-requirement",
  Sites_DeleteGeneralDocs = "sites:delete-general-docs",
  Sites_DeleteMachineRequirement = "sites:delete-machine-requirement",
  Sites_DeleteOwnerReq = "sites:delete-owner-req",
  Sites_DeleteRequirement = "sites:delete-requirement",
  Sites_DeleteSupplierReq = "sites:delete-supplier-req",
  Sites_DeleteToolRequirement = "sites:delete-tool-requirement",
  Sites_DeleteVehicleRequirement = "sites:delete-vehicle-requirement",
  Sites_DeleteWorkerRequirement = "sites:delete-worker-requirement",
  Sites_DownloadAll = "sites:download-all",
  Sites_DownloadChemicalDocuments = "sites:download-chemical-documents",
  Sites_DownloadMachineDocuments = "sites:download-machine-documents",
  Sites_DownloadSupplierDocs = "sites:download-supplier-docs",
  Sites_DownloadToolDocuments = "sites:download-tool-documents",
  Sites_DownloadVehicleDocuments = "sites:download-vehicle-documents",
  Sites_DownloadWorkerDocuments = "sites:download-worker-documents",
  Sites_EditChemicalRequirements = "sites:edit-chemical-requirements",
  Sites_EditGeneralDocs = "sites:edit-general-docs",
  Sites_TemporaryExcludingPermission = "sites:temporary-excluding",
  Sites_EditMachineRequirements = "sites:edit-machine-requirements",
  Sites_EditOwnerReq = "sites:edit-owner-req",
  Sites_EditRecords = "sites:edit-records",
  Sites_EditRequirements = "sites:edit-requirements",
  Sites_EditRoles = "sites:edit-roles",
  Sites_EditSupplierRequrements = "sites:edit-supplier-requirements",
  Sites_EditToolRequirements = "sites:edit-tool-requirements",
  Sites_EditVehicleRequirements = "sites:edit-vehicle-requirements",
  Sites_EditWorkerRequirements = "sites:edit-worker-requirements",
  Sites_EvalOverview = "sites:eval-overview",
  Sites_EvalOwnerReq = "sites:eval-owner-req",
  Sites_EvalSupplierRequirements = "sites:eval-supplier-requirements",
  Sites_InsertGeneralDocs = "sites:insert-general-docs",
  Sites_InsertOwnerReq = "sites:insert-owner-req",
  Sites_InviteSupplier = "sites:invite-supplier",
  Sites_Manage = "sites:manage",
  Sites_ManageBadgeReaders = "sites:manage-badge-readers",
  Sites_ManageBadges = "sites:manage-badges",
  Sites_NewChemicalRequirement = "sites:new-chemical-requirement",
  Sites_NewMachineRequirement = "sites:new-machine-requirement",
  Sites_NewRequirement = "sites:new-requirement",
  Sites_NewToolRequirement = "sites:new-tool-requirement",
  Sites_NewVehicleRequirement = "sites:new-vehicle-requirement",
  Sites_NewWorkerRequirement = "sites:new-worker-requirement",
  Sites_OwnerChat = "sites:owner-chat",
  Sites_OwnerResources = "sites:owner-resources",
  Sites_RemoveChemical = "sites:remove-chemical",
  Sites_RemoveMachine = "sites:remove-machine",
  Sites_RemoveTool = "sites:remove-tool",
  Sites_RemoveVehicle = "sites:remove-vehicle",
  Sites_RemoveWorker = "sites:remove-worker",
  Sites_Reports = "sites:reports",
  Sites_SetBadgeReaderStatus = "sites:set-badge-reader-status",
  Sites_SetChemicalState = "sites:set-chemical-state", // per ora non utilizzato
  Sites_SetGlobalChemicalEval = "sites:set-global-chemical-eval",
  Sites_SetGlobalMachineEval = "sites:set-global-machine-eval",
  Sites_SetGlobalSupplierEval = "sites:set-global-supplier-eval",
  Sites_SetGlobalToolEval = "sites:set-global-tool-eval",
  Sites_SetGlobalVehicleEval = "sites:set-global-vehicle-eval",
  Sites_SetGlobalWorkerEval = "sites:set-global-worker-eval",
  Sites_SetMachineState = "sites:set-machine-state", // per ora non utilizzato
  Sites_SetOwnerGlobalEval = "sites:set-owner-global-eval",
  Sites_SetOwnerStatus = "sites:set-owner-status",
  Sites_SetStatus = "sites:set-status",
  Sites_SetSupplierStatus = "sites:set-supplier-status",
  Sites_Settings = "sites:settings",
  Sites_SetToolState = "sites:set-tool-state", // per ora non utilizzato
  Sites_SetVehicleState = "sites:set-vehicle-state", // per ora non utilizzato
  Sites_SetWorkerState = "sites:set-worker-state", // per ora non utilizzato
  Sites_ShowBadgeReaderDetails = "sites:show-badge-reader-details",
  Sites_ShowBadgeReaders = "sites:show-badge-readers",
  Sites_ShowPendingEvals = "sites:show-pending-evals",
  Sites_SupplierChat = "sites:supplier-chat",
  Sites_Guardianship = "sites:guardianship",
  Sites_ViewClockingHistoryExactly = "sites:view-clocking-history-exactly",
  Sites_ViewClockingHistoryTotal = "sites:view-clocking-history-total",
  Sites_ViewClockingHistoryRounded = "sites:view-clocking-history-rounded",
  Sites_ViewClockingHistoryDay = "sites:view-clocking-history-day",
  Sites_ReviewEvalAddFile = 'sites:review-eval-add-file', //usato solo sul FE
  Sites_ReviewEvalDeleteFile = 'sites:review-eval-delete-file', //usato solo sul FE
  Sites_ReviewEvalEditFile = 'sites:review-eval-edit-file', //usato solo sul FE
  Sites_ReviewEvalChat = 'sites:review-eval-chat', //usato solo sul FE
  Sites_ReviewEvalEvaluate = 'sites:review-eval-evaluate', //usato solo sul FE
  Sites_ReviewEvalSetExpiration = 'sites:review-eval-set-expiration', //usato solo sul FE
  Sites_ReviewEvalReport = 'sites:review-eval-report', //usato solo sul FE
  Sites_ReviewEvalDownloadAll = 'sites:review-evaluations-download-all',
  Sites_ReviewEvalDownloadFile = 'sites:review-evaluations-download-document-file',
  Supplier_DownloadAll = "supplier:download-all",
  Supplier_DownloadChemicalDocs = "supplier:download-chemical-docs",
  Supplier_DownloadMachineDocs = "supplier:download-machine-docs",
  Supplier_DownloadToolDocs = "supplier:download-tool-docs",
  Supplier_DownloadVehicleDocs = "supplier:download-vehicle-docs",
  Supplier_DownloadWorkerDocs = "supplier:download-worker-docs",
  Supplier_EvalCompanyReq = "supplier:eval-company-req",
  Supplier_Global_EditFile = "supplier:edit-file-req",
  Supplier_Global_AddFile = "supplier:add-file-req",
  Supplier_Global_DeleteFile = "supplier:delete-file-req",
  Supplier_Global_SetExpiration = "supplier:file-set-expiration",

  Supplier_EvalChemicalReq = "supplier:eval-chemical-req",
  Supplier_Global_Chemical_AddFile = "supplier:global-chemical-add-file",
  Supplier_Global_Chemical_DeleteFile = "supplier:global-chemical-delete-file",
  Supplier_Global_Chemical_Edit = "supplier:global-chemical-edit",
  Supplier_Global_Chemical_Expiration = "supplier:global-chemical-set-expiration",

  Supplier_EvalMachineReq = "supplier:eval-machine-req",
  Supplier_Global_Machine_AddFile = "supplier:global-machine-add-file",
  Supplier_Global_Machine_DeleteFile = "supplier:global-machine-delete-file",
  Supplier_Global_Machine_Edit = "supplier:global-machine-edit",
  Supplier_Global_Machine_Expiration = "supplier:global-machine-set-expiration",

  Supplier_EvalToolReq = "supplier:eval-tool-req",
  Supplier_Global_Tool_AddFile = "supplier:global-tool-add-file",
  Supplier_Global_Tool_DeleteFile = "supplier:global-tool-delete-file",
  Supplier_Global_Tool_Edit = "supplier:global-tool-edit",
  Supplier_Global_Tool_Expiration = "supplier:global-tool-set-expiration",

  Supplier_EvalVehicleReq = "supplier:eval-vehicle-req",
  Supplier_Global_Vehicle_AddFile = "supplier:global-vehicle-add-file",
  Supplier_Global_Vehicle_DeleteFile = "supplier:global-vehicle-delete-file",
  Supplier_Global_Vehicle_Edit = "supplier:global-vehicle-edit",
  Supplier_Global_Vehicle_Expiration = "supplier:global-vehicle-set-expiration",

  Supplier_EvalWorkerReq = "supplier:eval-worker-req",
  Supplier_Global_Worker_AddFile = "supplier:global-worker-add-file",
  Supplier_Global_Worker_DeleteFile = "supplier:global-worker-delete-file",
  Supplier_Global_Worker_Edit = "supplier:global-worker-edit",
  Supplier_Global_Worker_Expiration = "supplier:global-worker-set-expiration",

  // Custom WorkingSites Permissions Start
  Worksite_Reports = "worksite:reports",

  //Details
  Worksite_EvalReq = "worksite:eval-req",
  Worksite_AddFile = "worksite:add-file",
  Worksite_DeleteFile = "worksite:delete-file",
  Worksite_EditFile = "worksite:file-edit",
  Worksite_SetExpiration = "worksite:set-expiration",
  Worksite_DownloadAllFile = "worksite:download-all-file",

  //Worker
  Worksite_EvalWorkerReq = "worksite:eval-worker-req",
  Worksite_Worker_AddFile = "worksite:worker-add-file",
  Worksite_Worker_DeleteFile = "worksite:worker-delete-file",
  Worksite_Worker_EditFile = "worksite:worker-edit-file",
  Worksite_Worker_SetExpiration = "worksite:worker-set-expiration",
  //Vehicle
  Worksite_EvalVehicleReq = "worksite:eval-vehicle-req",
  Worksite_Vehicle_AddFile = "worksite:vehicle-add-file",
  Worksite_Vehicle_DeleteFile = "worksite:vehicle-delete-file",
  Worksite_Vehicle_EditFile = "worksite:vehicle-edit-file",
  Worksite_Vehicle_SetExpiration = "worksite:vehicle-set-expiration",

  //Machine
  Worksite_EvalMachineReq = "worksite:eval-machine-req",
  Worksite_Machine_AddFile = "worksite:machine-add-file",
  Worksite_Machine_DeleteFile = "worksite:machine-delete-file",
  Worksite_Machine_EditFile = "worksite:machine-edit-file",
  Worksite_Machine_SetExpiration = "worksite:machine-set-expiration",
  //Chemical
  Worksite_EvalChemicalReq = "worksite:eval-chemical-req",
  Worksite_Chemical_AddFile = "worksite:chemical-add-file",
  Worksite_Chemical_DeleteFile = "worksite:chemical-delete-file",
  Worksite_Chemical_EditFile = "worksite:chemical-edit-file",
  Worksite_Chemical_SetExpiration = "worksite:chemical-set-expiration",
  //Tool
  Worksite_EvalToolReq = "worksite:eval-tool-req",
  Worksite_Tool_AddFile = "worksite:tool-add-file",
  Worksite_Tool_DeleteFile = "worksite:tool-delete-file",
  Worksite_Tool_EditFile = "worksite:tool-edit-file",
  Worksite_Tool_SetExpiration = "worksite:tool-set-expiration",
  // Custom WorkingSites Permissions End

  Worksite_AddCompanyReq = "worksite:add-company-req",
  Worksite_AttachChemical = "worksite:attach-chemical",
  Worksite_AttachMachine = "worksite:attach-machine",
  Worksite_AttachTool = "worksite:attach-tool",
  Worksite_AttachVehicle = "worksite:attach-vehicle",
  Worksite_AttachWorker = "worksite:attach-worker",
  Worksite_ChemicalChat = "worksite:chemical-chat",
  Worksite_CompanyChat = "worksite:company-chat",
  Worksite_DeleteChemicalReq = "worksite:delete-chemical-req",
  Worksite_DeleteCompanyReq = "worksite:delete-company-req",
  Worksite_DeleteMachineReq = "worksite:delete-machine-req",
  Worksite_DeleteToolReq = "worksite:delete-tool-req",
  Worksite_DeleteVehicleReq = "worksite:delete-vehicle-req",
  Worksite_DeleteWorkerReq = "worksite:delete-worker-req",
  Worksite_DetachChemical = "worksite:detach-chemical",
  Worksite_DetachMachine = "worksite:detach-machine",
  Worksite_DetachTool = "worksite:detach-tool",
  Worksite_DetachVehicle = "worksite:detach-vehicle",
  Worksite_DetachWorker = "worksite:detach-worker",
  Worksite_DownloadChemicalDoc = "worksite:download-chemical-doc",
  Worksite_DownloadDocs = "worksite:download-docs",
  Worksite_DownloadMachineDoc = "worksite:download-machine-doc",
  Worksite_DownloadToolDoc = "worksite:download-tool-doc",
  Worksite_DownloadVehicleDoc = "worksite:download-vehicle-doc",
  Worksite_DownloadWorkerDoc = "worksite:download-worker-doc",
  Worksite_EditChemicalReq = "worksite:edit-chemical-req",
  Worksite_EditCompanyReq = "worksite:edit-company-req",
  Worksite_EditMachineReq = "worksite:edit-machine-req",
  Worksite_EditToolReq = "worksite:edit-tool-req",
  Worksite_EditVehicleReq = "worksite:edit-vehicle-req",
  Worksite_EditWorkerReq = "worksite:edit-worker-req",
  Worksite_InviteSupplier = "worksite:invite-supplier",
  Worksite_MachineChat = "worksite:machine-chat",
  Worksite_NewChemicalReq = "worksite:new-chemical-req",
  Worksite_NewMachineReq = "worksite:new-machine-req",
  Worksite_NewToolReq = "worksite:new-tool-req",
  Worksite_NewVehicleReq = "worksite:new-vehicle-req",
  Worksite_NewWorkerReq = "worksite:new-worker-req",
  Worksite_Show = "worksite:show",
  Worksite_ShowChemicalGlobalEval = "worksite:show-chemical-global-eval",
  Worksite_ShowChemicalReqEval = "worksite:show-chemical-req-eval",
  Worksite_ShowCompanyReq = "worksite:show-company-req",
  Worksite_ShowGlobalCompanyEval = "worksite:show-global-company-eval",
  Worksite_ShowMachineGlobalEval = "worksite:show-machine-global-eval",
  Worksite_ShowMachineReqEval = "worksite:show-machine-req-eval",
  Worksite_ShowRequirements = "worksite:show-requirements",
  Worksite_ShowToolGlobalEval = "worksite:show-tool-global-eval",
  Worksite_ShowToolReqEval = "worksite:show-tool-req-eval",
  Worksite_ShowVehicleGlobalEval = "worksite:show-vehicle-global-eval",
  Worksite_ShowVehicleReqEval = "worksite:show-vehicle-req-eval",
  Worksite_ShowWorkerGlobalEval = "worksite:show-worker-global-eval",
  Worksite_ShowWorkerReqEval = "worksite:show-worker-req-eval",
  Worksite_ToolChat = "worksite:tool-chat",
  Worksite_VehicleChat = "worksite:vehicle-chat",
  Worksite_WorkerChat = "worksite:worker-chat",
  Worksite_Settings = "worksite:settings",
  Worksite_AddFileSubcontractor = "worksite:add-file-subcontractor",
}

export const permissionSectionMap = {

  "sites:cse-show-sites": "cse-show-sites",
  "worksite:cse-show-working-sites": "cse-show-working-sites",
  "calendar:show-expiration": "calendar",
  "company:ai": "ai",
  "supplier:create-derived-tenants": "create-derived-tenants",
  "company:add-badge": "badge",
  "company:add-new-company-doc": "general-documents",
  "company:add-req-group": "requirements-groups",
  "company:add-req": "requirements-groups",
  "company:delete-company-doc": "general-documents",
  "company:download-docs": "general-documents",
  "company:edit-company-docs": "general-documents",
  "company:eval-company-docs": "general-documents",
  "company:edit-doc-type": "document-types",
  "company:edit-preset": "document-presets",
  "company:edit-records": "records",
  "company:edit-req-group": "requirements-groups",
  "company:edit-roles": "records",
  "company:import-badge": "badge",
  "company:set-badge-status": "badge",
  "company:unlink-badge-resource": "badge",
  "company:link-badge-resource": "badge",
  "company:set-company-doc-expiration": "general-documents",
  "company:show-badge-details": "badge",
  "dashboard:show-expiring-company-or-site-evaluations": "dashboard",
  "dashboard:show-expiring-documents": "dashboard",
  "dashboard:show-expiring-evaluations": "dashboard",
  "dashboard:show-expiring-resources-evaluations": "dashboard",
  "dashboard:show-invited-suppliers": "dashboard",
  "dashboard:show-owned-sites-count": "dashboard",
  "dashboard:show-pending-evaluations": "dashboard",
  "dashboard:show-suppliers-count": "dashboard",
  "dashboard:show-unevaluated-documents": "dashboard",
  "dashboard:show-working-sites-count": "dashboard",
  "dashboard:show-expiring-company-documents": "dashboard",
  "resources:add-chemical-doc": "chemicals",
  "resources:add-chemical": "chemicals",
  "resources:add-machine-doc": "machines",
  "resources:add-machine": "machines",
  "resources:add-tool-doc": "tools",
  "resources:add-tool": "tools",
  "resources:add-vehicle-doc": "vehicles",
  "resources:add-vehicle": "vehicles",
  "resources:add-worker-doc": "workers",
  "resources:add-worker": "workers",
  "resources:delete-chemical-doc": "chemicals",
  "resources:delete-machine-doc": "machines",
  "resources:delete-tool-doc": "tools",
  "resources:delete-vehicle-doc": "vehicles",
  "resources:delete-worker-doc": "workers",
  "resources:download-chemical-docs": "chemicals",
  "resources:download-machine-docs": "machines",

  "resources:migrate-machine-to-ve": "machines",
  "resources:migrate-vehicle-to-ma": "vehicles",

  "resources:download-tool-docs": "tools",
  "resources:download-vehicle-docs": "vehicles",
  "resources:download-worker-docs": "workers",
  "resources:edit-chemical-docs": "chemicals",
  "resources:edit-chemical-typology": "chemicals",
  "resources:edit-machine-docs": "machines",
  "resources:edit-machine-typology": "machines",
  "resources:edit-tool-docs": "tools",
  "resources:edit-tool-typology": "tools",
  "resources:edit-vehicle-docs": "vehicles",
  "resources:edit-vehicle-typology": "vehicles",
  "resources:edit-worker-docs": "workers",
  "resources:edit-worker-typology": "workers",
  "resources:eval-chemical-docs": "chemicals",
  "resources:eval-machine-docs": "machines",
  "resources:eval-tool-docs": "tools",
  "resources:eval-vehicle-docs": "vehicles",
  "resources:eval-worker-docs": "workers",
  "resources:set-chemical-doc-expiration": "chemicals",
  "resources:set-chemical-state": "chemicals",
  "resources:set-machine-doc-expiration": "machines",
  "resources:set-machine-state": "machines",
  "resources:set-tool-doc-expiration": "tools",
  "resources:set-tool-state": "tools",
  "resources:set-vehicle-doc-expiration": "vehicles",
  "resources:set-vehicle-state": "vehicles",
  "resources:set-worker-doc-expiration": "workers",
  "resources:set-worker-state": "workers",
  "resources:show-chemical-details": "chemicals",
  "resources:show-machine-details": "machines",
  "resources:show-tool-details": "tools",
  "resources:show-vehicle-details": "vehicles",
  "resources:show-worker-details": "workers",
  "settings:edit-roles": "settings",
  "settings:edit-users": "settings",
  "settings:edit-webhooks": "settings",
  "settings:edit-custom-fields": "settings",
  "sites:access-chemical-chat": "chemicals",
  "sites:access-machine-chat": "machines",
  "sites:access-tool-chat": "tools",
  "sites:access-vehicle-chat": "vehicles",
  "sites:access-worker-chat": "workers",
  "sites:add-chemical": "chemicals",
  "sites:add-machine": "machines",
  "sites:add-manual-record": "access",
  "sites:add-supplier-req": "suppliers",
  "sites:add-tool": "tools",
  "sites:add-vehicle": "vehicles",
  "sites:add-worker": "workers",
  "sites:confirm-pending-evals": "review",
  "sites:create-badge-record": "access",
  "sites:delete-badge-record": "access",
  "sites:create-physical-badge-reader": "access",
  "sites:create-virtual-badge-reader": "access",
  "sites:delete-chemical-requirement": "chemicals",
  "site:eval-req": "site-documents",
  "site:set-expiration": "site-documents",
  "sites:delete-general-docs": "site-documents",
  "sites:delete-machine-requirement": "machines",
  "sites:delete-owner-req": "eval-owner",
  "sites:delete-requirement": "requirements",
  "sites:delete-supplier-req": "suppliers",
  "sites:delete-tool-requirement": "tools",
  "sites:delete-vehicle-requirement": "vehicles",
  "sites:delete-worker-requirement": "workers",
  "sites:download-all": "eval-owner",
  "sites:download-chemical-documents": "chemicals",
  "sites:download-machine-documents": "machines",
  "sites:download-supplier-docs": "suppliers",
  "sites:download-tool-documents": "tools",
  "sites:download-vehicle-documents": "vehicles",
  "sites:download-worker-documents": "workers",
  "sites:edit-chemical-requirements": "chemicals",
  "sites:edit-general-docs": "site-documents",
  "sites:edit-machine-requirements": "machines",
  "sites:edit-owner-req": "eval-owner",
  "sites:edit-records": "records",
  "sites:edit-requirements": "requirements",
  "sites:edit-roles": "records",
  "sites:edit-supplier-requirements": "suppliers",
  "sites:edit-tool-requirements": "tools",
  "sites:edit-vehicle-requirements": "vehicles",
  "sites:edit-worker-requirements": "workers",
  "sites:eval-overview": "overview",
  "sites:eval-owner-req": "eval-owner",
  "sites:eval-supplier-requirements": "suppliers",
  "sites:insert-general-docs": "site-documents",
  "sites:insert-owner-req": "eval-owner",
  "sites:invite-supplier": "suppliers",
  "sites:manage-badge-readers": "access",
  "sites:manage-badges": "access",
  "sites:manage": "my-sites",
  "sites:new-chemical-requirement": "chemicals",
  "sites:new-machine-requirement": "machines",
  "sites:new-requirement": "requirements",
  "sites:new-tool-requirement": "tools",
  "sites:new-vehicle-requirement": "vehicles",
  "sites:new-worker-requirement": "workers",
  "sites:owner-chat": "eval-owner",
  "sites:owner-resources": "eval-owner",
  "sites:remove-chemical": "chemicals",
  "sites:remove-machine": "machines",
  "sites:remove-tool": "tools",
  "sites:remove-vehicle": "vehicles",
  "sites:remove-worker": "workers",
  "sites:reports": "reports",
  "sites:set-badge-reader-status": "access",
  "sites:set-chemical-state": "chemicals",
  "sites:set-global-chemical-eval": "chemicals",
  "sites:set-global-machine-eval": "machines",
  "sites:set-global-supplier-eval": "suppliers",
  "sites:set-global-tool-eval": "tools",
  "sites:set-global-vehicle-eval": "vehicles",
  "sites:set-global-worker-eval": "workers",
  "sites:set-machine-state": "machines",
  "sites:set-owner-global-eval": "eval-owner",
  "sites:set-owner-status": "eval-owner",
  "sites:set-status": "records",
  "sites:set-supplier-status": "suppliers",
  "sites:set-tool-state": "tools",
  "sites:set-vehicle-state": "vehicles",
  "sites:set-worker-state": "workers",
  "sites:settings": "records",
  "sites:show-badge-reader-details": "access",
  "sites:show-badge-readers": "access",
  "sites:show-pending-evals": "review",
  "sites:supplier-chat": "suppliers",
  "sites:guardianship": "guardianship",
  "sites:view-clocking-history-exactly": "access",
  "sites:view-clocking-history-total": "access",
  "sites:view-clocking-history-rounded": "access",
  "sites:view-clocking-history-day": "access",
  "sites:review-evaluations-download-all": "review",
  "sites:review-evaluations-download-document-file" : "review",

  "supplier:download-all": "records",
  "supplier:download-chemical-docs": "chemicals",
  "supplier:download-machine-docs": "machines",
  "supplier:download-tool-docs": "tools",
  "supplier:download-vehicle-docs": "vehicles",
  "supplier:download-worker-docs": "workers",
  "supplier:eval-chemical-req": "chemicals",
  "supplier:eval-company-req": "records",
  "supplier:eval-machine-req": "machines",
  "supplier:eval-tool-req": "tools",
  "supplier:eval-vehicle-req": "vehicles",
  "supplier:eval-worker-req": "workers",
  // Custom map start
  "worksite:eval-req": "company-documents",
  "worksite:add-file": "company-documents",
  "worksite:delete-file": "company-documents",
  "worksite:file-edit": "company-documents",
  "worksite:set-expiration": "company-documents",
  // "worksite:company-chat": "company-documents",
  //Custom map end
  "worksite:add-company-req": "company-documents",
  "worksite:attach-chemical": "chemicals",
  "worksite:attach-machine": "machines",
  "worksite:attach-tool": "tools",
  "worksite:attach-vehicle": "vehicles",
  "worksite:attach-worker": "workers",
  "worksite:chemical-chat": "chemicals",
  "worksite:company-chat": "company-documents",
  "worksite:delete-chemical-req": "chemicals",
  "worksite:delete-company-req": "company-documents",
  "worksite:delete-machine-req": "machines",
  "worksite:delete-tool-req": "tools",
  "worksite:delete-vehicle-req": "vehicles",
  "worksite:delete-worker-req": "workers",
  "worksite:detach-chemical": "chemicals",
  "worksite:detach-machine": "machines",
  "worksite:detach-tool": "tools",
  "worksite:detach-vehicle": "vehicles",
  "worksite:detach-worker": "workers",
  "worksite:download-chemical-doc": "chemicals",
  "worksite:download-docs": "records",
  "worksite:download-machine-doc": "machines",
  "worksite:download-tool-doc": "tools",
  "worksite:download-vehicle-doc": "vehicles",
  "worksite:download-worker-doc": "workers",
  "worksite:edit-chemical-req": "chemicals",
  "worksite:edit-company-req": "company-documents",
  "worksite:edit-machine-req": "machines",
  "worksite:edit-tool-req": "tools",
  "worksite:edit-vehicle-req": "vehicles",
  "worksite:edit-worker-req": "workers",
  "worksite:invite-supplier": "subcontractors",
  "worksite:machine-chat": "machines",
  "worksite:new-chemical-req": "chemicals",
  "worksite:new-machine-req": "machines",
  "worksite:new-tool-req": "tools",
  "worksite:new-vehicle-req": "vehicles",
  "worksite:new-worker-req": "workers",
  "worksite:show-chemical-global-eval": "chemicals",
  "worksite:show-chemical-req-eval": "chemicals",
  "worksite:show-company-req": "company-documents",
  "worksite:show-global-company-eval": "company-documents",
  "worksite:show-machine-global-eval": "machines",
  "worksite:show-machine-req-eval": "machines",
  "worksite:show-requirements": "records",
  "worksite:show-tool-global-eval": "tools",
  "worksite:show-tool-req-eval": "tools",
  "worksite:show-vehicle-global-eval": "vehicles",
  "worksite:show-vehicle-req-eval": "vehicles",
  "worksite:show-worker-global-eval": "workers",
  "worksite:show-worker-req-eval": "workers",
  "worksite:show": "worksites",
  "worksite:tool-chat": "tools",
  "worksite:vehicle-chat": "vehicles",
  "worksite:worker-chat": "workers",
  "worksite:settings": "records"
};

export const getSectionPermissions = (
  // this string represent the substring before the semicolon in the key (e.g. "worksite:tool-chat": "tools", in this case is worksite)
  main: string,
  // this string represent the substring after the semicolon (e.g. "worksite:tool-chat": "tools", in this case is tools)
  sub: string | string[] = null
) =>
  (Object.keys(permissionSectionMap) as Permission[]).filter(
    (permission) =>
      permission.startsWith(main) && (sub ? verifySub(sub, permission) : true)
  );

const verifySub = (sub: string | string[], permission: Permission) =>
  Array.isArray(sub)
    ? sub.includes(permissionSectionMap[permission])
    : permissionSectionMap[permission] === sub;
