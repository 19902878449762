import UseCase from '../../../../core/app/usecase';
import { NewResource } from '../../../../presentation/hooks/Site/useSiteResourcesViewModel';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	resources: NewResource[];
	selectAll?: boolean;
	selectedResourceNumber?: number;
	selectAllVariants?: string;
	selectAllSpecializations?: string[];
	copyLastEvaluation: boolean,
	filterSiteResources?: Record<string, string | string[]>;
};

class CreateSiteMachine implements UseCase<Parameters, boolean> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({
		companyId,
		siteId,
		resources,
		selectAll,
		selectedResourceNumber,
		selectAllVariants,
		selectAllSpecializations,
		copyLastEvaluation,
		filterSiteResources,
	}: Parameters): Promise<boolean> {
		return this.repository.createSiteMachine(
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			copyLastEvaluation,
			filterSiteResources,
		);
	}
}

export default CreateSiteMachine;
