import {Text, View} from "@react-pdf/renderer";
import React from "react";
import Badge from "../../../../domain/entities/badge";
import FrontBadgeVehicle from "./FrontBadgeVehicle";
import FrontBadgeMachine from "./FrontBadgeMachine";
import FrontBadgeWorker from "./FrontBadgeWorker";
import QrCodeBadgeBack from "./QrCodeBadgeBack";
import {styles} from "./badgeStyles";
import { Format } from "../../../screens/Site/SiteBadgesPrint";

export enum TemplateMode {
  FRONT_BACK = "frontBack",
  FRONT = "front",
  BACK = "back"
}

export type BadgeTemplateProps = {
  badge: Badge;
  mode: TemplateMode;
  format?: Format;
};

const singleComponentMap = {
  [TemplateMode.FRONT]: {
    worker: FrontBadgeWorker,
    vehicle: FrontBadgeVehicle,
    machine: FrontBadgeMachine,
  },
  [TemplateMode.BACK]: {
    worker: QrCodeBadgeBack,
    vehicle: QrCodeBadgeBack,
    machine: QrCodeBadgeBack,
  }
}

const BadgeTemplate = ({ badge, mode, format }: BadgeTemplateProps) => {
  if (badge.type === "NFC" && mode === TemplateMode.BACK) {
    return null;
  }
  const BadgeComponent = mode === TemplateMode.FRONT_BACK ?
    BadgeRowTemplate :
    singleComponentMap[mode]?.[badge.resource.type] ?? null;
  
  return BadgeComponent ?
    <BadgeComponent badge={badge} format={format} /> :
    <Text>No matching badge component found</Text>
};

const BadgeRowTemplate = ({ badge, format }: { badge: Badge, format?: Format }) => {  
  const FrontBadge = singleComponentMap.front[badge.resource.type] ?? null;
  return FrontBadge ? (
    <View
      style={
        {
          flexDirection: "row",
          alignItems: 'center',
          width: "100%",
          margin: 15,
          gap: 10
        }
      }
      wrap={false}
    >
      <FrontBadge badge={badge} format={format} />
      {badge.type === 'QR Code' ? <QrCodeBadgeBack badge={badge} format={format} /> : <View style={styles.emptyContainer} />}
    </View>
  ) : <Text>No matching badge component found</Text>;
}

export default BadgeTemplate;
