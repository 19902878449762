import { makeGetSites } from '../../../core/app/factory/sites/usecases';
import { makeGetWebHooks, makeSetWebHooks, makeGetWebHookEvents } from '../../../core/app/factory/webhook/usecases';
import { SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import Site from '../../../domain/entities/site';
import { WebHook } from '../../../domain/entities/webHook';
import { WebHookFilters } from '../../hooks/WebHook/useWebHookViewModel';
import { ResultsModel } from "../../../domain/entities/ResultsModel";

class WebHookViewModel {
	private getOwnedSitesUseCase = makeGetSites();
	private getWebHooksUseCase = makeGetWebHooks();
	private setWebHooksUseCase = makeSetWebHooks();
	private getWebHookEventsUseCase = makeGetWebHookEvents();

	constructor(private companyId: string) {}

	async getOwnedSites(): Promise<ResultsModel<Site>> {
		return await this.getOwnedSitesUseCase.Call({ companyId: this.companyId });
	}

	async getWebHooks() {
		return await this.getWebHooksUseCase.Call({ companyId: this.companyId });
	}

	async setWebHooks($webHooks: WebHook[]) {
		return await this.setWebHooksUseCase.Call({ companyId: this.companyId, webHooks: $webHooks });
	}

	async getWebHookEvents() {
		return await this.getWebHookEventsUseCase.Call({ companyId: this.companyId });
	}
}

export { WebHookViewModel };
