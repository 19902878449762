import { useAuth } from "../../../providers/Auth0JWTProvider";
import Chemical from "../../../../domain/entities/chemical";
import { ChemicalViewModel } from "../../../viewmodels/chemicals/ChemicalViewModel";
import { useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { GetSitesFilter, GetSitesWithBadgesFilter } from "../../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../../utils";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import {CustomField} from "../../../../domain/entities/customFields";

const useChemicalViewModel = (id: string) => {
  const { companyId } = useAuth();

  const [sortResourceDocumentSites, setSortResourceDocumentSites] = useState<SortMeta>();
  const [filterResourceDocumentSites, setFilterResourceDocumentSites] =
    useState<GetSitesFilter>();

  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
  useState<GetSitesWithBadgesFilter>();

  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [resourceLinkableSitesCount, setResourceLinkableSitesCount] = useState<number>();
  const [selectAll, setSelectAll] = useState(false);
  const [showInfoSuccess, setShowInfoSuccess] = useState<string>(null);
  const [documentTypeId, setDocumentTypeId] = useState<string>();
  const viewModel = useMemo(() => new ChemicalViewModel(), []);
  const [act, setAct] = useState<string>();
  const [enableGetChemicalSites, setEnableGetChemicalSites] = useState<boolean>(false);
  const [enableGetChemicalSiteColection, setEnableGetChemicalSiteCollection] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    data: chemical,
    isFetching,
    refetch: getChemical,
    isLoading
  } = useQuery<Chemical, Error>(
    ["chemical", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const updateMutation = useMutation(
    ({ chemical, imageFile }: { chemical: Chemical; imageFile?: File }) =>
      viewModel.update(companyId, chemical, imageFile),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getChemical(),
    },
  );

  const updateChemical = (chemical: Chemical) => {
    return updateMutation.mutateAsync({ chemical });
  };

  const updateImage = (chemical: Chemical, imageFile?: File) => {
    return updateMutation.mutateAsync({
      chemical,
      imageFile,
    });
  };

  const getChemicalDocumentSites = useInfiniteQuery(
    ["resource-document-sites", companyId, id, sortResourceDocumentSites, filterResourceDocumentSites, documentTypeId],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getChemicalSites(
        companyId,
        id,
        sortResourceDocumentSites,
        filterResourceDocumentSites,
        pageParam,
        includeWorkingSites,
        false,
        documentTypeId
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceDocumentSites || !!filterResourceDocumentSites,
    },
  );

  const getChemicalSites = useInfiniteQuery(
    ["resource-sites", companyId, id, sortResourceSites, filterResourceSites, ],
    async ({ pageParam = 1 }) => {
      return await viewModel.getChemicalSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        false,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetChemicalSites,
    },
  );
  const hasSites = async (action, documentTypeId) => {
    setAct(action);
    setDocumentTypeId(documentTypeId)
    await queryClient.invalidateQueries([
      "resource-document-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getChemicalDocumentSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      const {results, count} = await viewModel.getResourceLinkableSites(
        companyId,
        "chemical" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
      setResourceLinkableSitesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetChemicalSiteColection,
    },
  );
  const linkChemicalToSites = (siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean) => {
    const params = { siteIds, copyLastEvaluation, selectAll, filters: filterSiteCollection };
    linkChemicalToSitesMutation.mutateAsync(params);
  };
  const linkChemicalToSitesMutation = useMutation(
    async (params:{ siteIds: string[], copyLastEvaluation: boolean, selectAll?: boolean, filters?: GetSitesFilter }) => {
      return await viewModel.linkResourceToSites(companyId, id, params.siteIds, params.copyLastEvaluation, params.selectAll, params.filters);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getChemicalSites.refetch();
        setShowInfoSuccess('resourceAssociatedToSite')
      }
    },
  );

  const resourceSites =
    getChemicalSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalSiteCount = getChemicalSites.data?.pages?.[0]?.count ?? 0;

  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["chemical-custom-fields", companyId],
    async () => await viewModel.getCustomFields(companyId),
  );

  const resourceDocumentSites =
  getChemicalDocumentSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalResourceDocumentSiteCount = getChemicalDocumentSites.data?.pages?.[0]?.count ?? 0;

  return {
    chemical,
    getChemical,
    isFetching,
    updateImage,
    updateChemical,

    resourceDocumentSites,
    isFetchingResourceDocumentSites: getChemicalDocumentSites.isFetching,
    resourceDocumentSitesHasNextPage: getChemicalDocumentSites.hasNextPage,
    resourceDocumentSitesFetchNextPage: getChemicalDocumentSites.fetchNextPage,
    updateFilterResourceDocumentSites: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterResourceDocumentSites, field, value);
    },
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    isFetchingSites: getChemicalSites.isFetching,
    hasNextPage: getChemicalSites.hasNextPage,
    fetchNextPage: getChemicalSites.fetchNextPage,
    setEnableGetChemicalSites,
    updateFilterResourceSites: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    setFilterResourceSites,
    filterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    siteCollectionIsLoading: getResourceLinkableSites.isLoading,
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    setEnableGetChemicalSiteCollection,
    linkChemicalToSites,
    isLinking: linkChemicalToSitesMutation.isLoading,
    totalSiteCount,
    selectAll,
    setSelectAll,
    showInfoSuccess,
    setShowInfoSuccess,
    resourceLinkableSitesCount,
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    loading: getCustomFieldsQuery.isLoading || isLoading,
  };
};

export default useChemicalViewModel;
