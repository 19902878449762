import UseCase from '../../../../core/app/usecase';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';
import { SiteResourceDocument } from '../../../entities/document';

type Parameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
};

class GetSiteChemicalDocumentsRejected implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, chemicalId }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteChemicalDocumentsRejected(companyId, siteId, chemicalId );
	}
}

export default GetSiteChemicalDocumentsRejected;
