import {
  Button,
  Checkbox,
  Flex,
  Select,
  Tbody,
  Td, Text,
  Th,
  Thead,
  Tr,
  useMediaQuery
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SiteState } from "../../../domain/entities/site";
import { COLORS } from "../../assets/theme/colors";
import StateTag from "../../components/Common/StateTag";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import SkeletonTableRow from "../../components/Common/table/SkeletonTableRow";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import SiteListActionBar from "../../components/Views/Sites/SitesListActionBar";
import ContentLayout from "../../layout/ContentLayout";
import SearchInput from "../Common/SearchInput";
import CreateSiteView from "./CreateSiteView";
import useSitesListViewModel from "../../hooks/Site/useSitesListViewModel";
import SmartDownloadModal from "../../components/Common/smartDownload/SmartDownloadModal";

const SiteListView = () => {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDownload, setShowDownload] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation("sites");
  const {
    sites,
    isFetching,
    createSite,
    setSiteCreationActive,
    typologies,
    requirementsGroups,
    getSitesHasNextPage,
    getSitesFetchNextPage,
    createSiteIsLoading,
    filterSites,
    updateFilterSites,
    sort,
    setSort,
    siteCount,
    siteVariants,
    setCreationRequirementGroup,
    setSearch,
    customFields,
    setCustomFieldsEnabled
  } = useSitesListViewModel()

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const columns = [
    {
      field: "name",
      type: "text",
      label: t("name"),
      width: isMobile ? "150px" : "30%",
    },
    {
      field: "protocol",
      type: "text",
      label: t("protocol"),
      width: isMobile ? "150px" : "20%",
    },
    {
      field: "location",
      type: "text",
      label: t("location"),
      width: isMobile ? "300px" : "30%",
    },
    {
      field: "state",
      type: "text",
      label: t("state"),
      width: isMobile ? "100px" : "20%",
    },
  ] as { field: string; type: "text" | "tags"; label: string; width: string }[];

  const excludeSort = new Set(["location"]);
  const fSites = filterSites ?? {
    name: "",
    protocol: "",
    state: "active",
    location: "",
  };

  const updateFilterState = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case SiteState.ACTIVE:
        updateFilterSites("state", SiteState.ACTIVE);
        break;
      case SiteState.CLOSED:
        updateFilterSites("state", SiteState.CLOSED);
        break;
      case SiteState.SUSPENDED:
        updateFilterSites("state", SiteState.SUSPENDED);
        break;
      default:
        updateFilterSites("state", undefined);
        break;
    }
  };

  // Selection logic.
  const [selectAllVisibleSites, setSelectAllVisibleSites] =
    useState<boolean>(false);
  const [selectAllPaginatedSites, setSelectAllPaginatedSites] =
    useState<boolean>(false);
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [showClearButton, setShowClearButton] = useState(false)
  const toggleItem = (id: string) => {
    if (selectAllVisibleSites || selectAllPaginatedSites) {
      setSelectAllVisibleSites(false);
      setSelectAllPaginatedSites(false);
  }
  if (!selectedSites?.includes(id)) {
      setSelectedSites([...selectedSites, id]);
  } else {
    setSelectedSites(selectedSites?.filter((i) => i !== id));
  }
  };
  
  const toggleSelectAll = (value: boolean) => {
    setSelectAllVisibleSites(value);
    setSelectAllPaginatedSites(value);
    if (!value) {
      setSelectedSites([]);
      setSelectAllPaginatedSites(false);
      setSelectAllVisibleSites(false)
    } else {
      const visibleSiteIds = sites.map((site) => site.id);
      setSelectedSites(visibleSiteIds);
    }
  };
  
  const handleSelectionButton = () => {
    setShowClearButton(!showClearButton)
    if (showClearButton) {
      setSelectedSites([]);
      setSelectAllVisibleSites(false);
      setSelectAllPaginatedSites(false);
    } else {
      setSelectAllPaginatedSites(true);
    }
  };
  

  useEffect(() => {
    if (sites.length > 0  && (selectAllPaginatedSites || selectAllVisibleSites) && !showClearButton) {
      const visibleSiteIds = sites.map((site) => site.id);
      const uniqueIds = Array.from(new Set([...selectedSites, ...visibleSiteIds]));
      setSelectedSites(uniqueIds);
    }
  }, [sites, selectAllPaginatedSites, selectAllVisibleSites]);

  return (
    <>
      <ContentLayout
        action={
          <SiteListActionBar
            onAdd={() => {
              setSiteCreationActive(true);
              setCustomFieldsEnabled(true);
              setShowCreate(true);
            }}
            onDownload={() => setShowDownload(true)}
            isDisabled={!selectedSites?.length}
          />
        }
      >
        <Flex
          flex={1}
          h="100%"
          w="100%"
          bg="white"
          alignItems="center"
          justifyContent="start"
          gap={4}
          textAlign="center"
          flexDirection="column"
          paddingLeft={10}
          paddingRight={3}
          paddingTop={10}
          paddingBottom={10}
        >
          <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
            <SearchInput onSearch={setSearch} />
          </Flex>
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            width="100%"
            position="relative"
            overflow={"hidden"}
          >
            <InfiniteTable
              tableId={"siteList"}
              autosize
              infiniteScroll={{
                dataLength: sites?.length,
                hasNextPage: getSitesHasNextPage,
                fetchNextPage: getSitesFetchNextPage,
              }}
              isLoading={createSiteIsLoading || isFetching}
              emptyText={t("noSiteFound", { ns: "sites" })}
              showEmptyText={sites?.length === 0}
            >
              <Thead>
                <Tr bg={COLORS.table.headerBg}>
                  <Th key={"selectAllCheckbox"} width={10}>
                    <Checkbox
                      borderColor={"gray.500"}
                      isChecked={selectAllVisibleSites}
                      onChange={() => toggleSelectAll(!selectAllVisibleSites)}
                    ></Checkbox>
                  </Th>
                  {Array.isArray(columns) && columns.map((column) => (
                    <Th width={column.width} key={column.field}>
                      <TableColumnHeader
                        text={column.label}
                        filter={{
                          isActive:
                            !!fSites[column.field] &&
                            (!Array.isArray(fSites[column.field]) ||
                              !!fSites[column.field][0]),
                          component: (
                            <>
                              {column.field !== "state" ? (
                                <ColumnFilterComponent
                                  type={column.type}
                                  value={fSites[column.field]}
                                  updateFilter={(value) =>
                                    updateFilterSites(
                                      column.field,
                                      value as string | string[],
                                    )
                                  }
                                />
                              ) : (
                                <Select
                                  value={fSites?.state}
                                  onChange={updateFilterState}
                                >
                                  <option value={undefined}>
                                    {t("status.site.allStatus", {
                                      ns: "common",
                                    })}
                                  </option>
                                  <option value={SiteState.ACTIVE}>
                                    {t("status.site.active", { ns: "common" })}
                                  </option>
                                  <option value={SiteState.CLOSED}>
                                    {t("status.site.closed", { ns: "common" })}
                                  </option>
                                  <option value={SiteState.SUSPENDED}>
                                    {t("status.site.suspended", {
                                      ns: "common",
                                    })}
                                  </option>
                                </Select>
                              )}
                            </>
                          ),
                        }}
                        sort={
                          excludeSort.has(column.field)
                            ? undefined
                            : {
                              handler: (direction) =>
                                setSort({ field: column.field, direction }),
                              direction:
                                sort?.field === column.field
                                  ? sort.direction
                                  : null,
                            }
                        }
                      />
                    </Th>
                  ))}
                </Tr>
              </Thead>
              {sites?.length > 0 && (
                <Tbody
                  style={selectAllVisibleSites ? { "tableLayout" : "auto" } : { "tableLayout" : "fixed" }}
                  className={sites?.length > 0 ? "real-row" : "skeleton-row"}>
                  {selectAllVisibleSites &&  (
                    <Tr>
                      <Th colSpan={columns.length+1} backgroundColor={"gray.100"}>
                        <Flex flexDirection={'column'} alignItems={'center'}>
                          {!selectAllPaginatedSites &&
                            t("siteSelectedVisible", { ns: "sites" })}
                          {selectAllPaginatedSites &&
                            t("siteSelectedNotVisible", {
                              ns: "sites",
                              count: siteCount,
                            })}
                          {getSitesHasNextPage && (
                            <Button
                              mt="10px"
                              ml="4px"
                              colorScheme="blue"
                              variant="link"
                              onClick={() => handleSelectionButton()}
                            >
                              {t(
                                showClearButton
                                  ? "clearSelection"
                                  : "sitesSelectAll",
                                { ns: "sites" },
                              )}
                            </Button>
                          )}
                        </Flex>
                      </Th>
                    </Tr>
                  )}
                  {sites?.length > 0 ? (
                    sites.map((s) => (
                      <Tr

                        key={s?.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/sites/${s?.id}/details`)}
                      >
                        <Td width={10} onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            borderColor={"gray.500"}
                            isChecked={
                              selectedSites
                                ? selectedSites?.includes(s?.id)
                                : false
                            }
                            onChange={(e) => {
                              e.stopPropagation();
                              toggleItem(s?.id);
                            }}
                          ></Checkbox>
                        </Td>
                        <Td width={columns[0].width}>{s?.name}</Td>
                        <Td width={columns[1].width}>{s?.protocol}</Td>
                        <Td width={columns[2].width}>
                          {s?.address && <span>{s?.address}</span>}
                          {s?.city && <span>, {s?.city}</span>}
                          {s?.zipCode && <span>, {s?.zipCode}</span>}
                        </Td>
                        <Td width={columns[3].width}>
                          <StateTag value={s?.state} type="siteStatus" />
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <SkeletonTableRow
                      columnNumber={4}
                      rowNumber={3}
                      hasAction={false}
                    />
                  )}
                </Tbody>
              )}
            </InfiniteTable>
          </Flex>
        </Flex>
      </ContentLayout>

      {showCreate && (
        <CreateSiteView
          onCancel={() => {
            setSiteCreationActive(false);
            setShowCreate(false);
            setCustomFieldsEnabled(false);
            setCreationRequirementGroup(undefined);
          }}
          onConfirm={createSite}
          createSiteIsLoading={createSiteIsLoading}
          typologies={typologies}
          requirementsGroups={requirementsGroups}
          siteVariants={siteVariants}
          setCreationRequirementGroup={setCreationRequirementGroup}
          customFields={customFields.data}
        />
      )}
      {showDownload && (
        <SmartDownloadModal onClose={() => {
          setShowDownload(false);
          setSelectedSites([]);
          setSelectAllVisibleSites(false);
        }}
        selectedSites={selectedSites}
        selectedFilterSites={fSites}
        siteCount={siteCount}
        selectAllSites={selectAllPaginatedSites} />
      )}
    </>
  );
};

export default SiteListView;
