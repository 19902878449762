import { injectable } from 'tsyringe';
import { ApiService } from '../utilities/apiService';
import { ActivationRepository } from '../../domain/repositories/activationRepository';

// thie keys are used to distinguish different activation cases
enum ActivationKey {
	ActivateSupplier,
	ActivateSubcontractor
  }

@injectable()
export class ServerActivationRepository implements ActivationRepository {
	constructor(private apiService: ApiService) {}

	private async sendActivationRequest(url: string, token: string, activationKey?: ActivationKey): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
				body: JSON.stringify({ token }),
			});

			if (!response.ok){
				switch (response.status){
					case 417: {
						const error = 'invalidToken';
			 			return Promise.reject(new Error(error));
					}
					case 403: {
						let error = 'unauthorized';
						if (activationKey == ActivationKey.ActivateSupplier || 
							activationKey == ActivationKey.ActivateSubcontractor){
							error = 'unauthorizedSupplier';
						}
						return Promise.reject(new Error(error));
					}
					case 416: {
						const error = 'tokenExpired';
						return Promise.reject(new Error(error));
					}
					case 404: {
						const error = 'notFound';
						return Promise.reject(new Error(error));
					}
					case 500: {
						const error = 'serverError';
						return Promise.reject(new Error(error));
					}
					default: {
						const { message } = await response.json();
						return Promise.reject(new Error(message));
					}
				}
			}

			return Promise.resolve();
		} catch (e) {
			const message = e.message || 'Unknown error occurred';
			return Promise.reject(new Error(message));
		}
	}

	async activateUser(token: string, companyId: string): Promise<void> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/users/activate`;
		return this.sendActivationRequest(url, token);
	}

	async activateSupplier(token: string, companyId: string): Promise<void> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/join`;
		return this.sendActivationRequest(url, token, ActivationKey.ActivateSupplier);
	}

	async activateSubcontractor(token: string, companyId: string): Promise<void> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/subcontractors/join`;
		return this.sendActivationRequest(url, token, ActivationKey.ActivateSubcontractor);
	}

	async confirmSubcontractor(token: string, companyId: string, siteId: string): Promise<void> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/subcontractors/confirm`;
		return this.sendActivationRequest(url, token);
	}
	async confirmUserEmail(token: string): Promise<void> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/users/emails/confirm`;
		return this.sendActivationRequest(url, token);
	}
}
