import React, { FC} from "react";
import { useTranslation } from "react-i18next";
import { Box,Flex, Select} from "@chakra-ui/react";
import {
  SelectedAllResourcesByType,
  SelectedResourcesByType, SmartDownloadSubContextDataType, SmartDownloadSubContextType
} from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import SmartDownloadSelectCompanySubContext from "./SmartDownloadSelectCompanySubContext";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import SmartDownloadSelectSubContextDataByResourceType from "./SmartDownloadSelectSubContextDataByResourceType";

interface Props {
  subContext?:SmartDownloadSubContextType;
  setSubContext?: (subContext: SmartDownloadSubContextType) => void;
  subContextData?:SmartDownloadSubContextDataType;
  setSubContextData?: (subContextData: SmartDownloadSubContextDataType) => void;
  setSelectedResourcesByType?: (selectedResources: SelectedResourcesByType) => void;
  setSelectedAllResourcesByType?: (selectedResources: SelectedAllResourcesByType) => void;
  selectedResourceType?: ResourceType;
  setSelectedResourceType?: (selectedResourcesType: ResourceType) => void;
  supplierResources?: any;
}
const subcontextOptions = [
  {
    value: 'all',
    label: 'subContextAll'
  },
  {
    value: 'company',
    label: 'subContextCompany'
  },
  {
    value: 'resource',
    label: 'subContextResources'
  },
]
const SmartDownloadSelectSubContext: FC<Props> = ({
                                                 subContext  ,
                                                 setSubContext,
                                                 setSubContextData,
                                                 supplierResources,
                                                 setSelectedAllResourcesByType,
                                                 setSelectedResourcesByType,
                                                 selectedResourceType,
                                                 setSelectedResourceType}) => {
  const { t } = useTranslation("common");
  return (
    <Flex gap={4} flexDirection={"column"}>
      <Select placeholder={t("smartDownload.selectSubContext")} size='md' value={subContext} onChange={(e) => {
          setSubContext(e.target.value as SmartDownloadSubContextType);
      }}>
        {subcontextOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {t(`smartDownload.${option.label}`)}
          </option>
        ))}
      </Select>
      <Box>
        {subContext === 'company' && ( <SmartDownloadSelectCompanySubContext setSubContextData={setSubContextData} />)}
        {subContext === 'resource' && ( <SmartDownloadSelectSubContextDataByResourceType setSelectedDataResourcesByType={setSelectedResourcesByType}
                                                                               setSelectedAllDataResourcesByType={setSelectedAllResourcesByType}
                                                                               data={supplierResources}
                                                                               selectedResourceType={selectedResourceType}
                                                                               setSelectedResourceType={setSelectedResourceType}/>)}
      </Box>
    </Flex>
  );
};
export default SmartDownloadSelectSubContext;