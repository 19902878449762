import UseCase from "../../../../core/app/usecase";
import DocumentRepository from "../../../repositories/documentRepository";
import AiTask from "../../../entities/aiTask";


type StopAiTaskParams = {
  companyId: string;
  taskId: string,
}
class StopAiTask implements UseCase<StopAiTaskParams, void> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, taskId}: StopAiTaskParams): Promise<void> {
    return await this.repository.stopAiTasks(companyId, taskId);
  }
}

export default StopAiTask;