import UseCase from '../../../core/app/usecase';
import { GetTenantsFilters } from '../../../presentation/components/Views/common/ChangeTenantModal';
import Company from '../../entities/company';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import CompanyRepository from '../../repositories/companyRepository';

type GetCompaniesParams = {
	filter?: GetTenantsFilters;
	sort?: SortMeta;
	pageParam?: number;
	showDerived?: boolean;
};

class GetCompanies implements UseCase<null, Company[]> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call({ filter, sort, pageParam, showDerived }: GetCompaniesParams): Promise<Company[]> {
		return await this.companyRepository.getCompanies(filter, sort, pageParam, showDerived);
	}
}

export default GetCompanies;
