import { makeCompanyEmailAvailable, makeUserEmailAvailable } from "../../../core/app/factory/users/usecases";
import {
  BusinessLicenseResponse,
  BusinessOption,
  BusinessSizeResponse,
  BusinessTypeResponse,
  SignUpCompany,
  SignUpderivedCompany,
  SignUpUser
} from "../../../domain/interactors/auth/args";
import {
  makeCreateBusinessAccount,
  makeCreateBusinessDerivedAccount,
  makeGetBusinessSizes,
  makeGetBusinessTypes,
  makeGetLicenseTypes,
  makeGetVatCountries,
  makeUserAlreadyRegistered,
} from "../../../domain/interactors/auth/createBusinessAccount";
import { makeCreatePersonalAccount } from "../../../domain/interactors/auth/createPersonalAccount";
import { makePerformLogin } from "../../../domain/interactors/auth/performLogin";
import { makeRequestPasswordReset } from "../../../domain/interactors/auth/requestPasswordReset";
import { LoginResponse } from "../../../infrastructure/responses/auth";

class OnboardingViewModel {
  private createPersonalAccountUC = makeCreatePersonalAccount();
  private createBusinessAccountUC = makeCreateBusinessAccount();
  private createBusinessDerivedAccountUC = makeCreateBusinessDerivedAccount();
  private userAlreadyRegisteredUC = makeUserAlreadyRegistered();
  private requestPasswordResetUC = makeRequestPasswordReset();
  private userEmailAvailableUC = makeUserEmailAvailable();
  private userCompanyAvailableUc = makeCompanyEmailAvailable();
  private loginUC = makePerformLogin();
  private getBusinessTypesUC = makeGetBusinessTypes();
  private getVatCountriesUC = makeGetVatCountries();
  private getBusinessSizesUC = makeGetBusinessSizes();
  private getLicenseTypesUC = makeGetLicenseTypes();

  async userAlreadyRegistered(
    token: string,
    company: string,
  ): Promise<{ already_registered: string }> {
    return this.userAlreadyRegisteredUC.Call(token, company);
  }

  async createBusinessAccount(data: SignUpCompany): Promise<boolean> {
    return this.createBusinessAccountUC.Call(data);
  }

  async createBusinessDerivedAccount(data: SignUpderivedCompany): Promise<boolean> {
    return this.createBusinessDerivedAccountUC.Call(data);
  }

  async createPersonalAccount(data: SignUpUser): Promise<boolean> {
    return this.createPersonalAccountUC.Call(data);
  }

  async login(email: string, password: string): Promise<LoginResponse> {
    return this.loginUC.Call({ email, password });
  }

  async requestPasswordReset(email: string): Promise<boolean> {
    return this.requestPasswordResetUC.Call(email);
  }

  async userEmailAvailable(email: string): Promise<boolean> {
    return await this.userEmailAvailableUC.Call({ email });
  }
  async userCompanyAvailable(email: string): Promise<boolean> {
    return await this.userCompanyAvailableUc.Call({ email });
  }
  async getBusinessTypes(): Promise<BusinessTypeResponse[]> {
    return await this.getBusinessTypesUC.Call();
  }
  async getVatCountries(): Promise<BusinessOption[]> {
    return await this.getVatCountriesUC.Call();
  }
  async getBusinessSizes(): Promise<BusinessSizeResponse[]> {
    return await this.getBusinessSizesUC.Call();
  }
  async getLicenseTypes(): Promise<BusinessLicenseResponse[]> {
    return await this.getLicenseTypesUC.Call();
  }
}

export default OnboardingViewModel;
