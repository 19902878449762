import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Text,
  Flex,
  Button,
  Stack,
  Skeleton,
  ModalContent,
  ModalOverlay,
  Modal,
  Divider,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  IconButton,
  Tooltip,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Box,
  VStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import AiTask from "../../../../domain/entities/aiTask";
import LoadingView from "../../../screens/Common/LoadingView";
import AiTaskDocument, {
  AiTaskDocumentType,
} from "../../../../domain/entities/aiTaskDocument";
import DocumentType from "../../../../domain/entities/documentType";
import { COLORS } from "../../../assets/theme/colors";
import Site from "../../../../domain/entities/site";
import SelectSitesView from "../common/SelectSitesView";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import { FiInfo } from "react-icons/fi";
import { AiTaskInfo } from "./AiTaskInfo";
import { ManageAiDocuments } from "./ManageAiDocuments";
import { UseDocumentAI } from "../../../hooks/Document/useDocumentAI";
import Document from "../../../../domain/entities/document";

interface AiTaskDetailModalProps {
  title: string;
  aiTask: AiTask;
  aiTaskDetailFetching: boolean;
  onClose: () => void;
  onConfirm: (task: AiTaskDocumentType[], siteIds: string[]) => void;
  onConfirmTaskIsLoading: boolean;
  isPropagable?: boolean;
  documentTypesForAi?: DocumentType[];
  sitesWhereToPropagate?: Site[];
  showTaskDetail?: boolean;
  documentsAiHook: UseDocumentAI;
  documents: Document[]
}

const AiTaskDetailModal = ({
  title,
  aiTask,
  aiTaskDetailFetching,
  onConfirm,
  onConfirmTaskIsLoading,
  onClose,
  isPropagable,
  documentTypesForAi,
  sitesWhereToPropagate,
  showTaskDetail,
  documentsAiHook,
  documents
}: AiTaskDetailModalProps) => {
  const { t } = useTranslation("documents");

  const [showSelectSites, setShowSelectSites] = useState(false);
  const [showNoManagedModal, setShowNoManagedModal] = useState(false);

  // this property tracks the element that will be used to show the preview
  const [selectedTaskFile, setSelectedTaskFile] = useState<AiTaskDocument>();

  const [recognizedFiles, setRecognizedFiles] =
    useState<AiTaskDocumentType[]>();
  const [unrecognizedFiles, setUnrecognizedFiles] =
    useState<AiTaskDocument[]>();
  const [siteIds, setSiteIds] = useState<string[]>([]);

  useEffect(() => {
    const unrecognizedFiles = aiTask?.result
      .filter(
        (item) =>
          !item.documentTypeId || item.documentTypeId === "_unrecognized_",
      )
      .flatMap((item) =>
        (item.files || []).map((file) => ({
          ...file,
          notExpire: !item.expiresAt,
        })),
      );

    setUnrecognizedFiles(unrecognizedFiles);
    const recognizedFilesWithExpire = aiTask?.result
      .filter(
        (item) =>
          item.documentTypeId && item.documentTypeId !== "_unrecognized_",
      )
      .map((item) => ({
        ...item,
        notExpire: !item.expiresAt,
      }));
    setRecognizedFiles(recognizedFilesWithExpire);
  }, [aiTask]);

  const confirmFilteredList = () => {
    const filteredList = recognizedFiles
      .filter(
        (file) =>
          documentTypesForAi.some(
            (docType) => docType.id === file.documentTypeId,
          ) && !file.archived,
      )
      .map((document) => {
        const filteredFiles = document.files.filter((file) => !file.archived);
        return { ...document, files: filteredFiles };
      })
      .filter((document) => document.files.length > 0);
    onConfirm(filteredList, siteIds);
  };



  const handleConfirm = (isInAlert?: boolean) => {
    if (isInAlert) {
      if(hasMatchingPublic(documents, recognizedFiles)) {
      setShowSelectSites(true);
      setShowNoManagedModal(false);
      return;
      } else {
        confirmFilteredList();
      }
    }
    if (showSelectSites || !isPropagable || !hasMatchingPublic(documents, recognizedFiles)) {
      if (unrecognizedFiles.length > 0) {
        setShowNoManagedModal(true);
      } else {
        confirmFilteredList();
        setShowNoManagedModal(false);
      }
    } else {
      if (unrecognizedFiles.length > 0) {
        setShowNoManagedModal(true);
      } else {
        setShowSelectSites(true);
        confirmFilteredList();
        setShowNoManagedModal(false);
      }
    }
  };

  const totalFiles = aiTask?.result.reduce((count, item) => {
    return count + (item.files?.length || 0);
  }, 0);

  return (
    <Modal
      isOpen={showTaskDetail}
      onClose={() => {
        onClose();
      }}
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent h={"85vh"} w={"full"}>
        <Flex
          flexDirection={"row"}
          borderRadius={5}
          overflow={"clip"}
          height={"100%"}
        >
          <Flex
            justifyContent={"start"}
            alignItems={"start"}
            width={"45%"}
            flexDirection={"column"}
            h={"85vh"}
          >
            <Flex
              padding={5}
              justifyContent={"space-between"}
              alignItems={"center"}
              height={70}
              backgroundColor={COLORS.darkYellow}
              width={"100%"}
            >
              <Text fontSize={20} fontWeight={"bold"}>
                {t("uploadNFiles", { count: totalFiles, ns: "documents" })}
              </Text>
              <Flex justifyContent={"flex-end"}>
                <Popover>
                  <PopoverTrigger>
                    <Button variant={"link"}>
                      <FiInfo color={COLORS.sikuroBlue} />
                    </Button>
                  </PopoverTrigger>

                  <PopoverContent width={"auto"} p={2}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <AiTaskInfo aiTask={aiTask} />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
            </Flex>

            <Flex
              flexDirection={"column"}
              width={"100%"}
              height="100%"
              flex={1} // Take up the remaining space
            >
              {aiTaskDetailFetching ? (
                <Stack width={"100%"}>
                  <Skeleton height="130px" />
                  <Skeleton height="130px" />
                  <Skeleton height="130px" />
                  <Skeleton height="130px" />
                </Stack>
              ) : (
                <ManageAiDocuments
                  documentTypesForAi={documentTypesForAi}
                  recognizedFiles={recognizedFiles}
                  unrecognizedFiles={unrecognizedFiles}
                  setUnrecognizedFiles={setUnrecognizedFiles}
                  setRecognizedFiles={setRecognizedFiles}
                  selectedTaskFile={selectedTaskFile}
                  setSelectedTaskFile={setSelectedTaskFile}
                  onClose={onClose}
                  onConfirm={handleConfirm}
                  onConfirmTaskIsLoading={onConfirmTaskIsLoading}
                  setShowSelectSites={setShowSelectSites}
                  showSelectSites={showSelectSites}
                  isPropagable={isPropagable}
                  setShowNoManagedModal={setShowNoManagedModal}
                  documents={documents}
                />
              )}
            </Flex>
          </Flex>

          <Divider orientation="vertical" />

          <Flex
            padding={5}
            justifyContent={"center"}
            alignItems={"flex-start"}
            width={"55%"}
            height={"auto"}
            flexDirection={"column"}
          >
            {!showSelectSites && (
              <iframe src={selectedTaskFile?.uri} width="100%" height="100%" />
            )}
            {showSelectSites && (
              <Flex direction="column">
                <Box height={"calc(85vh - 70px)"}>
                  <SelectSitesView
                    autosize
                    title={t("selectSitesCreateAiDocument", {
                      ns: "documents",
                    })}
                    reminderText={t("noSiteSelected", { ns: "common" })}
                    alertText={t("siteSelectionAiAlert", { ns: "documents" })}
                    siteSelectedAction={(siteIds) => {
                      setSiteIds(siteIds);
                    }}
                    siteList={sitesWhereToPropagate}
                    includeFooterButton={false}
                    showSelectAll={true}
                    selectAllSites={documentsAiHook.selectAllSites}
                    setSelectAllSites={documentsAiHook.setSelectAllSites}
                    hasNextPage={documentsAiHook.sitesHasNextPage}
                    fetchNextPage={documentsAiHook.sitesFetchNextPage}
                    siteCount={documentsAiHook.countSites}
                    isFetchingSites={documentsAiHook.isFetchingAiSites}
                    filterResourceSites={documentsAiHook.filterAiSites}
                    updateFilterResourceSites={(field, value) => {
                      documentsAiHook.updateFilterAiSites(field, value);
                    }}
                    setSortResourceSites={documentsAiHook.setSortAiSites}
                    sortResourceSites={documentsAiHook.sortAiSites}
                  />
                </Box>
                <Flex
                  justifyContent={"flex-end"}
                  p={2}
                  width="100%"
                  bottom={0}
                  position="sticky"
                  zIndex={1}
                  borderTop="solid"
                  borderColor={COLORS.lightGrey}
                  borderTopWidth="1px"
                >
                  <Flex justifyContent="flex-end">
                    <Button
                      colorScheme="blue"
                      isLoading={onConfirmTaskIsLoading}
                      onClick={() => {
                        confirmFilteredList();
                      }}
                    >
                      {t("confirm", { ns: "common" })}
                    </Button>
                    <Button
                      marginLeft={4}
                      colorScheme="red"
                      onClick={() => setShowSelectSites(false)}
                    >
                      {t("cancel", { ns: "common" })}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </ModalContent>
      {showNoManagedModal && (
        <ConfirmAlert
          onCancel={() => setShowNoManagedModal(false)}
          title={t("warning", { ns: "common" })}
          message={t("noManagedAllFiles")}
          onConfirm={() => {
            handleConfirm(true);
          }}
          variant="warning"
        />
      )}
    </Modal>
  );
};

export default AiTaskDetailModal;

export const hasMatchingPublic = (arrayWithFlag, arrayToMatch) => {
  if(arrayToMatch){
  const idsToMatch = arrayToMatch?.map(item => item.documentTypeId);
  return arrayWithFlag.some(item => idsToMatch?.includes(item.type?.id) && item.isPublic);}
};