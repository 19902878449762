import { Identifiable } from './interfaces/identifiable';

type FileEntity = Identifiable & {
	uri?: string;
	name?: string;
	ext?: string;
	expiresAt?: Date;
	uploadDate?: Date;
	updated?: boolean;
	binaries?: FileList;
	feedback?: FileFeedback;
};

export enum FileFeedbackEnum {
	OK = "ok",
	KO = "ko",
	EMPTY = "empty"
}

export type FileFeedback = {
	type: FileFeedbackEnum;
    date?: Date;
    userName?: string;
    userid?: string;
}

export default FileEntity;
