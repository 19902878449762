import { makeSmartDownloadRepository } from "./repositories";
import GetSupplierResources from "../../../../domain/interactors/smartDownload/getSupplierResources";
import DownloadRequest from "../../../../domain/interactors/smartDownload/downloadRequest";

export const makeGetSupplierResources = () => {
	return new GetSupplierResources(makeSmartDownloadRepository());
};
export const makeDownloadRequest = () => {
	return new DownloadRequest(makeSmartDownloadRepository());
};

