import { Image, Text, View } from "@react-pdf/renderer";
import { ReactNode } from "react";
import Badge from "../../../../domain/entities/badge";
import logo from "../../../../assets/images/logoSikuro.png";
import { styles } from "./badgeStyles";
import { useTranslation } from "react-i18next";
import { Format } from "../../../screens/Site/SiteBadgesPrint";

type FrontBadgeContainerProps = {
  children: ReactNode | ReactNode[];
  badge: Badge;
  photo: string;
  format?: Format;
};
const FrontBadgeContainer = ({ children, badge, photo, format }: FrontBadgeContainerProps) => {
  const { t } = useTranslation("badges");
  return (
    <View style={[styles.badgeContainer, {border: format === Format.A4 ? "1px solid black" : ""}]}>
      <View style={styles.badgeHeaderContainer}>
        <Text style={[styles.badgeHeader, {textTransform: "uppercase"}]}>{badge.site.name}</Text>
      </View>
      <View style={styles.mainContainer}>
        <View style={styles.photoContainer}>
          <Image style={styles.photo} src={photo} />
          <View style={styles.badgeNumber}>
            <Text style={{marginLeft: 2}}>{t("pdfBadgeDetails.badgeNumber")}</Text>
            <Text style={{marginLeft: 2}}>{`${badge.code}`}</Text>
          </View>
        </View>
        <View style={styles.badgeDetailsContainer}>
          <View style={{minHeight: "70%"}}>
            {children}
          </View>
          <View style={styles.logoContainer}>
            <Image style={styles.logo} src={logo} />
          </View>
        </View>
      </View>
      <View style={styles.footerBanner}>
        <Text style={{fontSize: 6}}>{t("pdfBadgeDetails.footer")}</Text>
      </View>
    </View>
  )
};

export default FrontBadgeContainer;
