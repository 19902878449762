import UseCase from '../../../core/app/usecase';
import SmartDownloadRepository from "../../repositories/smartDownloadRepository";
import { DownloadParams } from "../../../presentation/hooks/SmartDownload/useSmartDownloadViewModel";

interface Args {
  companyId: string;
  params: DownloadParams
}

class DownloadRequest implements UseCase<Args, void> {
  private smartDownloadRepository: SmartDownloadRepository;

  constructor(smartDownloadRepository: SmartDownloadRepository) {
    this.smartDownloadRepository = smartDownloadRepository;
  }
  async Call({ companyId, params}: Args): Promise<void>{
    return await this.smartDownloadRepository.downloadRequest(companyId, params);
  }
}

export default DownloadRequest;
