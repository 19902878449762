import { FiPlus, FiSend } from "react-icons/fi";
import CreateUserView from "./CreateUserView";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Role from "../../../../domain/entities/role";
import UserStateTag from "./Components/UserStateTag";
import { COLORS } from "../../../assets/theme/colors";
import { ConfirmAlert } from "../../Common/ConfirmAlert";
import ContentLayout from "../../../layout/ContentLayout";
import ActionBar from "../../../components/Common/ActionBar";
import User, { UserState } from "../../../../domain/entities/user";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import useUsersViewModel from "../../../hooks/Users/useUsersViewModel";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import { Box, Flex, HStack, Select, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useMediaQuery } from "@chakra-ui/react";
import TableColumnHeader from "../../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../../components/Common/table/ColumnFilterComponent";
import { BsPersonCircle } from "react-icons/bs";
import { Permission } from "../../../components/Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../../components/Permissions/RenderIf";
import ActionButton from "../../../components/Common/table/ActionButton";
import { Alert } from "../../Common/Alert";
import { formatDateBasedOnLanguage } from "../../../../utils";
import SearchInput from "../../Common/SearchInput";
import InfiniteTable from "../../../components/Common/table/InfiniteTable";
import { useBreakpointValue } from '@chakra-ui/react'

const columns = [
  {
    field: "name",
    type: "text",
    label: "users.name"
  },
  {
    field: "email",
    type: "text",
    label: "users.email"
  },
  {
    field: "state",
    type: "text",
    label: "users.state",
    options: [
      { value: UserState.BLOCKED, label: "users.blocked" },
      { value: UserState.ENABLED, label: "users.enabled" },
      { value: UserState.INVITED, label: "users.invited" },
    ],
  },
  {
    field: "lastLogin",
    type: "date-range",
    label: "users.lastLogin",
  },
] as { field: string; type: "text" | "tags"; label: string; options?: [{ value: UserState, label: string }] }[];

const UsersView = () => {
  // Hooks.
  const navigate = useNavigate();
  const { t } = useTranslation("settings");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    users,
    fetchNextPage,
    hasNextPage,
    isLoading,
    createUser,
    deleteUser,
    renewInvitation,
    messageInvitation,
    setMessageInvitation,
    userAlreadyInvited,
    setUserAlreadyInvited,
    setSearch,
    filters,
    updateFilters,
    sort,
    setSort,
  } = useUsersViewModel();

  const columnWidth = useBreakpointValue(
    {
      base: 'auto',
      md: '200px',
    }
  )
  // Local state.
  const [deleteTarget, setDeleteTarget] = useState<User | undefined>();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showAskConfirmModal, setShowAskConfirmModal] = useState<boolean>();
  const [invitationToken, setInvitationToken] = useState<string>();

  // Helper functions.
  const cancelCreate = () => setShowCreate(false);
  const onCreate = (name: string, email: string, role: Role) => {
    createUser(name, email, role, cancelCreate);
  };

  const cancelDelete = () => setDeleteTarget(undefined);
  const askDelete = (user: User) => setDeleteTarget(user);
  const onDelete = () => deleteUser(deleteTarget.id, cancelDelete);

  const canDeleteAndEdit = useHasPermissions([Permission.Settings_EditUsers]);

  const formatLastLogin = (source: string | Date): string => {
    if (!source) {
      return "-";
    }

    if (typeof source === "string") {
      return formatDateBasedOnLanguage(source, true);
    }

    return formatDateBasedOnLanguage(source, true);
  };

  const updateFilterState = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case UserState.BLOCKED:
        updateFilters("state",  UserState.BLOCKED );
        break;
      case UserState.ENABLED:
        updateFilters("state", UserState.ENABLED );
        break;
      case UserState.INVITED:
        updateFilters("state", UserState.INVITED );
        break;
      default:
        updateFilters("state", undefined );
        break;
    }
  };

  return (
    <>
      <ContentLayout
        action={
          <RenderIf permissions={[Permission.Settings_EditUsers]}>
            <ActionBar>
              <ActionBarItem
                onClick={() => setShowCreate(true)}
                icon={FiPlus}
                description={t("users.add")}
              />
            </ActionBar>
          </RenderIf>
        }
      >
        <Flex
          flex={1}
          h="100%"
          width={isMobile ? "900px" : "100%"}
          padding={10}
          textAlign="center"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Flex justifyContent={"space-between"} alignItems="center" w={"100%"}>
            <Box textAlign={"start"}>
              <Text
                textColor={COLORS.sikuroBlue}
                fontSize={20}
                fontWeight={"bold"}
              >
                {t("users.title")}
              </Text>
              <Text fontSize={16} color="black" fontWeight={"normal"}>
                {t("users.subtitle")}
              </Text>
            </Box>
            <SearchInput onSearch={setSearch} />
          </Flex>
          {
            // No user available.
            users?.length <= 0 && !isLoading && (
              <Text marginBottom={4} fontWeight="bold">
                {t("users.noUserFound")}
              </Text>
            )
          }
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            width="100%"
            position="relative"
            overflow={"auto"}
            marginTop={3}
            minHeight={"50vh"}
          >
            <InfiniteTable
              tableId="users-table"
              emptyText={t("noUsers", { ns: "settings" })}
              showEmptyText={users?.length === 0}
              isLoading={isLoading}
              infiniteScroll={{
                dataLength: users?.length,
                fetchNextPage: fetchNextPage,
                hasNextPage: hasNextPage
              }}
            >
              <Thead bg={"gray.300"}>
                <Tr>
                  {columns.map((column, index) =>
                    (<Th key={column.field} w={columnWidth}>
                      <TableColumnHeader
                        text={t(column.label)}
                        sort= {{
                            handler: (direction) => {
                              setSort({
                                field: column.field,
                                direction
                              });
                            },
                            direction:
                              sort &&
                              sort.field === column.field
                                ? sort.direction
                                : null,
                          }}
                        filter={{
                          isActive:
                            !!filters[column.field] &&
                            (!Array.isArray(filters[column.field]) ||
                              !!filters[column.field][0]),
                          component: (
                            <>
                              {column.field !== "state" ? (
                                <ColumnFilterComponent
                                  type={column.type}
                                  value={filters[column.field]}
                                  updateFilter={(value) =>
                                    updateFilters(
                                      column.field,
                                      value as string | string[],
                                    )
                                  }
                                />
                              ) : (
                                <Select
                                  value={filters?.state}
                                  onChange={updateFilterState}
                                >
                                  <option value={undefined}>
                                    {t("status.site.allStatus", {
                                      ns: "common"
                                    })}
                                  </option>
                                  {column.options.map(opt =>
                                    <option value={opt.value} key={opt.value}>
                                      {t(opt.label, {
                                        ns: "settings"
                                      })}
                                    </option> )
                                  }
                                </Select>
                              )}
                            </>
                          ),
                        }}
                      />
                    </Th>)
                  )}
                  <Th w={20} />
                </Tr>
              </Thead>
              <Tbody>
                {users &&
                  users?.map((user) => (
                    <Tr
                      key={user.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        canDeleteAndEdit &&
                          navigate(`/settings/access/users/${user.id}`);
                      }}
                    >
                      <Td w={columnWidth}>
                        <HStack gap={2}>
                          {user?.isCurrentUser && (
                            <div>
                              <BsPersonCircle />
                            </div>
                          )}
                          <div>{user.name}</div>
                        </HStack>
                      </Td>
                      <Td w={columnWidth}>{user.email}</Td>
                      <Td w={columnWidth}>
                        <UserStateTag state={user.state} />
                      </Td>
                      <Td w={columnWidth}>{formatLastLogin(user.lastLogin)}</Td>
                      <Td w={20}>
                        {user.invitationToken && (
                          <Tooltip
                            label={t("renewInvitation")}
                            aria-label={t("renewInvitation")}
                            placement="bottom"
                          >
                            <span>
                              <ActionButton
                                aria-label="send-reminder"
                                icon={<FiSend />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setInvitationToken(user.invitationToken),
                                    setShowAskConfirmModal(true);
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                        {canDeleteAndEdit &&
                          !(user?.role?.name === "owner") &&
                          !user.isCurrentUser && (
                            <DeleteButton
                              aria-label="delete-role"
                              onClick={(e) => {
                                e.stopPropagation();
                                askDelete(user);
                              }}
                            />
                          )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </InfiniteTable>
          </Flex>
        </Flex>
      </ContentLayout>

      {
        // Upsert modal view.
        showCreate && (
          <CreateUserView onCancel={cancelCreate} onCreate={onCreate} />
        )
      }

      {userAlreadyInvited && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("userAlreadyOnTheList", { ns: "errors" })}
          variant="info"
          onClose={() => {
            setUserAlreadyInvited(false);
          }}
        />
      )}

      {messageInvitation && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={
            messageInvitation === "success"
              ? t("renewalInvitationSuccess")
              : t(messageInvitation, { ns: "errors" })
          }
          variant="info"
          onClose={() => {
            setMessageInvitation(undefined);
          }}
        />
      )}

      {showAskConfirmModal && (
        <ConfirmAlert
          onCancel={() => setShowAskConfirmModal(false)}
          onConfirm={() => {
            renewInvitation(invitationToken), setShowAskConfirmModal(false);
          }}
          title={t("warning", { ns: "common" })}
          variant={"question"}
          message={t("users.reminderUser", { ns: "settings" })}
        />
      )}

      {
        // Confirm delete user alert.
        deleteTarget && (
          <ConfirmAlert
            variant="warning"
            onConfirm={onDelete}
            onCancel={cancelDelete}
            title={t("warning", { ns: "common" })}
            message={t("users.confirmDeleteUser?", {
              name: deleteTarget?.name,
              email: deleteTarget?.email,
            })}
          />
        )
      }
    </>
  );
};

export default UsersView;
