import UseCase from "../../../../core/app/usecase";
import { AiTaskSectionCode } from "../../../entities/aiTaskSectionCode";
import DocumentRepository from "../../../repositories/documentRepository";
import Site from "../../../entities/site";
import { SortMeta } from "../../../entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../repositories/siteRepository";


type GetAiTaskSitesParams = {
  companyId: string;
  sectionCode: AiTaskSectionCode;
  taskId: string;
  sortSites: SortMeta;
  filterSites: GetSitesFilter;
  pageParam?: number;
}

/**
 * Returns the list of all the existing Document.
 */
class GetAiTaskSites implements UseCase<GetAiTaskSitesParams,{results: Site[], count: number}> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, taskId, sectionCode, sortSites, filterSites, pageParam}: GetAiTaskSitesParams): Promise<{results: Site[], count: number}> {
    return await this.repository.getAiTaskSites(companyId, taskId, sectionCode, sortSites, filterSites, pageParam);
  }
}

export default GetAiTaskSites;