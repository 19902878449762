import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  InputGroup,
  InputRightAddon,
  RadioProps,
  Select,
  Text,
  Tooltip,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { compareAsc, parse, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClear } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import { formatDateBasedOnLanguage, getDateFormat } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import CustomInputDate from "../Views/common/CustomDateInput/CustomDateInput";
import { DateCalculationType } from "../Views/common/DocumentExpirationDate";

type EvaluationToggleProps = {
  status: string;
  onChange: (
    status: ResourceEvaluationState | ResourceDocumentEvaluationState,
  ) => void;
  type: "resource" | "document";
  documentDuration?: number;
  validity?: Date;
  graceDays?: number;
  disabled?: boolean;
  updateValidity?: (
    newValidity: string,
    clear?: boolean,
    noExpiration?: boolean,
  ) => void;
  showExpirationDate?: boolean;
};

const STATES_MAP = {
  resource: {
    [ResourceEvaluationState.ELIGIBLE]: COLORS.sikuroBlue,
    [ResourceEvaluationState.ELIGIBLE_WITH_INTEGRATION]: COLORS.darkYellow,
    [ResourceEvaluationState.NOT_ELIGIBLE]: "red",
  },
  document: {
    [ResourceDocumentEvaluationState.AVAILABLE]: COLORS.sikuroBlue,
    [ResourceDocumentEvaluationState.NOT_APPLICABLE]: COLORS.darkYellow,
    [ResourceDocumentEvaluationState.NOT_AVAILABLE]: "red",
  },
};

const ResourceEvaluationToggle = ({
  status,
  onChange,
  type,
  documentDuration,
  validity,
  graceDays,
  updateValidity,
  disabled,
  showExpirationDate = true,
}: EvaluationToggleProps) => {
  const { t } = useTranslation("requirements");
  const options = Object.keys(STATES_MAP[type]);
  const [docValidity, setDocValidity] = useState<string>();
  const [newValidity, setNewValidity] = useState<string>(validity?.toString());
  const [showUpdateValidity, setShowUpdateValidity] = useState<boolean>(false);
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [invalidDate, setInvalidDate] = useState<string>();
  const [expirationDateType, setExpirationDateType] =
    useState<DateCalculationType>(DateCalculationType.MANUAL);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    value: status ?? "unevaluated",
    onChange,
  });
  const group = getRootProps();

  const handleValidityChange = () => {
    const newDate = parse(newValidity, getDateFormat(), new Date());

    const newEvaluationExpiration = dateTypeCalculation(newDate);
    if (newEvaluationExpiration !== validity && !showErrorDate) {
      setShowUpdateValidity(false);
      updateValidity(
        newEvaluationExpiration.toLocaleDateString(),
        false,
        expirationDateType === DateCalculationType.NOEXPIRATION,
      );
      setShowErrorDate(false);
    }
    return;
  };

  const dateTypeCalculation = (val: Date) => {
    const date = new Date(val);
    if (
      expirationDateType === DateCalculationType.ISSUANCE &&
      documentDuration
    ) {
      date.setMonth(date.getMonth() + documentDuration);
    }
    return date;
  };

  useEffect(() => {
    setDocValidity(
      validity
        ? t("validity", { date: formatDateBasedOnLanguage(validity) })
        : null,
    );
  }, [t, validity]);

  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "10px",
        borderColor: "gray.300",
      }}
      width={"fit-content"}
    >
      <HStack {...group} p={2}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard
              key={value}
              evaluationType={type}
              value={value}
              disabled={disabled}
              {...radio}
            />
          );
        })}
      </HStack>
      {showExpirationDate && (
        <Box
          fontSize="14px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          padding={2}
        >
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {validity && (
              <>
                {compareAsc(new Date(validity), new Date()) === -1 ? (
                  <Box px={2}>
                    {t("evaluationExpired")}{" "}
                    {formatDateBasedOnLanguage(validity)}
                  </Box>
                ) : (
                  <Box px={2}>{docValidity}</Box>
                )
                }
                {graceDays && (
                  <Tooltip
                    label={t("graceDaysCount", {
                      ns: "requirements",
                      count: graceDays,
                    })}
                  >
                    <Flex gap={1} alignItems="center">
                      <span>{` +[${graceDays}] `}</span>
                    </Flex>
                  </Tooltip>
                )}
              </>
            )}
            {!validity && (
              <Box px={2}>
                {t(status ? "noEvaluationExpiration" : "doesNotExpire")}
              </Box>
            )}
            {status === "available" && (
              <span>
                <Tooltip label={t("updateValidity", { ns: "requirements" })}>
                  <IconButton
                    aria-label="Update validity"
                    fontSize="10px"
                    height={"1rem"}
                    onClick={() =>
                      setShowUpdateValidity((previousShow) => !previousShow)
                    }
                    icon={<CalendarIcon />}
                    background={"transparent"}
                    _hover={{ background: "transparent" }}
                  />
                </Tooltip>
              </span>
            )}
          </Box>
          {showUpdateValidity && (
            <>
              <Select
                value={expirationDateType}
                onChange={(e) => {
                  setExpirationDateType(e.target.value as DateCalculationType);
                  if (
                    (e.target.value as DateCalculationType) ===
                    DateCalculationType.NOEXPIRATION
                  ) {
                    setNewValidity(null);
                  }
                }}
              >
                {documentDuration && (
                  <option value={DateCalculationType.ISSUANCE}>
                    {t("calculateExpiresAt", { ns: "documents" })}{" "}
                    {t("addingDocumentTypeDuration", {
                      ns: "documents",
                      duration: documentDuration,
                    })}
                  </option>
                )}
                <option value={DateCalculationType.MANUAL}>
                  {t("insertManually", { ns: "documents" })}
                </option>
                <option value={DateCalculationType.NOEXPIRATION}>
                  {t("noEvaluationExpiration", { ns: "requirements" })}
                </option>
              </Select>
              <InputGroup
                size="sm"
                display={"flex"}
                alignItems={"start"}
                mt={1}
              >
                <CustomInputDate
                  value={newValidity}
                  onChange={setNewValidity}
                  minDate={
                    expirationDateType !== DateCalculationType.ISSUANCE
                      ? startOfDay(new Date())
                      : null
                  }
                  maxDate={new Date("9999-12-31")}
                  isClearable={false}
                  hasError={setShowErrorDate}
                  setValue={setInvalidDate}
                  isDisabled={
                    expirationDateType === DateCalculationType.NOEXPIRATION
                  }
                />
                <InputRightAddon
                  height={"2.5rem"}
                  cursor={newValidity && !showErrorDate ? "pointer" : "unset"}
                  color={
                    (newValidity &&
                      newValidity !== validity?.toString() &&
                      !showErrorDate) ||
                    expirationDateType === DateCalculationType.NOEXPIRATION
                      ? "black"
                      : "gray.200"
                  }
                  onClick={handleValidityChange}
                >
                  <FaCheck />
                </InputRightAddon>
                <InputRightAddon
                  height={"2.5rem"}
                  onClick={() => {
                    setShowUpdateValidity(false);
                    setNewValidity(null);
                    updateValidity(null, true);
                    setShowErrorDate(false);
                  }}
                >
                  <AiOutlineClear />
                </InputRightAddon>
              </InputGroup>
              {showErrorDate && (
                <Text
                  color={"#E53E3E"}
                  fontSize="0.875rem"
                  py="2"
                  pl={4}
                  lineHeight={"normal"}
                >
                  {t("invalidDateError", {
                    ns: "common",
                    date: invalidDate,
                  })}
                </Text>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

function RadioCard(
  props: {
    disabled?: boolean;
    value: string;
    evaluationType: "resource" | "document";
  } & RadioProps,
) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} disabled={props.disabled} />
      <EvaluationButton
        checkbox={checkbox}
        value={props.value}
        disabled={props.disabled}
        evaluationType={props.evaluationType}
      />
    </Box>
  );
}

export const EvaluationButton = (props: {
  disabled?: boolean;
  value: string;
  evaluationType: "resource" | "document";
  checkbox?: object;
}) => {
  const { t } = useTranslation();

  const getDocumentsEvaluationBackgroundColor = (value: string) => {
    switch (value) {
      case ResourceEvaluationState.ELIGIBLE:
      case ResourceDocumentEvaluationState.AVAILABLE:
        return COLORS.sikuroBlue;
      case ResourceEvaluationState.ELIGIBLE_WITH_INTEGRATION:
      case ResourceDocumentEvaluationState.NOT_APPLICABLE:
        return COLORS.darkYellow;
      case ResourceEvaluationState.NOT_ELIGIBLE:
      case ResourceDocumentEvaluationState.NOT_AVAILABLE:
        return "red";
      default:
        return "white";
    }
  };

  const getDocumentsEvaluationTextColor = (value: string) => {
    switch (value) {
      case ResourceEvaluationState.ELIGIBLE:
      case ResourceDocumentEvaluationState.AVAILABLE:
        return "white";
      case ResourceEvaluationState.ELIGIBLE_WITH_INTEGRATION:
      case ResourceDocumentEvaluationState.NOT_APPLICABLE:
        return "black";
      case ResourceEvaluationState.NOT_ELIGIBLE:
      case ResourceDocumentEvaluationState.NOT_AVAILABLE:
        return "white";
      default:
        return "black";
    }
  };

  return (
    <Flex
      {...(props.checkbox ?? { "data-checked": false })}
      cursor={!props.disabled && props.checkbox ? "pointer" : "default"}
      borderWidth="1px"
      borderRadius={props.evaluationType === "resource" ? "10px" : "5px"}
      _checked={{
        bg: getDocumentsEvaluationBackgroundColor(props.value),
        color: getDocumentsEvaluationTextColor(props.value),
      }}
      _focus={{
        boxShadow: "outline",
      }}
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      fontWeight={500}
      lineHeight={props.evaluationType === "resource" ? "16px" : "10px"}
      fontSize={props.evaluationType === "resource" ? "14px" : "10px"}
      width={props.evaluationType === "resource" ? "125px" : "96px"}
      height={props.evaluationType === "resource" ? "48px" : "28px"}
    >
      {props.value
        ? t(`evaluation.${props.evaluationType}.${props.value}`, {
            ns: "common",
          })
        : "-"}
    </Flex>
  );
};

export default ResourceEvaluationToggle;
