import UseCase from '../../../core/app/usecase';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { DashboardReport } from '../../entities/dasboardExpiringSupplierEvaluation';
import DashboardRepository, { GetSupplierEvaluationsFilter } from '../../repositories/dashboardRepository';
import { DashboardTopic } from "../../entities/dashboardTopic";

interface Args {
  days?: number;
  sort?: SortMeta;
  companyId: string;
  topic: DashboardTopic;
  pageParam?: number;
  filter?: GetSupplierEvaluationsFilter;
}

class GetDashboardReportByTopic implements UseCase<Args, DashboardReport[]> {
  private dashboardRepository: DashboardRepository;

  constructor(dashboardRepository: DashboardRepository) {
    this.dashboardRepository = dashboardRepository;
  }

  async Call({ companyId, topic, pageParam, sort, filter, days }: Args): Promise<DashboardReport[]> {
    return await this.dashboardRepository.getDashboardReportByTopic(companyId, topic, pageParam, sort, filter, days);
  }
}

export default GetDashboardReportByTopic;
