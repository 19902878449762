import UseCase from "../../../../core/app/usecase";
import { AiTaskDocumentType } from "../../../entities/aiTaskDocument";
import DocumentRepository from "../../../repositories/documentRepository";


type ConfirmAiTaskParams = {
  companyId: string;
  taskId: string;
  aiTaskDocuments: AiTaskDocumentType[];
  siteIds: string[];
  selectAll: boolean
}

/**
 * Returns the list of all the existing Document.
 */
class ConfirmAiTask implements UseCase<ConfirmAiTaskParams, void> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, taskId, aiTaskDocuments, siteIds, selectAll}: ConfirmAiTaskParams): Promise<void> {
    return await this.repository.confirmAiTask(companyId, taskId, aiTaskDocuments, siteIds, selectAll);
  }
}

export default ConfirmAiTask;