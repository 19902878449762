import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

import React from "react";
import Tag from "../../../domain/entities/tag";
import TagBadge, { TagType } from "./TagBadge";

type Props = {
  tags: Tag[];
  onDelete?: (tag: Tag) => void;
  onUpdate?: (tag: TagType) => void;
  editable?: boolean;
  ref?: any;
};
const TagMoreButton = ({ tags, onDelete, onUpdate, editable, ref }: Props) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="comment"
          variant="ghost"
          sx={{ color: "#767676" }}
          fill="#767676"
          minWidth="22px"
          height={6}
        >
          <TagBadge tag={{ id: "", name: "..." }} />
        </IconButton>
      </PopoverTrigger>
      <Portal>
      <div
            ref={ref}
            style={{
              position: "absolute",

              zIndex: 2500,
            }}
          >
      <PopoverContent position={"relative"} zIndex={9999}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex gap={2} flexWrap={"wrap"}>
            {Array.isArray(tags) &&
              tags?.map((variant) => {
                return (
                  <TagBadge
                    key={variant.id}
                    tag={variant}
                    onDelete={onDelete ? () => onDelete(variant) : undefined}
                    onUpdate={onUpdate}
                    editable={editable && !variant.is_default}
                  />
                );
              })}
          </Flex>
        </PopoverBody>
      </PopoverContent>
      </div>
      </Portal>
    </Popover>
  );
};

export default TagMoreButton;
