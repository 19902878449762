import Site from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import SiteRepository, { GetSitesFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import Permission from '../../entities/permission';
import { ResultsModel } from "../../entities/ResultsModel";

interface Args {
	companyId: string;
	filter?: GetSitesFilter;
	sort?: SortMeta;
	pageParam?: number;
	withPermissions?: boolean;
}

class GetSites implements UseCase<Args, ResultsModel<Site>> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, filter, sort, pageParam, withPermissions }: Args): Promise<{ results: Site[], count: number }> {
		return await this.siteRepository.getSites(companyId, filter, sort, pageParam, withPermissions);
	}
}

export default GetSites;
