import {AiOutlinePrinter} from "react-icons/ai";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Badge from "../../../domain/entities/badge";
import JSZip from "jszip";
import {Document, Page, pdf, View} from "@react-pdf/renderer";
import BadgeTemplate, {TemplateMode} from "../../components/Common/PDF/BadgeTemplate";
import {useAuth} from "../../providers/Auth0JWTProvider";
import BaseModal from "../../components/Common/alerts/BaseModal";
import { Box, Checkbox, Flex, Text } from "@chakra-ui/react";
import EnumSelectField from "../../components/Common/inputs/EnumSelectField";
import ActionBarItem from "../../components/Common/ActionBarItem";
import Site from "../../../domain/entities/site";

type SiteBadgesPrintProps = {
  selectedBadges: string[];
  siteBadges: any[];
  resetSelectedBadges: () => void;
  printAllBadges: boolean
  print: {
    allBadges: any[];
    allBadgesLoading: boolean
    printModalOpen: boolean
    setPrintModalOpen: (value: boolean) => void
  };
  getSiteData?: {
    data: Site;
    loading: boolean;
    setHasSiteImage: (value:boolean) => void;
    hasSiteImage: boolean
  };
}

export enum Format {
  A4 = "a4",
  CARD = "card"
}
enum Output {
  ZIP = "zip",
  PDF = "pdf"
}

type PrintOptions = {
  format: Format;
  output: Output;
  mode: TemplateMode;
  hasSiteImage?: boolean;
}

const defaultOptions = {
  format: Format.A4,
  output: Output.PDF,
  mode: TemplateMode.FRONT_BACK,
  hasSiteImage: false
}

const SiteBadgesPrint = ({ siteBadges, selectedBadges, resetSelectedBadges, printAllBadges, print: {allBadges, allBadgesLoading, setPrintModalOpen, printModalOpen}, getSiteData }: SiteBadgesPrintProps) => {
  const { t } = useTranslation("badges");
  const { token: { access_token }} = useAuth()
  const [printOptions, setPrintOptions] = useState<PrintOptions>(defaultOptions);
  const [loading, setLoading] = useState(false);
  const [includeSiteImage, setIncludeSiteImage] = useState(false);

  const getPrintBadges = () => (printAllBadges ? allBadges : siteBadges).filter((badge) =>
    (printAllBadges || selectedBadges.includes(badge.id)) &&
    badge.resource &&
    badge.resource?.name
  )

  const handleOpen = () => {
    setPrintModalOpen(true);
    getSiteData?.setHasSiteImage(true);
  }

  const downloadZip = async (badges) => {
    if (Array.isArray(badges) && badges.length > 0) {
      const zip = new JSZip();
      for (const badge of badges) {
        if (badge.type !== "NFC" || printOptions.mode !== TemplateMode.BACK) {
          zip.file(`${badge.code}_${badge.resource.name}.pdf`, await generatePdfBlob([badge]));
        }
      }
      await zip.generateAsync({ type: "blob" }).then((content) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "site_badges.zip";
        link.click();
      });
    }
  };

  const downloadPdf = async (badges) => {
    if (Array.isArray(badges) && badges.length > 0) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(await generatePdfBlob(badges));
      window.open(link.href);
    }
  };

  const generatePdfBlob = async (badges: Badge[]) => {
    const asPdf = pdf();
    asPdf.updateContainer(pdfDoc(badges, printOptions));
    return  await asPdf.toBlob();
  }

  const handleConfirm = async () => {
    setLoading(true);
    const badges = await badgesWithImages(getPrintBadges());

    printOptions.output === Output.ZIP ?
        await downloadZip(badges) :
        await downloadPdf(badges)
    setLoading(false);
    setPrintModalOpen(false);
    resetSelectedBadges();
    getSiteData?.setHasSiteImage(false);
    setIncludeSiteImage(false);
  }

  const badgesWithImages = async (badges: Badge[]) => {
    const siteImageUrl = includeSiteImage && getSiteData?.data?.photo ?
      await fetchImage(getSiteData?.data?.photo) :
      undefined;    
    const stakeholder = getSiteData?.data?.stakeholder;

    return await Promise.all(
      badges.map(async (badge) => {
        let imageUrl: string;
        if (badge.resource.meta.photo) {
          imageUrl = await fetchImage(badge.resource.meta.photo);
        }

        return {
          ...badge,
          resource: {
            ...badge.resource,
            meta: {
              ...badge.resource.meta,
              photo: imageUrl || badge.resource.meta.photo
            }
          },
          site: {
            ...badge.site,
            photo: siteImageUrl,
            stakeholder: stakeholder
          }
        };
      })
    );
  };

  const fetchImage = async (imageUrl: string) => {
    const response = await fetch(imageUrl, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  const onClose = () => {
    setPrintModalOpen(false);
    setPrintOptions(defaultOptions);
    getSiteData?.setHasSiteImage(false);
  }

  const updateOptions = (key: keyof PrintOptions) => (option) => {
    setPrintOptions((prev) => ({...prev, [key]: option.value}));
  }
  const handleSiteImage = (value: boolean) => {
    getSiteData?.setHasSiteImage(value);
    setIncludeSiteImage(value);
    getSiteData.setHasSiteImage(true);
  }
  return (
    <>
      <ActionBarItem
        onClick={handleOpen}
        icon={AiOutlinePrinter}
        disabledDescription={t("noBadgesSelected")}
        description={t("printBadges")}
        isDisabled={selectedBadges.length === 0}
      />
      {printModalOpen && (
        <BaseModal
          onClose={onClose}
          onConfirm={handleConfirm}
          isLoading={loading || allBadgesLoading || getSiteData?.loading}
          type="warning"
          title={t("warning", { ns: "common" })}
        >
          {getPrintBadges()?.length === 0 ? (
            <Text>{t("printBadgesEmpty")}</Text>
          ) : (
            <Box>
              <Text>{t("printBadgesCondition")}</Text>
              <Flex gap="20px" my="20px" flexDirection={'column'}>
                {['format', 'output', 'mode'].map((key: keyof PrintOptions) => (
                  <EnumSelectField
                    key={key}
                    enumOptions={{ format: Format, output: Output, mode: TemplateMode }[key]}
                    translation={{namespace: "badges", prefix: `printOptions.${key}`}}
                    label={t(`printOptions.${key}Label`)}
                    selectProps={{
                      defaultValue: {label: t(`printOptions.${key}.${printOptions[key]}`), value: printOptions[key] as string},
                      onChange: updateOptions(key)
                    }}
                  />
                ))}
                <Checkbox isChecked={includeSiteImage} onChange={(e) => handleSiteImage(e.target.checked)}>{t('includeSiteImage', { ns: "badges" })}</Checkbox>
              </Flex>
            </Box>
          )}
        </BaseModal>
      )}
    </>
  );
}

const pdfDoc = (
  printBadges: Badge | Badge[],
  printOptions: PrintOptions
) => {
  const badges = (Array.isArray(printBadges) ? printBadges : [printBadges])
    .filter(b => b.type !== "NFC" || printOptions.mode !== TemplateMode.BACK)
  return (
    <Document>
      {printOptions.format === Format.CARD && badges.map((badge) => (printOptions.mode === TemplateMode.FRONT_BACK ?
        [TemplateMode.FRONT, TemplateMode.BACK] : [printOptions.mode]).map((mode) => (mode !== TemplateMode.BACK || badge.type !== "NFC") && (
          <Page key={badge.id+mode} size="ID1" orientation="landscape">
            <BadgeTemplate badge={badge} mode={mode} />
          </Page>
        )))}
      {printOptions.format === Format.A4 &&
        groupBadges(groupBadges(badges, 2)).map((group, index) => (
          <Page key={index} size="A4" style={{padding: "40px"}} wrap>
            {group.map((row) => (
              <View
                key={row[0].id}
                style={{
                  flexDirection: printOptions.mode === TemplateMode.FRONT_BACK ? "column" : "row",
                  gap: 10,
                  justifyContent: 'center',
                  width: "100%",
                  marginBottom:
                    printOptions.mode !== TemplateMode.FRONT_BACK ? 10 :
                      0
                }}
                wrap={false}
              >
                {row.map((badge) => (
                  <BadgeTemplate
                    key={badge.id}
                    badge={badge}
                    mode={printOptions.mode}
                    format={printOptions.format}
                  />
                ))}
              </View>
            ))}
          </Page>
        ))}
    </Document>
  )
};

const groupBadges = (badges: Badge[], items = 4) => badges.reduce((acc, badge, index) => {
  const groupIndex = Math.floor(index / items);
  if (!acc[groupIndex]) {
    acc[groupIndex] = [];
  }
  acc[groupIndex].push(badge);
  return acc;
}, []);

export default SiteBadgesPrint;