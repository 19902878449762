import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";

const SitesTopBar = () => {
  const {t} = useTranslation('sites')
  const items: TopBarItem[] = [
    {
      title: t('list', {ns: 'common'}),
      path: "/sites",
    },
  ];

  return <TopBar title={t('sites')} items={items} />;
};

export default SitesTopBar;
