import { RepeatClockIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";
import { FaCalendarXmark } from "react-icons/fa6";
import AiTaskDocument, {
  AiTaskDocumentType,
} from "../../../../domain/entities/aiTaskDocument";
import Document from "../../../../domain/entities/document";
import DocumentType from "../../../../domain/entities/documentType";
import { formatDateString } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import CustomDateInput from "../common/CustomDateInput/CustomDateInput";
import { AiFileList } from "./AiFileList";
import { SearchDocumentMenu } from "./SearchDocumentMenu";

interface AiDocumentTypeListProps {
  document: AiTaskDocumentType;
  selectedTaskFile: AiTaskDocument;
  onDateChange?: (
    id: string,
    newDate: string,
    type: "recognized" | "unrecognized",
    notExpire: boolean,
    documentFileId?: string,
    isDocument?: boolean,
  ) => void;
  removeFile: (id: string, documentId: string) => void;
  restoreFile: (id: string, documentId: string) => void;
  setSelectedTaskFile: (file: AiTaskDocument) => void;
  documentTypesForAi: DocumentType[];
  removeDocument?: (documentId: string) => void;
  restoreDocument?: (documentId: string) => void;
  handleChangeDocumentType: (
    documentId: string,
    newDocumentType: string,
  ) => void;
  showSelectSites: boolean;
  recognizedFiles: AiTaskDocumentType[]
  documentsList: Document[]
}

export const AiDocumentTypeList = ({
  document,
  selectedTaskFile,
  onDateChange,
  removeFile,
  restoreFile,
  setSelectedTaskFile,
  documentTypesForAi,
  removeDocument,
  restoreDocument,
  handleChangeDocumentType,
  showSelectSites,
  recognizedFiles,
  documentsList
}: AiDocumentTypeListProps) => {
  const { t } = useTranslation("documents");
  const [notExpire, setNotExpire] = useState(false);
  const isSite = location.pathname.includes("/sites");

  useEffect(() => {
    if (!formatDateString(document.expiresAt) || document.notExpire) {
      setNotExpire(true);
    }
  }, []);

  return (
    <Box
      _hover={{ bg: document.archived ? "red.200" : "gray.200" }}
      transition="background-color 0.2s"
      backgroundColor={document.archived ? "red.100" : "unset"}
      borderBottom="1px solid"
      borderColor={COLORS.sikuroBlue}
      borderStyle="solid"
    >
      <Flex alignItems="center" gap={1} p={2} pr={0}>
        <Flex flex="1" minWidth="0">
          <SearchDocumentMenu
            isRecognizedfile
            archived={document.archived}
            documentType={
              documentTypesForAi?.find(
                (type) =>
                  type.id === document.documentTypeId
              )?.name
            }
            documentTypesForAi={documentTypesForAi}
            textColor={
              selectedTaskFile?.id === document.documentTypeId
                ? "white"
                : COLORS.sikuroBlue
            }
            handleChangeDocumentType={(newDocumentType: DocumentType) =>
              handleChangeDocumentType(
                document.documentTypeId,
                newDocumentType.id,
              )
            }
            recognizedFiles={recognizedFiles}
            isDisabled={showSelectSites}
            documentsList={documentsList}
          />
        </Flex>

        {!isSite && (
          <>
            {!notExpire ? (
              <Flex width={120}>
                <CustomDateInput
                  value={
                    document.expiresAt ? formatDateString(document.expiresAt) : ""
                  }
                  onChange={(e) => {
                    onDateChange(
                      document.documentTypeId,
                      e,
                      "recognized",
                      false,
                      null,
                      true,
                    );
                  }}
                  name="expiresAt"
                  isClearable={false}
                  isDisabled={document.archived || showSelectSites}
                />
              </Flex>
            ) : (
              <Text fontWeight={"bold"}>
                {t("doesNotExpire", { ns: "common" })}
              </Text>
            )}
            <Tooltip
              label={t(!notExpire ? "doesNotExpire" : "disableNotExpireAi", {
                ns: "common",
              })}
            >
              <IconButton
                aria-label={"doesNotExpire"}
                variant="link"
                onClick={() => {
                  const date = document.expiresAt
                    ? new Date(document.expiresAt)
                    : new Date();
                  const newNotExpire = !notExpire;
                  setNotExpire(newNotExpire);
                  onDateChange(
                    document.documentTypeId,
                    date.toLocaleDateString("it-IT"),
                    "recognized",
                    newNotExpire,
                    null,
                    true,
                  );
                }}
                isDisabled={document.archived || showSelectSites}
                color={
                  !notExpire
                    ? "#718096"
                    : selectedTaskFile?.id === document.documentTypeId &&
                        notExpire
                      ? "white"
                      : COLORS.sikuroBlue
                }
              >
                <FaCalendarXmark />
              </IconButton>
            </Tooltip>
          </>
        )}

        <Flex justifyContent="flex-end" mb={2} pt={2}>
          <Tooltip
            label={
              document.archived
                ? t("restore", { ns: "common" })
                : t("deleteDocumentType")
            }
          >
            <IconButton
              aria-label="add"
              variant="ghost"
              color={document.archived ? COLORS.sikuroBlue : "red.500"}
              onClick={() =>
                document.archived
                  ? restoreDocument(document.documentTypeId)
                  : removeDocument(document.documentTypeId)
              }
              _hover={{
                color: document.archived ? COLORS.sikuroBlue : "red.700",
              }}
              isDisabled={showSelectSites}
            >
              {document.archived ? <RepeatClockIcon /> : <BiTrash />}
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      {document?.files?.map((file, index) => (
        <Draggable draggableId={file.id} index={index} key={file.id}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <AiFileList
                key={file.id}
                file={file}
                selectedTaskFile={selectedTaskFile}
                setSelectedTaskFile={setSelectedTaskFile}
                removeFile={(fileId: string) =>
                  removeFile(fileId, document.documentTypeId)
                }
                restoreFile={(fileId: string) =>
                  restoreFile(fileId, document.documentTypeId)
                }
                isRecognizedfile
                recognizedFiles={recognizedFiles}
                showSelectSites={showSelectSites}
                documentArchived={document.archived}
                onDateChange={(
                  newDate: string,
                  type: "recognized" | "unrecognized",
                  notExpire: boolean,
                  documentFileId?: string,
                ) =>
                  onDateChange(
                    document.documentTypeId,
                    newDate,
                    type,
                    notExpire,
                    documentFileId,
                  )
                }
                documentList={documentsList}
              />
            </div>
          )}
        </Draggable>
      ))}
    </Box>
  );
};
