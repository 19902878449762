import UseCase from '../../../core/app/usecase';
import SmartDownloadRepository, { Resource } from "../../repositories/smartDownloadRepository";
import { ResourceType } from "../../../presentation/screens/Site/ResourceSelectableTable";
import { PaginatedResults, SortMeta } from "../../entities/interfaces/paginatedResults";
import { GetSupplierResourcesFilters } from "../../../presentation/hooks/Suppliers/useSupplierResourcesViewModel";
import { ContextFiltersType } from "../../../presentation/hooks/SmartDownload/useSmartDownloadViewModel";

interface Args {
  companyId: string;
  supplierIds: string[];
  selectedAllSuppliers: boolean,
  resourceType: ResourceType;
  filter?: GetSupplierResourcesFilters,
  sort?: SortMeta,
  pageParam?:number;
  contextFilters?: ContextFiltersType,
}

class GetSupplierResources implements UseCase<Args, PaginatedResults<Resource>> {
  private smartDownloadRepository: SmartDownloadRepository;

  constructor(smartDownloadRepository: SmartDownloadRepository) {
    this.smartDownloadRepository = smartDownloadRepository;
  }
  async Call({ companyId, supplierIds, selectedAllSuppliers, resourceType, filter, sort, pageParam, contextFilters, }: Args): Promise<PaginatedResults<Resource>>{
    return await this.smartDownloadRepository.getSupplierResources(companyId, supplierIds, selectedAllSuppliers, resourceType, filter, sort, pageParam, contextFilters,);
  }
}

export default GetSupplierResources;
