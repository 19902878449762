import {
  makeGetDashboardCount,
 makeGetDashboardReportByTopic
} from "../../../core/app/factory/dashboard/usecases";
import { DashboardTopic } from "../../../domain/entities/dashboardTopic";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  DashboardCount,
} from "../../../domain/entities/dashboardCount";
import { GetSupplierEvaluationsFilter } from "../../../domain/repositories/dashboardRepository";
import {
  DashboardReport,
} from "../../../domain/entities/dasboardExpiringSupplierEvaluation";

class DashboardViewModel {
  async getDashboardCount(
    companyId: string,
    topic: DashboardTopic,
    days?: number,
  ): Promise<DashboardCount> {
    return await makeGetDashboardCount().Call({ companyId, topic, days });
  }

  async getDashboardReportByTopic(
    companyId: string,
    topic: DashboardTopic,
    pageParam: number,
    sort?: SortMeta,
    filter?: GetSupplierEvaluationsFilter,
    days?: number,
  ): Promise<DashboardReport[]> {
    return await makeGetDashboardReportByTopic().Call({
      companyId,
      topic,
      pageParam,
      sort,
      filter,
      days,
    });
  }
}

export default DashboardViewModel;
