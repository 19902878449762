import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";

const WorkingSitesTopBar = () => {
  const {t} = useTranslation('common')
  const items: TopBarItem[] = [
    {
      title: t('list'),
      path: "/workingsites",
    },
  ];

  return <TopBar title={t('exportSections.myWorkingSites')} items={items} />;
};

export default WorkingSitesTopBar;
